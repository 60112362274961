import React, { useState, useEffect, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
// import "../../admin_tools.css";
import BackIcon from "../../../../assets/communication/Back.svg";
import { TELEPHONE_DIRECTORY_DEPOT_LIST } from "../../../../routes";
import { Loader } from "@progress/kendo-react-indicators";
import { DepotForm } from "../detail/depot_form";
import { createDepot,createSubDepot } from "../../../../store/telephoneDirectory";

export const AddDepot = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {
        update_data,
        update_error,
        update_loading
    } = useSelector((state) => state.telephone_directory);

    const handleSaveDepot = (data) => {
        console.log('submitForm:',data);
        if (data.depot=== null || data.depot === 'Select Parent Depot') {
            data.depot =data.child_depot;
            data.child_depot="";
            console.log('Create Depot');
            dispatch(createDepot({ data })).then(() => {
                navigate(TELEPHONE_DIRECTORY_DEPOT_LIST)
            })
        }
        else{
            console.log('Create Sub Depot');
            dispatch(createSubDepot({ data })).then(() => {
                navigate(TELEPHONE_DIRECTORY_DEPOT_LIST)
            })
        }
        
    }
    console.log('Add Form');
    return (
        <>
            <div className="detail-body">
                {update_loading || loading ? (
                    <Loader
                        type="infinite-spinner"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            width: "40px",
                            margin: "auto",
                            justifyContent: "center",
                            height: "100vh",
                        }}
                    />
                ) : (
                    <>
                        <div className="detail-header">
                            <div className="categoryback-group-div" onClick={() => navigate(TELEPHONE_DIRECTORY_DEPOT_LIST)}>
                                <img src={BackIcon} className="categoryback-icon" />
                                <span className="categoryback-label">Back</span>
                            </div>
                        </div>
                        <div className="detail-content">
                            <DepotForm
                                data={null}
                                isEdit={true}
                                isCreate = {true}
                                submitForm={handleSaveDepot}
                                setLoading={setLoading}
                            />

                        </div>
                    </>
                )}

            </div>
        </>

    );
};