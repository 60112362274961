import React, { useState, useEffect } from "react";
// redux
import { useNavigate } from "react-router";
// kendo
import { Button } from "@progress/kendo-react-buttons";
import { TextBox } from "@progress/kendo-react-inputs";
// routes
import { USER_MANAGEMENT_USERS } from "../../../routes";

export const UserManagementForm = ({
  data,
  isEdit,
  submitForm,
  setLoading,
}) => {
  // redux
  const navigate = useNavigate();
  // state
  const [name, setName] = useState(null);
  const [sapNo, setSapNo] = useState(null);
  const [department, setDepartment] = useState(null);
  const [email, setEmail] = useState(null);
  const [interchange, setInterchange] = useState(null);
  const [corpRank, setCorpRank] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [userGroups, setUserGroups] = useState([]);

  // useEffect
  useEffect(() => {
    console.log("User Details data:", data);
    if (data) {
      setName(data.user_name);
      setSapNo(data.sap_no);
      setDepartment(data.dept_desc);
      setEmail(data.email);
      setInterchange(data.sect_desc);
      setCorpRank(data.emp_sgrp_desc);
      setIsActive(data.is_active ? "Yes" : "No");
      setDesignation(data.designation);
      setUserGroups(data.groups);
    }
  }, []);

  /*
   "data": {
        "username": "125724",
        "user_name": "Lim Kia Hian Jermyn",
        "sap_no": "00125724",
        "sect_code": "",
        "dept_desc": "Bus Sppt-Service Performance  ",
        "email": "",
        "dept_code": "",
        "sect_desc": "Scheduling     ",
        "emp_sgrp": "",
        "emp_sgrp_desc": "MSO2                ",
        "is_active": true,
        "designation": null,
        "groups": []
    }
  */

  return (
    <>
      <table>
        <tr>
          <td>Name</td>
          <td>{data.user_name}</td>
        </tr>
        <tr>
          <td>SAP No</td>
          <td>{data.sap_no}</td>
        </tr>
        <tr>
          <td>Department</td>
          <td>{data.dept_desc}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{data.email}</td>
        </tr>
        <tr>
          <td>Interchange</td>
          <td>{data.sect_desc}</td>
        </tr>
        <tr>
          <td>Corp Rank</td>
          <td>{data.emp_sgrp_desc}</td>
        </tr>
        <tr>
          <td>Account Enabled</td>
          <td>{data.is_active ? "Yes" : "No"}</td>
        </tr>
        <tr>
          <td>Designation</td>
          <td>{data.designation}</td>
        </tr>
        <tr>
          <td>User Groups</td>
          <td>
            <div className="user-edit-permission-layout">
              {data.groups
                ? data.groups.map((v) => (
                    <div className="user-form-group-item">{v.group_name}</div>
                  ))
                : null}
            </div>
          </td>
        </tr>
      </table>
      <br></br>
    </>
  );
};
