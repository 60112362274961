import React, { useState } from "react";
import { useNavigate } from "react-router";
// redux
import { useDispatch, useSelector } from "react-redux";
import { createEmailTemplate } from "../../../../../store/emailTemplate";
// kendo
import { Loader } from "@progress/kendo-react-indicators";
// routes
import { ADMIN_TOOLS_EMAIL_TEMPLATE_LIST } from "../../../../../routes";
// component
import { EmailTemplateForm } from "../form";
// image
import BackIcon from "../../../../../assets/communication/Back.svg";
// css
import "../email_template.css";

export const EmailTemplateAddNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redux
  const {
    create_email_template,
    create_email_template_error,
    create_email_template_loading,
  } = useSelector((state) => state.emailTemplate);

  // state
  const [loading, setLoading] = useState(false);

  // method
  const handleSaveTemplate = (data) => {
    console.log("handleSaveTemplate: ", data);
    dispatch(createEmailTemplate(data)).then(() => {
      setLoading(false);
      navigate(ADMIN_TOOLS_EMAIL_TEMPLATE_LIST);
    });
  };
  return (
    <>
      <div className="email-template-detail-body">
        {loading || create_email_template_loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <>
            <div className="email-template-detail-header">
              <div
                className="email-template-detail-group-div"
                onClick={() => navigate(ADMIN_TOOLS_EMAIL_TEMPLATE_LIST)}
              >
                <img src={BackIcon} className="email-template-detail-icon" />
                <span className="email-template-detail-label">Back</span>
              </div>
            </div>
            <div className="email-template-detail-content">
              <EmailTemplateForm
                id={null}
                data={null}
                isEdit={true}
                setIsEdit={true}
                setLoading={setLoading}
                submitForm={handleSaveTemplate}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
