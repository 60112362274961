export const LOGIN_ROUTE = "/";
export const REGISTER_ROUTE = "/register";
export const FORGOT_PASSWORD_ROUTE = "/forgot_password";
export const DASHBOARD_ROUTE = "/dashboard";
export const INBOX_ROUTE = "/communication/inbox";
export const NEWSFEED_ROUTE = "/communication/newsfeed";
export const ADD_NOTIFICATION_INFORMATION = "/communication/inbox/add/info";
export const NOTIFICATION_RECALL_ROUTE = "/communication/inbox/recall";
export const NOTIFICATION_RECALL_RECIPIENT_ROUTE =
  "/communication/inbox/recall/recipients";
export const MIC_ROUTE = "/mic";
export const MIC_DETAILS_ROUTE = "/mic/:id";
export const NOTIFICATION_VIEW_ROUTE = "/communication/inbox/view";
export const NOTIFICATION_VIEW_RECIPIENT_ROUTE =
  "/communication/inbox/view/recipients";

export const DR_SAFE_INCIDENT_LIST = "/dr_safe/incident/list";
export const DR_SAFE_INCIDENT_DETAILS = "/dr_safe/incident/details";
export const DR_SAFE_CONTACT_LIST = "/dr_safe/contact/list";
export const DR_SAFE_CONTACT_DETAILS = "/dr_safe/contact/details";

export const DR_SECURE_INCIDENT_LIST = "/dr_secure/incident/list";
export const DR_SECURE_INCIDENT_DETAILS = "/dr_secure/incident/details";
export const DR_SECURE_CONTACT_LIST = "/dr_secure/contact/list";
export const DR_SECURE_CONTACT_DETAILS = "/dr_secure/contact/details";

export const DR_INFRA_INCIDENT_LIST = "/dr_infra/incident/list";
export const DR_INFRA_INCIDENT_DETAILS = "/dr_infra/incident/details";
export const DR_INFRA_LOCATION_LIST = "/dr_infra/location/list";
export const DR_INFRA_SUBSYSTEM_LIST = "/dr_infra/sub_sytem/list";
export const DR_INFRA_SYSTEM_LIST = "/dr_infra/system/list";
export const DR_INFRA_CONTACT_LIST = "/dr_infra/contact/list";

export const ADMIN_TOOLS_CATEGORY_LIST = "/admin_tools/communication/category";
export const ADMIN_TOOLS_CATEGORY_DETAILS =
  "/admin_tools/communication/category/details";
export const ADMIN_TOOLS_CATEGORY_ADD =
  "/admin_tools/communication/category/add";

export const ADMIN_TOOLS_ROLE_LIST = "/admin_tools/communication/role";
export const ADMIN_TOOLS_ROLE_DETAILS =
  "/admin_tools/communication/role/details";
export const ADMIN_TOOLS_ROLE_ADD = "/admin_tools/communication/role/add";

export const ADMIN_TOOLS_DIVISION_LIST = "/admin_tools/communication/division";
export const ADMIN_TOOLS_DIVISION_DETAILS =
  "/admin_tools/communication/division/details";
export const ADMIN_TOOLS_DIVISION_ADD =
  "/admin_tools/communication/division/add";

export const ADMIN_TOOLS_MATTER_LIST = "/admin_tools/ifeedback/matter";
export const ADMIN_TOOLS_MATTER_DETAILS =
  "/admin_tools/ifeedback/matter/details";
export const ADMIN_TOOLS_MATTER_ADD = "/admin_tools/ifeedback/matter/add";

export const ADMIN_TOOLS_MIC_TYPE_OF_IMPROVEMENT_ADD =
  "/admin_tools/mic/type-of-improvement/add";
export const ADMIN_TOOLS_MIC_TYPE_OF_IMPROVEMENT_DETAILS =
  "/admin_tools/mic/type-of-improvement/details/:id";
export const ADMIN_TOOLS_MIC_TYPE_OF_IMPROVEMENT =
  "/admin_tools/mic/type-of-improvement";

export const ADMIN_TOOLS_MIC_DEPARTMENT = "/admin_tools/mic/department";
export const ADMIN_TOOLS_MIC_DEPARTMENT_ADD = "/admin_tools/mic/department/add";
export const ADMIN_TOOLS_MIC_DEPARTMENT_DETAILS =
  "/admin_tools/mic/department/details/:id";

export const IFEEDBACK_LIST = "/feedback/ifeedback/list";
export const IFEEDBACK_VIEW_ROUTE = "/feedback/ifeedback/details";

export const FEEDBACK_ON_BUS_SERVICE = "/feedback-on-bus";
export const FEEDBACK_ON_BUS_SERVICE_CHECKER = "/feedback-on-bus/bsqc-checker";
export const FEEDBACK_ON_BUS_SERVICE_CHECKER_LIST =
  "/feedback-on-bus/bsqc-checker/:id";
export const FEEDBACK_ON_BUS_SERVICE_PREFERENCE =
  "/feedback-on-bus/checker-preference";
export const FEEDBACK_ON_BUS_SERVICE_SERVICES =
  "/feedback-on-bus/selected-services";
export const FEEDBACK_ON_BUS_SERVICE_APPROVED =
  "/feedback-on-bus/approved-assignments";
export const FEEDBACK_ON_BUS_ASSIGNMENT = "/feedback-on-bus/assignment";
export const FEEDBACK_ON_BUS_SUMMARY_OF_CHECK =
  "/feedback-on-bus/summary-of-check";
export const FEEDBACK_ON_BUS_REPORTS = "/feedback-on-bus/reports";
export const FEEDBACK_ON_BUS_ASSIGNMENT_LISTING =
  "/feedback-on-bus/assignmentListing";
export const ADD_FEEDBACK_ON_BUS_ASSIGNMENT_LISTING =
  "/feedback-on-bus/assignmentListing/add";
export const FEEDBACK_ON_BUS_SUMMARY_OF_CHECK_DETAILS =
  "/feedback-on-bus/summary-of-check/details";
export const FEEDBACK_ON_BUS_SUMMARY_OF_CHECK_DETAILS_EMAIL =
  "/feedback-on-bus/summary-of-check/details/email";

export const USER_MANAGEMENT = "/user_management/";

export const USER_MANAGEMENT_USERS = "/user_management/users/";
export const USER_MANAGEMENT_USERS_DETAILS = "/user_management/users/details/";

export const USER_MANAGEMENT_GROUPS = "/user_management/groups/";
export const USER_MANAGEMENT_GROUPS_ADD = "/user_management/groups/add/";
export const USER_MANAGEMENT_GROUPS_DETAILS =
  "/user_management/groups/details/";

export const TELEPHONE_DIRECTORY_DEPOT_LIST = "/telephone_directory/depot/list";
export const TELEPHONE_DIRECTORY_DEPOT_ADD = "/telephone_directory/depot/add";
export const TELEPHONE_DIRECTORY_DEPOT_DETAILS =
  "/telephone_directory/depot/details";
export const TELEPHONE_DIRECTORY_DEPARTMENT_LIST =
  "/telephone_directory/department/list";
export const TELEPHONE_DIRECTORY_DEPARTMENT_ADD =
  "/telephone_directory/department/add";
export const TELEPHONE_DIRECTORY_DEPARTMENT_DETAILS =
  "/telephone_directory/department/details";
export const TELEPHONE_DIRECTORY_CONTACT_LIST =
  "/telephone_directory/contact/list";
export const TELEPHONE_DIRECTORY_CONTACT_ADD =
  "/telephone_directory/contact/list/add";
export const TELEPHONE_DIRECTORY_CONTACT_DETAILS =
  "/telephone_directory/contact/details";

export const ISAFE_MESSAGE = "/admin_tools/communication/isafe";

export const ADMIN_TOOLS_EMAIL_GROUP_LIST =
  "/admin_tools/feedback_bus_service/email_group";
export const ADMIN_TOOLS_EMAIL_GROUP_DETAILS =
  "/admin_tools/feedback_bus_service/email_group/details";
export const ADMIN_TOOLS_EMAIL_GROUP_ADD =
  "/admin_tools/feedback_bus_service/email_group/add";

export const ADMIN_TOOLS_EMAIL_TEMPLATE_LIST =
  "/admin_tools/feedback_bus_service/email_template";
export const ADMIN_TOOLS_EMAIL_TEMPLATE_DETAILS =
  "/admin_tools/feedback_bus_service/email_template/details";
export const ADMIN_TOOLS_EMAIL_TEMPLATE_ADD =
  "/admin_tools/feedback_bus_service/email_template/add";

export const POLICY_DOCUMENT_ROUTE = "/admin_tools/policy_document";
