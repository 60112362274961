import React from "react";
import { useNavigate } from "react-router-dom";
// kendo
import { Loader } from "@progress/kendo-react-indicators";
// components
import DrContactTable from "../../../../components/drContactTable/dr_contact_table";
// routes
import { DR_SECURE_CONTACT_DETAILS } from "../../../../routes";

const ContactListBody = ({ loading, records }) => {
  const navigate = useNavigate();

  const handleActionDropdownClicked = (event, val) => {
    console.log("handleActionDropdownClicked event:", event);
    console.log("handleActionDropdownClicked val:", val);
    const { id } = val.dataItem;
    navigate(`${DR_SECURE_CONTACT_DETAILS}?id=${id}`);
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader
          className="dr-secure-contact-list-loader"
          type="infinite-spinner"
        />
      ) : (
        <DrContactTable
          data={records}
          actionDropdownClicked={handleActionDropdownClicked}
        />
      )}
    </React.Fragment>
  );
};

export default ContactListBody;
