import React, { useState } from "react";
import { Loader } from "@progress/kendo-react-indicators";
// kendo
import { TextArea } from "@progress/kendo-react-inputs";

import moment from "moment";
import {
  getUserPermission,
  checkMultiPermission,
  IFEEDBACK_ADD_UPDATE,
  IFEEDBACK_ADD_INTERNAL_NOTE,
} from "../../../../config/permission_helper";

const shouldShowAddUpdate = () => {
  const userPermissions = getUserPermission();
  if (checkMultiPermission(userPermissions, IFEEDBACK_ADD_UPDATE)) {
    return true;
  } else {
    return false;
  }
};

const shouldShowAddInternalNote = () => {
  const userPermissions = getUserPermission();
  if (checkMultiPermission(userPermissions, IFEEDBACK_ADD_INTERNAL_NOTE)) {
    return true;
  } else {
    return false;
  }
};

export const IFeedbackDetailsUpdates = ({updates, saveNewUpdates, update_loading, internalNotes, saveNewInternalNotes, notes_loading }) => {
    const [newUpdates,setNewUpdates] = useState("");
    const [newInternalNotes,setNewInternalNotes] = useState("");
    console.log('Detail Updates',updates,'update_loading',update_loading)
    const onUpdatesChange = (e) => {setNewUpdates(e.target.value);};
    const onNotesChange = (e) => {setNewInternalNotes(e.target.value);};
    const btnAddUpdatesClicked = () => {
        saveNewUpdates(newUpdates);
      };
    const btnAddInternalNotesClicked = () => {
      saveNewInternalNotes(newInternalNotes)
    }
    const detailsUpdates = (props) => (
    <div className="dr-safe-details-updates-single-div-wrapper">
        <div className="dr-safe-details-updates-single-div">
        <span className="dr-safe-details-updates-single-username">
            {props.created_by.name}
            {console.log('detailsUpdates',props)}
        </span>
        {props.role && (
            <span className="dr-safe-details-updates-single-role">
            {props.role}
            </span>
        )}
        </div>
        <div className="dr-safe-details-updates-single-div">
        <span className="dr-safe-details-updates-single-datetime">
            {moment(props.created_on).format("DD MMM YYYY, hh:mm A")}
        </span>
        </div>
        <div>
        <span className="dr-safe-details-updates-single-description">
            {props.description}
        </span>
        </div>
        <div>
        </div>
    </div>
    );
    const detailsInternalNotes = (props) => (
      <div className="dr-safe-details-updates-single-div-wrapper">
          <div className="dr-safe-details-updates-single-div">
          <span className="dr-safe-details-updates-single-username">
              {props.created_by.name}
              {console.log('details internalnotes',props)}
          </span>
          {props.role && (
              <span className="dr-safe-details-updates-single-role">
              {props.role}
              </span>
          )}
          </div>
          <div className="dr-safe-details-updates-single-div">
          <span className="dr-safe-details-updates-single-datetime">
              {moment(props.created_on).format("DD MMM YYYY, hh:mm A")}
          </span>
          </div>
          <div>
          <span className="dr-safe-details-updates-single-description">
              {props.description}
          </span>
          </div>
          <div>
          </div>
      </div>
      );
    return (
        <div className="ifeedback-details-updates-wrapper">
        <div className="ifeedback-details-updates-title">Internal notes</div>
      {notes_loading ? (
        <Loader
          type="infinite-spinner"
          className="ifeedback-details-update-loader"
        />
      ) : (
        <React.Fragment>          
          {internalNotes.length === 0 && <p>No available internal notes.</p>}
          {internalNotes.length > 0 && (
            <div className="ifeedback-details-updates-list-wrapper">              
              {internalNotes.map((upd, ind) => {
                return detailsInternalNotes(upd);
              })}
            </div>
          )}
          { shouldShowAddInternalNote() && (
          <div className="ifeedback-details-updates-input-wrapper">
            <p className="ifeedback-details-updates-input-wrapper-title">
              Add Internal notes
            </p>
            <TextArea
              className="ifeedback-details-updates-input"
              onChange={onNotesChange}
            />
            <div
              className="ifeedback-details-updates-input-wrapper-btn"
              onClick={btnAddInternalNotesClicked}
            >
              Add
            </div>
          </div>
          )}
        </React.Fragment>
      )}
      <div><p></p></div>
      <div className="ifeedback-details-updates-title">Updates</div>
      {update_loading ? (
        <Loader
          type="infinite-spinner"
          className="ifeedback-details-update-loader"
        />
      ) : (
        <React.Fragment>          
          {updates.length === 0 && <p>No available updates.</p>}
          {updates.length > 0 && (
            <div className="ifeedback-details-updates-list-wrapper">              
              {updates.map((upd, ind) => {
                return detailsUpdates(upd);
              })}
            </div>
          )}
          { shouldShowAddUpdate() && (
          <div className="ifeedback-details-updates-input-wrapper">
            <p className="ifeedback-details-updates-input-wrapper-title">
              Add Updates
            </p>
            <TextArea
              className="ifeedback-details-updates-input"
              onChange={onUpdatesChange}
            />
            <div
              className="ifeedback-details-updates-input-wrapper-btn"
              onClick={btnAddUpdatesClicked}
            >
              Add
            </div>
          </div>
          )}
        </React.Fragment>
      )}
    </div>
    );
};
