// kendo
import { Loader } from "@progress/kendo-react-indicators";

export const IFeedbackDetailsAudit = ({ audits, loading }) => {
  return (
    <div className="ifeedback-details-audit-wrapper">
      <div className="ifeedback-details-audit-title">Activity</div>
      {loading ? (
        <Loader
          type="infinite-spinner"
          className="dr-safe-details-status-loader"
        />
      ) : (
        <>
          {audits.length === 0 && (
            <p className="ifeedback-details-audit-no-activity">No activity</p>
          )}
          <ul class={"ifeedback-details-audit-content"}>
            {audits.map(
              ({ created_by: { name }, pk, desc, changed_at }, idx) => (
                <div
                  class={`ifeedback-details-audit-content-item ${
                    audits?.length - 1 === idx ? "current" : "done"
                  } is-done`}
                >
                  <strong>{desc}</strong>
                  <div className="username-action">by {name}</div>
                  <div>{changed_at}</div>
                </div>
              )
            )}
          </ul>
        </>
      )}
    </div>
  );
};
