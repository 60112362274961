import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox, TextBox } from "@progress/kendo-react-inputs";
import { Button } from "bootstrap";
import React from "react";

export const AssignmentTableFilter = (props) => {
  return (
    <Dialog
      className="search-notification"
      title={" "}
      width={395}
      height={"100%"}
      onClose={() => props.setShowFilter(false)}
    >
      <div className="search-body">
        <div className="filter-title">
          <div className="title">Filter</div>
          <div className="clear-filter" onClick={props.ClearFilter}>
            <u>Clear Filter</u>
          </div>
        </div>
        <div className="dialog-row">
          <span className="search-row-title">Feedback Scheme</span>
          <div className="category-flex-row">
            <DropDownList
              className="search-input"
              value={props.editFilter.feedbackScheme}
              data={["BSQC", "MRCBC"]}
              onChange={(e) =>
                props.onEditFilterChange("feedbackScheme", e.target.value)
              }
              placeholder="---Please select incident type---"
            />
          </div>
        </div>
        <div className="dialog-row" style={{ height: "80%" }}>
          <span className="search-row-title">Assigned to</span>
          <DropDownList
            className="search-input"
            // value={props.editFilter.assigned_to}
            style={{ width: "100%" }}
            data={props.checker}
            dataItemKey="username"
            textField="name"
            onChange={(e) =>
              props.onEditFilterChange("assigned_to", e.target.value.name)
            }
            placeholder="---Please select incident type---"
          />
        </div>
        <div className="dialog-row" style={{ height: "80%" }}>
          <span className="search-row-title">Service No.</span>
          <TextBox
            className="search-input"
            onChange={(e) =>
              props.onEditFilterChange("serviceNo", e.target.value)
            }
            style={{ width: "100%" }}
            value={props.editFilter.serviceNo}
          />
        </div>
        <div className="dialog-row" style={{ height: "80%" }}>
          <span className="search-row-title">Direction</span>
          <DropDownList
            className="search-input"
            value={props.editFilter.direction}
            style={{ width: "100%" }}
            data={[1, 2]}
            onChange={(e) =>
              props.onEditFilterChange("direction", e.target.value)
            }
          />
        </div>
        <div className="dialog-row" style={{ height: "80%" }}>
          <span className="search-row-title">Bus Stop Code</span>
          <TextBox
            className="search-input"
            onChange={(e) =>
              props.onEditFilterChange("busStopCode", e.target.value)
            }
            style={{ width: "100%" }}
            value={props.editFilter.busStopCode}
          />
        </div>
        <div className="dialog-row" style={{ height: "80%" }}>
          <span className="search-row-title">Assigned BC</span>
          <TextBox
            className="search-input"
            onChange={(e) =>
              props.onEditFilterChange("assignedBC", e.target.value)
            }
            style={{ width: "100%" }}
            value={props.editFilter.assignedBC}
          />
        </div>
      </div>
      <DialogActionsBar>
        <div
          className="search-button cancel-btn"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => props.setShowFilter(false)}
        >
          Cancel
        </div>
        <div
          className="search-button search-btn"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => props.onSubmitFilter()}
          //   disabled={interchange_loading || depot_loading || designation_loading}
        >
          Submit
        </div>
      </DialogActionsBar>
    </Dialog>
  );
};
