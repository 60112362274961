import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import RefreshIcon from "../../../assets/header/refresh.svg";
import ExportIcon from "../../../assets/header/export.svg";
import SelectOption from "../bus-checker-periods/components/select-options";
import { CustomDatePicker } from "../../../components/custom/datepicker";
import ModalStatus from "../../../components/modal-status";
import Table from "../bus-checker-periods/partials/list-of-check-period/table";
import Modal from "../bus-checker-periods/partials/list-of-check-period/components/modal";
import ModalCheckerPrefDetail from "./partials/checker-pref-detail";

import { useCheckerPreference } from "./actions";
import "./checker-preference.css";
import { useMasterData } from "../bus-checker-periods/actions";
import moment from "moment";
import { useState, useMemo } from "react";
import { getHeaders } from "./constant";
import { Confirmation } from "../../../components/dialog/confirmation";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";

const Page = () => {
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [select, setSelect] = useState({});
  const [approveModal, setApproveModal] = useState(false);

  const onToggleUpdateModal = () => setIsUpdateMode((prev) => !prev);

  useMasterData();
  const {
    exportRef,
    exportData,
    id,
    isApproved,
    list,
    isFetching,
    isAbleShowList,
    success,
    errors,
    selectPeriodPublish,
    bsqcPeriodPublish,
    onSelectPeriod,
    onCloseSuccessModal,
    onCloseErrorsModal,
    onApproved,
    onExport,
    GetCheckerList,
    GetApprovedCheckerList,
    refetch,
  } = useCheckerPreference();

  const HEADER = useMemo(
    () => getHeaders(selectPeriodPublish?.data?.name),
    [selectPeriodPublish?.data?.name]
  );

  return (
    <div className="checker-preference">
      <div className="container">
        <div className="checker-preference-container">
          <div className="checker-preference-headers">
            <h2>Feedback on Bus Service</h2>
            {isAbleShowList && (
              <div className="filter-group">
                <Button
                  className="custom-button"
                  onClick={() => isApproved? GetApprovedCheckerList(id): GetCheckerList(id)}
                >
                  <img
                    style={{ width: 20, height: 20 }}
                    src={RefreshIcon}
                    alt="refresh-icon"
                  />
                </Button>
                <CSVLink
                  uFEFF={false}
                  asyncOnClick={true}
                  data={exportData}
                  filename="export-checker-preference"
                  ref={exportRef}
                  hidden
                >
                  Download
                </CSVLink>
                {isApproved &&
                  checkPermission(
                    getUserPermission(),
                    "fob.bsqc_export_checker_preference"
                  ) && (
                    <Button className="custom-button" onClick={onExport}>
                      <img
                        style={{ width: 20, height: 20 }}
                        src={ExportIcon}
                        alt="export-icon"
                      />
                    </Button>
                  )}
              </div>
            )}
          </div>
        </div>
        <div className={`form-date-select`} style={{ marginTop: 40 }}>
          <div className="input-group">
            <div className="input-date-label">
              <span>
                <span style={{ fontWeight: "bold" }}>BSQC Check Period</span>
              </span>
            </div>
            <SelectOption
              className="check-period-options"
              title={selectPeriodPublish?.label ?? "Select BSQC Check Period"}
              select={selectPeriodPublish}
              options={bsqcPeriodPublish}
              onSelect={onSelectPeriod}
              inputStyle={{ minHeight: 30, margin: "unset" }}
            />
          </div>
          {isAbleShowList && (
            <div>
              <div className="input-date-label">
                <span>
                  <span style={{ fontWeight: "bold" }}>
                    BSQC Check Preference Selection Period
                  </span>
                </span>
              </div>
              <div
                className={
                  isApproved
                    ? "form-group-approval-no-btn-approval"
                    : "form-group-approval"
                }
              >
                <div className="input-group">
                  <DatePicker
                    disabled={isFetching}
                    className="filter-date-range"
                    // format={"dd MMM yyyy"}
                    dateFormat={"dd/MM/yyyy"}
                    selected={moment(
                      selectPeriodPublish?.data?.start_date
                    ).toDate()}
                    // onChange={(date)=> onChangeDate(date, 'start')}
                    customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                  />
                </div>

                <div className="input-group">
                  <DatePicker
                    disabled={isFetching}
                    className="filter-date-range"
                    // format={"dd MMM yyyy"}
                    dateFormat={"dd/MM/yyyy"}
                    selected={moment(
                      selectPeriodPublish?.data?.end_date
                    ).toDate()}
                    // onChange={(date)=> onChangeDate(date, 'end')}
                    customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                  />
                </div>

                {!isApproved &&
                  checkPermission(
                    getUserPermission(),
                    "fob.bsqc_approve_checker_preference"
                  ) && (
                    <button
                      onClick={() => setApproveModal(true)}
                      className="button-approved cursor-pointer"
                      disabled={isFetching}                                        
                    >
                      {"Approve"}
                    </button>
                  )}
              </div>
            </div>
          )}
        </div>
        {approveModal && (
          <Confirmation
            title={"Approve Check Period"}
            message="Are you sure your want to approve this check period."
            onClickNo={() => setApproveModal(false)}
            onClickYes={() => {
              setApproveModal(false);
              onApproved();
            }}
          />
        )}

        <div style={{ marginTop: 20 }}>
          {isFetching ? (
            <div style={{ width: "100%" }}>
              <Loader
                type="infinite-spinner"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "40px",
                  margin: "auto",
                  justifyContent: "center",
                  height: "100vh",
                }}
              />
            </div>
          ) : (
            isAbleShowList && (
              <Table
                columns={HEADER}
                data={list}
                permission={"fob.bsqc_can_assign_checker_preference"}
                approve={isApproved}
                onAction={(data) => {
                  setIsUpdateMode(true);
                  setSelect(data);
                  console.log("action: ", data);
                }}
              />
            )
          )}
        </div>
        <ModalCheckerPrefDetail
          data={{ id, cognito_username: select?.cognito_username, ...select }}
          isOpen={isUpdateMode}
          onToggle={onToggleUpdateModal}
          onSubmit={() => {
            setTimeout(() => {
              refetch();
            }, 1000);
          }}
        />

        <ModalStatus
          isOpen={!!success}
          message={success}
          type={"success"}
          onClose={onCloseSuccessModal}
        />

        <Modal
          width={400}
          height={200}
          isOpen={!!errors}
          onClose={onCloseErrorsModal}
        >
          <h2>{errors}</h2>
        </Modal>
      </div>
    </div>
  );
};

export default Page;
