import { useMemo } from 'react';
import './button.css';

const Component = props => {
  const classButton = useMemo(
    () => {
      if (props?.secondary) return 'button-secondary'

      return 'button-primary'
    },
    [props?.secondary]
  );

  return (
    <button className={`button ${classButton}`} {...props}>
      {props?.children}
    </button>
  )
}

export default Component;
