import React, { useState, useEffect } from "react";
// redux
import { useNavigate } from "react-router";
// kendo
import { Button } from "@progress/kendo-react-buttons";
import { TextBox, TextArea } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
// routes
import { ADMIN_TOOLS_EMAIL_TEMPLATE_LIST } from "../../../../../routes";

export const EmailTemplateForm = ({
  id: email_template_id,
  data,
  isEdit,
  submitForm,
  setLoading,
}) => {
  // redux
  const navigate = useNavigate();
  // state
  const [subject, setSubject] = useState(null);
  const [content, setContent] = useState(null);
  const [type, setType] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const allTypes = [
    {
      id: "BSQC",
      text: "Bus Service Quality Check",
    },
  ];

  // methods
  const cancelForm = () => {
    console.log(data);
    if (data & isEdit) {
      // setIsEdit(true);
    } else {
      navigate(ADMIN_TOOLS_EMAIL_TEMPLATE_LIST);
    }
  };

  const submitData = () => {
    setLoading(true);
    let submit_data = {
      subject,
      content,
      type,
    };
    if (email_template_id) {
      submit_data["id"] = email_template_id;
    }
    submitForm(submit_data);
  };

  // useEffect
  useEffect(() => {
    console.log("Template Details data:", data);
    if (data) {
      setSubject(data.subject);
      setType(data.type);
      setContent(data.content);
    }
  }, []);

  useEffect(() => {
    if (subject && content && type) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [subject, content, type]);

  return (
    <>
      <table>
        <tr>
          <td>Email Type</td>
          {isEdit ? (
            <td>
              <DropDownList
                data={allTypes}
                textField="text"
                dataItemKey="id"
                defaultValue={allTypes.filter((x) => x.id === type)[0]}
                onChange={(e) => setType(e.target.value.id)}
              />
            </td>
          ) : (
            <td>{data.type}</td>
          )}
        </tr>
        <tr>
          <td>Email Subject</td>
          {isEdit ? (
            <td>
              <TextBox
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </td>
          ) : (
            <td>{data.subject}</td>
          )}
        </tr>
        <tr>
          <td>Email Content</td>
          {isEdit ? (
            <td>
              <TextArea
                className="email-template-text-area"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </td>
          ) : (
            <td style={{ whiteSpace: "pre-wrap" }}>{data.content}</td>
          )}
        </tr>
        {isEdit && (
          <tr>
            <td colSpan={2}>
              <div className="email-template-detail-button-div">
                <Button
                  className="email-template-detail-button email-template-detail-cancel"
                  onClick={cancelForm}
                >
                  Cancel
                </Button>
                <Button
                  className="email-template-detail-button email-template-detail-submit"
                  onClick={submitData}
                  disabled={disableSubmit}
                >
                  Submit
                </Button>
              </div>
            </td>
          </tr>
        )}
      </table>
    </>
  );
};
