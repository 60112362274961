import { useGetSummaryCountQuery } from "../../actions";

import "./summary-count.css"

const Component = (props) => {
  return (
    <div className="summary-count">
      <div className="card">
        <h1 className="result">{props?.total_new ?? "-"}</h1>
        <div className="title">New</div>
      </div>

      <div className="card">
        <h1 className="result">{props?.total_closed ?? "-"}</h1>
        <div className="title">Closed</div>
      </div>
      <div className="card">
        <h1 className="result">{props?.total_ack ?? "-"}</h1>
        <div className="title">Acknowledged</div>
      </div>
      <div className="card">
        <h1 className="result">{props?.total_count ?? "-"}</h1>
        <div className="title">All</div>
      </div>
    </div>
  )
}

export default Component;
