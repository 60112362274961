import Modal from "../../components/modal";

const Component = (props) => {
  const onConfirm = () => {
    if (typeof props?.onConfirm === "function") {
      props?.onConfirm();
    }
  };

  return (
    <Modal
      isOpen={props?.isOpen}
      onSubmit={onConfirm}
      onClose={props?.onClose}
      width={350}
      height={180}
      actionComponent={
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            columnGap: 20,
          }}
        >
          <button onClick={props?.onClose} className="button-cancel">
            Cancel
          </button>

          <button onClick={onConfirm} className="button-submit">
            {props?.loading ? "Loading ..." : "Delete"}
          </button>
        </div>
      }
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{ fontSize: "20px", fontStyle: "normal", fontWeight: "bold" }}
        >
          Proceed to delete this item?
        </div>
        <h4>{props?.message ?? "Want to delete this BSQC Period?"}</h4>
      </div>
    </Modal>
  );
};

export default Component;
