import React, { useState, useRef, useEffect } from "react";

import { Button } from "@progress/kendo-react-buttons";
import { useLocation, useNavigate } from "react-router";

import "./table.css";
import ExportIcon from "../../assets/header/export.svg";
import RefreshIcon from "../../assets/header/refresh.svg";
import FilterIcon from "../../assets/header/filter.svg";
import SearchIcon from "../../assets/header/search.svg";
import { CSVLink } from "react-csv";

import { exportToCSVNotifications } from "../../store/notification";
import { useDispatch } from "react-redux";

export const TableHeader = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const exportLink = useRef(null);
  const [loading, setLoading] = useState(false);
  const [exportData, setExportData] = useState([]);

  useEffect(() => {
    if (exportData.length > 0) {
      console.log("ExportExcel useEffect", exportData);
      exportLink.current.link.click();
    }
  }, [exportData]);

  const onClickAddButton = () => {
    const { addurl } = props;
    navigate(addurl);
  };

  const onClickExportButton = () => {
    if (typeof props?.onExport === "function") return props?.onExport();

    setLoading(true);
    let data = {};
    if (query.get("title")) data.title = query.get("title");
    if (query.get("created_by_name"))
      data.created_by_name = query.get("created_by_name");
    if (query.get("start_date")) data.start_date = query.get("start_date");
    if (query.get("end_date")) data.end_date = query.get("end_date");
    if (query.get("created_on_start"))
      data.created_on_start = query.get("created_on_start");
    if (query.get("created_on_end"))
      data.created_on_end = query.get("created_on_end");
    if (query.get("status")) data.status = JSON.parse(query.get("status"));
    if (query.get("sort_by")) data.sort_by = query.get("sort_by");
    if (query.get("order")) data.order = query.get("order");
    if (query.get("next_key"))
      data.next_key = JSON.parse(query.get("next_key", {}));
    if (query.get("category_id"))
      data.category_id = JSON.parse(query.get("category_id"));
    dispatch(exportToCSVNotifications(data)).then((res) => {
      console.log(res.payload);
      let data = res.payload;
      console.log(data);
      if (data.length > 0) {
        const total_read_index = data[0].indexOf("Total Read");
        data = data.map((v) => {
          const new_data = v.map((new_v, i) =>
            i === total_read_index && new_v !== "Total Read"
              ? `""${new_v}""`
              : new_v
          );
          return new_data;
        });
        console.log("exportToCSVNotifications data:", data);
      }
      setExportData(data);
      setLoading(false);
      //
    });
  };

  return (
    <div className="table-header">
      <div className="table-header-div">
        <p className="table-name">{props.title}</p>
        <div className="buttons_group">
          {!props.hideaddbtn && (
            <Button
              className="addnew_btn"
              onClick={onClickAddButton}
              style={{ borderRadius: 8 }}
            >
              + Add New
            </Button>
          )}
          {"hideSyncUsers" in props && !props.hideSyncUsers && (
            <Button
              title="Sync"
              className="addnew_btn"
              onClick={props.onSyncAction}
              disabled={props.showSyncBtnLoading}
            >
              {props.showSyncBtnLoading ? (
                <span>Loading</span>
              ) : (
                <span>Sync user info</span>
              )}
            </Button>
          )}
          {!props.hiderefreshbtn && (
            <Button
              onClick={props?.onRefresh}
              title="Refresh"
              className="button2"
            >
              <img src={RefreshIcon} alt="refresh-icon" />
            </Button>
          )}
          {props.showNotificationExport && (
            <>
              {typeof props?.onExport !== "function" && (
                <CSVLink
                  uFEFF={false}
                  asyncOnClick={true}
                  data={exportData}
                  filename="export_notification"
                  ref={exportLink}
                  hidden
                >
                  Download
                </CSVLink>
              )}
              <Button
                disabled={loading}
                onClick={onClickExportButton}
                className="button2"
              >
                <img src={ExportIcon} alt="copy-icon" />
              </Button>
            </>
          )}
          {!props.hideFilterBtn && (
            <Button onClick={props?.onFilter} className="button2">
              <img src={FilterIcon} alt="filter" />
            </Button>
          )}
          {!props.hideSearchBtn && (
            <Button onClick={props?.onSearch} className="button2">
              <img src={SearchIcon} alt="search-icon" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
