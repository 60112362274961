import React, { useState,useEffect } from "react";
import { Navigate, useNavigate,useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { TableHeader } from "../../../../components/table/Tableheader";
import { DivisionTableBody } from "../../../../components/table/divisionTable";

import { Loader } from "@progress/kendo-react-indicators";
import { fetchAllDivisions,deleteDivision } from "../../../../store/division";
import { ADMIN_TOOLS_DIVISION_ADD, ADMIN_TOOLS_DIVISION_LIST } from "../../../../routes";
import { FilterDivision } from "../../../../components/dialog/division_filter";
import { SearchDivision } from "../../../../components/dialog/division_search";

import moment from "moment";

export const DivisionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [division_ids,setDivision_ids] = useState([]);
  const [all_divisions, setAllDivisions] = useState([]);
  const {loading, error} = useSelector((state)=> state.division)
  const [showFilterDialog,setShowFilterDialog ] = useState(false);
  const [showSearchDialog ,setShowSearchDialog ] = useState(false);

  useEffect(()=>{
    let results = all_divisions;
    console.log(all_divisions);
    if(query.toString()){
      if(query.get("created_on_start")){
        const formatted_created_on = new Date(moment(query.get("created_on_start")))
        results = results.filter((v)=> new Date(moment(v.created_on,"DD MMM YYYY,hh:mma"))>= formatted_created_on)
      }
      if(query.get("created_on_end")){
        const formatted_created_end = new Date(moment(query.get("created_on_end")).hours(23).minutes(59).seconds(59))
        results = results.filter((v)=> new Date(moment(v.created_on,"DD MMM YYYY,hh:mma"))<= formatted_created_end)
      }
      if(query.get("updated_on_start")){
        const formatted_updated_start = new Date(moment(query.get("updated_on_start")))
        results = results.filter((v)=> new Date(moment(v.updated_on,"DD MMM YYYY,hh:mma"))>= formatted_updated_start)
      }
      if(query.get("updated_on_end")){
        const formatted_updated_end = new Date(moment(query.get("updated_on_end")).hours(23).minutes(59).seconds(59))
        results = results.filter((v)=> new Date(moment(v.updated_on,"DD MMM YYYY,hh:mma"))<= formatted_updated_end)
      }
      if(query.get("name")){
        results = results.filter((v)=> v.name.toLowerCase().indexOf(query.get("name").toLowerCase())>-1)
      }
      if(query.get("created_by_name")){
        results = results.filter((v)=> v.created_by.name.toLowerCase().indexOf(query.get("created_by_name").toLowerCase())>-1)
      }
    }
    setDivision_ids(results);
  },[all_divisions]);

  useEffect(() => {
    dispatch(fetchAllDivisions()).then((res)=>{
      setAllDivisions(res.payload);
    });
  }, []);
  const handleDeleteAction = ((id)=>{
    dispatch(deleteDivision(id)).then(()=>{
      dispatch(fetchAllDivisions()).then((res)=>{
        setAllDivisions(res.payload);
      });
    })
  })
  const handleFilterCallback = (data)=>{
    const {
      created_on_start,
      created_on_end,
      updated_on_start,
      updated_on_end,
    } = data;
    query.delete("filter");
    query.set("created_on_start",new Date(created_on_start)? created_on_start: "");
    query.set("created_on_end",new Date(created_on_end)? created_on_end: "");
    query.set("updated_on_start",new Date(updated_on_start)? updated_on_start: "");
    query.set("updated_on_end",new Date(updated_on_end)? updated_on_end: "");
    if(created_on_start || created_on_end || updated_on_start || updated_on_end){
      query.set("filter",true);
    }
    navigate(ADMIN_TOOLS_DIVISION_LIST+"?"+ query.toString());
    setShowFilterDialog(false);
    handleFilterAction();
  }
  const handleFilterAction = ()=>{
    let results = all_divisions;
    
    if(query.toString()){
      if(query.get("created_on_start")){
        const formatted_created_on = new Date(moment(query.get("created_on_start")))
        results = results.filter((v)=> new Date(moment(v.created_on,"DD MMM YYYY,hh:mma"))>= formatted_created_on)
      }
      if(query.get("created_on_end")){
        const formatted_created_end = new Date(moment(query.get("created_on_end")).hours(23).minutes(59).seconds(59))
        results = results.filter((v)=> new Date(moment(v.created_on,"DD MMM YYYY,hh:mma"))<= formatted_created_end)
      }
      if(query.get("updated_on_start")){
        const formatted_updated_start = new Date(moment(query.get("updated_on_start")))
        results = results.filter((v)=> new Date(moment(v.updated_on,"DD MMM YYYY,hh:mma"))>= formatted_updated_start)
      }
      if(query.get("updated_on_end")){
        const formatted_updated_end = new Date(moment(query.get("updated_on_end")).hours(23).minutes(59).seconds(59))
        results = results.filter((v)=> new Date(moment(v.updated_on,"DD MMM YYYY,hh:mma"))<= formatted_updated_end)
      }
      if(query.get("name")){
        results = results.filter((v)=> v.name.toLowerCase().indexOf(query.get("name").toLowerCase())>-1)
      }
      if(query.get("created_by_name")){
        results = results.filter((v)=> v.created_by.name.toLowerCase().indexOf(query.get("created_by_name").toLowerCase())>-1)
      }
    }
    setDivision_ids(results);
  }
  const handleSearchCallback = (data)=>{
    const {
      name,
      created_by_name
    } = data;
    query.delete("search");
    query.set("name",name);
    query.set("created_by_name",created_by_name);
    if(name || created_by_name){
      query.set("search",true);
    }
    navigate(ADMIN_TOOLS_DIVISION_LIST+"?"+ query.toString());
    setShowSearchDialog(false);
    handleFilterAction();
  }
  const handleClearFilterOptions = ()=>{
    query.delete("filter");
    query.delete("created_on_start");
    query.delete("created_on_end");
    query.delete("updated_on_start");
    query.delete("updated_on_end");
    
    navigate(ADMIN_TOOLS_DIVISION_LIST+"?"+query.toString());
    handleFilterAction();
  }
  const handleClearSearchOptions = ()=>{
    query.delete("search");
    query.delete("name");
    query.delete("created_by_name");
    navigate(ADMIN_TOOLS_DIVISION_LIST+"?"+query.toString());
    handleFilterAction();
  }  
  return (
    <div className="body">
      {showFilterDialog && (
        <FilterDivision
          setShowFilterDialog={setShowFilterDialog}
          onFiltercallback={handleFilterCallback}          
        />
      )}
      {showSearchDialog && (
        <SearchDivision
          setShowSearchDialog={setShowSearchDialog}
          onSearchcallback={handleSearchCallback}
        />
      )}
      <TableHeader 
        title = "Manage Division"
        loading={loading}
        onRefresh={
          ()=> {
            dispatch(fetchAllDivisions()).then((res)=>{
              setAllDivisions(res.payload);
            })
          }
        }
        onFilter={() => setShowFilterDialog(true)}
        onSearch={() => setShowSearchDialog(true)}
        addurl={ADMIN_TOOLS_DIVISION_ADD}
      />
      {(query.get("filter") || query.get("search")) && (
      <div className="table-filter-div">
        {query.get("filter")&& (
        <div className="table-filter-item">
          <div className="table-filter-title">Filter applied</div>
          <span className="table-filter-clear" onClick={handleClearFilterOptions} >Clear filter</span>
        </div>
        )}
        {query.get("search") && (
        <div className="table-filter-item">
          <div className="table-filter-title">Keyword search applied</div>
          <span className="table-filter-clear" onClick={handleClearSearchOptions}>Clear search</span>
        </div>
        )}
      </div>
      )}       
      {loading ? (
      <Loader
        type="infinite-spinner"
        style={{
          display: "flex",
          alignItems: "center",
          width: "40px",
          margin: "auto",
          justifyContent: "center",
          height: "100vh",
        }}
      />
      ):(
        <DivisionTableBody 
        data={division_ids}
        handleDeleteAction={handleDeleteAction}
        />
      )}
    </div>
  );
};