import { useEffect, useState } from "react";
import { Navigate, useNavigate,useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { ContactListHeader } from './contact_list_header';
import '../../telephone_directory.css';
import ContactListBody from './contact_list_body';
import { fetchAllContacts, deleteContact } from '../../../../store/telephoneDirectory';
import { TELEPHONE_DIRECTORY_CONTACT_ADD, TELEPHONE_DIRECTORY_CONTACT_LIST } from '../../../../routes';
import { ContactSearch } from './contact_search';

import {
  getUserPermission,
  checkMultiPermission,
} from "../../../../config/permission_helper";
import {
  TEL_DIR_PERMISSIONS_FOR_ADD_DIRECTORY,
  TEL_DIR_PERMISSIONS_FOR_DELETE_DIRECTORY,
} from "../../../../config/permission_helper";

export const ContactList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const [directoryList, setDirectoryList] = useState([]);
    const { contactList, contact_loading, contact_error } = useSelector(
        (state) => state.telephone_directory);
    const [showSearchDialog ,setShowSearchDialog ] = useState(false);
    console.log('Fetch All Departments--',contactList);
    useEffect(() => {
    if (contactList) {
        setDirectoryList(contactList);
    }
    }, [contactList]);
    useEffect(() => {
        dispatch(fetchAllContacts());
    }, []);
    const handleDeleteAction = ((id)=>{
        dispatch(deleteContact(id)).then(()=>{
          dispatch(fetchAllContacts()).then((res)=>{
            setDirectoryList(res.payload);
          });
        })
      });
    console.log('data from db ',contactList);
    console.log('data from const ',directoryList);
    const handleSearchCallback = (data)=>{
      console.log('Search DATA>>>>>',data);
      const {
        name,
        contact_number,
        department
      } = data;
      query.delete("search");
      query.set("name",name);
      query.set("contact_number",contact_number);
      query.set("department",department);
      if(name || contact_number || department){
        query.set("search",true);
      }
      navigate(TELEPHONE_DIRECTORY_CONTACT_LIST+"?"+ query.toString());
      setShowSearchDialog(false);
      handleFilterAction();
    };
    const handleFilterAction = ()=>{
      console.log("handleFilterAction",contactList)
      let results = contactList;
      
      if(query.toString()){
        // if(query.get("created_on_start")){
        //   const formatted_created_on = new Date(moment(query.get("created_on_start")))
        //   results = results.filter((v)=> new Date(moment(v.created_on,"DD MMM YYYY,hh:mma"))>= formatted_created_on)
        // }
        // if(query.get("created_on_end")){
        //   const formatted_created_end = new Date(moment(query.get("created_on_end")).hours(23).minutes(59).seconds(59))
        //   results = results.filter((v)=> new Date(moment(v.created_on,"DD MMM YYYY,hh:mma"))<= formatted_created_end)
        // }
        // if(query.get("updated_on_start")){
        //   const formatted_updated_start = new Date(moment(query.get("updated_on_start")))
        //   results = results.filter((v)=> new Date(moment(v.updated_on,"DD MMM YYYY,hh:mma"))>= formatted_updated_start)
        // }
        // if(query.get("updated_on_end")){
        //   const formatted_updated_end = new Date(moment(query.get("updated_on_end")).hours(23).minutes(59).seconds(59))
        //   results = results.filter((v)=> new Date(moment(v.updated_on,"DD MMM YYYY,hh:mma"))<= formatted_updated_end)
        // }
        if(query.get("name")){
          results = results.filter((v)=> v.contact_name.toLowerCase().indexOf(query.get("name").toLowerCase())>-1)
        }
        if(query.get("contact_number")){
          results = results.filter((v)=> v.contact_number.toLowerCase().indexOf(query.get("contact_number").toLowerCase())>-1)
        }
        if(query.get("department")){
          results = results.filter((v)=> v.department.toLowerCase().indexOf(query.get("department").toLowerCase())>-1)
        }
      }
      setDirectoryList(results);
    };
    const handleClearSearchOptions = ()=>{
      query.delete("search");
      query.delete("name");
      query.delete("contact_number");
      query.delete("department");
      navigate(TELEPHONE_DIRECTORY_CONTACT_LIST+"?"+query.toString());
      handleFilterAction();
    };

    const shouldHideAddButton = () => {
      const userPermissions = getUserPermission();
      return checkMultiPermission(userPermissions, TEL_DIR_PERMISSIONS_FOR_ADD_DIRECTORY)
        ? false
        : true;
    };

    const shouldHideDeleteButton = () => {
      const userPermissions = getUserPermission();
      return checkMultiPermission(userPermissions, TEL_DIR_PERMISSIONS_FOR_DELETE_DIRECTORY)
        ? false
        : true;
    };
    return(
        <div className="telephone-dir-list-wrapper">
            {showSearchDialog && (
                <ContactSearch
                setShowSearchDialog={setShowSearchDialog}
                onSearchcallback={handleSearchCallback}
                />
            )}
            <ContactListHeader
              loading={contact_loading}
              addurl={TELEPHONE_DIRECTORY_CONTACT_ADD}
              onRefresh = {()=> {dispatch(fetchAllContacts()).then((res)=>{
                setDirectoryList(res.payload);
              });}}
              onSearch={() => setShowSearchDialog(true)}
              hideaddbtn={shouldHideAddButton()}
            />
            {query.get("search") && (
            <div className="table-filter-item">
              <div className="table-filter-title">Keyword search applied</div>
              <span className="table-filter-clear" onClick={handleClearSearchOptions}>Clear search</span>
            </div>
            )}
            <ContactListBody
             loading = {contact_loading}
             data = {directoryList}
             handleDeleteAction={handleDeleteAction}
             hideDeleteButton={shouldHideDeleteButton()}
            />
        </div>
    );
    
};