import { getCurrentLoggedInSessionTokenWithPromise } from '../../config/userPool';

class Fetch {
  constructor(props) {
    this.baseUrl = props?.BaseURL ?? "";
    this.headers = props?.headers ?? {
      Origin: window.location.origin
    };

    this.interceptorRequest();
    this.controller = new AbortController();
  }

  async interceptorRequest() {
    const token = await getCurrentLoggedInSessionTokenWithPromise();

    this.headers = {
      ...this.headers,
      'Accept': '*.*',
      Authorization: token ?? ""
    }
  }

  queryParamsParse = (params = {}) => {
    let qsParse = '';
    if (!Object.keys(params).length) return '';
    
    Object.keys(params).forEach(
      (key) => {
        if (params[key]) {
          qsParse += `${key}=${
            Array.isArray(params[key]) ? `[${params[key]?.map(itm => `"${itm}"`)?.join(',')}]` : params[key]
          }`
          qsParse += '&'
        }
      }
    )

    return qsParse ? `?${qsParse}` : '';
  }

  async serializer({ url = '', params = {}, payload = {}, headers = {}, method = 'GET', isFormData = false }, options) {
    // TODO: need to added mechanism retry instead of call multiple request to get token and refresh token
    await this.interceptorRequest()
    try {
      const qsParse = this.queryParamsParse(params);
      
      const response = await fetch(
        `${this.baseUrl}${url}${qsParse ?? "" }`,
        {
          ...(options?.signal ? { signal: options?.signal } : {}),
          method,
          headers: {
            ...this.headers,
            ...headers,
            ...(!isFormData ? { 'Content-Type': 'application/json' } : {})
          },
          ...(payload && ['POST', 'DELETE'].includes(method) ? { body: isFormData ? payload : JSON.stringify(payload) } : {} )
        }
      );

      const data = await response.json();

      if (data?.status === "error") return [new Error(data?.message), null]

      return [null, data];
    } catch (err) {
      return [err, null];
    }
  }

  get({ url, headers, params }, options) {
    return this.serializer(
      { url, headers, params, method: 'GET' },
      options
    );
  }

  post({ url, headers, params, payload, isFormData }, options) {
    return this.serializer(
      { url, headers, params, payload, method: 'POST', isFormData },
      options
    );
  }

  delete({ url, headers, params, payload }, options) {
    const customHeader = { ...headers, 'Content-Type': 'application/json'};
    return this.serializer(
      { url, headers: customHeader, params, payload, method: 'DELETE' },
      options
    );
  }
}

export default Fetch;