import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { CaretDown } from "@phosphor-icons/react";
import { STEP_BUS_CHECKER_PERIODS } from "../../../../../store/feedback-on-bus/constant";
import { useAction, useDropdown } from "./action";
import {
  onChangeStep,
  onSelectCheckPeriod,
} from "../../../../../store/feedback-on-bus";
import { useNavigate } from "react-router";
import moment from "moment";
import {
  checkPermission,
  getUserPermission,
} from "../../../../../config/permission_helper";

const Component = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isFetching, isFetchSearch, periods, onSearch } = useAction();
  const { open, onToggle, ref } = useDropdown();

  const onNext = useCallback(
    () => dispatch(onChangeStep(STEP_BUS_CHECKER_PERIODS.FORM_UPLOADER)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSelectPeriod = useCallback(
    (id) => {
      dispatch(onChangeStep(STEP_BUS_CHECKER_PERIODS.LIST_DATA_UPLOADER));
      if (id) dispatch(onSelectCheckPeriod(id));

      navigate(`/feedback-on-bus/bsqc-checker/${id}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      <h2>Feedback on Bus Service</h2>
      <div style={{ marginTop: 42 }}>
        <span style={{ fontWeight: "bold" }}>BSQC Check Period</span>
        <div ref={ref} style={{ position: "relative" }}>
          <div className="select-input-wrapper">
            <div
              onClick={onToggle}
              className="card-item"
              style={{
                position: "relative",
                padding: "8px 16px",
                cursor: "pointer",
                color: "black",
              }}
            >
              <span>
                {isFetching ? "Loading ..." : "Select BSQC Check Period"}
              </span>
              <div className="button-action-dropdown">
                <CaretDown size={20} />
              </div>
            </div>
            {checkPermission(
              getUserPermission(),
              "fob.bsqc_add_check_period"
            ) && (
              <div className="wrapper-button">
                <button onClick={onNext} className="button-add cursor-pointer">
                  Add
                </button>
              </div>
            )}
          </div>
          {open && !isFetching && (
            <div className="dropdown-select">
              <input
                onChange={onSearch}
                className="search-options"
                type="text"
                placeholder="Please input"
              />
              <div className="list-opt">
                {isFetchSearch && (
                  <div className="select-label">Loading ...</div>
                )}
                {!isFetchSearch && !!periods.length && (
                  <>
                    <div className="select-label">Select BSQC Check Period</div>
                    {periods?.map(({ name, pk, updated_on }) => (
                      <div
                        onClick={() => onSelectPeriod(pk)}
                        key={pk}
                        className="select-label cursor-pointer"
                      >
                        {name} -{" "}
                        {moment(updated_on).format("MMM DD YYYY hh:mm A")}
                      </div>
                    ))}
                  </>
                )}
                {!isFetchSearch && !periods?.length && (
                  <div className="select-label">Not found</div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Component;
