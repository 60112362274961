import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Navigate, useNavigate } from "react-router";
import "../../communication.css";
import { Icon } from "@progress/kendo-react-common";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import NextIcon from "../../../../assets/communication/NextIcon.svg";
import Attachment_Image from "../../../../assets/communication/Attachment_.svg";
import Attachment_File from "../../../../assets/communication/attachment_file.svg";
import moment from "moment/moment";

export const ViewForm = (props) => {
  const { data,createdUser,updatedUser } = props;
  return (
    <>
      {/* <table className="recall-form-table" width={700}>
                <tr>
                    <td className="font-weight-600 font-size-16">Status</td>
                    <td className="font-weight-500">{data.status}</td>
                </tr>
                <tr>
                    <td className="font-weight-600 font-size-16">Channel</td>
                    <td className="font-weight-500">
                        <span>{data.channel_names}</span>
                    </td>
                </tr>
                <tr>
                    <td className="font-weight-600 font-size-16">Title in English</td>
                    <td className="font-weight-500">
                        <span>{data.title_en}</span>
                    </td>
                </tr>
                <tr>
                    <td className="font-weight-600 font-size-16">Title in Chinese</td>
                    <td className="font-weight-500">
                        <span>{data.title_zh}</span>
                    </td>
                </tr>
                <tr>
                    <td className="font-weight-600 font-size-16">Message in English</td>
                    <td className="font-weight-500">
                    <div dangerouslySetInnerHTML={{ __html: `${data.message_en}` }} />
                    </td>
                </tr>
                <tr>
                    <td className="font-weight-600 font-size-16">Message in Chinese</td>
                    <td className="font-weight-500">
                        <div dangerouslySetInnerHTML={{ __html: `${data.message_zh}` }} />
                    </td>
                </tr>                
                <tr>
                    <td className="font-weight-600 font-size-16">High Priority Flag</td>
                    <td className="font-weight-500">
                        <span>{data.hight_priority_flag? "Yes" : "No"}</span>
                    </td>
                </tr>
                <tr>
                    <td className="font-weight-600 font-size-16">Category</td>
                    <td className="font-weight-500">
                        <span>{data.category_name}</span>
                    </td>
                </tr>
                <tr>
                    <td className="font-weight-600 font-size-16">Scheduled /<br/>Publish On</td>
                    <td className="font-weight-500">
                        <span>{data.publish_on}</span>
                    </td>
                </tr>
                <tr>
                    <td className="font-weight-600 font-size-16">Created By</td>
                    <td className="font-weight-500">
                        <span>{data.created_by? data.created_by.name: null}</span>
                    </td>
                </tr>  
                <tr>
                    <td className="font-weight-600 font-size-16">Created On</td>
                    <td className="font-weight-500">
                        <span>{data.created_on}</span>
                    </td>
                </tr>        
                <tr>
                    <td className="font-weight-600 font-size-16">Updated By</td>
                    <td className="font-weight-500">
                        <span>{data.updated_by? data.updated_by.name: null}</span>
                    </td>
                </tr>  
                <tr>
                    <td className="font-weight-600 font-size-16">Updated On</td>
                    <td className="font-weight-500">
                        <span>{data.updated_on}</span>
                    </td>
                </tr>                                                            
                <tr>
                    <td className="font-weight-600 font-size-16">Message Image</td>
                    <td>
                        {data.message_image &&(
                            <>
                            {data.message_image.map((v)=>
                            <p>
                                <img src={v.s3_url} width={174} />
                            </p>                                            
                            )}
                            </>
                        )}
                    </td>
                </tr>
                <tr>
                    <td className="font-weight-600 font-size-16">Home Banner Image</td>
                    <td>
                        {data.home_banner_image &&(
                            <>
                            {data.home_banner_image.map((v)=>
                            <p>
                                <img src={v.s3_url} width={174} />
                            </p>                                            
                            )}
                            </>
                        )}
                    </td>
                </tr>
                <tr>
                    <td className="font-weight-600 font-size-16">Splash Image</td>
                    <td>
                        {data.splash_image &&(
                            <>
                            {data.splash_image.map((v)=>
                            <p>
                                <img src={v.s3_url} width={174} />
                            </p>                                            
                            )}
                            </>
                        )}
                    </td>
                </tr>
                <tr>
                    <td className="font-weight-600 font-size-16">PopUp Image</td>
                    <td>
                        {data.popup_image &&(
                            <>
                            {data.popup_image.map((v)=>
                            <p>
                                <img src={v.s3_url} width={174}/>
                            </p>                                            
                            )}
                            </>
                        )}
                    </td>
                </tr>                                
                <tr>
                    <td className="font-weight-600 font-size-16">Appreciation Wall Image</td>
                    <td>
                        {data.app_wall_image &&(
                            <>
                            {data.app_wall_image.map((v)=>
                            <p>
                                <img src={v.s3_url} width={174} height={112} />
                            </p>                                            
                            )}
                            </>
                        )}
                    </td>
                </tr>
                <tr>
                    <td className="font-weight-600 font-size-16 attach-td">Message Attachment</td>
                    <td className="attach-td attach-file-td">
                        {data.message_attachment &&(
                            <>
                            {data.message_attachment.map((v)=>
                            <div className="attach-file review-file">
                                <img className="attach-file-icon" src={Attachment_File} />
                                <label className="attach-file-label font-weight-600">{v.name}</label>
                            </div>
                            )}                           
                            </>
                        )}

                    </td>
                </tr>
                <tr>
                    <td className="font-weight-600 font-size-16 pad-top-20">Upload Video</td>
                    <td className="pad-top-20">
                        {data.message_video? data.message_video.map((v)=>
                            <ReactPlayer width={150} height={106} url={v.s3_url} controls={true} />
                        ): null}
                    </td>
                </tr>
            </table> */}

      <div className="notification-details__body">
        <div className="notification-details-info">
          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">Status</div>
            <div className="notification-details-info__value">
              {data.status}
            </div>
          </div>
          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">Channel</div>
            <div className="notification-details-info__value">
              {data.channel_names}
            </div>
          </div>
          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">
              Title in English
            </div>
            <div className="notification-details-info__value">
              {data.title_en}
            </div>
          </div>
          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">
              Title in Chinese
            </div>
            <div className="notification-details-info__value">
              {data.title_zh}
            </div>
          </div>
          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">
              Message in English
            </div>
            <div
              className="notification-details-info__value"
              dangerouslySetInnerHTML={{ __html: `${data.message_en}` }}
            ></div>
          </div>

          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">
              Message in Chinese
            </div>
            <div
              className="notification-details-info__value"
              dangerouslySetInnerHTML={{ __html: `${data.message_zh}` }}
            />
          </div>

          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">
              High Priority Flag
            </div>
            <div className="notification-details-info__value">
              {data.high_priority_flag ? "Yes" : "No"}
            </div>
          </div>

          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">Category</div>
            <div className="notification-details-info__value">
              {data.category_name}
            </div>
          </div>
          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">
              Scheduled /<br />
              Publish On
            </div>
            <div className="notification-details-info__value">
              {data.publish_on}
            </div>
          </div>

          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">Created By</div>
            <div className="notification-details-info__value">
              {data.created_by ? data.created_by.name : null}
            </div>
          </div>
          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">&nbsp;&nbsp;&nbsp;&nbsp;SAP/BC No</div>
            <div className="notification-details-info__value">
              {createdUser ? createdUser.username : null}
            </div>
          </div>          
          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">&nbsp;&nbsp;&nbsp;&nbsp;Department</div>
            <div className="notification-details-info__value">
              {createdUser ? createdUser.dept_desc : null}
            </div>
          </div>
          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">&nbsp;&nbsp;&nbsp;&nbsp;Section</div>
            <div className="notification-details-info__value">
              {createdUser ? createdUser.sect_desc : null}
            </div>
          </div>          
          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">&nbsp;&nbsp;&nbsp;&nbsp;Created On</div>
            <div className="notification-details-info__value">
              {data.created_on}
            </div>
          </div>

          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">Updated By</div>
            <div className="notification-details-info__value">
              {data.updated_by ? data.updated_by.name : null}
            </div>
          </div>
          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">&nbsp;&nbsp;&nbsp;&nbsp;SAP/BC No</div>
            <div className="notification-details-info__value">
              {updatedUser? updatedUser.username: null}
            </div>
          </div>
          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">&nbsp;&nbsp;&nbsp;&nbsp;Department</div>
            <div className="notification-details-info__value">
              {updatedUser? updatedUser.dept_desc: null}
            </div>
          </div>

          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">&nbsp;&nbsp;&nbsp;&nbsp;Section</div>
            <div className="notification-details-info__value">
              {updatedUser? updatedUser.sect_desc: null}
            </div>
          </div>

          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">&nbsp;&nbsp;&nbsp;&nbsp;Updated On</div>
            <div className="notification-details-info__value">
              {data.updated_on}
            </div>
          </div>

          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">Message Image</div>
            <div className="notification-details-info__value">
              {data.message_image && (
                <>
                  {data.message_image.map((v) => (
                    <p>
                      <img src={v.s3_url} width={174} />
                    </p>
                  ))}
                </>
              )}
            </div>
          </div>

          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">
              Home Banner Image
            </div>
            <div className="notification-details-info__value">
              {data.home_banner_image && (
                <>
                  {data.home_banner_image.map((v) => (
                    <p>
                      <img src={v.s3_url} width={174} />
                    </p>
                  ))}
                </>
              )}
            </div>
          </div>

          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">Splash Image</div>
            <div className="notification-details-info__value">
              {data.splash_image && (
                <>
                  {data.splash_image.map((v) => (
                    <p>
                      <img src={v.s3_url} width={174} />
                    </p>
                  ))}
                </>
              )}
            </div>
          </div>

          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">PopUp Image</div>
            <div className="notification-details-info__value">
              {data.popup_image && (
                <>
                  {data.popup_image.map((v) => (
                    <p>
                      <img src={v.s3_url} width={174} />
                    </p>
                  ))}
                </>
              )}
            </div>
          </div>

          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">
              Message Attachment
            </div>
            <div className="notification-details-info__value">
              {data.message_attachment && (
                <>
                  {data.message_attachment.map((v) => (
                    <a href={v.s3_url} target="_blank">
                      <div className="attach-file review-file">
                        <img
                          className="attach-file-icon"
                          src={Attachment_File}
                        />
                        <label className="attach-file-label font-weight-600">
                          {v.name}
                        </label>
                      </div>
                    </a>
                  ))}
                </>
              )}
            </div>
          </div>

          <div className="notification-details-info__item">
            <div className="notification-details-info__desc">Upload Video</div>
            <div className="notification-details-info__value">
              {data.message_video
                ? data.message_video.map((v) => (
                    <ReactPlayer
                      width={150}
                      height={106}
                      url={v.s3_url}
                      controls={true}
                    />
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
