import { useState, useEffect, useCallback, useRef } from "react";
import debounce from "lodash.debounce";
import { Dialog } from "@progress/kendo-react-dialogs";
import Modal from "../../components/modal";
import {
  CreateOrUpdateBusService,
  GetServiceDetailByID,
} from "../../../../../../../services/feedbackOnBus";
import {
  checkPermission,
  getUserPermission,
} from "../../../../../../../config/permission_helper";

const Component = (props) => {
  const controller = useRef(new AbortController());
  const [errors, setErrors] = useState("");
  const [form, setForm] = useState(props?.initForm ?? {});
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchDetail, setFetchDetail] = useState(false);

  useEffect(() => {
    if (props?.initForm) setForm(props?.initForm);
  }, [props?.initForm]);

  const onChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;

    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeFetchToPopulate = (e, targetInput) => {
    const name = e?.target?.name;
    const bus_stop_code = e?.target?.value ?? "";
    setForm((prev) => ({ ...prev, [name]: bus_stop_code }));

    const onFetchWithDebounce = debounce(async () => {
      setFetchDetail(true);
      const svc = form?.svc ?? props?.initForm?.svc;
      const [err, response] = await GetServiceDetailByID(svc, bus_stop_code, {
        signal: controller.current.signal,
      });

      if (err) {
        setErrors(err?.message);
      }

      let newFormData = {};
      if (!err && response) {
        newFormData[`road_name_${targetInput}`] =
          response?.data?.road_name ?? "";
        newFormData[`bus_stop_name_${targetInput}`] =
          response?.data?.bus_stop_name ?? "";
        // newFormData[`time_${targetInput}`] = response?.data?.time ?? "";
        // newFormData[`direction_${targetInput}`] =
        //   response?.data?.direction ?? "";
        console.log("newFormData: ", newFormData, response);
      }

      if (err) {
        newFormData[`road_name_${targetInput}`] = "";
        newFormData[`bus_stop_name_${targetInput}`] = "";
        // newFormData[`time_${targetInput}`] = "";
        // newFormData[`direction_${targetInput}`] = "";
      }

      setForm((prev) => ({ ...prev, ...newFormData }));
      setFetchDetail(false);
    }, 1000);

    onFetchWithDebounce();
  };

  useEffect(() => {
    if (
      props?.initForm?.svc &&
      props?.initForm?.bus_stop_code_am &&
      props?.formMode === "edit"
    ) {
      const GetBSQCDetails = async () => {
        setIsFetching(true);
        const [responseAm, responsePM] = await Promise.all([
          GetServiceDetailByID(
            props?.initForm?.svc,
            props?.initForm?.bus_stop_code_am
          ),
          GetServiceDetailByID(
            props?.initForm?.svc,
            props?.initForm?.bus_stop_code_pm
          ),
        ]);

        const [errFetchAM, responseResultAM] = responseAm;
        const [errFetchPM, responseResultPM] = responsePM;

        let newFormData = {};
        if (!errFetchAM && responseResultAM) {
          console.log("responseResultAM?.data: ", responseResultAM?.data);
          newFormData["road_name_am"] = responseResultAM?.data?.road_name ?? "";
          newFormData["bus_stop_name_am"] =
            responseResultAM?.data?.bus_stop_name ?? "";
        }

        if (!errFetchPM && responseResultPM) {
          newFormData["road_name_pm"] = responseResultPM?.data?.road_name ?? "";
          newFormData["bus_stop_name_pm"] =
            responseResultPM?.data?.bus_stop_name ?? "";
        }

        setForm((prev) => ({ ...prev, ...newFormData }));
        setIsFetching(false);
      };

      GetBSQCDetails();
    }
  }, [
    props?.initForm?.svc,
    props?.initForm?.bus_stop_code_am,
    props?.initForm?.bus_stop_code_pm,
    props?.formMode,
  ]);

  const onValidate = useCallback(
    (cb, cbError) => {
      const fields = [
        "svc",
        "depot",
        "interchange",
        "bus_stop_code_am",
        "bus_stop_name_am",
        "road_name_am",
        "time_am",
        "direction_am",
        "bus_stop_code_pm",
        "bus_stop_name_pm",
        "road_name_pm",
        "time_pm",
        "direction_pm",
      ];

      let isNotValid = [];

      fields.forEach((key) => {
        if (!form?.[key]) {
          isNotValid.push({ key, message: `${key} is required` });
        }
      });

      if (isNotValid?.length) cbError(isNotValid);
      else cb();
    },
    [form]
  );

  const onSubmit = useCallback(
    () =>
      onValidate(
        async () => {
          setIsFetching(true);
          const payload = {
            ...form,
            sk_am: props?.initForm?.sk_am,
            sk_pm: props?.initForm?.sk_pm,
            text_am: props?.textForm?.am_text,
            text_pm: props?.textForm?.pm_text,
            id: props?.id,
            is_new: props?.formMode === "edit" ? false : true,
          };
          console.log(payload);

          const [err, _] = await CreateOrUpdateBusService(
            props?.id,
            form?.svc,
            form?.depot,
            form?.interchange,
            payload
          );

          if (err) {
            setIsFetching(false);
            props?.onError(err?.message);

            return;
          }
          props.refresh();

          setIsFetching(false);
          props?.onSubmit(payload);
        },
        (err) => {
          setErrors("Please fill in all the required fields");
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form, props]
  );

  const onCloseModal = () => {
    if (typeof props?.onClose === "function") {
      if (controller.current && (isFetchDetail || isFetching)) {
        controller.current.abort();
      }

      setFetchDetail(false);
      setIsFetching(false);
      setErrors("");

      props?.onClose();
    }
  };

  return (
    <>
      <Modal
        isOpen={props?.isOpen}
        onSubmit={onSubmit}
        height={550}
        onClose={onCloseModal}
        actionComponent={
          <>
            {checkPermission(
              getUserPermission(),
              "fob.bsqc_add_edit_services"
            ) && (
              <div
                style={{
                  display: "flex",
                  columnGap: 10,
                  position: "absolute",
                  right: 0,
                }}
              >
                <button
                  disabled={isFetching || isFetchDetail}
                  onClick={() => !isFetching && onSubmit()}
                  className="button-submit"
                >
                  {isFetching || isFetchDetail ? "Loading ..." : "SUBMIT"}
                </button>

                <button onClick={onCloseModal} className="button-cancel">
                  CANCEL
                </button>
              </div>
            )}
          </>
        }
      >
        <>
          <h2>{props?.formMode === "edit" ? "Edit" : "Add"} BSQC Detail</h2>

          <div
            style={{
              marginTop: "3em",
              display: "grid",
              gridTemplateColumns: "auto auto auto",
              columnGap: 10,
            }}
          >
            <div className="input-group">
              <div className="input-date-label">
                <span>
                  <span style={{ fontWeight: "bold" }}>Service No</span>
                  <span style={{ color: "red" }}>*</span>
                </span>
              </div>
              <input
                disabled={isFetching || isFetchDetail}
                name="svc"
                value={form["svc"]}
                onChange={onChange}
                className="textfield"
              />
            </div>

            <div className="input-group">
              <div className="input-date-label">
                <span>
                  <span style={{ fontWeight: "bold" }}>Depot</span>
                  <span style={{ color: "red" }}>*</span>
                </span>
              </div>
              <input
                name="depot"
                disabled={isFetching || isFetchDetail}
                value={form["depot"]}
                onChange={onChange}
                className="textfield"
              />
            </div>

            <div className="input-group">
              <div className="input-date-label">
                <span>
                  <span style={{ fontWeight: "bold" }}>Interchange</span>
                  <span style={{ color: "red" }}>*</span>
                </span>
              </div>
              <input
                name="interchange"
                disabled={isFetching || isFetchDetail}
                value={form["interchange"]}
                onChange={onChange}
                className="textfield"
              />
            </div>
          </div>

          <div style={{ marginTop: "2em", textAlign: "center" }}>
            <h4>{props?.textForm?.am_text ?? "AM(6am to 9am)"}</h4>
          </div>
          <div
            style={{
              marginTop: 10,
              display: "grid",
              gridTemplateColumns: "auto auto auto 120px 120px",
              columnGap: 16,
            }}
          >
            <div className="input-group">
              <div className="input-date-label">
                <span>
                  <span style={{ fontWeight: "bold" }}>Bus Stop No</span>
                  <span style={{ color: "red" }}>*</span>
                </span>
              </div>
              <input
                name="bus_stop_code_am"
                disabled={isFetching}
                value={form["bus_stop_code_am"]}
                onChange={onChange}
                onBlur={(e) => onChangeFetchToPopulate(e, "am")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onChangeFetchToPopulate(e, "am");
                  }
                }}
                className="textfield"
              />
            </div>

            <div className="input-group">
              <div className="input-date-label">
                <span>
                  <span style={{ fontWeight: "bold" }}>
                    Bus Stop Description
                  </span>
                  <span style={{ color: "red" }}>*</span>
                </span>
              </div>
              <input
                name="bus_stop_name_am"
                disabled={isFetching || isFetchDetail}
                value={form["bus_stop_name_am"]}
                onChange={onChange}
                className="textfield"
              />
            </div>

            <div className="input-group">
              <div className="input-date-label">
                <span>
                  <span style={{ fontWeight: "bold" }}>Road Name</span>
                  <span style={{ color: "red" }}>*</span>
                </span>
              </div>
              <input
                name="road_name_am"
                disabled={isFetching || isFetchDetail}
                value={form["road_name_am"]}
                onChange={onChange}
                className="textfield"
              />
            </div>

            <div className="input-group">
              <div className="input-date-label">
                <span>
                  <span style={{ fontWeight: "bold" }}>Time</span>
                  <span style={{ color: "red" }}>*</span>
                </span>
              </div>
              <input
                name="time_am"
                disabled={isFetching || isFetchDetail}
                value={form["time_am"]}
                onChange={onChange}
                className="textfield"
              />
            </div>

            <div className="input-group">
              <div className="input-date-label">
                <span>
                  <span style={{ fontWeight: "bold" }}>Direction</span>
                  <span style={{ color: "red" }}>*</span>
                </span>
              </div>
              <input
                name="direction_am"
                disabled={isFetching || isFetchDetail}
                value={form["direction_am"]}
                onChange={onChange}
                className="textfield"
              />
            </div>
          </div>

          <div style={{ marginTop: "2em", textAlign: "center" }}>
            <h4>{props?.textForm?.pm_text ?? "PM(5pm to 8pm)"}</h4>
          </div>
          <div
            style={{
              marginTop: 10,
              display: "grid",
              gridTemplateColumns: "auto auto auto 120px 120px",
              columnGap: 16,
            }}
          >
            <div className="input-group">
              <div className="input-date-label">
                <span>
                  <span style={{ fontWeight: "bold" }}>Bus Stop No</span>
                  <span style={{ color: "red" }}>*</span>
                </span>
              </div>
              <input
                name="bus_stop_code_pm"
                disabled={isFetching}
                value={form["bus_stop_code_pm"]}
                onChange={onChange}
                onBlur={(e) => onChangeFetchToPopulate(e, "pm")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onChangeFetchToPopulate(e, "pm");
                  }
                }}
                className="textfield"
              />
            </div>

            <div className="input-group">
              <div className="input-date-label">
                <span>
                  <span style={{ fontWeight: "bold" }}>
                    Bus Stop Description
                  </span>
                  <span style={{ color: "red" }}>*</span>
                </span>
              </div>
              <input
                name="bus_stop_name_pm"
                disabled={isFetching || isFetchDetail}
                value={form["bus_stop_name_pm"]}
                onChange={onChange}
                className="textfield"
              />
            </div>

            <div className="input-group">
              <div className="input-date-label">
                <span>
                  <span style={{ fontWeight: "bold" }}>Road Name</span>
                  <span style={{ color: "red" }}>*</span>
                </span>
              </div>
              <input
                name="road_name_pm"
                disabled={isFetching || isFetchDetail}
                value={form["road_name_pm"]}
                onChange={onChange}
                className="textfield"
              />
            </div>

            <div className="input-group">
              <div className="input-date-label">
                <span>
                  <span style={{ fontWeight: "bold" }}>Time</span>
                  <span style={{ color: "red" }}>*</span>
                </span>
              </div>
              <input
                name="time_pm"
                disabled={isFetching || isFetchDetail}
                value={form["time_pm"]}
                onChange={onChange}
                className="textfield"
              />
            </div>

            <div className="input-group">
              <div className="input-date-label">
                <span>
                  <span style={{ fontWeight: "bold" }}>Direction</span>
                  <span style={{ color: "red" }}>*</span>
                </span>
              </div>
              <input
                name="direction_pm"
                disabled={isFetching || isFetchDetail}
                value={form["direction_pm"]}
                onChange={onChange}
                className="textfield"
              />
            </div>
          </div>
        </>
      </Modal>
      {errors && (
        <Dialog
          className="alert-form"
          title={"Alert"}
          isOpen={true}
          width={400}
          onClose={() => setErrors("")}
        >
          <p>{errors}</p>
        </Dialog>
      )}
    </>
  );
};

export default Component;
