// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const { TELEPONE_DIRECTORY_CONTACT_ENDPOINT,
    TELEPONE_DIRECTORY_DEPARTMENT_ENDPOINT,
    TELEPONE_DIRECTORY_DEPOT_ENDPOINT,
    TELEPONE_DIRECTORY_SUB_DEPOT_ENDPOINT } = config.api_endpoint;

const initialState = {
    contact_loading: false,
    contact_detail_loading:false,
    contact_detail_data:[],
    contactList: [],
    contact_error: null,
    contact_detail_error:null,
    department_loading: false,
    dept_detail_loading: false,
    departmentList: [],
    dept_detail_data:[],
    department_error: null,
    dept_detail_error:null,
    depot_loading: false,
    depot_detail_loading: false,
    depotsList: [],
    depot_detail_data: [],    
    depot_error: null,
    depot_detail_error: null,
    update_loading:false,
    update_error:null,
    depot_subdepot_loading:false,
    depotsSubDepotList:[],
    depot_subdepot_error:null,
};

export const fetchAllContacts = createAsyncThunk(
    "telephone_directory/fetchAllContacts",
    async (_, { rejectWithValue }) => {
        try {
        const token = getCurrentLoggedInSessionToken();
        const results = await api.get(`${TELEPONE_DIRECTORY_CONTACT_ENDPOINT}`, {}, token);
        //   let finalData = [];
        console.log("Contact List Results:",results.data);
        return results.data;
        } catch (error) {
        console.log("error: ", error);
        return rejectWithValue(error.details);
        }
    }
);

export const fetchAllDepartments = createAsyncThunk(
    "telephone_directory/fetchAllDepartments",
    async (_, { rejectWithValue }) => {
      try {
        const token = getCurrentLoggedInSessionToken();
        const results = await api.get(`${TELEPONE_DIRECTORY_DEPARTMENT_ENDPOINT}`, {}, token);
      //   let finalData = [];
        console.log("Department List Results:",results.data);
        return results.data;
      } catch (error) {
        console.log("error: ", error);
        return rejectWithValue(error.details);
      }
    }
);

export const fetchAllTelephoneDepotsSubDepot = createAsyncThunk(
    "telephone_directory/fetchAllTelephoneDepots",
    async (_, { rejectWithValue }) => {
      try {
        
        const token = getCurrentLoggedInSessionToken();
        const depot_results = await api.get(`${TELEPONE_DIRECTORY_DEPOT_ENDPOINT}`, {}, token);
        const subdepot_results = await api.get(`${TELEPONE_DIRECTORY_SUB_DEPOT_ENDPOINT}`, {}, token);
        console.log("Depot List Results:",depot_results);
        console.log("Sub Depot List Results:",subdepot_results);
        let finalData = [...depot_results.data, ...subdepot_results.data];
        console.log("Final Data: ", finalData);
        return finalData;
      } catch (error) {
        console.log("error: ", error);
        return rejectWithValue(error.details);
      }
    }
  );

  export const fetchAllDepots = createAsyncThunk(
    "telephone_directory/fetchAllSubDepots",
    async (_, { rejectWithValue }) => {
      try {
        const token = getCurrentLoggedInSessionToken();
        const results = await api.get(`${TELEPONE_DIRECTORY_DEPOT_ENDPOINT}`, {}, token);
      //   let finalData = [];
        console.log("Depot List Results:",results);
        return results.data;
      } catch (error) {
        console.log("error: ", error);
        return rejectWithValue(error.details);
      }
    }
  );

export const fetchAllSubDepots = createAsyncThunk(
    "telephone_directory/fetchAllSubDepots",
    async (filter_by, { rejectWithValue }) => {
      try {
        console.log("filter_by?", filter_by)
        let depot = "";
        if (filter_by != "undefined" && "parentDepot" in filter_by) {
          depot = filter_by["parentDepot"]
        }

        const token = getCurrentLoggedInSessionToken();
        const results = await api.get(`${TELEPONE_DIRECTORY_SUB_DEPOT_ENDPOINT}`, {depot: depot}, token);
      //   let finalData = [];
        console.log("Sub Depot List Results:",results);
        return results.data;
      } catch (error) {
        console.log("error: ", error);
        return rejectWithValue(error.details);
      }
    }
  );
  
  export const createContact = createAsyncThunk(
    "telephone_directory/createContact",
    async ({data}, { rejectWithValue }) => {
      try {
        console.log(data);
        const token = getCurrentLoggedInSessionToken();
        const results = await api.post(`${TELEPONE_DIRECTORY_CONTACT_ENDPOINT}`, JSON.stringify(data), token);
      //   let finalData = [];
        console.log("Create Contact API Results:",results);
        return results.data;
      } catch (error) {
        console.log("error: ", error);
        return rejectWithValue(error.details);
      }
    }
  );
  
  export const createDepartment = createAsyncThunk(
    "telephone_directory/createDepartment",
    async ({data}, { rejectWithValue }) => {
      try {
        console.log(data);
        const token = getCurrentLoggedInSessionToken();
        const results = await api.post(`${TELEPONE_DIRECTORY_DEPARTMENT_ENDPOINT}`, JSON.stringify(data), token);
      //   let finalData = [];
        console.log("Department create api-:",results);
      
        return results.data;
      } catch (error) {
        console.log("error: ", error);
        return rejectWithValue(error.details);
      }
    }
  );
  
  export const updateDepartment = createAsyncThunk(
    "telephone_directory/updateDepartment",
    async ({data,dept_id}, { rejectWithValue }) => {
      try {
        console.log(data);
        console.log(dept_id);
        const token = getCurrentLoggedInSessionToken();
        const res = await api.post(
          `${TELEPONE_DIRECTORY_DEPARTMENT_ENDPOINT}/${dept_id}`,
          JSON.stringify(data),
          token
        );
        return res.data;
      } catch (error) {
        console.log("error: ", error);
        return rejectWithValue(error.details);
      }
    }
  );

  export const fetchDepartmentById = createAsyncThunk(
    "telephone_directory/fetchDepartmentById",
    async (department_id, { rejectWithValue }) => {
      try {
        console.log('Dept ID---',department_id);
        const token = getCurrentLoggedInSessionToken();
        const results = await api.get(`${TELEPONE_DIRECTORY_DEPARTMENT_ENDPOINT}/${department_id}`, {}, token);
      //   let finalData = [];
        console.log("Department Detail Results:",results.data);
        return results.data;
      } catch (error) {
        console.log("error: ", error);
        return rejectWithValue(error.details);
      }
    }
 );

export const deleteDepartment = createAsyncThunk(
  "telephone_directory/deleteDepartment",
  async (id, { rejectWithValue }) => {
    try {
      console.log("delete dept id",id);
      const token = getCurrentLoggedInSessionToken();
      const res = await api.remove(
        `${TELEPONE_DIRECTORY_DEPARTMENT_ENDPOINT}/${id}`,
        JSON.stringify({id: id}),
        token
      );
      console.log('Dept Delete API res--',res);
      return res.data
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const deleteContact = createAsyncThunk(
  "telephone_directory/deleteContact",
  async (id, { rejectWithValue }) => {
    try {
      console.log("delete dept id",id);
      const token = getCurrentLoggedInSessionToken();
      const res = await api.remove(
        `${TELEPONE_DIRECTORY_CONTACT_ENDPOINT}/${id}`,
        JSON.stringify({id: id}),
        token
      );
      console.log('Contact Delete API res--',res);
      return res.data
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const updateContact = createAsyncThunk(
  "telephone_directory/updateContact",
  async ({data,id}, { rejectWithValue }) => {
    try {
      console.log(data);
      console.log(id);
      const token = getCurrentLoggedInSessionToken();
      const res = await api.post(
        `${TELEPONE_DIRECTORY_CONTACT_ENDPOINT}/${id}`,
        JSON.stringify(data),
        token
      );
      return res.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);
export const fetchContactById = createAsyncThunk(
  "telephone_directory/fetchContactById",
  async (id, { rejectWithValue }) => {
    try {
      console.log('Contact ID---',id);
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(`${TELEPONE_DIRECTORY_CONTACT_ENDPOINT}/${id}`, {}, token);
    //   let finalData = [];
      console.log("Contact Detail Results:",results.data);
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const createDepot = createAsyncThunk(
  "telephone_directory/createDepot",
  async ({data}, { rejectWithValue }) => {
    try {
      console.log(data);
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(`${TELEPONE_DIRECTORY_DEPOT_ENDPOINT}`, JSON.stringify(data), token);
      console.log("Create Depot API Results:",results);
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const deleteDepot = createAsyncThunk(
  "telephone_directory/deleteDepot",
  async (id, { rejectWithValue }) => {
    try {
      console.log("delete depot id",id);
      const token = getCurrentLoggedInSessionToken();
      const res = await api.remove(
        `${TELEPONE_DIRECTORY_DEPOT_ENDPOINT}/${id}`,
        JSON.stringify({id: id}),
        token
      );
      console.log('Depot Delete API res--',res);
      return res.data
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const createSubDepot = createAsyncThunk(
  "telephone_directory/createSubDepot",
  async ({data}, { rejectWithValue }) => {
    try {
      console.log(data);
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(`${TELEPONE_DIRECTORY_SUB_DEPOT_ENDPOINT}`, JSON.stringify(data), token);
    
      console.log("Create Sub Depot API Results:",results);
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const updateDepot = createAsyncThunk(
  "telephone_directory/updateDepot",
  async ({data,id}, { rejectWithValue }) => {
    try {
      console.log("updateDepot",data);
      console.log(id);
      const token = getCurrentLoggedInSessionToken();
      const res = await api.post(
        `${TELEPONE_DIRECTORY_DEPOT_ENDPOINT}/${id}`,
        JSON.stringify(data),
        token
      );
      return res.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const updateSubDepot = createAsyncThunk(
  "telephone_directory/updateSubDepot",
  async ({data,id}, { rejectWithValue }) => {
    try {
      console.log("updateSubDepot",data);
      console.log(id);
      const token = getCurrentLoggedInSessionToken();
      const res = await api.post(
        `${TELEPONE_DIRECTORY_SUB_DEPOT_ENDPOINT}/${id}`,
        JSON.stringify(data),
        token
      );
      console.log("Update SubDepot API result: ",res);
      return res.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchDepotById = createAsyncThunk(
  "telephone_directory/fetchDepotById",
  async (id, { rejectWithValue }) => {
    try {
      console.log('Depot ID---',id);
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(`${TELEPONE_DIRECTORY_DEPOT_ENDPOINT}/${id}`, {}, token);
    //   let finalData = [];
      console.log("Depot Detail Results:",results.data);
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchChildDepotById = createAsyncThunk(
  "telephone_directory/fetchChildDepotById",
  async (id, { rejectWithValue }) => {
    try {
      console.log('Depot ID---',id);
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(`${TELEPONE_DIRECTORY_SUB_DEPOT_ENDPOINT}/${id}`, {}, token);
    //   let finalData = [];
      console.log("Child Depot Detail Results:",results.data);
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);
const telephoneDirectorySlice = createSlice({
    name: "telephone_directory",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchAllDepartments.pending, (state) => {
          console.log("fetch all department pending: ");
          state.department_loading = true;
        })
        .addCase(fetchAllDepartments.fulfilled, (state, action) => {
          console.log("fetch all department fulfilled: ", state, action);
          state.department_loading = false;
          state.departmentList = action.payload;
        })
        .addCase(fetchAllDepartments.rejected, (state, action) => {
          console.log("fetch all department rejected: ", state, action);
          state.department_loading = false;
          state.department_error = action.error.message;
        })
        .addCase(fetchAllContacts.pending, (state) => {
          console.log("fetch all contact pending: ");
          state.contact_loading = true;
        })
        .addCase(fetchAllContacts.fulfilled, (state, action) => {
          console.log("fetch all contact fulfilled: ", state, action);
          state.contact_loading = false;
          state.contactList = action.payload;
        })
        .addCase(fetchAllContacts.rejected, (state, action) => {
          console.log("fetch all contact rejected: ", state, action);
          state.contact_loading = false;
          state.contact_error = action.error.message;
        })
        .addCase(fetchAllTelephoneDepotsSubDepot.pending, (state) => {
          console.log("fetch all depots sub depots pending: ", state);
          state.depot_subdepot_loading = true;
        })
        .addCase(fetchAllTelephoneDepotsSubDepot.fulfilled, (state, action) => {
          console.log("fetch all depots sub depots fulfilled: ", state, action);
          state.depot_subdepot_loading = false;
          state.depotsSubDepotList = action.payload;
        })
        .addCase(fetchAllTelephoneDepotsSubDepot.rejected, (state, action) => {
          console.log("fetch all depots sub depots rejected: ", state, action);
          state.depot_subdepot_loading = false;
          state.depot_subdepot_error = action.error.message;
        })
        .addCase(fetchAllDepots.pending, (state) => {
          console.log("fetch all depots pending: ", state);
          state.depot_loading = true;
        })
        .addCase(fetchAllDepots.fulfilled, (state, action) => {
          console.log("fetch all depots fulfilled: ", state, action);
          state.depot_loading = false;
          state.depotsList = action.payload;
        })
        .addCase(fetchAllDepots.rejected, (state, action) => {
          console.log("fetch all depots rejected: ", state, action);
          state.depot_loading = false;
          state.depot_error = action.error.message;
        })
        .addCase(createContact.pending, (state) => {
          state.update_loading = true;
        })
        .addCase(createContact.fulfilled, (state, action) => {
          state.update_loading = false;
          // state.update_data = action.payload;
        })
        .addCase(createContact.rejected, (state, action) => {
          state.update_loading = false;
          state.update_error = action.error.message;
        })
        .addCase(createDepartment.pending, (state) => {
          state.update_loading = true;
        })
        .addCase(createDepartment.fulfilled, (state, action) => {
          state.update_loading = false;
          // state.update_data = action.payload;
        })
        .addCase(createDepartment.rejected, (state, action) => {
          state.update_loading = false;
          state.update_error = action.error.message;
        })
        .addCase(fetchDepartmentById.pending, (state) => {
          state.dept_detail_loading = true;
        })
        .addCase(fetchDepartmentById.fulfilled, (state, action) => {
          state.dept_detail_loading = false;
          state.dept_detail_data = action.payload;
        })
        .addCase(fetchDepartmentById.rejected, (state, action) => {
          state.dept_detail_loading = false;
          state.dept_detail_error = action.error.message;
        })
        .addCase(updateDepartment.pending, (state) => {
          state.update_loading = true;
        })
        .addCase(updateDepartment.fulfilled, (state, action) => {
          state.update_loading = false;
          state.dept_detail_data = action.payload;
        })
        .addCase(updateDepartment.rejected, (state, action) => {
          state.update_loading = false;
          state.update_error = action.error.message;
        })
        .addCase(fetchContactById.pending, (state) => {
          state.contact_detail_loading = true;
        })
        .addCase(fetchContactById.fulfilled, (state, action) => {
          state.contact_detail_loading = false;
          state.contact_detail_data = action.payload;
        })
        .addCase(fetchContactById.rejected, (state, action) => {
          state.contact_detail_loading = false;
          state.contact_detail_error = action.error.message;
        })
        .addCase(fetchDepotById.pending, (state) => {
          state.depot_detail_loading = true;
        })
        .addCase(fetchDepotById.fulfilled, (state, action) => {
          state.depot_detail_loading = false;
          state.depot_detail_data = action.payload;
        })
        .addCase(fetchDepotById.rejected, (state, action) => {
          state.depot_detail_loading = false;
          state.depot_detail_error = action.error.message;
        })
        .addCase(createDepot.pending, (state) => {
          state.update_loading = true;
        })
        .addCase(createDepot.fulfilled, (state, action) => {
          state.update_loading = false;
          // state.update_data = action.payload;
        })
        .addCase(createDepot.rejected, (state, action) => {
          state.update_loading = false;
          state.update_error = action.error.message;
        })
        .addCase(createSubDepot.pending, (state) => {
          state.update_loading = true;
        })
        .addCase(createSubDepot.fulfilled, (state, action) => {
          state.update_loading = false;
          // state.update_data = action.payload;
        })
        .addCase(createSubDepot.rejected, (state, action) => {
          state.update_loading = false;
          state.update_error = action.error.message;
        })
        .addCase(fetchChildDepotById.pending, (state) => {
          state.depot_detail_loading = true;
        })
        .addCase(fetchChildDepotById.fulfilled, (state, action) => {
          state.depot_detail_loading = false;
          state.depot_detail_data = action.payload;
        })
        .addCase(fetchChildDepotById.rejected, (state, action) => {
          state.depot_detail_loading = false;
          state.depot_detail_error = action.error.message;
        });
    },
  });
  
  const telephoneDirectoryReducer = telephoneDirectorySlice.reducer;
  export default telephoneDirectoryReducer;

  