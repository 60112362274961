import Request from './index';

export const GetActivityLog = async (id = '') => {
  const payload = {
    source_id: id,
    table_name: 'ideas'
  }
  const endpoint = '/api-audit/audit';

  return await Request.get({ url: endpoint, params: payload });
}
