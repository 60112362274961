import config from "./config";

const get = (path, params, token, type) => {
  return doRequest(path, params, "GET", token, type);
};

const post = (path, params, token, type) => {
  return doRequest(path, params, "POST", token, type);
};

const postDiff = (path, params, token) => {
  return doRequest(path, params, "POST_DIFF", token);
};

const postForm = (path, params, token) => {
  return doRequest(path, params, "POST", token, true);
};

const put = (path, params, token) => {
  return doRequest(path, params, "PUT", token);
};

const remove = (path, params, token, type) => {
  return doRequest(path, params, "DELETE", token, type);
};

const objectToQueryString = (obj) => {
  return Object.keys(obj)
    .map((key) => key + "=" + obj[key])
    .join("&");
};

const doRequest = (path, params, method, token, isForm = false) => {
  // console.log(token);
  const options = { method, headers: {} };

  // convert the object to params
  params
    ? method === "GET"
      ? (path += `?${objectToQueryString(params)}`)
      : (options.body = params)
    : (options.body = params);

  if (method === "POST" && !isForm) {
    options.headers = new Headers({
      "Content-Type": "application/json",
      Authorization: `${token}`,
    });
  } else if (method === "POST" && isForm) {
    options.headers = new Headers({
      Authorization: `${token}`,
    });
  }

  if (method === "PUT" || method === "DELETE") {
    options.headers = new Headers({
      "Content-Type": "application/json",
      Authorization: `${token}`,
    });
  }

  if (method === "GET") {
    if (path[path.length - 1] === "?") {
      path = path.slice(0, -1);
    }

    options.headers = new Headers({
      // "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Origin: window.location.origin,
      Authorization: `${token}`,
    });
  }

  if (method !== "POST_DIFF") {
    options.credentials = "include";
  }

  if (method === "POST_DIFF") {
    options.method = "PUT";
    // options.headers = {
    //   "Content-Type": "application/pdf",
    // };

    return fetch(path, options)
      .then((response) => {
        if (response.status === 204) {
          return undefined;
        }
        if (response.status === 200) {
          return 200;
        } else {
          return 400;
        }
        // return response.json().then((result) => {
        //   let res = { ...result };
        //   res["status_code"] = response.status;
        //   return res;
        // });
        // return response;
      })
      .catch((err) => {
        return 400;
      });
  } else {
    // options.credentials = "include";
    return fetch(config.url + path, options).then((response) => {
      if (response.status === 204) {
        return undefined;
      }
      if (response.status === 403) {
        token = null;
      }
      return response.json().then((result) => {
        let res = { ...result };
        res["status_code"] = response.status;
        return res;
      });
    });
  }
};
export default {
  get,
  post,
  postForm,
  remove,
  put,
  postDiff,
};
