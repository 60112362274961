// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const { DR_SECURE_CONTACT_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: false,
  details_loading: false,
  drSecureContactList: [],
  drSecureContactDetails: [],
  drSecureContactNewDetails: {},
  drSecureContactUpdateDetails: {},
  error: null,
  details_error: null,
};

export const fetchAllDRSecureContacts = createAsyncThunk(
  "dr_secure_contacts/fetchAllContacts",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(DR_SECURE_CONTACT_ENDPOINT, {}, token);
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllDRSecureContactDetails = createAsyncThunk(
  "dr_secure_contacts/fetchAllContactDetails",
  async (id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        `${DR_SECURE_CONTACT_ENDPOINT}/${id}`,
        {},
        token
      );
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const saveDRSecureNewContactDetails = createAsyncThunk(
  "dr_secure_contacts/saveNewContactDetails",
  async ({ contact_name, contact_number, occ }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        `${DR_SECURE_CONTACT_ENDPOINT}`,
        JSON.stringify({
          contact_name: contact_name,
          contact_number: contact_number,
          occ: occ,
        }),
        token
      );
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const updateDRSecureContactDetails = createAsyncThunk(
  "dr_secure_contacts/updateContactDetails",
  async ({ id, contact_name, contact_number, occ }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        `${DR_SECURE_CONTACT_ENDPOINT}/${id}`,
        JSON.stringify({
          contact_name: contact_name,
          contact_number: contact_number,
          occ: occ,
        }),
        token
      );
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

const drSecureContactSlice = createSlice({
  name: "dr_secure_contacts",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDRSecureContacts.pending, (state) => {
        console.log("fetch all dr secure contacts pending: ", state);
        state.loading = true;
      })
      .addCase(fetchAllDRSecureContacts.fulfilled, (state, action) => {
        console.log("fetch all dr secure contacts fulfilled: ", state, action);
        state.loading = false;
        state.drSecureContactList = action.payload;
      })
      .addCase(fetchAllDRSecureContacts.rejected, (state, action) => {
        console.log("fetch all dr secure contacts rejected: ", state, action);
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAllDRSecureContactDetails.pending, (state) => {
        console.log("fetch all dr secure contact details pending: ", state);
        state.details_loading = true;
      })
      .addCase(fetchAllDRSecureContactDetails.fulfilled, (state, action) => {
        console.log(
          "fetch all dr secure contact details fulfilled: ",
          state,
          action
        );
        state.details_loading = false;
        state.drSecureContactDetails = action.payload;
      })
      .addCase(fetchAllDRSecureContactDetails.rejected, (state, action) => {
        console.log(
          "fetch all dr secure contact details rejected: ",
          state,
          action
        );
        state.details_loading = false;
        state.details_error = action.error.message;
      })
      .addCase(saveDRSecureNewContactDetails.pending, (state) => {
        console.log("save new dr secure contact details pending: ", state);
        state.details_loading = true;
      })
      .addCase(saveDRSecureNewContactDetails.fulfilled, (state, action) => {
        console.log(
          "save new dr secure contact details fulfilled: ",
          state,
          action
        );
        state.details_loading = false;
        state.drSecureContactNewDetails = action.payload;
      })
      .addCase(saveDRSecureNewContactDetails.rejected, (state, action) => {
        console.log(
          "save new dr secure contact details rejected: ",
          state,
          action
        );
        state.details_loading = false;
        state.details_error = action.error.message;
      })
      .addCase(updateDRSecureContactDetails.pending, (state) => {
        console.log("update dr secure contact details pending: ", state);
        state.details_loading = true;
      })
      .addCase(updateDRSecureContactDetails.fulfilled, (state, action) => {
        console.log(
          "update dr secure contact details fulfilled: ",
          state,
          action
        );
        state.details_loading = false;
        state.drSecureContactUpdateDetails = action.payload;
      })
      .addCase(updateDRSecureContactDetails.rejected, (state, action) => {
        console.log(
          "update dr secure contact details rejected: ",
          state,
          action
        );
        state.details_loading = false;
        state.details_error = action.error.message;
      });
  },
});

const drSecureContactReducer = drSecureContactSlice.reducer;
export default drSecureContactReducer;
