import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Dialog,DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";

import "../../../../components/common/dr_search_dialog.css";

const DrSecureListSearchDialog = ({onToggleDialog, onSubmitShare}) => {
    const [caseRefNo, setCaseRefNo] = useState("");
    const [busRegNo, setBusRegNo] = useState("");
    const [busService, setBusService] = useState("");
    const [incidentLocation, setIncidentLocation] = useState("");
    const [updatedBy, setUpdatedBy] = useState("");
    const onCaseRefNoChange = (e) => {
        setCaseRefNo(e.target.value);
    }
    const onBusRegNoChange = (e) => {
        setBusRegNo(e.target.value);
    }
    const onBusServiceChange = (e) => {
        setBusService(e.target.value);
    }
    const onIncidentLocationChange = (e) => {
        setIncidentLocation(e.target.value);
    }
    const onUpdatedByChange = (e) => {
        setUpdatedBy(e.target.value);
    }
    const onSubmit = () => {
        console.log(caseRefNo, busRegNo, busService, incidentLocation, updatedBy);
        onSubmitShare(caseRefNo, busRegNo, busService, incidentLocation, updatedBy)
    };
    return (
        <>
        <Dialog
            className="search-notification"
            title={" "}
            // width={500}
            height={"100%"}
            onClose={onToggleDialog}
            >
            <div className="search-body">
                <div className="noti-dialog-title dialog-row">Search</div>
                <div className="dialog-row">
                    <span className="search-row-title">Case Ref No</span>
                    <Input className="search-input" onChange={onCaseRefNoChange}/>
                </div>
                <div className="dialog-row" style={{height:"80%"}}>
                    <span className="search-row-title">Bus Reg No</span>
                    <Input className="search-input" onChange={onBusRegNoChange}/>
                </div>
                <div className="dialog-row" style={{height:"80%"}}>
                    <span className="search-row-title">Bus Service</span>
                    <Input className="search-input" onChange={onBusServiceChange}/>
                </div>
                
                <div className="dialog-row" style={{height:"80%"}}>
                    <span className="search-row-title">Updated By</span>
                    <Input className="search-input" onChange={onUpdatedByChange}/>
                </div>
                <div className="dialog-row" style={{height:"80%"}}>
                    <span className="search-row-title">Incident Location</span>
                    <Input className="search-input" onChange={onIncidentLocationChange}/>
                </div>
            </div>
            <DialogActionsBar>
                <Button className="search-button cancel-btn" onClick={onToggleDialog}>Cancel</Button>
                <Button className="search-button search-btn" onClick={onSubmit}>Submit</Button>
            </DialogActionsBar>
        </Dialog>        
        </>
        
    );
  };

  export default DrSecureListSearchDialog;