// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { 
  FEEDBACK_ON_BUS_SUMMARY_CHECKS_DETAIL_ENDPOINT,
  FEEDBACK_ON_BUS_SUMMARY_CHECKS_DETAIL_ROUTEHISTORY_ENDPOINT,
  FEEDBACK_ON_BUS_SUMMARY_CHECK_ROUTE_EMAIL,
  FEEDBACK_ON_BUS_SUMMARY_CHECKS_ACKNOWLEDGE_ENDPOINT,
  FEEDBACK_ON_BUS_SUMMARY_CHECK_LIST,
  FEEDBACK_ON_BUS_SUMMARY_CHECKS_EXPORT,
  FEEDBACK_ON_BUS_UPDATE_STATUS,
  FEEDBACK_ON_BUS_SUMMARY_CHECKS_LATEST_ACKNOWLEDGE_ENDPOINT,
  FEEDBACK_ON_BUS_SUMMARY_CHECKS_ADD_UPDATE,
  FEEDBACK_ON_BUS_SUMMARY_CHECKS_UPDATES_LIST,
  FEEDBACK_ON_BUS_SUMMARY_CHECKS_ACKNOWLEDGE_LIST_ENDPOINT
} = config.api_endpoint;

const initialState = {
  loading: false,
  data: {},
  error: null,
  history: [],
  history_loading: false,
  history_error: null,
  route_loading: false,
  route_data: {},
  route_error: null,
  export_data: [],
  export_loading: false,
  export_error: null,
  ack_loading: false,
  ack_data: {},
  ack_error: false,
  latest_ack:{},
  latest_ack_loading: false,
  latest_ack_error: null,
  feedback_updates: [],
  feedback_updates_loading: false,
  feedback_updates_error: null,
  feedback_updates_list : [],
  feedback_updates_list_loading: false,
  feedback_updates_list_error: null,
  feedback_acknowledges : [],
  feedback_acknowledges_loading: false,
  feedback_acknowledges_error: null
};

export const fetchFOBSummaryCheckList = createAsyncThunk(
  "feedbackonbus_summarycheckdetails/fetchFOBSummaryCheckList",
  async (query, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();

      const results = await api.get(
        FEEDBACK_ON_BUS_SUMMARY_CHECK_LIST+"?"+query.toString(),
        {},
        token
      );
      console.log("fetchFOBSummaryCheckList Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchFOBSummaryCheckDetailsById = createAsyncThunk(
  "feedbackonbus_summarycheckdetails/fetchFOBSummaryCheckDetailsById",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        FEEDBACK_ON_BUS_SUMMARY_CHECKS_DETAIL_ENDPOINT,
        JSON.stringify(data),
        token
      );
      console.log("fetchFOBSummaryCheckDetailsById Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchFOBSummaryCheckRouteHistoryById = createAsyncThunk(
  "feedbackonbus_summarycheckdetails/fetchFOBSummaryCheckRouteHistoryById",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        FEEDBACK_ON_BUS_SUMMARY_CHECKS_DETAIL_ROUTEHISTORY_ENDPOINT,
        JSON.stringify(data),
        token
      );
      console.log("fetchFOBSummaryCheckRouteHistoryById Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const DeleteFOBSummaryChecks = createAsyncThunk(
  "feedbackonbus_summarycheckdetails/DeleteFOBSummaryChecks",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.remove(
        FEEDBACK_ON_BUS_SUMMARY_CHECKS_DETAIL_ENDPOINT,
        JSON.stringify(data),
        token
      );
      console.log("DeleteFOBSummaryChecks Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const RouteFOBSummaryChecks = createAsyncThunk(
  "feedbackonbus_summarycheckdetails/RouteFOBSummaryChecks",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        FEEDBACK_ON_BUS_SUMMARY_CHECK_ROUTE_EMAIL,
        JSON.stringify(data),
        token
      );
      console.log("RouteFOBSummaryChecks Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchFOBSummaryCheckExport = createAsyncThunk(
  "feedbackonbus_summarycheckdetails/fetchFOBSummaryCheckExport",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        FEEDBACK_ON_BUS_SUMMARY_CHECKS_EXPORT,
        {},
        token
      );
      console.log("fetchFOBSummaryCheckExport Results:", results);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const AcknowledgeFOBSummaryChecks = createAsyncThunk(
  "feedbackonbus_summarycheckdetails/AcknowledgeFOBSummaryChecks",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        FEEDBACK_ON_BUS_SUMMARY_CHECKS_ACKNOWLEDGE_ENDPOINT,
        JSON.stringify(data),
        token
      );
      console.log("AcknowledgeFOBSummaryChecks Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchLatestFOBSummaryCheckAcknowledge = createAsyncThunk(
  "feedbackonbus_summarycheckdetails/fetchLatestFOBSummaryCheckAcknowledge",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        FEEDBACK_ON_BUS_SUMMARY_CHECKS_LATEST_ACKNOWLEDGE_ENDPOINT,
        JSON.stringify(data),
        token
      );
      console.log("fetchLatestFOBSummaryCheckAcknowledge Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAcknowledgeFOBSummaryChecksList = createAsyncThunk(
  "feedbackonbus_summarycheckdetails/fetchAcknowledgeFOBSummaryChecksList",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        FEEDBACK_ON_BUS_SUMMARY_CHECKS_ACKNOWLEDGE_LIST_ENDPOINT,
        JSON.stringify(data),
        token
      );
      console.log("fetchAcknowledgeFOBSummaryChecksList Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const updateFOBSummaryCheckStatus = createAsyncThunk(
  "feedbackonbus_summarycheckdetails/updateFOBSummaryCheckStatus",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        FEEDBACK_ON_BUS_UPDATE_STATUS,
        JSON.stringify(data),
        token
      );
      console.log("updateFOBSummaryCheckStatus Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const AddUpdatesFOBSummaryChecks = createAsyncThunk(
  "feedbackonbus_summarycheckdetails/AddUpdatesFOBSummaryChecks",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        FEEDBACK_ON_BUS_SUMMARY_CHECKS_ADD_UPDATE,
        JSON.stringify(data),
        token
      );
      console.log("AddUpdatesFOBSummaryChecks Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchFOBSummaryCheckUpdates = createAsyncThunk(
  "feedbackonbus_summarycheckdetails/fetchFOBSummaryCheckUpdates",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        FEEDBACK_ON_BUS_SUMMARY_CHECKS_UPDATES_LIST,
        JSON.stringify(data),
        token
      );
      console.log("fetchFOBSummaryCheckUpdates Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);
const FeedbackOnBusSummaryCheckSlice = createSlice({
  name: "FeedbackOnBusSummaryCheck",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFOBSummaryCheckList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFOBSummaryCheckList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchFOBSummaryCheckList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchFOBSummaryCheckExport.pending, (state) => {
        state.export_loading = true;
      })
      .addCase(fetchFOBSummaryCheckExport.fulfilled, (state, action) => {
        state.export_loading = false;
        state.data = action.payload;
      })
      .addCase(fetchFOBSummaryCheckExport.rejected, (state, action) => {
        state.export_loading = false;
        state.export_error = action.error.message;
      })       
      .addCase(fetchFOBSummaryCheckDetailsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFOBSummaryCheckDetailsById.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchFOBSummaryCheckDetailsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchFOBSummaryCheckRouteHistoryById.pending, (state) => {
        state.history_loading = true;
      })
      .addCase(fetchFOBSummaryCheckRouteHistoryById.fulfilled, (state, action) => {
        state.history_loading = false;
        state.history = action.payload;
      })
      .addCase(fetchFOBSummaryCheckRouteHistoryById.rejected, (state, action) => {
        state.history_loading = false;
        state.history_error = action.error.message;
      })
      .addCase(RouteFOBSummaryChecks.pending, (state) => {
        state.route_loading = true;
      })
      .addCase(RouteFOBSummaryChecks.fulfilled, (state, action) => {
        state.route_loading = false;
        state.route_data = action.payload;
      })
      .addCase(RouteFOBSummaryChecks.rejected, (state, action) => {
        state.route_loading = false;
        state.route_error = action.error.message;
      })
      .addCase(AcknowledgeFOBSummaryChecks.pending, (state) => {
        state.ack_loading = true;
      })
      .addCase(AcknowledgeFOBSummaryChecks.fulfilled, (state, action) => {
        state.ack_loading = false;
        state.ack_data = action.payload;
      })
      .addCase(AcknowledgeFOBSummaryChecks.rejected, (state, action) => {
        state.ack_loading = false;
        state.ack_error = action.error.message;
      })
      .addCase(fetchLatestFOBSummaryCheckAcknowledge.pending, (state) => {
        state.latest_ack_loading = true;
      })
      .addCase(fetchLatestFOBSummaryCheckAcknowledge.fulfilled, (state, action) => {
        state.latest_ack_loading = false;
        state.latest_ack = action.payload;
      })
      .addCase(fetchLatestFOBSummaryCheckAcknowledge.rejected, (state, action) => {
        state.latest_ack_loading = false;
        state.latest_ack_error = action.error.message;
      })
      .addCase(updateFOBSummaryCheckStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateFOBSummaryCheckStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateFOBSummaryCheckStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(AddUpdatesFOBSummaryChecks.pending, (state) => {
        state.feedback_updates_loading = true;
      })
      .addCase(AddUpdatesFOBSummaryChecks.fulfilled, (state, action) => {
        state.feedback_updates_loading = false;
        state.feedback_updates = action.payload;
      })
      .addCase(AddUpdatesFOBSummaryChecks.rejected, (state, action) => {
        state.feedback_updates_loading = false;
        state.feedback_updates_error = action.error.message;
      })      
      .addCase(fetchFOBSummaryCheckUpdates.pending, (state) => {
        state.feedback_updates_list_loading = true;
      })
      .addCase(fetchFOBSummaryCheckUpdates.fulfilled, (state, action) => {
        state.feedback_updates_list_loading = false;
        state.feedback_updates_list = action.payload;
      })
      .addCase(fetchFOBSummaryCheckUpdates.rejected, (state, action) => {
        state.feedback_updates_list_loading = false;
        state.feedback_updates_list_error = action.error.message;
      }) 
      .addCase(DeleteFOBSummaryChecks.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteFOBSummaryChecks.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(DeleteFOBSummaryChecks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAcknowledgeFOBSummaryChecksList.pending, (state) => {
        state.feedback_acknowledges_loading = true;
      })
      .addCase(fetchAcknowledgeFOBSummaryChecksList.fulfilled, (state, action) => {
        state.feedback_acknowledges_loading = false;
        state.feedback_acknowledges = action.payload;
      })
      .addCase(fetchAcknowledgeFOBSummaryChecksList.rejected, (state, action) => {
        state.feedback_acknowledges_loading = false;
        state.feedback_acknowledges_error = action.error.message;
      });
  },
});

const FeedbackOnBusSummaryCheckSliceReducer = FeedbackOnBusSummaryCheckSlice.reducer;
export default FeedbackOnBusSummaryCheckSliceReducer;
