import NavigateIcon from "../../../../assets/dr_safe/navigate.png";
// components
import DrInfraDetailsStatusDialog from "./dr_infra_details_status_dialog";
// import DrInfraDetailsDuplicateDialog from "./dr_infra_details_duplicate_dialog";
import DrInfraDetailsRouteToDepDialog from "./dr_infra_details_route_dialog";
import DrInfraDetailsShareDialog from "./dr_infra_details_share_dialog";
import DrInfraDetailsMarkAsDuplicateDialog from "./dr_infra_details_mark_as_duplicate_dialog";


const DrInfraDetailsActions = ({
  onToggleDialog,
  incidentStatuses,
  currentIncidentStatus,
  onChangeIncidentStatus,
  onSaveIncidentStatus,
  showChangeStatusDialog,
  onUpdateStatusBtnClicked,

  onDuplicateClose,
  showDuplicateDialog,
  onDuplicateAction,
  onDuplicateOpen,
  
  onRouteToDepClose,
  onRouteToDepOpen,
  onRouteToDepAction,
  showRouteToDepeDialog,
  case_ref_no,
  parentCasesList,
  parentcaseloading,

  showChangeShareDialog,
  onShareBtnClicked,
  onShareDialogClose,
  onSubmitShare,

  shouldShowBtnUpdateStatus,
  shouldShowBtnRoute,
  shouldShowBtnShare,
  shouldShowBtnMarkDuplicate
}) => {
  return (
    <div className="dr-infra-details-actions-wrapper">
      <div className="dr-infra-details-actions-title">Actions</div>

      <div className="dr-infra-details-actions-button-list">
        {shouldShowBtnUpdateStatus && (
          <div
            className="dr-infra-details-actions-button"
            onClick={onUpdateStatusBtnClicked}
          >
            <span>Update status</span> <img src={NavigateIcon} />
        </div>)
        }
        {shouldShowBtnRoute && (
          <div className="dr-infra-details-actions-button" onClick={onRouteToDepOpen}>
            <span>Route to other department</span> <img src={NavigateIcon} />
          </div>)
        }
        
        {/* <div className="dr-infra-details-actions-button">
          <span>Change incident type</span> <img src={NavigateIcon} />
        </div> */}
        {shouldShowBtnMarkDuplicate && (
          <div className="dr-infra-details-actions-button" onClick={onDuplicateOpen}>
            <span>Mark as duplicate</span> <img src={NavigateIcon} />
          </div>
        )}
        
        {shouldShowBtnShare && (
          <div className="dr-infra-details-actions-button" onClick={onShareBtnClicked}>
            <span>Share</span> <img src={NavigateIcon} />
          </div>
        )}
      </div>
      {showChangeStatusDialog && (
        <DrInfraDetailsStatusDialog
          onToggleDialog={onToggleDialog}
          incidentStatuses={incidentStatuses}
          currentIncidentStatus={currentIncidentStatus}
          onChangeIncidentStatus={onChangeIncidentStatus}
          onSaveIncidentStatus={onSaveIncidentStatus}
        />
      )}
      {showDuplicateDialog && (
        <DrInfraDetailsMarkAsDuplicateDialog
        onToggleDialog={onDuplicateClose}
        onSubmitMarkAsDuplicate={onDuplicateAction}
        parentCasesList={parentCasesList}

        // currentParentCase={currentParentCase}
        // onChangeParentCaseList={onChangeParentCaseList}
      />
      )}
      {showRouteToDepeDialog && (
        <DrInfraDetailsRouteToDepDialog
        case_ref_no={case_ref_no}
        onRouteToDepClose={onRouteToDepClose}
        onRouteToDepAction={onRouteToDepAction}
        />
      )}
      {showChangeShareDialog && (
        <DrInfraDetailsShareDialog
          onToggleDialog={onShareDialogClose}
          onSubmitShare={onSubmitShare}
        />
      )}
    </div>
  );
};

export default DrInfraDetailsActions;
