import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import "./dialog.css";
import { Checkbox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TextBox, TextArea } from "@progress/kendo-react-inputs";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate,useLocation } from "react-router";
import { fetchAllEmailGroupList } from "../../store/emailGroup";
import { Loader } from "@progress/kendo-react-indicators";

export const FobSummaryChecksAcknowledgeDialog = (props) => {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  // redux
  const [remarks,setRemarks] = useState("");
  const onhandleSubmit = (e)=>{
    props.onSubmit(remarks); 
  }
  return (
    <>
      <Dialog
          width={500}
          title={"Acknowledge"}
          onClose={props.onCloseDialog}
      >
        <div className="summary-check-route-remark-div">
          <div>Remarks</div>
          <div>
            <TextBox placeholder="Enter Remarks" style={{"borderRadius": "5px"}} value={remarks} onChange={(e)=> setRemarks(e.target.value)}/>
          </div>
        </div>
        <div className="summary-check-route-button-group">
          <Button className="summary-check-route-button-cancel" onClick={props.onCloseDialog}>Cancel</Button>
          <Button className="summary-check-route-button-submit" onClick={onhandleSubmit}>Submit</Button>
        </div>
      </Dialog>      
    </>
  );
};
