import {
  getUserPermission,
  checkMultiPermission,
  INBOX_ADD_PERMISSIONS,
  INBOX_RECALL_MESSAGE_PERMISSIONS,
  INBOX_DUPLICATE_MESSAGE_PERMISSIONS,
  INBOX_DELETE_MESSAGE_PERMISSIONS,
  INBOX_RESUBMIT_MESSAGE_PERMISSIONS,
  INBOX_ARCHIVE_MESSAGE_PERMISSIONS,
  INBOX_APPROVE_MESSAGE_PERMISSIONS,
  INBOX_EXPORT_PERMISSIONS,
} from "../../../config/permission_helper";

// permission checkers
export const shouldShowRecallMessage = () => {
  const userPermissions = getUserPermission();
  return checkMultiPermission(userPermissions, INBOX_RECALL_MESSAGE_PERMISSIONS)
    ? true
    : false;
};

export const shouldShowDuplicateMessage = () => {
  const userPermissions = getUserPermission();
  return checkMultiPermission(
    userPermissions,
    INBOX_DUPLICATE_MESSAGE_PERMISSIONS
  )
    ? true
    : false;
};

export const shouldShowDeleteMessage = () => {
  const userPermissions = getUserPermission();
  return checkMultiPermission(userPermissions, INBOX_DELETE_MESSAGE_PERMISSIONS)
    ? true
    : false;
};

export const shouldShowResubmitMessage = () => {
  const userPermissions = getUserPermission();
  return checkMultiPermission(
    userPermissions,
    INBOX_RESUBMIT_MESSAGE_PERMISSIONS
  )
    ? true
    : false;
};

export const shouldShowArchiveMessage = () => {
  const userPermissions = getUserPermission();
  return checkMultiPermission(
    userPermissions,
    INBOX_ARCHIVE_MESSAGE_PERMISSIONS
  )
    ? true
    : false;
};

export const shouldShowApproveMessage = () => {
  const userPermissions = getUserPermission();
  return checkMultiPermission(
    userPermissions,
    INBOX_APPROVE_MESSAGE_PERMISSIONS
  )
    ? true
    : false;
};

export const shouldShowAddNew = () => {
  const userPermissions = getUserPermission();
  return checkMultiPermission(userPermissions, INBOX_ADD_PERMISSIONS)
    ? true
    : false;
};

export const shouldShowExport = () => {
  const userPermissions = getUserPermission();
  return checkMultiPermission(userPermissions, INBOX_EXPORT_PERMISSIONS)
    ? true
    : false;
};

// some constants
const RECALL_ACTION = "recall";
const DUPLICATE_ACTION = "duplicate";
const DELETE_ACTION = "delete";
const RESUBMIT_ACTION = "resubmit";
const ARCHIVE_ACTION = "archive";
const APPROVE_ACTION = "approve";
const REJECT_ACTION = "reject";
const ALL_ACTIONS = [
  RECALL_ACTION,
  DUPLICATE_ACTION,
  DELETE_ACTION,
  RESUBMIT_ACTION,
  ARCHIVE_ACTION,
  APPROVE_ACTION,
  REJECT_ACTION,
];

// data filter helpers
export const filterActionByPermissions = (data_all, overview_data) => {
  console.log("this one call", data_all, overview_data);
  let data = data_all;
  const showRecallMessage = shouldShowRecallMessage();
  const showDuplicateMessage = shouldShowDuplicateMessage();
  const showDeleteMessage = shouldShowDeleteMessage();
  const showResubmitMessage = shouldShowResubmitMessage();
  const showArchiveMessage = shouldShowArchiveMessage();
  const showApproveMessage = shouldShowApproveMessage();
  if (overview_data) {
    if (overview_data.total_process !== overview_data.total_recipient) {
      data = data.filter((x) => x.id !== "approve" && x.id !== "reject");
    }
  }
  if (
    showRecallMessage &&
    showDuplicateMessage &&
    showDeleteMessage &&
    showResubmitMessage &&
    showArchiveMessage &&
    showApproveMessage
  ) {
    // user has all permissions so no need to filter
    return data;
  }
  // filter actions according to permissions
  let actions = data.filter((x) => {
    if (x.id === RECALL_ACTION && showRecallMessage) {
      return x;
    } else if (x.id === DUPLICATE_ACTION && showDuplicateMessage) {
      return x;
    } else if (x.id === DELETE_ACTION && showDeleteMessage) {
      return x;
    } else if (x.id === RESUBMIT_ACTION && showResubmitMessage) {
      return x;
    } else if (x.id === ARCHIVE_ACTION && showArchiveMessage) {
      return x;
    } else if (x.id === APPROVE_ACTION && showApproveMessage) {
      return x;
    } else if (x.id === REJECT_ACTION && showApproveMessage) {
      return x;
    } else if (ALL_ACTIONS.indexOf(x.id) < 0) {
      return x;
    }
  });
  console.log("filterActionByPermissions result:", actions);
  return actions;
};

export const filterDataByPermissions = (data) => {
  console.log("filterDataByPermissions data:", data);

  const showRecallMessage = shouldShowRecallMessage();
  const showDuplicateMessage = shouldShowDuplicateMessage();
  const showDeleteMessage = shouldShowDeleteMessage();
  const showResubmitMessage = shouldShowResubmitMessage();
  const showArchiveMessage = shouldShowArchiveMessage();
  const showApproveMessage = shouldShowApproveMessage();

  // user has all permissions so no need to filter
  if (
    showRecallMessage &&
    showDuplicateMessage &&
    showDeleteMessage &&
    showResubmitMessage &&
    showArchiveMessage &&
    showApproveMessage
  ) {
    return data;
  }

  let tmpData = JSON.parse(JSON.stringify(data));
  for (let i = 0; i < tmpData.length; i++) {
    // filter each record action per permissions & assign back to the list
    let actions = filterActionByPermissions(tmpData[i].action);
    tmpData[i].action = actions;
  }
  console.log("filterDataByPermissions result:", tmpData);
  return tmpData;
};
