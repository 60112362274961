import { useMemo, useState } from "react";
import { orderBy } from "@progress/kendo-data-query";

const initialDataState = {
  skip: 1,
  take: 10,
  dir: "asc",
};
const initialSort = [
  {
    field: "publish_on",
    dir: "desc",
  },
];

export const useTable = ({ data }, paginateInfo) => {
  const [page, setPage] = useState(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState(1);
  const [sort, setSort] = useState(initialSort);
  
  const onPageChange = event => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === 'All' ? 77 : event.page.take;
    
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }

    setPage({ ...event.page, take });
  };

  const onSort = (e) => setSort(e.sort);

  const pageable = {
    buttonCount: 4,
    pageSizeValue: pageSizeValue,
    info: false,
  }

  console.log('data : ', data)
  const dataGrid = useMemo(
    () => {

      return orderBy(data ?? [], sort)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )

  return {
    page,
    pageSizeValue,
    sort,
    data: dataGrid,
    pageable,
    onPageChange,
    onSort
  }
}
