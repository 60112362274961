import { SummaryFeedbackExtensionPanel } from "./expansion_panel";
import "../../summary-of-checks.css";
import BackIcon from "../../../../../assets/communication/Back.svg";
import RouteIcon from "../../../../../assets/bsqc/route.svg";
import DeleteIcon from "../../../../../assets/bsqc/delete.svg";
import { Loader } from "@progress/kendo-react-indicators";
import { FEEDBACK_ON_BUS_SUMMARY_OF_CHECK, FEEDBACK_ON_BUS_SUMMARY_OF_CHECK_DETAILS_EMAIL } from "../../../../../routes";
import { Route, useNavigate,useLocation } from "react-router";
import { Button } from "@progress/kendo-react-buttons";
import { FobSummaryChecksRouteDialog } from "../../../../../components/dialog/fob_summary_checks_route";
import { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import {Confirmation} from "../../../../../components/dialog/confirmation";
import { AcknowledgeFOBSummaryChecks, AddUpdatesFOBSummaryChecks } from "../../../../../store/feedbackonbusSummaryChecks";

import { 
  getUserPermission,
  checkMultiPermission,
  FOB_SUMMARY_OF_CHECK_CLOSE_CASE, 
  FOB_SUMMARY_OF_CHECK_REOPEN_CASE
} from "../../../../../config/permission_helper";

import { 
  fetchFOBSummaryCheckDetailsById ,
  fetchFOBSummaryCheckRouteHistoryById,
  DeleteFOBSummaryChecks,
  updateFOBSummaryCheckStatus,
  fetchLatestFOBSummaryCheckAcknowledge,
  AddUpdateFOBSummaryChecks,
  fetchFOBSummaryCheckUpdates
} from "../../../../../store/feedbackonbusSummaryChecks";
import { FobSummaryChecksAcknowledgeDialog } from "../../../../../components/dialog/fob_summary_checks_ack";
import {
  Tooltip
} from "@progress/kendo-react-tooltip";

const Component = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [openRouteDialog,setOpenRouteDialog] = useState(false); 
  const [openDeleteDialog,setOpenDeleteDialog] = useState(false);
  const [openAckDialog,setOpenAckDialog] = useState(false);
  const [routehistory,setRouteHistory] = useState([]);
  const [showCloseConfirmation,setShowCloseConfirmation] = useState(false);
  const [latest_ack_text,setLatestAckText] = useState("");
  const [feedbackUpdates,setFeedbackUpdates] = useState([]);
  const { 
    loading, 
    data: detail_data, 
    error,
    history,
    history_loading,
    ack_loading,
    ack_data,
    ack_error,
    latest_ack_loading,
    latest_ack,
    latest_ack_error,
    feedback_updates,
    feedback_updates_loading,
    feedback_updates_error,
    feedback_updates_list,
    feedback_updates_list_loading,
    feedback_updates_list_error
   } = useSelector(
    (state) => state.SummaryCheckfeedbackOnBus
  );
  // const { ack_loading, ack_data, ack_error } = useSelector((state) => state.SummaryCheckfeedbackOnBus)

  const onRouteChange = (email_groups_ids,remarks)=>{
    // console.log(email_groups_ids);
    const _groups_ids = email_groups_ids.map((v)=>{ return {"id": v.pk, "name": v.name}} );
    // console.log(JSON.stringify(_groups_ids));
    query.set("group_id",JSON.stringify(_groups_ids));
    query.set("remarks",remarks);
    query.set("svc", detail_data.svc);
    query.set("reference_no",detail_data.reference_no);
    // query.set("");
    setOpenRouteDialog(false);
    navigate(FEEDBACK_ON_BUS_SUMMARY_OF_CHECK_DETAILS_EMAIL+"?"+query.toString());
  }
  const shouldShowCloseButton = () => {
    const userPermissions = getUserPermission();
    return checkMultiPermission(userPermissions, FOB_SUMMARY_OF_CHECK_CLOSE_CASE) && detail_data.status_str !== "Closed"
      ? true
      : false;
  };
  const shouldShowReOpenButton = ()=>{
    const userPermissions = getUserPermission();
    return checkMultiPermission(userPermissions, FOB_SUMMARY_OF_CHECK_REOPEN_CASE) && detail_data.status_str === "Closed"
      ? true
      : false;
  }
  useEffect(()=>{
    console.log(detail_data);
  },[detail_data])
  useEffect(()=>{
    setRouteHistory(history);
  },[history])
  
  useEffect(()=>{
    if(feedback_updates_list){
      setFeedbackUpdates(feedback_updates_list);
    }
  },[feedback_updates_list]);

  useEffect(() => {
    let data = {
      "id": query.get("id"),
      "sk": query.get("sk")
    }
    onRefresh(data);
    // dispatch(fetchFOBSummaryCheckDetailsById(data));
    // dispatch(fetchFOBSummaryCheckRouteHistoryById(data));
    // dispatch(fetchLatestFOBSummaryCheckAcknowledge(data));
  }, []);
  const onRefresh = (data)=>{
    dispatch(fetchFOBSummaryCheckDetailsById(data));
    dispatch(fetchFOBSummaryCheckRouteHistoryById(data));
    dispatch(fetchLatestFOBSummaryCheckAcknowledge(data));
    dispatch(fetchFOBSummaryCheckUpdates(data));
  }
  const onhandleDeleteConfirm= ()=>{
    setOpenDeleteDialog(false);
    let data = {
      "id": query.get("id"),
      "sk": query.get("sk")
    }
    dispatch(DeleteFOBSummaryChecks(data)).then((res)=>{
      navigate(FEEDBACK_ON_BUS_SUMMARY_OF_CHECK);
    })
  }
  const onhandleAcknowledge = (remarks)=>{    
    setOpenAckDialog(false);
    let data = {
      "id": query.get("id"),
      "sk": query.get("sk"),
      "remark": remarks,
      "status": "acknowledged"
    }    
    dispatch(AcknowledgeFOBSummaryChecks(data));
    dispatch(updateFOBSummaryCheckStatus(data)).then(()=>{
      onRefresh(data);
    });
  }
  const onhandleClosedCase = ()=>{
    setShowCloseConfirmation(false);
    let data = {
      "id": query.get("id"),
      "sk": query.get("sk"),
      "status": "closed"
    }
    dispatch(updateFOBSummaryCheckStatus(data)).then(()=>{
      onRefresh(data);
    });
  } 
  const onhandleReOpenCase = ()=>{
    let data = {
      "id": query.get("id"),
      "sk": query.get("sk"),
      "status": "acknowledged"
    }
    dispatch(updateFOBSummaryCheckStatus(data)).then(()=>{
      dispatch(fetchFOBSummaryCheckDetailsById(data));
      dispatch(fetchFOBSummaryCheckRouteHistoryById(data))
    });
  } 
  const onhandleAddUpdate = (description)=>{
    console.log(description);
    let data = {
      "id": query.get("id"),
      "sk": query.get("sk"),
      "description": description
    }
    dispatch(AddUpdatesFOBSummaryChecks(data)).then(()=>{
      dispatch(fetchFOBSummaryCheckUpdates(data));
    })
  }
  return (
    <div>
      <div className="summarycheck-detail-header">
        <div className="summarycheck-detail-header-item">
          <div className="detail-header">
            <div className="detail-header-back-button" onClick={() => navigate(FEEDBACK_ON_BUS_SUMMARY_OF_CHECK)}>
              <img src={BackIcon} className="categoryback-icon" width={10}/>
              Back
            </div>
          </div>
        </div>
        <div className="summarycheck-detail-header-item">
        {loading || history_loading || ack_loading || feedback_updates_list_loading ?(<></>):(<>
          <div className="header-right-button-group">
            { detail_data.status_str !== "Closed" && (
              <Tooltip anchorElement="target" parentTitle={true}>
                <Button title={latest_ack_text} onClick={()=> setOpenAckDialog(true)}>Acknowledge</Button>
            </Tooltip>
            )}
            {shouldShowCloseButton() && (
              <Button onClick={()=> setShowCloseConfirmation(true)}>Close</Button>
            )}
            {shouldShowReOpenButton() && (
              <Button onClick={()=> onhandleReOpenCase()}>Re-Open</Button>
            )}
            <Button imageUrl={RouteIcon} onClick={(e)=>setOpenRouteDialog(true)}>{detail_data.status_str == "New"? "Route": "Re-Route"}</Button>
            <Button imageUrl={DeleteIcon} onClick={()=> setOpenDeleteDialog(true)}>Delete</Button>
          </div>        
        </>)}
        </div>
      </div>
      {showCloseConfirmation && (
        <Confirmation
          title={"Confirmation"}
          message={"Are you sure, you want to close the case ?"}
          onClickNo={(e)=> setShowCloseConfirmation(false)}
          onClickYes={onhandleClosedCase}   
        />
      )}
      {openAckDialog && (
        <FobSummaryChecksAcknowledgeDialog
          onCloseDialog={()=> setOpenAckDialog(false)}
          onSubmit={(remarks)=> onhandleAcknowledge(remarks)}
        />
      )}
      {openRouteDialog && (
        <FobSummaryChecksRouteDialog 
          onCloseDialog={(e)=>{setOpenRouteDialog(false)}} 
          onSubmit={(ids,remarks)=>onRouteChange(ids,remarks)}
        />
      )}
      {openDeleteDialog && (
        <Confirmation
        title={"Confirmation"}
        message={"Are you sure, you want to delete ?"}
        onClickNo={(e)=> setOpenDeleteDialog(false)}
        onClickYes={onhandleDeleteConfirm}
      />
      )}
      {loading || history_loading || ack_loading ?(
        <Loader
        type="infinite-spinner"
        style={{
          height: "80vh",
          display: "flex",
          alignItems: "center",
          width: "40px",
          margin: "auto",
          justifyContent: "center",
          minHeight: "100%",
        }}
      />
      ):(
        <SummaryFeedbackExtensionPanel 
          data={detail_data} 
          loading={loading} 
          routehistory = {routehistory} 
          updateHistory = {feedbackUpdates}
          onUpdate={(description)=> onhandleAddUpdate(description)}
          feedback_updates_loading={feedback_updates_loading}
          feedback_updates_list_loading={feedback_updates_list_loading}
        />
      )}
    </div>
  )
}

export default Component;
