import { useEffect, useState } from "react";
import { Navigate, useNavigate,useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { DepartmentListHeader } from './department_list_header';
import '../../telephone_directory.css';
import DepartmentListBody from './department_list_body';
import { fetchAllDepartments, deleteDepartment } from '../../../../store/telephoneDirectory';
import { TELEPHONE_DIRECTORY_DEPARTMENT_ADD, TELEPHONE_DIRECTORY_DEPARTMENT_LIST } from '../../../../routes';
import { DepartmentSearch } from './department_search';

import {
  getUserPermission,
  checkMultiPermission,
} from "../../../../config/permission_helper";
import {
  TEL_DIR_PERMISSIONS_FOR_ADD_DEPARTMENT,
  TEL_DIR_PERMISSIONS_FOR_DELETE_DEPARTMENT,
} from "../../../../config/permission_helper";

export const DepartmentList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const [directoryList, setDirectoryList] = useState([]);
    const [showSearchDialog ,setShowSearchDialog ] = useState(false);
    const { departmentList, department_loading, department_error } = useSelector(
        (state) => state.telephone_directory);
    console.log('Fetch All Departments--',departmentList);
    useEffect(() => {
    if (departmentList) {
        setDirectoryList(departmentList);
    }
    }, [departmentList]);
    useEffect(() => {
        console.log("USEEFFECT----CALL ALL DEPARTMENT")
        dispatch(fetchAllDepartments());
    }, []);
    const handleDeleteAction = ((id)=>{
        dispatch(deleteDepartment(id)).then(()=>{
          dispatch(fetchAllDepartments()).then((res)=>{
            setDirectoryList(res.payload);
          });
        })
      });
      const handleSearchCallback = (data)=>{
        const {
          name,
          // created_by_name
        } = data;
        query.delete("search");
        query.set("name",name);
        // query.set("created_by_name",created_by_name);
        if(name){
          query.set("search",true);
        }
        navigate(TELEPHONE_DIRECTORY_DEPARTMENT_LIST+"?"+ query.toString());
        setShowSearchDialog(false);
        handleFilterAction();
      }
      const handleFilterAction = ()=>{
        console.log("handleFilterAction",departmentList)
        let results = departmentList;
        
        if(query.toString()){
          // if(query.get("created_on_start")){
          //   const formatted_created_on = new Date(moment(query.get("created_on_start")))
          //   results = results.filter((v)=> new Date(moment(v.created_on,"DD MMM YYYY,hh:mma"))>= formatted_created_on)
          // }
          // if(query.get("created_on_end")){
          //   const formatted_created_end = new Date(moment(query.get("created_on_end")).hours(23).minutes(59).seconds(59))
          //   results = results.filter((v)=> new Date(moment(v.created_on,"DD MMM YYYY,hh:mma"))<= formatted_created_end)
          // }
          // if(query.get("updated_on_start")){
          //   const formatted_updated_start = new Date(moment(query.get("updated_on_start")))
          //   results = results.filter((v)=> new Date(moment(v.updated_on,"DD MMM YYYY,hh:mma"))>= formatted_updated_start)
          // }
          // if(query.get("updated_on_end")){
          //   const formatted_updated_end = new Date(moment(query.get("updated_on_end")).hours(23).minutes(59).seconds(59))
          //   results = results.filter((v)=> new Date(moment(v.updated_on,"DD MMM YYYY,hh:mma"))<= formatted_updated_end)
          // }
          if(query.get("name")){
            results = results.filter((v)=> v.department.toLowerCase().indexOf(query.get("name").toLowerCase())>-1)
          }
          // if(query.get("created_by_name")){
          //   results = results.filter((v)=> v.created_by.name.toLowerCase().indexOf(query.get("created_by_name").toLowerCase())>-1)
          // }
        }
        setDirectoryList(results);
      };
      const handleClearSearchOptions = ()=>{
        query.delete("search");
        query.delete("name");
        query.delete("created_by_name");
        navigate(TELEPHONE_DIRECTORY_DEPARTMENT_LIST+"?"+query.toString());
        handleFilterAction();
      };

      const shouldHideAddButton = () => {
        const userPermissions = getUserPermission();
        return checkMultiPermission(userPermissions, TEL_DIR_PERMISSIONS_FOR_ADD_DEPARTMENT)
          ? false
          : true;
      };
  
      const shouldHideDeleteButton = () => {
        const userPermissions = getUserPermission();
        return checkMultiPermission(userPermissions, TEL_DIR_PERMISSIONS_FOR_DELETE_DEPARTMENT)
          ? false
          : true;
      };
    console.log('data from db ',departmentList);
    console.log('data from const ',directoryList);
    return(
        <div className="telephone-dir-list-wrapper">
          {showSearchDialog && (
            <DepartmentSearch
              setShowSearchDialog={setShowSearchDialog}
              onSearchcallback={handleSearchCallback}
            />
          )}
          
            <DepartmentListHeader
                loading={department_loading}
                addurl={TELEPHONE_DIRECTORY_DEPARTMENT_ADD}
                onRefresh = {()=> {dispatch(fetchAllDepartments()).then((res)=>{
                  setDirectoryList(res.payload);
                });}}
                onSearch={() => setShowSearchDialog(true)}
                hideaddbtn={shouldHideAddButton()}
            />
            {query.get("search") && (
            <div className="table-filter-item">
              <div className="table-filter-title">Keyword search applied</div>
              <span className="table-filter-clear" onClick={handleClearSearchOptions}>Clear search</span>
            </div>
            )}
            <DepartmentListBody
             loading = {department_loading}
             data = {directoryList}
             handleDeleteAction={handleDeleteAction}
             hideDeleteButton={shouldHideDeleteButton()}
            />
        </div>
    );
    
};