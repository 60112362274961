import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import "./dialog.css";
import { Checkbox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TextBox, TextArea } from "@progress/kendo-react-inputs";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate,useLocation } from "react-router";
import { fetchAllEmailGroupList } from "../../store/emailGroup";
import { Loader } from "@progress/kendo-react-indicators";

export const FobSummaryChecksRouteDialog = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  // redux
  const { email_groups_loading, email_groups, delete_email_group_loading } =
    useSelector((state) => state.emailGroup);

  const [remarks,setRemarks] = useState("");
  const [selectedEmails,setSelectedEmails] = useState([]);
  const onhandleSubmit = (e)=>{
    props.onSubmit(selectedEmails,remarks); 
  }
  const onhandleSelectedEmails = (e)=>{
    console.log(email_groups);
    var updatedList = [...selectedEmails];
    if(e.target.value){
      var selected_index = email_groups.map((v) => v.pk).indexOf(e.target.name);
      updatedList =[...selectedEmails,email_groups[selected_index]]
    }else{
      var selected_index = selectedEmails.map((v)=>v.pk).indexOf(e.target.name);
      updatedList.splice(selected_index,1);
    }
    console.log(updatedList);    
    setSelectedEmails(updatedList);
  }
  const isChecked = (item) =>
    selectedEmails.map((v) => v.pk).includes(item) ? true : false;
  useEffect(()=>{
    dispatch(fetchAllEmailGroupList());
  },[])
  return (
    <>
    {email_groups_loading ?(
<Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
    ):(
      <>
      <Dialog
          width={500}
          title={"  "}
          onClose={props.onCloseDialog}
      >
        <p className="summarycheck-route-dialog-title">Route</p>
        <div className="summarycheck-route-email-group">
          {email_groups.map((v)=>(
            <div>
              <Checkbox key={v.pk} label={v.name} name={v.pk} onChange={(e)=>onhandleSelectedEmails(e)} checked={isChecked(v.pk)}/>
            </div>
          ))}
        </div>
        <div className="summary-check-route-remark-div">
          <div>Remarks</div>
          <div>
            <TextBox placeholder="Enter Remarks" style={{"borderRadius": "5px"}} value={remarks} onChange={(e)=> setRemarks(e.target.value)}/>
          </div>
        </div>
        <div className="summary-check-route-button-group">
          <Button className="summary-check-route-button-cancel" onClick={props.onCloseDialog}>Cancel</Button>
          <Button className="summary-check-route-button-submit" onClick={onhandleSubmit}>Submit</Button>
        </div>
      </Dialog>      
      </>
    )}
    </>
  );
};
