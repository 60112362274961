import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";

import "./table.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ADMIN_TOOLS_EMAIL_TEMPLATE_DETAILS } from "../../routes";
import { Confirmation } from "../dialog/confirmation";

const initialDataState = {
  skip: 0,
  take: 10,
  dir: "asc",
};

const initialSort = [
  {
    field: "type",
    dir: "asc",
  },
];

const itemRender = (li, itemProps) => {
  const index = itemProps.index;
  const itemChildren = (
    <span
      style={{
        display: "flex",
      }}
    >
      {li.props.children}
    </span>
  );
  return React.cloneElement(li, li.props, itemChildren);
};

const valueRender = (element, value) => {
  if (!value) {
    return element;
  }
  const children = [
    <span>&nbsp;&nbsp;&nbsp;&nbsp; {element.props.children}</span>,
  ];
  return React.cloneElement(
    element,
    {
      ...element.props,
    },
    children
  );
};

export const EmailTemplateTableBody = (props) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState(initialSort);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const options = ["View Details"];
  if (props.showDeleteOption) {
    options.push("Delete");
  }

  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === "All" ? 77 : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const onItemActionChange = (e, val) => {
    const selected = e.target.value;
    const { pk: id } = val;
    if (selected === "View Details") {
      navigate(`${ADMIN_TOOLS_EMAIL_TEMPLATE_DETAILS}?id=${id}`);
    } else {
      setSelectedID(id);
      setShowConfirm(true);
    }
  };
  const handleConfirmDelete = () => {
    setShowConfirm(false);
    props.handleDeleteAction(selectedID);
  };
  return (
    <div className="tbody-content">
      {showConfirm && (
        <Confirmation
          onClickYes={handleConfirmDelete}
          onClickNo={() => setShowConfirm(false)}
        />
      )}
      <Grid
        data={
          props.data
            ? orderBy(props.data.slice(page.skip, page.take + page.skip), sort)
            : []
        }
        skip={page.skip}
        take={page.take}
        total={props.data ? props.data.length : 0}
        sortable={true}
        sort={sort}
        onSortChange={(e) => {
          setSort(e.sort);
        }}
        pageable={{
          buttonCount: 4,
          pageSizes: [5, 10, 15, "All"],
          pageSizeValue: pageSizeValue,
          info: false,
          pageSizes: false,
        }}
        pageSize={false}
        info={false}
        pageSizes={false}
        onPageChange={pageChange}
      >
        <GridColumn field="type" title="Email Type" width={150} />
        <GridColumn field="subject" title="Email Subject" width={200} />
        <GridColumn
          field="content"
          title="Email Content"
          cell={(props) => {
            var text = props.dataItem.content;
            return (
              <td className="k-table-td" style={{ whiteSpace: "pre-wrap" }}>
                <p>{text}</p>
              </td>
            );
          }}
        />
        <GridColumn
          width={150}
          sortable={false}
          field="Action"
          cell={(props) => (
            <td className="k-table-td">
              <DropDownList
                size={"large"}
                fillMode={"solid"}
                rounded={"large"}
                className="action-btn-dropdown"
                data={options}
                valueRender={valueRender}
                itemRender={itemRender}
                defaultValue={"Action"}
                onChange={(e) => onItemActionChange(e, props.dataItem)}
                style={{
                  fontSize: "15px",
                }}
              />
            </td>
          )}
        />
      </Grid>
    </div>
  );
};
