import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUserList } from "../../store/groupMgmt";
// kendo
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { MultiSelect } from "@progress/kendo-react-dropdowns";

import "./notification.css";

export const FilterEmailGroup = (props) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  // redux
  const { group_users, group_users_loading, group_users_error } = useSelector(
    (state) => state.groupMgmt
  );

  // state
  const [loading, setLoading] = useState(true);
  const [allUsers, setAllUsers] = useState([]);

  const [selectedUser, setSelectedUser] = useState([]);

  // methods
  const handleClearFilter = () => {
    setSelectedUser([]);
  };

  const onSubmitFilter = () => {
    let data = {
      user: selectedUser,
    };
    console.log("onSubmitFilter:", data);
    props.onFiltercallback(data);
  };

  const handleUserDropdownChanged = (e) => {
    setSelectedUser(e.target.value.slice(0, 3));
  };

  // useEffect
  useEffect(() => {
    if (group_users) {
      let all_users = ["All"];
      group_users.filter((x) => all_users.push(x.user_name.trim()));
      setAllUsers(all_users);

      setLoading(false);
    }
  }, [group_users]);

  useEffect(() => {
    if (query.toString()) {
      if (query.get("user")) {
        const user = query.get("user");
        console.log("filter user:", user);
        if (user) {
          let usernames = user.split(",");
          setSelectedUser(usernames);
        }
      }
    }

    dispatch(fetchAllUserList());
  }, []);

  return (
    <>
      <Dialog
        className="search-notification"
        title={" "}
        width={395}
        height={"100%"}
        onClose={() => props.setShowFilterDialog(false)}
      >
        {group_users_loading || loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <div className="search-body">
            <div className="filter-title">
              <div className="title">Filter</div>
              <div className="clear-filter" onClick={handleClearFilter}>
                <u>Clear Filter</u>
              </div>
            </div>
            <div className="filter-body">
              <p className="filter-sub-title">Employee</p>
              <div>
                <div className="user-filter-select-multi">
                  <MultiSelect
                    className="user-filter-dropdown-multi"
                    style={{ width: "100%" }}
                    data={allUsers}
                    onChange={handleUserDropdownChanged}
                    value={selectedUser}
                    placeholder="Select (max 3 items)"
                    disabled={group_users_loading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <DialogActionsBar>
          <Button
            className="search-button cancel-btn"
            onClick={() => props.setShowFilterDialog(false)}
          >
            Cancel
          </Button>
          <Button
            className="search-button search-btn"
            onClick={() => onSubmitFilter()}
            disabled={group_users_loading}
          >
            Submit
          </Button>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};
