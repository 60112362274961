import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Dialog,DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";

import "../../../../components/common/dr_search_dialog.css";

const DrInfraListSearchDialog = ({onToggleDialog, onSubmitShare}) => {
    const [caseRefNo, setCaseRefNo] = useState("");
    const [createdBy, setCreatedBy] = useState("");
    const [updatedBy, setUpdatedBy] = useState("");
    const onCaseRefNoChange = (e) => {
        setCaseRefNo(e.target.value);
    }
    const onCreatedByChange = (e) => {
        setCreatedBy(e.target.value);
    }
    const onUpdatedByChange = (e) => {
        setUpdatedBy(e.target.value);
    }
    const onSubmit = () => {
        console.log(caseRefNo, createdBy, updatedBy);
        onSubmitShare(caseRefNo, createdBy, updatedBy)
    };
    return (
        <>
        <Dialog
            className="search-notification"
            title={" "}
            // width={500}
            height={"100%"}
            onClose={onToggleDialog}
            >
            <div className="search-body">
                <div className="noti-dialog-title dialog-row">Search</div>
                <div className="dialog-row">
                    <span className="search-row-title">Case Ref No</span>
                    <Input className="search-input" onChange={onCaseRefNoChange}/>
                </div>
                <div className="dialog-row" style={{height:"80%"}}>
                    <span className="search-row-title">Created by</span>
                    <Input className="search-input" onChange={onCreatedByChange}/>
                </div>
                <div className="dialog-row" style={{height:"80%"}}>
                    <span className="search-row-title">Updated by</span>
                    <Input className="search-input" onChange={onUpdatedByChange}/>
                </div>
            </div>
            <DialogActionsBar>
                <Button className="search-button cancel-btn" onClick={onToggleDialog}>Cancel</Button>
                <Button className="search-button search-btn" onClick={onSubmit}>Submit</Button>
            </DialogActionsBar>
        </Dialog>        
        </>
        
    );
  };

  export default DrInfraListSearchDialog;