import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { useDispatch } from "react-redux";
import "./table.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { IFEEDBACK_VIEW_ROUTE } from "../../routes";
import { Confirmation } from "../dialog/confirmation";

import { Dialog } from "@progress/kendo-react-dialogs";
import {
  checkMultiPermission,
  getUserPermission,
} from "../../config/permission_helper";

const initialDataState = {
  skip: 0,
  take: 10,
  dir: "asc",
};
const initialSort = [
  {
    field: "name",
    dir: "desc",
  },
];

const itemRender = (li, itemProps) => {
  const index = itemProps.index;
  const itemChildren = (
    <span
      style={{
        display: "flex",
      }}
    >
      {li.props.children}
    </span>
  );
  return React.cloneElement(li, li.props, itemChildren);
};

const valueRender = (element, value) => {
  if (!value) {
    return element;
  }
  const children = [
    <span>&nbsp;&nbsp;&nbsp;&nbsp; {element.props.children}</span>,
  ];
  return React.cloneElement(
    element,
    {
      ...element.props,
    },
    children
  );
};

export const IFeedbackTableBody = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState(initialSort);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const userPermissions = getUserPermission();
  const check = checkMultiPermission(userPermissions, [
    "ifeedback.delete_ifeedback",
    "ifeedback.limited_delete_ifeedback",
  ]);

  console.log("props.alldata > ", props);

  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === "All" ? 77 : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const onItemActionChange = (e, val) => {
    const selected = e.target.value;
    console.log(val);
    const { no } = val;
    if (selected === "View Details") {
      navigate(`${IFEEDBACK_VIEW_ROUTE}?id=${no}`);
    } else if (selected === "Delete") {
      props.DeleteFeedback(e, val);
    } else {
      console.log("Update Status", val.no);
      props.openStatusDialog(val.no, val.status);
      setSelectedID(val.no);
      setShowConfirm(true);
    }
  };

  const handlePreviousPage = () => {
    props.handlePreviouscallback();
  };

  const handleNextPage = () => {
    props.handlenextpagecallback();
  };
  // const handleConfirmDelete = (()=>{
  //   setShowConfirm(false);
  //   props.handleDeleteAction(selectedID);
  // })
  // console.log("props in iFeedback Table- ",props);
  return (
    <div className="tbody-content">
      <Grid
        data={props.data} //</div>{orderBy(props.data.slice(page.skip, page.take + page.skip),sort)}
        skip={page.skip}
        take={page.take}
        total={props.data.length}
        sortable={true}
        sort={sort}
        onSortChange={(e) => {
          setSort(e.sort);
        }}
      >
        <GridColumn field="no" title="iFeedback No." />
        <GridColumn field="matter" title="Matter" />
        <GridColumn field="created_by.name" title="Created By" width={200} />
        <GridColumn field="created_on" title="Created On" />
        <GridColumn field="updated_by.name" title="Updated By" />
        <GridColumn field="updated_on" title="Updated On" />
        <GridColumn field="status" title="Status" />

        <GridColumn
          width={160}
          sortable={false}
          field="Action"
          cell={(props) => (
            <td className="k-table-td">
              <DropDownList
                style={{
                  fontSize: "15px",
                  width: "120px",
                  height: "48px",
                  backgroundColor: "#EB6E1F",
                  color: "white",
                  textAlign: "center",
                  borderRadius: "8px",
                }}
                defaultValue={"Action"}
                data={
                  check
                    ? ["Update Status", "View Details", "Delete"]
                    : ["Update Status", "View Details"]
                }
                // data={["Update Status", "View Details", "Delete"]}
                // data={
                //   user_permission &&
                //   user_permission["ifeedback.limited_delete_ifeedback"] === true
                //     ? ["Update Status", "View Details", "Delete"]
                //     : user_permission &&
                //       user_permission["ifeedback.delete_ifeedback"] === true
                //     ? ["Update Status", "View Details", "Delete"]
                //     : ["Update Status", "View Details"]
                // }
                onChange={(e) => onItemActionChange(e, props.dataItem)}
                size={"large"}
                fillMode={"solid"}
                rounded={"large"}
                // className="action-btn-dropdown"
                valueRender={valueRender}
                itemRender={itemRender}
              />
            </td>
          )}
        />
      </Grid>

      <div className="table-custom-pagination">
        <div
          class="k-pager k-pager-md k-grid-pager"
          role="application"
          aria-roledescription="pager"
          style={{ border: "none" }}
        >
          <div class="k-pager-numbers-wrap">
            <button
              title="Go to the previous page"
              role="button"
              aria-disabled="true"
              class="k-button k-button-md k-button-flat k-button-flat-base k-icon-button k-pager-nav"
              disabled={!props.is_previous}
              onClick={handlePreviousPage}
            >
              <span
                class="k-icon k-i-caret-alt-left k-button-icon"
                role="presentation"
              ></span>
            </button>
            <div class="k-pager-numbers">
              {props.data.length > 0 && (
                <>
                  {props.length > 4 ? (
                    <></>
                  ) : (
                    <>
                      {props.alldata &&
                        props.alldata.map((v, i) => (
                          <button
                            role="button"
                            aria-label="undefined 1"
                            aria-current="true"
                            class={`k-button k-button-md k-button-flat k-button-flat-primary ${
                              props.pagenumber === i ? `k-selected` : ""
                            }`}
                            onClick={() => props.handlePageChange(i)}
                          >
                            <span class="k-button-text">{i + 1}</span>
                          </button>
                        ))}
                    </>
                  )}
                </>
              )}
            </div>
            <button
              title="Go to the next page"
              role="button"
              aria-disabled="true"
              class="k-button k-button-md k-button-flat k-button-flat-base k-icon-button k-pager-nav"
              disabled={props.is_next}
              onClick={handleNextPage}
            >
              <span
                class="k-icon k-i-caret-alt-right k-button-icon"
                role="presentation"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
