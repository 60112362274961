
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../config/api";
import config from "../config/config";
// import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const { ISAFE_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: false,
  messages: [],
  error: null,
};

export const fetchMessage = createAsyncThunk(
  "isafe/fetchMessage",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionToken();
      const results = await api.get(ISAFE_ENDPOINT, {}, token);
      console.log('isafe api result>>',results);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);


export const saveMessage = createAsyncThunk(
  "isafe/saveMessage",
  async ({data}, { rejectWithValue }) => {
    try {
      console.log('Save iSafe request',data);
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(`${ISAFE_ENDPOINT}`, JSON.stringify(data), token);
      console.log("Save iSafe API Results:",results);
      return results;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);
const iSafeSlice = createSlice({
    name: "isafe",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchMessage.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchMessage.fulfilled, (state, action) => {
          // console.log(action);
          state.loading = false;
          state.messages = action.payload;
        })
        .addCase(fetchMessage.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        });
    },
  });
  
  const iSafeReducer = iSafeSlice.reducer;
  export default iSafeReducer;