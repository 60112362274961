import React from "react";
import { useNavigate } from "react-router-dom";
// kendo
import { Loader } from "@progress/kendo-react-indicators";
// components
import { DrInfraIncidentTable } from "../../../../components/drTable/dr_infra_incidents_table";
import DrInfraDetailsStatusDialog from "../details/dr_infra_details_status_dialog";
// routes
import { DR_INFRA_INCIDENT_DETAILS } from "../../../../routes";

const DrInfraListBody = ({
  loading,
  records,
  onSortAction,
  openStatusDialog,
  onToggleDialog,
  incidentStatuses,
  currentIncidentStatus,
  onChangeIncidentStatus,
  onSaveIncidentStatus,
  showChangeStatusDialog,
  showUpdateStatusButton,
  DeleteCall,
}) => {
  const navigate = useNavigate();

  const handleActionDropdownClicked = (event, val) => {
    console.log("handleActionDropdownClicked event:", event);
    console.log("handleActionDropdownClicked val:", val);
    if (event.value === "Delete") {
      DeleteCall(val);
    } else {
      const { case_ref_no } = val.dataItem;
      const { status } = val.dataItem;
      if (event.value == "Update Status") {
        console.log("incident currentstatus-", case_ref_no, status);
        openStatusDialog(case_ref_no, status);
      } else {
        navigate(`${DR_INFRA_INCIDENT_DETAILS}?case_ref_no=${case_ref_no}`);
      }
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader className="dr-infra-list-loader" type="infinite-spinner" />
      ) : (
        <DrInfraIncidentTable
          data={records}
          actionDropdownClicked={handleActionDropdownClicked}
          onSortBy={onSortAction}
          showUpdateStatusOption={showUpdateStatusButton}
        />
      )}
      {showChangeStatusDialog && (
        <DrInfraDetailsStatusDialog
          onToggleDialog={onToggleDialog}
          incidentStatuses={incidentStatuses}
          currentIncidentStatus={currentIncidentStatus}
          onChangeIncidentStatus={onChangeIncidentStatus}
          onSaveIncidentStatus={onSaveIncidentStatus}
        />
      )}
    </React.Fragment>
  );
};

export default DrInfraListBody;
