import React, { useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import moment from "moment";
// css
import "./dr_table.css";

const DrInfraLocationTable = ({ data, actionDropdownClicked }) => {
  const initialDataState = {
    skip: 0,
    take: 10,
  };
  const initialSort = [
    {
      field: "case_ref_no",
      dir: "asc",
    },
  ];
  const [page, setPage] = useState(initialDataState);
  const [sort, setSort] = useState(initialSort);
  const [pageSizeValue, setPageSizeValue] = React.useState();
  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === "All" ? 77 : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  return (
    <React.Fragment>
      {data && (
        <Grid
          style={{
            // height: "420px",
          }}
          data={data.slice(page.skip, page.take + page.skip)}
          skip={page.skip}
          take={page.take}
          total={data.length}
          pageable={{
            buttonCount: 4,
            pageSizes: [10,20,30, "All"],
            pageSizeValue: pageSizeValue,
          }}
          onPageChange={pageChange}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
          className="drTable"
        >
          <GridColumn title={"Location Category"} field={"location_category"}/>
          <GridColumn title={"Location"} field={"name"} />
          <GridColumn title={"Rail Line ID"} field={"rail_line_id"}/>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default DrInfraLocationTable;
