// Basic Info Keys
export const BASICINFO_NO = 'basicinfo:no';
export const BASICINFO_SERVICE_NO = 'svc';
export const BASICINFO_DEPOT = 'depot';
export const BASICINFO_INTERCHANGE = 'interchange';

// Time Period Keys (AM)
export const AM_TIME_B_N_NO = 'bus_stop_code_am';
export const AM_TIME_BUS_STOP = 'bus_stop_name_am';
export const AM_TIME_ROAD_NAME = 'road_name_am';
export const AM_TIME_TIMES = 'time_am';
export const AM_TIME_DIRECTIONS = 'direction_am';


// Time Period Keys (PM)
export const PM_TIME_B_N_NO = 'bus_stop_code_pm';
export const PM_TIME_BUS_STOP = 'bus_stop_name_pm';
export const PM_TIME_ROAD_NAME = 'road_name_pm';
export const PM_TIME_TIMES = 'time_pm';
export const PM_TIME_DIRECTIONS = 'direction_pm';

export const getColumn = (amText, pmText) => [
  {
    Header: ' ',
    columns: [
      {
        Header: "No.",
        accessor: BASICINFO_NO,
      },
      {
        Header: "Service. No",
        accessor: BASICINFO_SERVICE_NO,
      },
      {
        Header: "Depot",
        accessor: BASICINFO_DEPOT,
      },
      {
        Header: "Interchange",
        accessor: BASICINFO_INTERCHANGE,
      }
    ]
  },
  {
    Header: amText || "AM (6am to 9am)",
    columns: [
      {
        Header: "B/S No.",
        accessor: AM_TIME_B_N_NO,
      },
      {
        Header: "Bus Stop",
        accessor: AM_TIME_BUS_STOP,
      },
      {
        Header: "Road Name",
        accessor: AM_TIME_ROAD_NAME,
      },
      {
        Header: "Time",
        accessor: AM_TIME_TIMES,
      },
      {
        Header: "Direction",
        accessor: AM_TIME_DIRECTIONS,
      }
    ]
  },
  {
    Header: pmText || "PM (5am to 8am)",
    columns: [
      {
        Header: "B/S No.",
        accessor: PM_TIME_B_N_NO,
      },
      {
        Header: "Bus Stop",
        accessor: PM_TIME_BUS_STOP,
      },
      {
        Header: "Road Name",
        accessor: PM_TIME_ROAD_NAME,
      },
      {
        Header: "Time",
        accessor: PM_TIME_TIMES,
      },
      {
        Header: "Direction",
        accessor: PM_TIME_DIRECTIONS,
      }
    ]
  }
]