// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";
// import { AddMatter } from "../views/admin_tools/ifeedback/matter/matter_add";

const { IFEEDBACK_MATTER_ENDPOINT, IFEEDBACK_MATTER_LIST_ENDPOINT } =
  config.api_endpoint;

const initialState = {
  loading: false,
  matter_ids: [],
  error: null,
  detail_data: {},
  detail_loading: false,
  detail_error: null,
  delete_res: {},
  delete_loading: false,
  delete_error: null,
  matter_list_loading: false,
  matter_list_data: [],
  matter_list_error: null,
};

export const fetchAllMatters = createAsyncThunk(
  "matter/fetchAllMatters",
  async (_, { rejectWithValue }) => {
    try {
      // console.log(query);
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(IFEEDBACK_MATTER_ENDPOINT, {}, token);
      // console.log(results);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchMatterList = createAsyncThunk(
  "matter/fetchMatterList",
  async (_, { rejectWithValue }) => {
    try {
      // console.log(query);
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(IFEEDBACK_MATTER_LIST_ENDPOINT, {}, token);
      // console.log(results);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchMatterById = createAsyncThunk(
  "matter/fetchMatterById",
  async (matter_id, { rejectWithValue }) => {
    try {
      // console.log(matter_id);
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        `${IFEEDBACK_MATTER_ENDPOINT}/${matter_id}`,
        {},
        token
      );
      // console.log(results.data[0])
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const updateMatter = createAsyncThunk(
  "matter/updateMatter",
  async ({ data, matter_id }, { rejectWithValue }) => {
    try {
      console.log(data);
      console.log(matter_id);
      const token = getCurrentLoggedInSessionToken();
      const res = await api.post(
        `${IFEEDBACK_MATTER_ENDPOINT}/${matter_id}`,
        JSON.stringify(data),
        token
      );
      return res.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);
export const createMatter = createAsyncThunk(
  "matter/createMatter",
  async ({ data }, { rejectWithValue }) => {
    try {
      console.log(data);
      const token = getCurrentLoggedInSessionToken();
      const res = await api.post(
        `${IFEEDBACK_MATTER_ENDPOINT}`,
        JSON.stringify(data),
        token
      );
      return res.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);
export const deleteMatter = createAsyncThunk(
  "matter/deleteMatter",
  async (matter_id, { rejectWithValue }) => {
    try {
      console.log(matter_id);
      const token = getCurrentLoggedInSessionToken();
      const res = await api.remove(
        `${IFEEDBACK_MATTER_ENDPOINT}/${matter_id}`,
        JSON.stringify({ id: matter_id }),
        token
      );
      return res.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

const MatterSlice = createSlice({
  name: "matter",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllMatters.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllMatters.fulfilled, (state, action) => {
        state.loading = false;
        state.matter_ids = action.payload;
      })
      .addCase(fetchAllMatters.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchMatterById.pending, (state) => {
        state.detail_loading = true;
      })
      .addCase(fetchMatterById.fulfilled, (state, action) => {
        state.detail_loading = false;
        state.detail_data = action.payload;
        // console.log(state.detail_data);
      })
      .addCase(fetchMatterById.rejected, (state, action) => {
        state.detail_loading = false;
        state.detail_error = action.error.message;
      })
      .addCase(updateMatter.pending, (state) => {
        state.update_loading = true;
      })
      .addCase(updateMatter.fulfilled, (state, action) => {
        state.update_loading = false;
        state.detail_data = action.payload;
      })
      .addCase(updateMatter.rejected, (state, action) => {
        state.update_loading = false;
        state.update_error = action.error.message;
      })
      .addCase(createMatter.pending, (state) => {
        state.update_loading = true;
      })
      .addCase(createMatter.fulfilled, (state, action) => {
        state.update_loading = false;
        state.update_data = action.payload;
      })
      .addCase(createMatter.rejected, (state, action) => {
        state.update_loading = false;
        state.update_error = action.error.message;
      })
      .addCase(deleteMatter.pending, (state) => {
        state.delete_loading = true;
      })
      .addCase(deleteMatter.fulfilled, (state, action) => {
        state.delete_loading = false;
        state.delete_res = action.payload;
      })
      .addCase(deleteMatter.rejected, (state, action) => {
        state.delete_loading = false;
        state.delete_error = action.error.message;
      })
      .addCase(fetchMatterList.pending, (state) => {
        state.matter_list_loading = true;
      })
      .addCase(fetchMatterList.fulfilled, (state, action) => {
        state.matter_list_loading = false;
        state.matter_list_data = action.payload;
      })
      .addCase(fetchMatterList.rejected, (state, action) => {
        state.matter_list_loading = false;
        state.matter_list_error = action.error.message;
      });
  },
});

const MatterReducer = MatterSlice.reducer;
export default MatterReducer;
