// components
import PageTitle from "../../../../components/pageTitle/page_title";
import { Button } from "@progress/kendo-react-buttons";
import { useNavigate } from "react-router";
import '../../telephone_directory.css';
// icons
import ExportIcon from "../../../../assets/dr_safe/export.png";
import FilterIcon from "../../../../assets/dr_safe/filter.png";
import RefreshIcon from "../../../../assets/header/refresh.svg";
import SearchIcon from "../../../../assets/dr_safe/search.png";

export const DepotListHeader = (props) => {
    const navigate = useNavigate();
    const onClickAddButton = ()=>{
        const {addurl} = props;
        navigate(addurl);
      }
    return(
        <div className="telephone-list-header">
            <PageTitle title="Depot/ Sub Depot Listing"/>
            <div className="telephone-list-header-right">
            
            <div className="buttons_group">
            
                {!props.hideaddbtn && <Button className="addnew_btn" onClick={onClickAddButton} style={{borderRadius:8}} >+ Add New </Button>}
            
                <Button className="button2" title="Refresh" onClick={()=> props.onRefresh()} disabled={props.loading}>
                <img src={RefreshIcon} />
                </Button>
           
                <Button className="telephone-header-icon" title="Search" onClick={props?.onSearch} 
                >
                <img src={SearchIcon} />
            </Button>
            </div>
            </div>
        </div>
    );
};