import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TextArea } from "@progress/kendo-react-inputs";
// icon
import CrossIcon from "../../../../assets/dr_safe/cross_gray_icon.png";
// css
import "./dr_secure_details.css";

const DrSecureDetailsShareDialog = ({
  onToggleDialog,
  onSubmitShare,
}) => {
  const navigate = useNavigate();
  const [shareEmails, setShareEmails] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const onShareChange = (e) => {
    setShareEmails(e.target.value);
  };
  const onSubmit = () => {
    if(shareEmails == ''){
      setErrorMessage("Please fill out the below field")
    }
    else
    {
      console.log("Share Emails in Dialog: ",shareEmails);
      onSubmitShare(shareEmails)
    }
  };
  return (
    <Dialog>
      <div className="dr-safe-details-share-dialog">
        <div
          style={{
            position: "absolute",
            top: "15px",
            right: "13px",
            cursor: "pointer",
          }}
          onClick={onToggleDialog}
        >
          <img src={CrossIcon} />
        </div>
        <div className="dr-secure-details-status-dialog-title">Share</div>
        <div className="dr-secure-details-updates-input-wrapper">
          
          <TextArea className="dr-secure-share-updates-input" placeholder='Emails, separate by comma' onChange={onShareChange} 
            />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="dr-secure-details-status-dialog-button"
            onClick={onSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DrSecureDetailsShareDialog;
