import Request from './index';

export const GetAssignmentListing = async ({
  service_no,
  bus_stop_code,
  direction,
  checker_name
}) => {
  const endpoint = `/api-fob/fob-assignment-list?service_no=${service_no}&bus_stop_code=${bus_stop_code}&direction=${direction}&checker_name=${checker_name}`;
  return await Request.get({ url: endpoint });
}
