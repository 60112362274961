import React, { useMemo } from "react";

import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";

import "./table.css"
import { useTable } from "./actions";

export const TableBody = (props) => {
  const initializeTable = {
    data: props?.data ?? [],
    unablePagination: props?.unablePagination
  };

  const {
    page,
    sort,
    data,
    pageable,
    onSort,
    onPageChange
  } = useTable(initializeTable, props?.paginateInfo ?? {});

  const paginateProps = useMemo(
    () => {
      if (props?.useCustomPagination || props?.unablePagination) return {}

      return {
        ...page,
        pageable,
        info: false,
        onPageChange: (e) => {
          if (typeof props?.onNextPage === 'function') {
            props?.onNextPage()
          }
          onPageChange(e);
        },
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageable, props]
  )
  return (
    <div className="tbody-content">
      {props?.loading ? (
        <div style={{ width: '100%' }}>
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        </div>
      ) : (
        <>
          <Grid
            data={data}
            skip={page.dir}
            take={page.take}
            total={props?.data?.length}
            sortable={true}
            sort={sort}
            onSortChange={onSort}
            {...paginateProps}>
            {(props?.table ?? [])?.map(
              ({ field, title, style, cell }) => {
                return (
                  <GridColumn field={field} title={title} {...style} cell={cell}/>
                )
              }
            )}
          </Grid>
          {(props?.useCustomPagination && !props?.unablePagination) && (
            <div className="table-custom-pagination">
              <div class="k-pager k-pager-md k-grid-pager" role="application" aria-roledescription="pager" style={{ border: "none" }}>
                <div class="k-pager-numbers-wrap">
                  <button
                    aria-disabled="true"
                    disabled={props?.paginateInfo?.currentPage === 0}
                    class="k-button k-button-md k-button-flat k-button-flat-base k-icon-button k-pager-nav"
                    onClick={props?.onBackPage}
                  >
                    <span class="k-icon k-i-caret-alt-left k-button-icon" role="presentation"></span>
                  </button>
                  <div class="k-pager-numbers">
                    <>
                      {Array.from(Array(props?.paginateInfo?.lastPage+1).keys()).map((v, i) => (
                        <button
                          key={i}
                          aria-label="undefined 1"
                          aria-current="true"
                          class={`k-button k-button-md k-button-flat k-button-flat-primary ${props.paginateInfo?.currentPage === i ? `k-selected`: ""}`}
                          onClick={() => props?.onChangePage(i)}
                        >
                          <span class="k-button-text">{i + 1}</span>
                        </button>
                      ))}
                    </>
                  </div>
                  <button
                    aria-disabled="true"
                    disabled={!props?.paginateInfo?.nextKey}
                    class="k-button k-button-md k-button-flat k-button-flat-base k-icon-button k-pager-nav"
                    onClick={() => props?.onNextPage(props?.paginateInfo?.currentPage + 1)}
                    >
                    <span class="k-icon k-i-caret-alt-right k-button-icon" role="presentation"></span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};