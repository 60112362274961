import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// kendo
import { Loader } from "@progress/kendo-react-indicators";
// components
import DepartmentTable from "../../../../components/table/departmentTable";
// routes
import { TELEPHONE_DIRECTORY_DEPARTMENT_DETAILS } from "../../../../routes";
import { Confirmation } from "../../../../components/dialog/confirmation";


const DepartmentListBody = ({ 
  loading, 
  data, 
  onSortAction,
  handleDeleteAction,
  onToggleDialog,
  incidentStatuses,
  currentIncidentStatus,
  onChangeIncidentStatus,
  onSaveIncidentStatus,
  showChangeStatusDialog,
  hideDeleteButton }) => {
  const navigate = useNavigate();
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedID,setSelectedID] = useState(null);
  const handleActionDropdownClicked = (event, val) => {
    console.log("handleActionDropdownClicked event:", event);
    console.log("handleActionDropdownClicked val:", val);
    console.log('val.dataItem---',val.dataItem);
    const { id } = val.dataItem;
    if (event.value == 'Delete'){
      console.log("Delete-",id);
      setSelectedID(id);      
      setShowConfirm(true);
    }
    else{
      console.log("View Detail: ",id);
      navigate(`${TELEPHONE_DIRECTORY_DEPARTMENT_DETAILS}?id=${id}`);
    };    
  };
  const handleConfirmDelete = (()=>{
    setShowConfirm(false);
    console.log(selectedID);
    handleDeleteAction(selectedID);
  });
  return (
    <React.Fragment>
      {loading ? (
        <Loader className="dr-safe-list-loader" type="infinite-spinner" />
      ) : (
        <DepartmentTable
          data={data}
          actionDropdownClicked={handleActionDropdownClicked}
        //   onSortBy={onSortAction}
        hideDeleteOption={hideDeleteButton}
        />
      )}
      {showConfirm && (
        <Confirmation 
          onClickYes={handleConfirmDelete}
          onClickNo={()=>setShowConfirm(false)}        
        />
      )}
    </React.Fragment>
    
  );
};

export default DepartmentListBody;
