import { useCallback, useEffect, useRef, useState } from "react";
import qs from "qs";
import {
  FOBCheckerExport,
  FOBPeriodApprove,
  GetBSQCPeriod,
  GetFOBCheckerList,
  GetFOBApprovedCheckerList
} from "../../../services/feedbackOnBus";
import moment from "moment";
import { useLocation, useNavigate } from "react-router";

export const useCheckerPreference = () => {
  const navigate = useNavigate();
  const params = useLocation();

  const [isFetching, setIsFetching] = useState(true);
  const [bsqcPeriodPublish, setBsqcPeriodPublish] = useState([]);
  const [selectPeriodPublish, setSelectPeriodPublish] = useState({});
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [id, setID] = useState("");
  const [list, setList] = useState([]);

  const exportRef = useRef();
  const [exportData, setExportData] = useState([]);

  const { id: idBSQC } = qs.parse(params?.search?.replace("?", ""));

  useEffect(() => {
    if (exportData.length > 0) {
      exportRef.current.link.click();
    }
  }, [exportData]);

  useEffect(() => {
    if (params?.search && idBSQC) {
      setID(idBSQC);
    }
  }, [params?.search, idBSQC]);

  useEffect(() => {
    const getListBSQCPeriod = async () => {
      setIsFetching(true);
      const [err, response] = await GetBSQCPeriod({ is_published: true });

      if (!err && response) {
        const period = response?.data ?? [];

        if (period?.length) {
          const newList = period?.map(
            ({ name, pk, updated_on, end_date, start_date, is_approved }) => ({
              label: `${name} - ${moment(updated_on).format(
                "MMM DD YYYY hh:mm A"
              )}`,
              id: pk,
              isSelected: true,
              data: { name, pk, end_date, start_date, is_approved },
            })
          );
          setIsApproved(response.data[0].is_approved);

          setBsqcPeriodPublish(newList);
          console.log("newList?.[0]: ", newList?.[0]);
        }
      }

      setIsFetching(false);
    };

    getListBSQCPeriod();
  }, []);

  const getListBSQCPeriod = async () => {
    setIsFetching(true);
    const [err, response] = await GetBSQCPeriod({ is_published: true });

    if (!err && response) {
      const period = response?.data ?? [];

      if (period?.length) {
        const newList = period?.map(
          ({ name, pk, updated_on, end_date, start_date, is_approved }) => ({
            label: `${name} - ${moment(updated_on).format(
              "MMM DD YYYY hh:mm A"
            )}`,
            id: pk,
            isSelected: true,
            data: { name, pk, end_date, start_date, is_approved },
          })
        );
        setIsApproved(response.data[0].is_approved);

        setBsqcPeriodPublish(newList);
        console.log("newList?.[0]: ", newList?.[0]);
      }
    }

    setIsFetching(false);
  };

  const GetCheckerList = async (id = "") => {
    console.log("this one call");
    setIsFetching(true);
    const [err, response] = await GetFOBCheckerList(id);

    if (!err && response) {
      const newListChecker = (response?.data || [])?.map(
        ({ is_am, ...rest }) => ({
          ...rest,
          am_pm: is_am ? "AM" : is_am === "" ? "" : "PM",
        })
      );
      console.log(newListChecker);
      setList(newListChecker);
    }

    setIsFetching(false);
  };

  const GetApprovedCheckerList = async (id = "") => {
    console.log("this one call");
    setIsFetching(true);
    const [err, response] = await GetApprovedCheckerList(id);

    if (!err && response) {
      const newListChecker = (response?.data || [])?.map(
        ({ is_am, ...rest }) => ({
          ...rest,
          am_pm: is_am ? "AM" : is_am === "" ? "" : "PM",
        })
      );
      console.log(newListChecker);
      setList(newListChecker);
    }

    setIsFetching(false);
  };

  const refetch = useCallback(() => isApproved == true? GetApprovedCheckerList(id): GetCheckerList(id), [id,isApproved]);

  useEffect(() => {
    if (idBSQC) GetCheckerList(idBSQC);
  }, [params?.search, idBSQC]);

  const onSelectPeriod = ({ pk }) => {
    const findPeriod = bsqcPeriodPublish?.filter(({ id }) => id === pk)[0];

    setSelectPeriodPublish(findPeriod);
    navigate(`/feedback-on-bus/checker-preference?id=${findPeriod?.id}`);
  };

  const onApproved = useCallback(async () => {
    setIsFetching(true);
    if (id) {
      const [err, response] = await FOBPeriodApprove(id);

      if (!err && response) {
        getListBSQCPeriod();
        refetch();
        setSuccess("Success Approve");

        return;
      }

      setErrors(err?.message);
    }
  }, [id]);

  const onExport = useCallback(
    async () => {
      const [err, response] = await FOBCheckerExport(id);
      if (err) return;

      const customHeader = [
        [
          selectPeriodPublish?.data?.name,
          null,
          "BSQC Check Preference",
          null,
          null,
          null,
          null,
          null,
        ],
        [null, null, "Allocation 1", null, null, null, null, null],
      ];

      setExportData([...customHeader, ...response?.data]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, selectPeriodPublish]
  );

  const onCloseSuccessModal = () => setSuccess("");
  const onCloseErrorsModal = () => setErrors("");

  return {
    exportRef,
    exportData,
    id,
    isApproved,
    success,
    list,
    bsqcPeriodPublish,
    selectPeriodPublish,
    isFetching,
    isAbleShowList: !!idBSQC,
    onSelectPeriod,
    onCloseSuccessModal,
    onCloseErrorsModal,
    onApproved,
    onExport,
    GetCheckerList,
    GetApprovedCheckerList,
    refetch,
  };
};
