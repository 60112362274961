import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router";
import "../../communication.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { fetchAllDivisions } from "../../../../store/division";
import { fetchAllInterchanges } from "../../../../store/interchange";
import { fetchAllDepot, fetchDepotByDivisions } from "../../../../store/depot";
import { fetchAllDesignations } from "../../../../store/designation";
import { useDispatch, useSelector } from "react-redux";

export const SelectUserGroups = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    division_ids,
    loading: division_loading,
    error: division_error,
  } = useSelector((state) => state.division);
  const {
    interchanges,
    loading: interchange_loading,
    error: interchange_error,
  } = useSelector((state) => state.interchange);
  const {
    designations,
    loading: designation_loading,
    error: designation_error,
  } = useSelector((state) => state.designation);
  const {
    depot_ids,
    loading: depot_loading,
    error: depot_error,
  } = useSelector((state) => state.depot);
  const [divisionDisabled, setDivisionDisabled] = useState(true);
  const [depotDisabled, setDepotDisabled] = useState(true);
  const [interchangeDisabled, setInterchangeDisabled] = useState(true);
  const [selectAllDivision, setSelectAllDivision] = useState(false);
  const [selectAllDepot, setSelectAllDepot] = useState(false);
  const [selectAllInterchange, setSelectAllInterchange] = useState(false);

  // Division
  const [allDivisions, setAllDivisions] = useState([]);
  const [chosenDivisions, setChosenDivisions] = useState([]);

  // Depot
  // const [depot_loading, setDepotLoading] = useState(false);
  const [allDepots, setAllDepots] = useState([]);
  const [chosenDepots, setChosenDepots] = useState([]);

  // Interchange
  // depot is available in division API so need to filter upon successful dispatch from division
  const [allInterchangesByDepot, setAllInterchangesByDepot] = useState({});
  const [selectedInterchanges, setSelectedInterchanges] = useState([]);
  const [chosenInterchanges, setChosenInterchanges] = useState([]);

  // Designation
  const [allDesignations, setAllDesignations] = useState([]);
  const [chosenDesignations, setChosenDesignations] = useState([]);

  const checkHasSelectAll = (data) => {
    return data.filter((x) => x.id === "all").length > 0 ? true : false;
  };

  const handleDivisionDropdownChanged = async (e) => {
    console.log("division dropdown changed:", e.target.value);
    setSelectAllDivision(false);
    setChosenDivisions(e.target.value);
    setChosenDepots([]);
    setChosenInterchanges([]);
    let depts = [];
    console.log("allDivisions:", allDivisions);
    if (e.target.value.length === 0) {
      // do nothing
    } else if (checkHasSelectAll(e.target.value)) {
      // setDepotLoading(true);
      // setIsAllDivision(true);
      depts = division_ids;
      setSelectAllDivision(true);
    } else {
      depts = e.target.value.filter((x) => x.id !== "all");
    }
    console.log("selected departments:", depts);
    if (depts.length > 0) {
      let data = {
        division: depts.map((x) => x.id),
      };
      console.log(data);
      if (checkHasSelectAll(e.target.value)) {
        // data = {
        //   "division": ["All"]
        // }
        // dispatch(fetchDepotByDivisions({data}));
        dispatch(fetchAllDepot());
      } else {
        dispatch(fetchDepotByDivisions({ data }));
      }
      // let depots = [];
      // depts.map((x) => {
      //   x.depot.forEach((y) => depots.push(y));
      // });
      // depots.unshift({ id: "all", name: "All" });
      // console.log("depots in division dropdown changed:", depots);
      // setAllDepots(depots);
      setDepotDisabled(false);
    } else {
      setAllDepots([]);
      setChosenDepots([]);
      setChosenInterchanges([]);
      setInterchangeDisabled(true);
      setDepotDisabled(true);
      props.resetUserList({});
      props.onFilter({});
    }
    // setDepotLoading(false);
    // setDepotDisabled(false);
  };

  const handleDepotDropdownChanged = (e) => {
    console.log("depot dropdown changed:", e.target.value);
    // console.log("allInterchangesByDepot:", allInterchangesByDepot);
    setChosenDepots(e.target.value);
    setSelectAllDepot(false);
    let selected_depots = [];
    if (e.target.value.length === 0) {
      // do nothing
    } else if (checkHasSelectAll(e.target.value)) {
      // let all_values = Object.keys(allDepots).map(function (key) {
      //   return allInterchangesByDepot[key];
      // });
      // all_values.forEach((x) => _interchanges.push(...x));
      setSelectAllDepot(true);
      selected_depots = depot_ids;
    } else {
      selected_depots = e.target.value.filter((x) => x.id != "all");
    }
    console.log(selected_depots);
    if (selected_depots.length > 0) {
      setInterchangeDisabled(false);
      dispatch(
        fetchAllInterchanges(
          selected_depots.map((x) => {
            return x.id;
          })
        )
      );
    } else {
      setChosenInterchanges([]);
      setSelectedInterchanges([]);
      setInterchangeDisabled(true);
      props.resetUserList({});
      props.onFilter({});
    }
    // console.log("_interchanges:", _interchanges);
    // setSelectedInterchanges(_interchanges);
    // setInterchangeDisabled(false);
  };

  const handleInterchangeDropdownChanged = (e) => {
    console.log("interchange dropdown changed:", e.target.value);
    props.resetUserList({});
    setSelectAllInterchange(false);
    setChosenInterchanges(e.target.value);
    let interchangesToFilter = e.target.value.map((x) => x.id);
    console.log("interchangesToFilter:", interchangesToFilter);
    if (interchangesToFilter.indexOf("all") > -1) {
      setSelectAllInterchange(true);
      props.onFilter({
        interchange: selectedInterchanges
          .slice(1, selectedInterchanges.length)
          .map((x) => x.id),
        is_all: selectAllDivision && selectAllDepot,
        depot: selectAllDepot
          ? depot_ids.map((x) => {
              return x.id;
            })
          : chosenDepots.map((x) => {
              return x.id;
            }),
      });
    } else {
      props.onFilter({
        interchange: interchangesToFilter,
        depot: selectAllDepot
          ? depot_ids.map((x) => {
              return x.id;
            })
          : chosenDepots.map((x) => {
              return x.id;
            }),
      });
    }
  };

  const handleDesignationDropdownChanged = (e) => {
    console.log("designation dropdown changed:", e.target.value);
    setChosenDesignations(e.target.value);
    let designationsToFilter = e.target.value.map((x) => x.id);
    console.log("designationsToFilter:", designationsToFilter);
    if (designationsToFilter.indexOf("all") > -1) {
      props.onFilter({ designation: ["all"] });
    } else {
      props.onFilter({ designation: designationsToFilter });
    }
  };

  useEffect(() => {
    // setAllDepots([]);

    if (division_ids) {
      let all_depot_ids = [];
      let all_divisions = [{ id: "all", name: "All" }];
      division_ids.filter((x) => {
        // let dIds = x.depot.map((a) => a.id);
        // all_depot_ids = [...all_depot_ids, ...dIds];
        all_divisions.push(x);
      });
      // console.log("all_depot_ids:", all_depot_ids);
      setAllDivisions(all_divisions);
      // dispatch(fetchAllInterchanges(all_depot_ids));
      setDivisionDisabled(false);
    }
  }, [division_ids]);

  useEffect(() => {
    console.log("interchanges use effect:", interchanges);
    if (interchanges.filter((x) => x.id != "").length > 0) {
      let depot_interchanges = [];
      let tmp = {};
      interchanges.forEach((i) => {
        if (depot_interchanges.filter((x) => x.id === i.id).length == 0) {
          depot_interchanges.push(i);
        }
        // if (i.id) {
        //   if (i.parent_depot in tmp) {
        //     tmp[i.parent_depot].push(i);
        //   } else {
        //     tmp[i.parent_depot] = [i];
        //   }
        // }
      });
      if (depot_interchanges.filter((x) => x.id).length > 0) {
        setSelectedInterchanges([
          { id: "all", name: "All" },
          ...depot_interchanges,
        ]);
      }
    } else {
      setSelectedInterchanges([]);
    }
  }, [interchanges]);

  useEffect(() => {
    console.log("Depots use effect:", depot_ids);
    if (depot_ids.length > 0) {
      setAllDepots([{ id: "all", name: "All" }, ...depot_ids]);
    } else {
      setAllDepots([]);
    }
  }, [depot_ids]);

  useEffect(() => {
    console.log("designations use effect:", designations);
    if (designations) {
      let tmp = [{ id: "all", name: "All" }, ...designations];
      setAllDesignations(tmp);
    }
  }, [designations]);

  useEffect(() => {
    dispatch(fetchAllDivisions());
    dispatch(fetchAllDesignations());
    // dispatch(fetchAllDepot());
  }, []);

  return (
    <>
      <div className="select-user-group">
        <div className="tab-name">Select User Group</div>
        <div className="user-group-box">
          <div className="user-group-row">
            <div className="user-group-col">
              <label className="user-group-label">
                Division
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
              </label>
              <div className="user-group-select-multi">
                <MultiSelect
                  className="user-group-dropdown-multi"
                  style={{ width: "100%" }}
                  data={allDivisions}
                  onChange={handleDivisionDropdownChanged}
                  autoClose={false}
                  value={chosenDivisions}
                  placeholder="Select"
                  disabled={divisionDisabled || division_loading}
                  textField="name"
                  dataItemKey="id"
                />
              </div>
            </div>
            <div className="user-group-col">
              <label className="user-group-label">
                Department / Depot
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
              </label>
              <div className="user-group-select-multi">
                <MultiSelect
                  className="user-group-dropdown-multi"
                  style={{ width: "100%" }}
                  autoClose={false}
                  data={allDepots}
                  onChange={handleDepotDropdownChanged}
                  value={chosenDepots}
                  placeholder="Select"
                  disabled={
                    depot_loading || depotDisabled
                    // depotDisabled || depot_loading || interchange_loading
                  }
                  textField="name"
                  dataItemKey="id"
                />
              </div>
            </div>
            <div className="user-group-col">
              <label className="user-group-label">
                Section / Interchange Group
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
              </label>
              <div className="user-group-select-multi">
                <MultiSelect
                  autoClose={false}
                  className="user-group-dropdown-multi"
                  style={{ width: "100%" }}
                  data={selectedInterchanges}
                  onChange={handleInterchangeDropdownChanged}
                  value={chosenInterchanges}
                  placeholder="Select"
                  disabled={interchange_loading || interchangeDisabled}
                  textField="name"
                  dataItemKey="id"
                />
              </div>
            </div>
          </div>
          <div className="user-group-row">
            <div className="user-group-col">
              <label className="user-group-label">
                Designation
                {/* <span style={{ color: "red", fontSize: "16px" }}>*</span> */}
              </label>
              <div className="user-group-select-multi">
                <MultiSelect
                  autoClose={false}
                  className="user-group-dropdown-multi"
                  style={{ width: "100%" }}
                  data={allDesignations}
                  onChange={handleDesignationDropdownChanged}
                  value={chosenDesignations}
                  placeholder="Select"
                  disabled={designation_loading}
                  textField="name"
                  dataItemKey="id"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
