import Request from "./index";

export const GetIdeas = async (paramsSearch = {}, options) => {
  const endpoint = "/api-my-ideas/ideas";

  return await Request.get({ url: endpoint, params: paramsSearch }, options);
};

export const GetMostLikedIdeas = async (paramsSearch = {}) => {
  const endpoint = "/api-my-ideas/ideas-top-10";

  return await Request.get({ url: endpoint, params: paramsSearch });
};

export const GetIdeaByID = async (pk = "") => {
  const endpoint = `/api-my-ideas/ideas/${pk}`;

  return await Request.get({ url: endpoint });
};

export const ExportIdeas = async () => {
  const endpoint = "/api-my-ideas/my-ideas-csv";

  return await Request.post({ url: endpoint });
};

/**
 * Department
 */
export const GetCategory = async () => {
  const endpoint = "/api-my-ideas/group";

  return await Request.get({ url: endpoint });
};

export const GetCategoryByID = async (id = "") => {
  const endpoint = `/api-my-ideas/group/${id}`;

  return await Request.get({ url: endpoint });
};

export const CreateDepartment = async (desc = "", userList = []) => {
  const endpoint = "/api-my-ideas/group";
  const payload = { desc, user_list: userList };

  return await Request.post({ url: endpoint, payload });
};

export const UpdateDepartment = async (id = "", payload = {}) => {
  const endpoint = `/api-my-ideas/group/${id}`;

  return await Request.post({ url: endpoint, payload: { ...payload, id } });
};

export const DeleteDepartment = async (id = "") => {
  const endpoint = `/api-my-ideas/group/${id}`;
  const payload = { id };

  return await Request.delete({ url: endpoint, payload });
};
/**
 * Type Of Improvement
 */

export const GetTypeImprovement = async () => {
  const endpoint = `/api-my-ideas/improvement-type`;

  return await Request.get({ url: endpoint });
};

export const GetTypeImprovementByID = async (id = "") => {
  const endpoint = `/api-my-ideas/improvement-type/${id}`;

  return await Request.get({ url: endpoint });
};

export const CreateTypeOfImprovement = async (desc = "") => {
  const endpoint = `/api-my-ideas/improvement-type`;
  const payload = { desc };

  return await Request.post({
    url: endpoint,
    payload,
    headers: { "Content-Type": "application/json" },
  });
};

export const UpdateTypeOfImprovement = async (id = "", desc = "") => {
  const endpoint = `/api-my-ideas/improvement-type/${id}`;
  const payload = { desc, id };

  return await Request.post({ url: endpoint, payload });
};

export const DeleteTypeOfImprovement = async (id = "") => {
  const endpoint = `/api-my-ideas/improvement-type/${id}`;
  const payload = { id };

  return await Request.delete({ url: endpoint, payload });
};

export const UpdateGroup = async () => {};
export const UpdateVisibility = async ({ id, sk, is_visible, comment }) => {
  const endpoint = `/api-my-ideas/visibility/${id}`;
  const payload = { id, sk, is_visible, comment };

  return await Request.post({ url: endpoint, payload });
};
export const UpdateStatus = async ({ id, sk, status }) => {
  console.log(id, sk, status);
  const endpoint = `/api-my-ideas/status/${id}`;
  const payload = { id, sk, status };

  return await Request.post({ url: endpoint, payload });
};

export const deleteMIC = async ({ id }) => {
  const endpoint = `/api-my-ideas/ideas/${id}`;
  const payload = { id };

  return await Request.delete({ url: endpoint, payload });
};

export const ToggleComment = async ({ id, sk, is_show }) => {
  const endpoint = `/api-my-ideas/comments-hide-show/${id}`;
  const payload = { id, sk, is_show };

  return await Request.post({ url: endpoint, payload });
};

export const CreateComment = async (id, desc = "") => {
  const endpoint = `/api-my-ideas/comments/${id}`;

  return await Request.post({ url: endpoint, payload: { desc } });
};

export const ChangeGroup = async ({ id, sk, group_id }) => {
  const endpoint = `/api-my-ideas/change-group/${id}`;
  const payload = { id, sk, group_id };

  return await Request.post({ url: endpoint, payload });
};
