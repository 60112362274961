import { useEffect, useMemo, useState, useCallback } from "react"
import moment from "moment";
import qs from 'qs';
import { useLocation, useNavigate } from "react-router";
import { GetBSQCPeriod, GetFOBApprovedAssigned } from "../../../services/feedbackOnBus";

export const useApprovedAssignment = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const [isFetching, setIsFetching] = useState(false);
  const [period, setPeriod] = useState([]);
  const [selectPeriodPublish, setSelectPeriodPublish] = useState({});
  const [exportData, setExportData] = useState([]);
  
  const [list, setList] = useState([]);
  const [maxAllocation, setMaxAllocation] = useState(0);

  const id = useMemo(
    () => {
      const { id } = qs.parse(params?.search?.replace('?', ''))

      return id
    },
    [params?.search]
  )
  
  useEffect(() => {
    const GetBSQCPeriodApproved = async () => {
      setIsFetching(true)
      const [err, response] = await GetBSQCPeriod({ is_approved: true });

      if (!err && response) {
        const period = response?.data ?? [];

        if (period?.length) {
          const newList = period?.map(
            ({ name, pk, updated_on, end_date, start_date, approved_by }) => ({
              label: `${name} - ${moment(updated_on).format('MMM DD YYYY hh:mm A')}`,
              id: pk,
              isSelected: true,
              approved_by: approved_by?.name,
              data: { name, pk, end_date, start_date }
            })
          );
  
          setPeriod(newList);
        }
      }

      setIsFetching(false)
    }

    GetBSQCPeriodApproved();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const GetBSQCPreferenceSelection = async (idSelect) => {
    setIsFetching(true);
    const [err, response] = await GetFOBApprovedAssigned(idSelect);

    if (!err && response) {
      let getMaxAllocation = response?.data?.max_assignment ?? 1;
      const newList = (response?.data?.items ?? [])?.map(
        ({ item = [], cognito_username, name }) => {
          return { data: item, name }
        }
      )

      const getMaximumItems = (response?.data?.items ?? [])?.reduce(
        (state, curr) => {
          if (state < curr?.item?.length) {
            return curr?.item?.length
          }

          return state;
        },
        0
      )

      setList(newList);
      setMaxAllocation(getMaximumItems ?? getMaxAllocation);
    }

    setIsFetching(false);
  }

  const refetch = () => GetBSQCPreferenceSelection(id);

  const onSelectPeriod = useCallback(
    ({ pk }, disableDirection = false) => {
      const findPeriod = period?.filter(
        ({ id }) => id === pk
      )[0];
  
      console.log('findPeriod: ', findPeriod, pk, period)
      setSelectPeriodPublish(findPeriod);
      if (!disableDirection) navigate(`/feedback-on-bus/approved-assignments?id=${findPeriod?.id}`)
    },
    [period]
  )

  useEffect(() => {
    if (id) {
      GetBSQCPreferenceSelection(id);
    }
  }, [id])

  useEffect(() => {
    if (id) {
      onSelectPeriod({ pk: id}, true);
    }
  }, [id, period]);

  const onExport = useCallback(
    async () => {
      const root = ["","","BSQC Check Preference"];
      let header = [selectPeriodPublish?.data?.name,""];
      Array(maxAllocation).fill("").forEach((_, idx) => {
        let rowHeader = Array.from({ length: 7 });
        rowHeader[0] = "Assignment"+(idx+1);

        rowHeader.forEach((itm) => {
          header.push(itm)
        })
      })

      const tableHeader = new Array(maxAllocation).fill("").map(() => (["SV No", "Bus Stop", "Bus Stop Description", "Road Name", "AM/PM", "Direction", "Time"]));
      let theadList = ["S.No", "Checkers"];
      tableHeader.forEach(itm => {
        theadList = [...theadList, ...itm]
      });

      const data = list.map(
        ({ data: dataItem, name }, idx) => {
          const data = [];

          // Set initial row
          data.push(idx+1);
          data.push(name);

          new Array(maxAllocation).fill("").forEach((_, key) => {
            const currData = dataItem?.[key];

            const AmPm = currData?.service_no
              ? currData?.is_am ? "AM" : "PM"
              : ""
            data.push(currData?.service_no ?? "");
            data.push(currData?.bus_stop_code ?? "");
            data.push(currData?.bus_stop_name ?? "");
            data.push(currData?.road_name ?? "");
            data.push(AmPm);
            data.push(currData?.direction ?? "");
            data.push(currData?.time ?? "");
          })

          return data;
        }
      )

      setExportData([
        root,
        header,
        theadList,
        ...data,
      ])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, id]
  )

  return {
    exportData,
    isFetching,
    list,
    maxAllocation,
    period,
    selectPeriodPublish,
    isAbleShowDetail: !!id,
    onSelectPeriod,
    refetch,
    onExport
  };
}