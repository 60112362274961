import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import "../../communication.css";
import { Icon } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import NextIcon from "../../../../assets/communication/NextIcon.svg";
import Attachment_File from "../../../../assets/communication/attachment_file.svg";
import ReactPlayer from "react-player";
import config from "../../../../config/config";
import api from "../../../../config/api";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../../../config/userPool";
import { Loader } from "@progress/kendo-react-indicators";
import moment from "moment";
import { fetchNotificationRecipients } from "../../../../store/notification";
import { ImageChecker } from "../../../../components/dialog/imageChecker";

const { UPLOAD_ENDPOINT, NOTIFICATION_ENDPOINT } = config.api_endpoint;
const message_body_file_type = "notification_message";
const message_home_banner_file_type = "notification_home_banner";
const message_appwall_file_type = "notification_appwall";
const message_attachment_file_type = "notification_attachement";
const message_video_file_type = "notification_video";
const message_splash_file_type = "notification_splash";
const message_popup_file_type = "notification_popup";
const MAX_FILE_SIZE = 51200;

export const AttachmentForm = (props) => {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const dispatch = useDispatch();
  const [message_body, setMessageBody] = useState([]);
  const [message_homebanner, setMessageHomeBanner] = useState([]);
  const [message_splash, setMessageSplash] = useState([]);
  const [message_popup, setMessagePopUp] = useState([]);
  const [message_appwall, setMessageAppWall] = useState([]);
  const [message_attachment, setMessageAttachment] = useState([]);
  const [message_videos, setMessageVideos] = useState([]);
  const [notification_data, setNotificationData] = useState({});
  const [channel_names, setChannelNames] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [autoSaveOn, setAutoSaveOn] = useState(false);

  const [timer, setTimer] = useState(0);
  const hiddenmessageBodyInput = useRef(null);
  const hiddenmessageBannerInput = useRef(null);
  const hiddenSplashImageInput = useRef(null);
  const hiddenPopUpImageInput = useRef(null);

  const hiddenmessageAppWall = useRef(null);
  const hiddenmessageAttachment = useRef(null);
  const hiddenVideoRef = useRef(null);

  const notification__id = query.get("id");
  const copyId = query.get("copyId", false);
  const { recipients, recipient_loading, recipient_error } = useSelector(
    (state) => state.notification
  );

  // useRef value to check autoSaveOn
  // to save the data if autoSaveOn is true when component unloads
  const autoSaveOnRef = useRef();
  autoSaveOnRef.current = autoSaveOn;
  const recipientsRef = useRef({});
  const notificationDataRef = useRef({});
  const imagesRef = useRef({});
  const [uploadedImage, setUploadedImage] = useState({});
  const [showImageCheckerDialog, setShowImageCheckerDialog] = useState(false);

  useEffect(() => {
    localStorage.removeItem("inbox_save_clicked");
    props.setLoading(true);
    // if (copyId) {
    //   //   load_data(copyId);
    //   dispatch(fetchNotificationRecipients(copyId));
    // } else if (notification__id) {
    //   //   load_data(notification__id);
    //   dispatch(fetchNotificationRecipients(notification__id));
    // }
    return () => {
      let flagFromLocal = localStorage.getItem("inbox_save_clicked");
      if (autoSaveOnRef.current === true && flagFromLocal === null) {
        AutoUpdategetImageUrls(true);
      }
      if (flagFromLocal) {
        localStorage.removeItem("inbox_save_clicked");
      }
    };
  }, []);

  useEffect(() => {
    if (!props.autoSaveInProgress) {
      if (copyId) {
        load_data(copyId);
      } else if (notification__id) {
        load_data(notification__id);
      }
    }
  }, [props.autoSaveInProgress]);

  // useEffect(() => {
  //   recipientsRef.current = recipients;
  //   console.log("load data recipientsRef.current:", recipientsRef.current);
  // }, [recipients]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(timer + 1);
      if (autoSaveOnRef.current) {
        console.log(moment().format());
        AutoUpdategetImageUrls();
        // props.callbackAutoSave(update_body)
      }
    }, 20000);
    return () => clearInterval(interval);
  }, [timer]);
  const load_data = async (recId) => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    await api
      .get(`${NOTIFICATION_ENDPOINT}/${recId}`, {}, token)
      .then((res) => {
        if (res.status === "success") {
          const data = res.data;
          if(data.channel.length == 1 && data.channel.some(e=>e.id =="SplashScreen")){
            props.setIsSplashOnly(true);
          }          
          setNotificationData(data);
          notificationDataRef.current = data;
          let imageObj = {};
          imageObj["app_wall"] = [];
          if (data.message_image) {
            const message_image = data.message_image.map((v) => ({
              id: 1 + Math.random() * (100 - 1),
              file_type: message_body_file_type,
              ...v,
            }));
            imageObj["message_body"] = message_image;
            setMessageBody(message_image);
          }
          if (data.home_banner_image) {
            const home_banner = data.home_banner_image.map((v) => ({
              id: 1 + Math.random() * (100 - 1),
              file_type: message_home_banner_file_type,
              ...v,
            }));
            imageObj["home_banner"] = home_banner;
            setMessageHomeBanner(home_banner);
          }
          if (data.message_video) {
            const message_video_ = data.message_video.map((v) => ({
              id: 1 + Math.random() * (100 - 1),
              file_type: message_video_file_type,
              ...v,
            }));
            imageObj["message_video"] = message_video_;
            setMessageVideos(message_video_);
          }
          if (data.message_attachment) {
            const attachments = data.message_attachment.map((v) => ({
              id: 1 + Math.random() * (100 - 1),
              file_type: message_attachment_file_type,
              ...v,
            }));
            imageObj["message_attachment"] = attachments;
            setMessageAttachment(attachments);
          }
          if (data.splash_image) {
            const splash_image = data.splash_image.map((v) => ({
              id: 1 + Math.random() * (100 - 1),
              file_type: message_splash_file_type,
              ...v,
            }));
            console.log(splash_image);
            imageObj["splash_image"] = splash_image;
            setMessageSplash(splash_image);
          }
          if (data.popup_image) {
            const popup_image = data.popup_image.map((v) => ({
              id: 1 + Math.random() * (100 - 1),
              file_type: message_popup_file_type,
              ...v,
            }));
            imageObj["popup_image"] = popup_image;
            setMessagePopUp(popup_image);
          }
          if (data.channel) {
            const names = data.channel.map((v) => v.id);
            imageObj["channel"] = names;
            setChannelNames(names);
          }
          recipientsRef.current = data.recipient;
          console.log("load data imagesRef.current:", imageObj);
          imagesRef.current = imageObj;
        }
        props.setLoading(false);
      });
  };

  const GetUpdate_body = () => {
    let recipients_list = recipientsRef.current.map((v) => ({
      id: v["id"],
      name: v["name"],
      depot: v.depot || v.department,
      interchange: v.interchange,
      designation: v.designation,
      file1: v.file1,
      file2: v.file2,
      file3: v.file3
    }));
    return {
      title_en: notification_data.title_en,
      title_zh: notification_data.title_zh,
      message_en: notification_data.message_en,
      message_zh: notification_data.message_zh,
      start_date: notification_data.start_date,
      start_time: notification_data.start_time,
      end_date: notification_data.end_date,
      end_time: notification_data.end_time,
      channel: notification_data.channel,
      high_priority_flag: notification_data.high_priority_flag,
      status: notification_data.status,
      link_to: notification_data.link_to,
      link_to_type: notification_data.link_to_type,
      category_id: notification_data.category_id,
      depot: notification_data.depot,
      division: notification_data.division,
      interchange: notification_data.interchange,
      home_banner_image: notification_data.home_banner_image
        ? notification_data.home_banner_image.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      splash_image: notification_data.splash_image
        ? notification_data.splash_image.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      message_image: notification_data.message_image
        ? notification_data.message_image.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      message_video: notification_data.message_video
        ? notification_data.message_video.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      popup_image: notification_data.popup_image
        ? notification_data.popup_image.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      message_attachment: notification_data.message_attachment
        ? notification_data.message_attachment.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      recipient: recipients_list,
    };
  };

  const GetUpdate_body_autosave = () => {
    let recipients_list = recipientsRef.current.map((v) => ({
      id: v["id"],
      name: v["name"],
      depot: v.depot || v.department,
      interchange: v.interchange,
      designation: v.designation,
      file1: v.file1,
      file2: v.file2,
      file3: v.file3
    }));
    return {
      title_en: notificationDataRef.current.title_en,
      title_zh: notificationDataRef.current.title_zh,
      message_en: notificationDataRef.current.message_en,
      message_zh: notificationDataRef.current.message_zh,
      start_date: notificationDataRef.current.start_date,
      start_time: notificationDataRef.current.start_time,
      end_date: notificationDataRef.current.end_date,
      end_time: notificationDataRef.current.end_time,
      channel: notificationDataRef.current.channel,
      high_priority_flag: notificationDataRef.current.high_priority_flag,
      status: notificationDataRef.current.status,
      link_to: notificationDataRef.current.link_to,
      link_to_type: notificationDataRef.current.link_to_type,
      category_id: notificationDataRef.current.category_id,
      depot: notificationDataRef.current.depot,
      division: notificationDataRef.current.division,
      interchange: notificationDataRef.current.interchange,
      home_banner_image: notificationDataRef.current.home_banner_image
        ? notificationDataRef.current.home_banner_image.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      splash_image: notificationDataRef.current.splash_image
        ? notificationDataRef.current.splash_image.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      message_image: notificationDataRef.current.message_image
        ? notificationDataRef.current.message_image.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      message_video: notificationDataRef.current.message_video
        ? notificationDataRef.current.message_video.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      popup_image: notificationDataRef.current.popup_image
        ? notificationDataRef.current.popup_image.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      message_attachment: notificationDataRef.current.message_attachment
        ? notificationDataRef.current.message_attachment.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      recipient: recipients_list,
    };
  };

  const handleClick = (event) => {
    hiddenmessageBodyInput.current.click();
  };

  const handleImageCheckerClose = (file_type) => {
    console.log("handleImageCheckerClose:", file_type);
    if (message_body_file_type === file_type) {
      hiddenmessageBodyInput.current.value = null;
    }
    if (message_home_banner_file_type === file_type) {
      hiddenmessageBannerInput.current.value = null;
    }
    if (message_splash_file_type === file_type) {
      hiddenSplashImageInput.current.value = null;
    }
    if (message_popup_file_type === file_type) {
      hiddenPopUpImageInput.current.value = null;
    }
    setShowImageCheckerDialog(false);
  };

  const handleAddCroppedImage = async (file, file_name, file_type, type) => {
    console.log("handleAddCroppedImage");
    const image_value = {
      file: file,
      file_type,
      url: false,
      name: file_name,
      id: 1 + Math.random() * (100 - 1),
    };
    console.log(file_type);
    let imageObj = { ...imagesRef.current };
    if (file_type === message_body_file_type) {
      setMessageBody((oldArray) => [...oldArray, image_value]);
      imageObj["message_body"] = [...imageObj["message_body"], image_value];
    } else if (file_type === message_home_banner_file_type) {
      setMessageHomeBanner([image_value]);
      imageObj["home_banner"] = [image_value];
    } else if (file_type === message_appwall_file_type) {
      setMessageAppWall([image_value]);
      imageObj["app_wall"] = [image_value];
    } else if (file_type === message_attachment_file_type) {
      setMessageAttachment((arr) => [...arr, image_value]);
      imageObj["message_attachment"] = [
        ...imageObj["message_attachment"],
        image_value,
      ];
    } else if (file_type === message_video_file_type) {
      setMessageVideos([image_value]);
      imageObj["message_video"] = [image_value];
    } else if (file_type == message_splash_file_type) {
      setMessageSplash([image_value]);
      imageObj["splash_image"] = [image_value];
    } else if (file_type == message_popup_file_type) {
      setMessagePopUp([image_value]);
      imageObj["popup_image"] = [image_value];
    }
    imagesRef.current = imageObj;
    setShowImageCheckerDialog(false);
    setAutoSaveOn(true);
    autoSaveOnRef.current = true;
    console.log("Done handleAddCroppedImage");
  };

  const onUploadImage = async (e, file_type) => {
    let required_ratio = 6 / 5;
    // let required_ratio = 3 / 2.12;

    if ([message_splash_file_type].indexOf(file_type) > -1) {
      // required_ratio = 1 / 1.17;
      required_ratio = 5 / 11;
    }

    const fileUploaded = e.target.files[0];
    let flag = true;

    if (fileUploaded.size / 1024 > MAX_FILE_SIZE) {
      props.setErrors("File size too big.");
      flag = false;
    } else {
      if (
        [
          message_body_file_type,
          message_home_banner_file_type,
          message_splash_file_type,
          message_popup_file_type,
        ].indexOf(file_type) > -1
      ) {
        const imageBitmap = await createImageBitmap(fileUploaded); // Blob file
        const { width, height } = imageBitmap;
        const current_ratio = width / height;
        console.log("imageBitmap:", width, height);
        console.log("current_ratio:", current_ratio);
        console.log("required_ratio:", required_ratio);

        let obj = {
          img: fileUploaded,
          width,
          height,
          required_ratio,
          current_ratio,
          file_type,
        };
        console.log("obj:", obj);

        if (required_ratio.toFixed(2) !== current_ratio.toFixed(2)) {
          setUploadedImage(obj);
          setShowImageCheckerDialog(true);
          flag = false;
        }
      }
    }
    if (flag === true) {
      const image_value = {
        file: fileUploaded,
        file_type,
        url: false,
        name: fileUploaded.name,
        id: 1 + Math.random() * (100 - 1),
      };
      console.log(file_type);
      let imageObj = { ...imagesRef.current };
      if (file_type === message_body_file_type) {
        setMessageBody((oldArray) => [...oldArray, image_value]);
        imageObj["message_body"] = [...imageObj["message_body"], image_value];
      } else if (file_type === message_home_banner_file_type) {
        setMessageHomeBanner([image_value]);
        imageObj["home_banner"] = [image_value];
      } else if (file_type === message_appwall_file_type) {
        setMessageAppWall([image_value]);
        imageObj["app_wall"] = [image_value];
      } else if (file_type === message_attachment_file_type) {
        setMessageAttachment((arr) => [...arr, image_value]);
        imageObj["message_attachment"] = [
          ...imageObj["message_attachment"],
          image_value,
        ];
      } else if (file_type === message_video_file_type) {
        setMessageVideos([image_value]);
        imageObj["message_video"] = [image_value];
      } else if (file_type == message_splash_file_type) {
        setMessageSplash([image_value]);
        imageObj["splash_image"] = [image_value];
      } else if (file_type == message_popup_file_type) {
        setMessagePopUp([image_value]);
        imageObj["popup_image"] = [image_value];
      }
      imagesRef.current = imageObj;
      setAutoSaveOn(true);
      autoSaveOnRef.current = true;
    }
  };
  const onRemoveImage = (e, id, file_type) => {
    let imageObj = { ...imagesRef.current };
    if (file_type === message_body_file_type) {
      setMessageBody(message_body.filter((v) => v.id !== id));
      imageObj["message_body"] = imageObj["message_body"].filter(
        (v) => v.id !== id
      );
      hiddenmessageBodyInput.current.value = null;
    } else if (file_type === message_home_banner_file_type) {
      setMessageHomeBanner(message_homebanner.filter((v) => v.id !== id));
      imageObj["home_banner"] = imageObj["home_banner"].filter(
        (v) => v.id !== id
      );
      hiddenmessageBannerInput.current.value = null;
    } else if (file_type === message_appwall_file_type) {
      setMessageAppWall(message_appwall.filter((v) => v.id !== id));
      imageObj["app_wall"] = imageObj["app_wall"].filter((v) => v.id !== id);
    } else if (file_type === message_attachment_file_type) {
      setMessageAttachment(message_attachment.filter((v) => v.id !== id));
      imageObj["message_attachment"] = imageObj["message_attachment"].filter(
        (v) => v.id !== id
      );
    } else if (file_type === message_video_file_type) {
      setMessageVideos(message_videos.filter((v) => v.id !== id));
      imageObj["message_video"] = imageObj["message_video"].filter(
        (v) => v.id !== id
      );
    } else if (file_type === message_splash_file_type) {
      setMessageSplash(message_splash.filter((v) => v.id !== id));
      imageObj["splash_image"] = imageObj["splash_image"].filter(
        (v) => v.id !== id
      );
      hiddenSplashImageInput.current.value = null;
    } else if (file_type === message_popup_file_type) {
      setMessagePopUp(message_popup.filter((v) => v.id !== id));
      imageObj["popup_image"] = imageObj["popup_image"].filter(
        (v) => v.id !== id
      );
      hiddenPopUpImageInput.current.value = null;
    }
    imagesRef.current = imageObj;
    setAutoSaveOn(true);
    autoSaveOnRef.current = true;
  };
  const getImageUrls = async (isSave) => {
    console.log("this one uploading");
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const all_attachments = message_body.concat(
      message_homebanner,
      message_appwall,
      message_attachment,
      message_videos,
      message_splash,
      message_popup
    );
    const uploaded_existing = all_attachments.filter((v) => v.s3_url);
    const new_files = all_attachments.filter((v) => v.file);
    console.log(new_files);
    Promise.all(
      new_files.map(async (i) => {
        console.log(i.file.type.split("/"));
        const extension_ =
          i.file.type === "" ? i.name.split(".") : i.file.type.split("/");
        console.log(extension_[1]);
        return await api
          .post(
            "/api-comm/get-presigned-url",
            JSON.stringify({
              extension: extension_[1],
              upload_type: i.file_type,
            }),
            token
          )
          .then((res) => {
            return api.postDiff(res.data.presigned_url, i.file).then((res1) => {
              console.log(res1);
              if (res1 === 200) {
                return { ...i, url: res.data.key };
              }
            });
          });
      })
    ).then((responese) => {
      console.log(responese);
      const merge_res = responese.concat(uploaded_existing);
      let message_body_url = merge_res.filter(
        (v) => v.file_type === message_body_file_type
      );
      let message_banner_url = merge_res.filter(
        (v) => v.file_type === message_home_banner_file_type
      );
      let message_appwall_url = merge_res.filter(
        (v) => v.file_type === message_appwall_file_type
      );
      let message_attachment_url = merge_res.filter(
        (v) => v.file_type === message_attachment_file_type
      );
      let message_videos_url = merge_res.filter(
        (v) => v.file_type === message_video_file_type
      );
      let message_splash_url = merge_res.filter(
        (v) => v.file_type === message_splash_file_type
      );
      let message_popup_url = merge_res.filter(
        (v) => v.file_type === message_popup_file_type
      );

      setMessageBody(message_body_url);
      setMessageHomeBanner(message_banner_url);
      setMessageAppWall(message_appwall);
      setMessageAttachment(message_attachment_url);
      setMessageVideos(message_videos_url);
      setMessageSplash(message_splash_url);
      setMessagePopUp(message_popup_url);

      const attachment_info = {
        message_image: message_body_url.map(({ name, url }) => ({ name, url })),
        home_banner_image: message_banner_url.map(({ name, url }) => ({
          name,
          url,
        })),
        // app_wall_image: message_appwall_url.map(({name,url}) => ({name,url})),
        message_attachment: message_attachment_url.map(({ name, url }) => ({
          name,
          url,
        })),
        message_video: message_videos_url.map(({ name, url }) => ({
          name,
          url,
        })),
        splash_image: message_splash_url.map(({ name, url }) => ({
          name,
          url,
        })),
        popup_image: message_popup_url.map(({ name, url }) => ({ name, url })),
      };
      let update_info = GetUpdate_body();
      update_info = { ...update_info, ...attachment_info };
      if (notification__id) {
        update_info.id = notification__id;
      }
      console.log(update_info);
      props.submitcallback(update_info);
    });
    // Promise.all(
    //   new_files.map(async (v) => {
    //     const formData = new FormData();
    //     formData.append("upload_type", v.file_type);
    //     formData.append("file", v.file);
    //     formData.append("filename", v.name);
    //     return await api
    //       .postForm(UPLOAD_ENDPOINT, formData, token)
    //       .then(async (res) => {
    //         const result = await res.data;
    //         if (result) {
    //           return { ...v, url: result.url };
    //         }
    //       });
    //   })
    // ).then((res) => {
    //   const merge_res = res.concat(uploaded_existing);
    //   let message_body_url = merge_res.filter(
    //     (v) => v.file_type === message_body_file_type
    //   );
    //   let message_banner_url = merge_res.filter(
    //     (v) => v.file_type === message_home_banner_file_type
    //   );
    //   let message_appwall_url = merge_res.filter(
    //     (v) => v.file_type === message_appwall_file_type
    //   );
    //   let message_attachment_url = merge_res.filter(
    //     (v) => v.file_type === message_attachment_file_type
    //   );
    //   let message_videos_url = merge_res.filter(
    //     (v) => v.file_type === message_video_file_type
    //   );
    //   let message_splash_url = merge_res.filter(
    //     (v) => v.file_type === message_splash_file_type
    //   );
    //   let message_popup_url = merge_res.filter(
    //     (v) => v.file_type === message_popup_file_type
    //   );

    //   setMessageBody(message_body_url);
    //   setMessageHomeBanner(message_banner_url);
    //   setMessageAppWall(message_appwall);
    //   setMessageAttachment(message_attachment_url);
    //   setMessageVideos(message_videos_url);
    //   setMessageSplash(message_splash_url);
    //   setMessagePopUp(message_popup_url);

    //   const attachment_info = {
    //     message_image: message_body_url.map(({ name, url }) => ({ name, url })),
    //     home_banner_image: message_banner_url.map(({ name, url }) => ({
    //       name,
    //       url,
    //     })),
    //     // app_wall_image: message_appwall_url.map(({name,url}) => ({name,url})),
    //     message_attachment: message_attachment_url.map(({ name, url }) => ({
    //       name,
    //       url,
    //     })),
    //     message_video: message_videos_url.map(({ name, url }) => ({
    //       name,
    //       url,
    //     })),
    //     splash_image: message_splash_url.map(({ name, url }) => ({
    //       name,
    //       url,
    //     })),
    //     popup_image: message_popup_url.map(({ name, url }) => ({ name, url })),
    //   };
    //   let update_info = GetUpdate_body();
    //   update_info = { ...update_info, ...attachment_info };
    //   if (notification__id) {
    //     update_info.id = notification__id;
    //   }
    //   console.log(update_info);
    //   props.submitcallback(update_info);
    // });
  };
  const AutoUpdategetImageUrls = async (addLoading) => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    let imageObj = { ...imagesRef.current };
    console.log('imageObj["message_body"]:', imageObj["message_body"]);
    console.log('imageObj["home_banner"]:', imageObj["home_banner"]);
    console.log('imageObj["app_wall"]:', imageObj["app_wall"]);
    console.log(
      'imageObj["message_attachment"]:',
      imageObj["message_attachment"]
    );
    console.log('imageObj["message_video"]:', imageObj["message_video"]);
    console.log('imageObj["splash_image"]:', imageObj["splash_image"]);
    console.log('imageObj["popup_image"]:', imageObj["popup_image"]);
    const all_attachments = imageObj["message_body"].concat(
      imageObj["home_banner"],
      imageObj["app_wall"],
      imageObj["message_attachment"],
      imageObj["message_video"],
      imageObj["splash_image"],
      imageObj["popup_image"]
    );
    console.log("AutoUpdategetImageUrls all_attachments:", all_attachments);
    const uploaded_existing = all_attachments.filter((v) => v.s3_url);
    const new_files = all_attachments.filter((v) => v.file);
    // Promise.all(
    //   new_files.map(async (v) => {
    //     const formData = new FormData();
    //     formData.append("upload_type", v.file_type);
    //     formData.append("file", v.file);
    //     formData.append("filename", v.name);
    //     return await api
    //       .postForm(UPLOAD_ENDPOINT, formData, token)
    //       .then(async (res) => {
    //         const result = await res.data;
    //         if (result) {
    //           return { ...v, url: result.url };
    //         }
    //       });
    //   })
    // )

    Promise.all(
      new_files.map(async (i) => {
        console.log(i.file.type.split("/"));
        const extension_ =
          i.file.type === "" ? i.name.split(".")[1] : i.file.type.split("/");

        return await api
          .post(
            "/api-comm/get-presigned-url",
            JSON.stringify({
              extension: extension_,
              upload_type: i.file_type,
            }),
            token
          )
          .then((res) => {
            console.log(res);
            return api.postDiff(res.data.presigned_url, i.file).then((res1) => {
              console.log(res1);
              if (res1 === 200) {
                return { ...i, url: res.data.key };
              }
            });
          });
      })
    ).then((res) => {
      const merge_res = res.concat(uploaded_existing);
      let message_body_url = merge_res.filter(
        (v) => v.file_type === message_body_file_type
      );
      let message_banner_url = merge_res.filter(
        (v) => v.file_type === message_home_banner_file_type
      );
      let message_appwall_url = merge_res.filter(
        (v) => v.file_type === message_appwall_file_type
      );
      let message_attachment_url = merge_res.filter(
        (v) => v.file_type === message_attachment_file_type
      );
      let message_videos_url = merge_res.filter(
        (v) => v.file_type === message_video_file_type
      );
      let message_splash_url = merge_res.filter(
        (v) => v.file_type === message_splash_file_type
      );
      let message_popup_url = merge_res.filter(
        (v) => v.file_type === message_popup_file_type
      );

      setMessageBody(message_body_url);
      imageObj["message_body"] = message_body_url;

      setMessageHomeBanner(message_banner_url);
      imageObj["home_banner"] = message_banner_url;

      setMessageAppWall(message_appwall);
      imageObj["app_wall"] = message_banner_url;

      setMessageAttachment(message_attachment_url);
      imageObj["message_attachment"] = message_banner_url;

      setMessageVideos(message_videos_url);
      imageObj["message_video"] = message_banner_url;

      setMessageSplash(message_splash_url);
      imageObj["splash_image"] = message_banner_url;

      setMessagePopUp(message_popup_url);
      imageObj["popup_image"] = message_banner_url;

      const attachment_info = {
        message_image: message_body_url.map(({ name, url }) => ({ name, url })),
        home_banner_image: message_banner_url.map(({ name, url }) => ({
          name,
          url,
        })),
        // app_wall_image: message_appwall_url.map(({name,url}) => ({name,url})),
        message_attachment: message_attachment_url.map(({ name, url }) => ({
          name,
          url,
        })),
        message_video: message_videos_url.map(({ name, url }) => ({
          name,
          url,
        })),
        splash_image: message_splash_url.map(({ name, url }) => ({
          name,
          url,
        })),
        popup_image: message_popup_url.map(({ name, url }) => ({ name, url })),
      };
      let update_info = GetUpdate_body_autosave();
      update_info = { ...update_info, ...attachment_info };
      if (notification__id) {
        update_info.id = notification__id;
      }
      console.log(update_info);
      props.callbackAutoSave(update_info, addLoading);
    });
  };
  const onSubmitImage = async (e, isSave) => {
    e.preventDefault();
    localStorage.setItem("inbox_save_clicked", true);
    props.setLoading(true);
    await getImageUrls(isSave);
  };
  return (
    <>
      {showImageCheckerDialog && (
        <ImageChecker
          {...uploadedImage}
          onCloseClicked={handleImageCheckerClose}
          onAddCroppedImage={handleAddCroppedImage}
        />
      )}
      {props.loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div onChange={() => setAutoSaveOn(true)}>
          <div className="message-body no-pad-top">
            <p className="noti-form-title">
              Message Image
              <span className="gray-color-text letter-spacing-1 font-family-poppins font-weight-500">
                {" "}
                (Image ratio 6:5, png or jpeg, Max size 5MB)
              </span>
            </p>
            <input
              className="display-none"
              ref={hiddenmessageBodyInput}
              type="file"
              onChange={(e) => onUploadImage(e, message_body_file_type)}
              accept="image/png, image/jpeg"
            />
            {message_body.map((v) => (
              <>
                <div className="attach-item-box">
                  <div className="attach-item-body">
                    <img
                      alt=""
                      src={v.file ? URL.createObjectURL(v.file) : v.s3_url}
                      className="item-image"
                    />
                    <label className="item-label">{v.name}</label>
                    <Icon
                      name="delete"
                      className="item-delete-icon"
                      onClick={(e) =>
                        onRemoveImage(e, v.id, message_body_file_type)
                      }
                    />
                  </div>
                </div>
              </>
            ))}

            {message_body.length < 3 && (
              <Button
                className="attachment-add-btn font-weight-600"
                onClick={handleClick}
              >
                + <span className="gray-color-text">Add More</span>
              </Button>
            )}
          </div>
          {channel_names.indexOf("HomeBanner") > -1 && (
            <div className="message-body no-pad-top">
              <p className="noti-form-title">
                Home Banner Image
                <span className="gray-color-text letter-spacing-1 font-family-poppins font-weight-500">
                  {" "}
                  (Image ratio 6:5, png or jpeg, Max size 5MB)
                </span>
              </p>
              <input
                className="display-none"
                ref={hiddenmessageBannerInput}
                type="file"
                onChange={(e) =>
                  onUploadImage(e, message_home_banner_file_type)
                }
                accept="image/png, image/jpeg"
              />
              {message_homebanner.map((v) => (
                <>
                  <div className="attach-item-box">
                    <div className="attach-item-body">
                      <img
                        alt=""
                        src={v.file ? URL.createObjectURL(v.file) : v.s3_url}
                        className="item-image"
                      />
                      <label className="item-label">{v.name}</label>
                      <Button
                        className="attachment-replace-btn"
                        onClick={(e) =>
                          hiddenmessageBannerInput.current.click()
                        }
                      >
                        Replace
                      </Button>
                      {/* <Icon name="delete" className="item-delete-icon" onClick={(e) => onRemoveImage(e, v.id, message_home_banner_file_type)} /> */}
                    </div>
                  </div>
                </>
              ))}
              {message_homebanner.length === 0 && (
                <>
                  <Button
                    className="attachment-add-btn font-weight-600"
                    onClick={(e) => hiddenmessageBannerInput.current.click()}
                  >
                    + <span className="gray-color-text">Add</span>
                  </Button>
                </>
              )}
            </div>
          )}

          {channel_names.indexOf("SplashScreen") > -1 && (
            <div className="message-body no-pad-top">
              <p className="noti-form-title">
                Splash Image
                <span className="gray-color-text letter-spacing-1 font-family-poppins font-weight-500">
                  {" "}
                  (Image ratio 5:11, png or jpeg, Max size 5MB)
                </span>
              </p>
              <input
                className="display-none"
                ref={hiddenSplashImageInput}
                type="file"
                onChange={(e) => onUploadImage(e, message_splash_file_type)}
                accept="image/png, image/jpeg"
              />
              {message_splash.map((v) => (
                <>
                  <div className="attach-item-box">
                    <div className="attach-item-body">
                      <img
                        alt=""
                        src={v.file ? URL.createObjectURL(v.file) : v.s3_url}
                        className="item-image"
                      />
                      <label className="item-label">{v.name}</label>
                      {/* <Icon name="delete" className="item-delete-icon" onClick={(e) => onRemoveImage(e, v.id, message_splash_file_type)} /> */}
                      <Button
                        className="attachment-replace-btn"
                        onClick={(e) => hiddenSplashImageInput.current.click()}
                      >
                        Replace
                      </Button>
                    </div>
                  </div>
                </>
              ))}
              {message_splash.length === 0 && (
                <>
                  <Button
                    className="attachment-add-btn font-weight-600"
                    onClick={(e) => hiddenSplashImageInput.current.click()}
                  >
                    + <span className="gray-color-text">Add</span>
                  </Button>
                </>
              )}
            </div>
          )}
          {channel_names.indexOf("PopUp") > -1 && (
            <div className="message-body no-pad-top">
              <p className="noti-form-title">
                PopUp Image
                <span className="gray-color-text letter-spacing-1 font-family-poppins font-weight-500">
                  {" "}
                  (Image ratio 6:5, png or jpeg, Max size 5MB)
                </span>
              </p>
              <input
                className="display-none"
                ref={hiddenPopUpImageInput}
                type="file"
                onChange={(e) => onUploadImage(e, message_popup_file_type)}
                accept="image/png, image/jpeg"
              />
              {message_popup.map((v) => (
                <>
                  <div className="attach-item-box">
                    <div className="attach-item-body">
                      <img
                        alt=""
                        src={v.file ? URL.createObjectURL(v.file) : v.s3_url}
                        className="item-image"
                      />
                      <label className="item-label">{v.name}</label>
                      {/* <Icon name="delete" className="item-delete-icon" onClick={(e) => onRemoveImage(e, v.id, message_popup_file_type)} /> */}
                      <Button
                        className="attachment-replace-btn"
                        onClick={(e) => hiddenPopUpImageInput.current.click()}
                      >
                        Replace
                      </Button>
                    </div>
                  </div>
                </>
              ))}
              {message_popup.length === 0 && (
                <>
                  <Button
                    className="attachment-add-btn font-weight-600"
                    onClick={(e) => hiddenPopUpImageInput.current.click()}
                  >
                    + <span className="gray-color-text">Add</span>
                  </Button>
                </>
              )}
            </div>
          )}

          {/* <div className="message-body no-pad-top"> */}
          {/* <p className="noti-form-title"> */}
          {/* Appreciation Wall Image */}
          {/* <span className="gray-color-text letter-spacing-1 font-family-poppins font-weight-500"> (Max size 5MB)</span> */}
          {/* </p> */}
          {/* <input className="display-none" ref={hiddenmessageAppWall} type="file" onChange={(e) => onUploadImage(e, message_appwall_file_type)}  accept="image/png, image/jpeg"/> */}
          {/* {message_appwall.map((v) => */}
          {/* <> */}
          {/* <div className="attach-item-box"> */}
          {/* <div className="attach-item-body"> */}
          {/* <img alt="" src={v.file? URL.createObjectURL(v.file): v.s3_url} className="item-image" /> */}
          {/* <label className="item-label">{v.name}</label> */}
          {/* <Button className="attachment-replace-btn" onClick={(e) => hiddenmessageAppWall.current.click()}>Replace</Button>                                         */}
          {/* <Icon name="delete" className="item-delete-icon" onClick={(e) => onRemoveImage(e, v.id, message_appwall_file_type)} /> */}
          {/* </div> */}
          {/* </div> */}
          {/* </> */}
          {/* )} */}
          {/* {message_appwall.length === 0 && ( */}
          {/* <> */}
          {/* <Button className="attachment-add-btn font-weight-600" onClick={(e) => hiddenmessageAppWall.current.click()}> */}
          {/* + <span className="gray-color-text">Add</span> */}
          {/* </Button> */}
          {/* </> */}
          {/* )} */}
          {/* </div> */}
          <div className="message-body no-pad-top">
            <p className="noti-form-title">
              Message Attachments
              <span className="gray-color-text letter-spacing-1 font-family-poppins font-weight-500">
                {" "}
                (PDF, Max size 5MB, Not more than 3 files)
              </span>
            </p>
            {message_attachment.map((v) => (
              <>
                <div className="attach-file-box">
                  <div className="attach-file-group">
                    <a className="attach-file" href={v.url}>
                      <img
                        alt=""
                        className="attach-file-icon"
                        src={Attachment_File}
                      />
                      <label className="attach-file-label font-weight-600">
                        {v.name}
                      </label>
                    </a>
                    <div className="attach-file-space">&nbsp;</div>
                    <Icon
                      name="delete"
                      className="item-delete-icon"
                      onClick={(e) =>
                        onRemoveImage(e, v.id, message_attachment_file_type)
                      }
                    />
                  </div>
                </div>
              </>
            ))}
            {message_attachment.length < 3 && (
              <div>
                <input
                  className="display-none"
                  ref={hiddenmessageAttachment}
                  type="file"
                  onChange={(e) =>
                    onUploadImage(e, message_attachment_file_type)
                  }
                  accept=".pdf"
                />

                <Button
                  className="upload-attach-box"
                  onClick={(e) => hiddenmessageAttachment.current.click()}
                >
                  <span className="upload-attach-group">
                    <img alt="" src={Attachment_File} />
                    <label className="attach-file-label">Upload</label>
                  </span>
                </Button>
              </div>
            )}
            <div className="gray-color-text font-weight-600 font-size-16 pad-top-6">
              Total attachment size 15MB
            </div>
          </div>
          <div className="message-body no-pad-top margin-bottom-30">
            <p className="noti-form-title">
              Upload Video
              <span className="gray-color-text letter-spacing-1 font-family-poppins font-weight-500">
                {" "}
                (Recommended aspect ratio 16:9, mp4 or mov, Max size 50MB)
              </span>
            </p>
            <input
              className="display-none"
              ref={hiddenVideoRef}
              type="file"
              onChange={(e) => onUploadImage(e, message_video_file_type)}
              accept=".mp4"
            />
            {message_videos.map((v) => (
              <>
                <div className="attach-item-box">
                  <div className="attach-item-body">
                    <ReactPlayer
                      width={165}
                      height={106}
                      url={v.file ? URL.createObjectURL(v.file) : v.s3_url}
                      controls={true}
                    />
                    <label className="item-label">{v.name}</label>
                    <Button
                      className="attachment-replace-btn"
                      onClick={(e) => hiddenVideoRef.current.click()}
                    >
                      Replace
                    </Button>
                    {/* <Icon name="delete" className="item-delete-icon" onClick={(e) => onRemoveImage(e, v.id, message_video_file_type)} /> */}
                  </div>
                </div>
              </>
            ))}
            {message_videos.length < 1 && (
              <>
                <Button
                  className="attachment-add-btn font-weight-600"
                  onClick={(e) => hiddenVideoRef.current.click()}
                >
                  + <span className="gray-color-text">Add</span>
                </Button>
              </>
            )}
          </div>
          <div className="footer no-pad-left pad-bottom-20">
            <div className="footer-left-btn-groups">
              <Button
                className="footer-previous-btn font-family-poppins"
                onClick={props.backBtncallback}
              >
                Previous
              </Button>
              <Button
                className="footer-save-btn"
                onClick={(e) => onSubmitImage(e, true)}
                disabled={disabled}
              >
                Save
              </Button>
            </div>
            <div className="footer-right">
              <Button
                className="footer-next-btn"
                type="submit"
                onClick={(e) => onSubmitImage(e, false)}
                disabled={disabled}
              >
                Next
                <Icon>
                  <img alt="" src={NextIcon} />
                </Icon>
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
