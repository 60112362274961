import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Dialog,DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox } from "@progress/kendo-react-inputs";
// import { DatePicker } from "@progress/kendo-react-dateinputs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CustomDatePicker } from "../../../../components/custom/datepicker";
import "../../../../components/common/dr_search_dialog.css";
import moment from "moment/moment";

const DrInfraListFilterDialog = ({onToggleDialog, onSubmitFilter}) => {
    const [status, setStatus] = useState("");
    const [createdDateFrom,setCreatedDateFrom] = useState("");
    const [createdDateTo,setCreatedDateTo] = useState("");
    const [updatedDateFrom,setUpdatedDateFrom] = useState("");
    const [updatedDateTo,setUpdatedDateTo] = useState("");
    const filter_status = [
        {value: "submitted",label: "Submitted"},
        {value:"verified",label: "Verified"},
        {value: "checked",label: "Checked"},
        {value:"duplicated",label: "Duplicated"},
        {value:"completed",label: "Completed"}
    ];
    const onStatusChange = (e,value) => {
        console.log("status-",e.target.value);
        var statusList = [...status];
        if (e.target.value) {
            statusList = [...status, value];
        } else {
            statusList.splice(status.indexOf(value),1);
        }
        setStatus(statusList);
      };
    const isStatusChecked = (item) => status.includes(item) ? true:false;
    const handleclearfilter = () => {
        console.log("clear");
        setCreatedDateFrom("");
        setCreatedDateTo("");
        setUpdatedDateFrom("");
        setUpdatedDateTo("");
        setStatus([]);
    };
    const onSubmit = () => {
        console.log("status[]- ",status,"createdDateFrom- ",createdDateFrom,"createdDateTo- ",createdDateTo,"updatedDateFrom-",updatedDateFrom,"updatedDateTo-",updatedDateTo);
        let created_on_from = moment(createdDateFrom).format("YYYY-MM-DD");
        let created_on_to = moment(createdDateTo).format("YYYY-MM-DD");
        let updated_on_from = moment(updatedDateFrom).format("YYYY-MM-DD");
        let updated_on_to = moment(updatedDateTo).format("YYYY-MM-DD");
        onSubmitFilter(status, created_on_from, created_on_to,updated_on_from,updated_on_to);
    };
    return (
        <>
        <Dialog
            className="search-notification"
            title={" "}
            // width={500}
            height={"100%"}
            onClose={onToggleDialog}
            >
            <div className="search-body">
                <div className="filter-title">
                    <div className="title">Filter</div>
                    <div className="clear-filter" onClick={handleclearfilter}><u>Clear Filter</u></div>
                </div>
                {/* <div className="dialog-row">
                    <span className="search-row-title">Defect Location</span>
                    <MultiSelect className="search-input" style={{width: "300px",}}
                        // data={}
                        // defaultValue={}
                    />
                </div>
        
                <div className="dialog-row">
                    <span className="search-row-title">Sub System</span>
                    <MultiSelect className="search-input" style={{width: "300px",}}
                        // data={}
                        // defaultValue={}
                    />
                </div>

                <div className="dialog-row">
                    <span className="search-row-title">System ID</span>
                    <MultiSelect className="search-input" style={{width: "300px",}}
                        // data={}
                        // defaultValue={}
                    />
                </div> */}
                
                <div className="dialog-row" style={{height:"80%"}}>
                    <span className="search-row-title">Created On</span> 
                    <div className="category-flex-row">
                            <div className="category-date-div">
                                <div>Date From</div>
                                <DatePicker
                                    className="filter-date-range"
                                    format={"dd MMM yyyy"}
                                    selected={createdDateFrom}
                                    onChange={(date)=> setCreatedDateFrom(date)}
                                    customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                                    />
                            </div>
                            <div  className="category-date-div">                               
                                <div>Date To</div>
                                <DatePicker
                                    className="filter-date-range"
                                    format={"dd MMM yyyy"}
                                    selected={createdDateTo}
                                    onChange={(date)=> setCreatedDateTo(date)}
                                    customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                                    />
                            </div>
                        </div> 
                </div>
                <div className="dialog-row" style={{height:"80%"}}>
                    <span className="search-row-title">Updated On</span>
                    <div className="category-flex-row">
                            <div className="category-date-div">
                                <div>Date From</div>
                                <DatePicker
                                    className="filter-date-range"
                                    format={"dd MMM yyyy"}
                                    selected={updatedDateFrom}
                                    onChange={(date)=> setUpdatedDateFrom(date)}
                                    customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                                    />
                            </div>
                            <div  className="category-date-div">                               
                                <div>Date To</div>
                                <DatePicker
                                    className="filter-date-range"
                                    format={"dd MMM yyyy"}
                                    selected={updatedDateTo}
                                    onChange={(date)=> setUpdatedDateTo(date)}
                                    customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                                    />
                            </div>
                        </div> 
                </div>
                <div className="dialog-row">
                    <span className="search-row-title">Status</span>
                    <div className="category-flex-row">
                            {filter_status.map((v)=>(
                                <div className="date-option-div">
                                    <Checkbox
                                        style={{border:"1px solid #CCC"}}
                                        key={v.value} 
                                        value={v.value}
                                        checked={isStatusChecked(v.value)}
                                        onChange={(e) => onStatusChange(e,v.value)}                                   
                                    />
                                    <label>{v.label}</label>
                                </div>
                            ))}
                        </div>  
                </div>
            </div>
            <DialogActionsBar>
                <Button className="search-button cancel-btn" onClick={onToggleDialog}>Cancel</Button>
                <Button className="search-button search-btn" onClick={onSubmit}>Submit</Button>
            </DialogActionsBar>
        </Dialog>        
        </>
        
    );
  };

  export default DrInfraListFilterDialog;