import React, { useState, useEffect } from "react";
// redux
import { useNavigate } from "react-router";
import { fetchAllPermissions } from "../../../store/groupMgmt";
// component
import { PermissionListDialog } from "../../../components/dialog/permissionListDialog";
import { UserListUserMgmtDialog } from "../../../components/dialog/userListUserMgmtDialog";
// kendo
import { Button } from "@progress/kendo-react-buttons";
import { TextBox } from "@progress/kendo-react-inputs";
// routes
import { USER_MANAGEMENT_GROUPS } from "../../../routes";

export const GroupManagementForm = ({
  data,
  isEdit,
  submitForm,
  setLoading,
}) => {
  // redux
  const navigate = useNavigate();
  // state
  const [name, setName] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [users, setUsers] = useState([]);
  const [showPermissionList, setShowPermissionList] = useState(false);
  const [showUserList, setShowUserList] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  // methods
  const getPermissionNames = (d) => {
    let codes = [];
    for (let i = 0; i < d.length; i++) {
      d[i].permissions.filter((x) => codes.push(x.name));
    }
    return codes;
  };

  const permissionSelectedCallback = (data) => {
    setPermissions(data);
    setShowPermissionList(false);
  };

  const userSelectedCallback = (data) => {
    setUsers(data);
    setShowUserList(false);
  };

  const cancelForm = () => {
    console.log(data);
    if (data & isEdit) {
      // setIsEdit(true);
    } else {
      navigate(USER_MANAGEMENT_GROUPS);
    }
  };

  const submitData = () => {
    setLoading(true);
    let data = {
      name,
      perms: getPermissionNames(permissions),
      usernames: users.map((x) => x.username),
    };
    submitForm(data);
  };

  // useEffect
  useEffect(() => {
    console.log("Group Details data:", data);
    if (data) {
      setName(data.name);
      setPermissions(data.perms);
      setUsers(data.users);
    }
  }, []);

  useEffect(() => {
    if (name && permissions.length > 0 && users.length > 0) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [name, permissions, users]);

  return (
    <>
      {showUserList && (
        <UserListUserMgmtDialog
          callback={userSelectedCallback}
          defaultSelected={users}
          onClickNo={() => setShowUserList(false)}
        />
      )}
      {showPermissionList && (
        <PermissionListDialog
          callback={permissionSelectedCallback}
          defaultSelected={getPermissionNames(permissions)}
          onClickNo={() => setShowPermissionList(false)}
        />
      )}
      <table>
        <tr>
          <td>Group Name</td>
          {isEdit ? (
            <td>
              <TextBox value={name} onChange={(e) => setName(e.target.value)} />
            </td>
          ) : (
            <td>{data.name}</td>
          )}
        </tr>
        <tr>
          <td>List of Permission</td>
          <td>
            {isEdit ? (
              <div className="group-edit-permission-layout">
                <Button onClick={() => setShowPermissionList(true)}>
                  Click here to update the permission(s)
                </Button>
                {permissions
                  ? permissions.map((v) => (
                      <div className="group-permission-item">
                        <p className="group-permission-item-title">
                          {v.module_name}
                        </p>
                        <div className="group-permission-item-permission-group">
                          {v.permissions.map((pp) => (
                            <p>
                              {pp.description} ({pp.name})
                            </p>
                          ))}
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            ) : (
              <div className="group-edit-permission-layout">
                {data.perms
                  ? data.perms.map((v) => (
                      <div className="group-permission-item">
                        <p className="group-permission-item-title">
                          {v.module_name}
                        </p>
                        <div className="group-permission-item-permission-group">
                          {v.permissions.map((pp) => (
                            <p>
                              {pp.description} ({pp.name})
                            </p>
                          ))}
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            )}
          </td>
        </tr>
        <tr>
          <td>List of User</td>
          <td>
            {isEdit ? (
              <div className="group-edit-permission-layout">
                <Button onClick={() => setShowUserList(true)}>
                  Click here to update the user(s)
                </Button>
                {users
                  ? users.map((v) => (
                      <div className="group-permission-item">
                        {v.name || v.user_name}
                      </div>
                    ))
                  : null}
              </div>
            ) : (
              <div className="group-edit-permission-layout">
                {data.users
                  ? data.users.map((v) => (
                      <div className="group-permission-item">
                        {v.name || v.user_name}
                      </div>
                    ))
                  : null}
              </div>
            )}
          </td>
        </tr>
        {isEdit && (
          <tr>
            <td colSpan={2}>
              <div className="group-detail-button-div">
                <Button
                  className="group-detail-button group-detail-cancel"
                  onClick={cancelForm}
                >
                  Cancel
                </Button>
                <Button
                  className="group-detail-button group-detail-submit"
                  onClick={submitData}
                  disabled={disableSubmit}
                >
                  Submit
                </Button>
              </div>
            </td>
          </tr>
        )}
      </table>
    </>
  );
};
