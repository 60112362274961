import React, { useEffect, useState } from "react";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Dialog,DialogActionsBar } from "@progress/kendo-react-dialogs";
import "./notification.css";
import "./dialog.css";

import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker, start } from "@progress/kendo-react-dateinputs";
import { CustomDatePicker } from "../custom/datepicker";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import moment from "moment";
import { useLocation,useNavigate } from "react-router";
import { FEEDBACK_ON_BUS_SERVICE_CHECKER_LIST } from "../../routes";
import { TextBox } from "@progress/kendo-react-inputs";

export const FilterSummaryChecks = (props) => {
    const navigate = useNavigate();
    let {search} = useLocation();
    const query = new URLSearchParams(search);
    const [selectedRange,setSelectedRange] = useState("Select");
    const [selectedYear,setSelectedYear] = useState("2024");
    const [selectedMonth,setSelectedMonth] =useState("Jan");
    const [selectedQuarter,setSelectedQuarter] =useState("Q1");
    const [startDate,setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [selectedScheme,setSelectedScheme] = useState("Feedback Scheme");
    const [selectedStatus,setSelectedStatus] = useState("Select All");
    const [selectedShow,setSelectedShow] = useState('10');
    const [searchRef,setSearchRef] = useState("");
    const onChangeRange = (e)=>{
        console.log(e.target.value);        
    }
    const onClearFilter = ()=>{
        setSelectedRange("Select");
        query.delete("range_type");
        query.delete("filter_year");
        query.delete("filter_month");
        query.delete("filter_quarter");
        query.delete("filter_start_date");
        query.delete("filter_end_date");
        query.delete("scheme");
        query.delete("status");
        query.delete("ref_no");
        navigate(`/feedback-on-bus/summary-of-check?${query.toString()}`);
    }
    useEffect(()=>{
        if(query.get("range_type","")){       
            if(query.get("range_type") == "yearly")     
                setSelectedRange("Yearly");
            else if(query.get("range_type") == "monthly")
                setSelectedRange("Monthly");
            else if(query.get("range_type") == "quarterly")
                setSelectedRange("Quarterly");
            else if(query.get("range_type" == "custom"))
                setSelectedRange("Custom")
            else
                setSelectedRange("Select");
        }
        else
            setSelectedRange("Select");        
        if(query.get("filter_year")){
            setSelectedYear(query.get("filter_year"));
        }
        if(query.get("filter_month"))
            setSelectedMonth(query.get("filter_month"))
        if(query.get("filter_quarter"))
            setSelectedQuarter(query.get("filter_quarter"))
        if(query.get("filter_start_date")){
            setStartDate(moment(query.get("filter_start_date")));
        }
        if(query.get("filter_end_date","") != ""){
            setEndDate(moment(query.get("filter_end_date")))
        }
        if(query.get('scheme'))
            setSelectedScheme(query.get("scheme"))
        else{
            setSelectedScheme("Feedback Scheme");
        }
        if(query.get("status","")){
            setSelectedStatus(query.get("status"));
        }else{
            setSelectedStatus("Select All");
        }
        if(query.get("ref_no")){
            setSearchRef(query.get("ref_no"));
        }
    },[])
    const onhandleSubmit = ()=>{        
        let data = {}
        if(selectedRange != "Select"){                        
            query.set("range_type",selectedRange.toLocaleLowerCase());
            query.set("filter_year",selectedYear);
            query.set("filter_month",selectedMonth);
            query.set("filter_quarter",selectedQuarter);
            if(startDate != null){
                query.set("filter_start_date", moment(startDate).format("YYYY-MM-DD"));
            }
            if(endDate != null){
                query.set("filter_end_date",moment(endDate).format("YYYY-MM-DD"));
            }
        }
        if(selectedScheme != "Feedback Scheme"){
            query.set("scheme", selectedScheme);
        }
        if(selectedStatus != "Select All"){
            query.set("status",selectedStatus);
        }
        if(searchRef != ""){
            query.set("ref_no",searchRef);
        }
        props.onClose();
        navigate(`/feedback-on-bus/summary-of-check?${query.toString()}`);
    }
    return (
        <>
        <Dialog
            className="search-notification"
            title={" "}
            // width={500}
            height={"100%"}
            onClose={props.onClose}
            >
            <div className="search-body">
                <div className="filter-title">
                    <div className="title">Filter</div>
                    <div className="clear-filter" onClick={onClearFilter}><u>Clear Filter</u></div>
                </div>
                <div className="dialog-row" style={{height:"80%"}}>
                    <span className="search-row-title">Select</span>
                    <DropDownList 
                        className="select-range-type"
                        data={["Select","Yearly","Monthly","Quarterly","Custom"]}
                        onChange={(e)=> setSelectedRange(e.target.value)}
                        defaultValue={selectedRange}
                    />
                    {selectedRange == "Yearly" && (
                        <DropDownList
                            required={true}
                            className="select-range-type"
                            value={selectedYear}
                            data={["2020","2021","2022","2023","2024"]}
                            onChange={(e)=> setSelectedYear(e.target.value) }
                        />
                    )}
                    {selectedRange == "Monthly" && (
                        <>                        
                        <DropDownList
                            required={true}
                            className="select-range-type"
                            value={selectedYear}
                            data={["2020","2021","2022","2023"]}
                            onChange={(e)=> setSelectedYear(e.target.value) }
                        />
                        <DropDownList
                            required={true}
                            className="select-range-type"
                            value={selectedMonth}
                            data={["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]}
                            onChange={(e)=> setSelectedMonth(e.target.value) }
                        />
                        </>
                    )}
                    {selectedRange == "Quarterly" && (
                        <>
                        <DropDownList
                            required={true}
                            className="select-range-type"
                            value={selectedYear}
                            data={["2020","2021","2022","2023"]}
                            onChange={(e)=> setSelectedYear(e.target.value) }
                        /> 
                        <DropDownList
                            required={true}
                            className="select-range-type"
                            value={selectedQuarter}
                            data={["Q1","Q2","Q3","Q4"]}
                            onChange={(e)=> setSelectedQuarter(e.target.value) }
                        />
                        </>
                    )}
                    {selectedRange == "Custom" && (
                        <>
                        Start:
                        <DatePicker
                            required
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date);
                            }}
                            dateFormatCalendar="MMMM"
                            customInput={<CustomDatePicker />}
                        />
                        End:
                        <DatePicker
                            required
                            selected={endDate}
                            onChange={(date) => {
                                setEndDate(date);
                            }}
                            dateFormatCalendar="MMMM"
                            customInput={<CustomDatePicker />}
                        />
                        </>
                    )}
                </div>
                <div className="dialog-row" style={{height:"80%"}}>
                    <DropDownList
                        className="select-range-type"
                        value={selectedScheme}
                        data={["Feedback Scheme","BSQC"]}
                        onChange={(e)=> setSelectedScheme(e.target.value) }
                    />
                </div>
                <div className="dialog-row" style={{height:"80%"}}>
                    <span className="search-row-title">Selected</span>
                    <DropDownList
                      className="select-range-type"
                      value={selectedStatus}
                      data={['Select All','New','Closed']}
                      onChange={(e)=> setSelectedStatus(e.target.value)}
                    />
                </div>
                <div className="dialog-row" style={{height:"80%"}}>
                    <span className="search-row-title">Selected</span>
                    <DropDownList
                      className="select-range-type"
                      value={selectedShow}
                      data={['10','15','20','30','50']}
                      onChange={(e)=> setSelectedShow(e.target.value)}
                    />
                </div>                                    
                <div className="dialog-row" style={{height:"80%"}}>
                    <span className="search-row-title">Reference No</span>
                    <TextBox value={searchRef} onChange={(e)=> setSearchRef(e.target.value)}/>
                </div>                                    
            </div>
            <DialogActionsBar>
                <Button className="search-button cancel-btn" onClick={props.onClose}>Cancel</Button>
                <Button className="search-button search-btn" onClick={onhandleSubmit}>Submit</Button>
            </DialogActionsBar>
        </Dialog>        
        </>
        
    );
  };