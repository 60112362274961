import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Dialog,DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { TextBox } from "@progress/kendo-react-inputs";

import "./notification.css";
export const SearchNotification = (props) => {
    let {search} = useLocation();
    const query = new URLSearchParams(search);
    const [searchTitle,setSearchTitle] = useState(null);
    const [searchCreator,setSearchCreator] = useState(null);
    useEffect(() => {
        if(query.get("title")){
            setSearchTitle(query.get("title"));            
        }
        if(query.get("created_by_name")){
            setSearchCreator(query.get("created_by_name"));
        }
    },[])
    const onhandleSearch = ()=>{
        const data = {
            title : searchTitle? searchTitle: "",
            created_by_name : searchCreator? searchCreator: "",
        }
        console.log(data);
        props.onSearchcallback(data);
    }
    const handleSearchTitle = (e)=>{
        setSearchTitle(e.target.value);
    }
    const handleSearchCreator = (e)=>
        setSearchCreator(e.target.value);
    return (
        <>
        <Dialog
            className="search-notification"
            title={" "}
            // width={500}
            height={"100%"}
            onClose={()=> props.setShowSearchDialog(false)}
            >
            <div className="search-body">
                <div className="noti-dialog-title dialog-row">Search</div>
                <div className="dialog-row">
                    <span className="search-row-title">By title</span>
                    <TextBox 
                        className="search-input" 
                        value={searchTitle}
                        onChange={handleSearchTitle}
                        suffix={()=>(
                            <Button className="clear-button-dialog" onClick={()=> setSearchTitle("")}>X</Button>
                        )}
                    />
                </div>
                <div className="dialog-row" style={{height:"80%"}}>
                    <span className="search-row-title">By creator's name</span>
                    <TextBox 
                        className="search-input" 
                        onChange={handleSearchCreator} 
                        value={searchCreator}
                        suffix={()=>(
                            <Button className="clear-button-dialog" onClick={()=> setSearchCreator("")}>X</Button>
                        )}/>
                </div>
            </div>
            <DialogActionsBar>
                <Button className="search-button cancel-btn" onClick={()=> props.setShowSearchDialog(false)}>Cancel</Button>
                <Button className="search-button search-btn" onClick={onhandleSearch}>Submit</Button>
            </DialogActionsBar>
        </Dialog>        
        </>
        
    );
  };