export const MIC_PERMISSIONS_FOR_EXPORT = [
  "my_ideas.limited_export_ideas",
  "my_ideas.export_ideas",
];

// DR
export const DR_INFRA_PERMISSIONS_FOR_UPDATE_STATUS = [
  "dr_infra.update_status",
];
export const DR_INFRA_DELETE = ["dr_infra.delete_incident"];
export const DR_SAFE_PERMISSIONS_FOR_UPDATE_STATUS = ["dr_safe.update_status"];
export const DR_SECURE_PERMISSIONS_FOR_UPDATE_STATUS = [
  "dr_secure.update_status",
];
export const DR_INFRA_PERMISSIONS_FOR_SUBMIT_UPDATE = [
  "dr_infra.submit_update",
];
export const DR_SAFE_PERMISSIONS_FOR_SUBMIT_UPDATE = ["dr_safe.submit_update"];
export const DR_SECURE_PERMISSIONS_FOR_SUBMIT_UPDATE = [
  "dr_secure.submit_update",
];
export const DR_SAFE_DELETE = ["dr_safe.delete_incident"];
export const DR_INFRA_PERMISSIONS_FOR_ROUTE = ["dr_infra.can_route"];
export const DR_SAFE_PERMISSIONS_FOR_ROUTE = ["dr_safe.can_route"];
export const DR_SECURE_PERMISSIONS_FOR_ROUTE = ["dr_secure.can_route"];
export const DR_INFRA_PERMISSIONS_FOR_SHARE = ["dr_infra.can_share"];
export const DR_SECURE_DELETE = ["dr_secure.delete_incident"];
export const DR_SAFE_PERMISSIONS_FOR_SHARE = ["dr_safe.can_share"];
export const DR_SECURE_PERMISSIONS_FOR_SHARE = ["dr_secure.can_share"];
export const DR_INFRA_PERMISSIONS_FOR_MARK_DUPLICATE = [
  "dr_infra.can_mark_duplicate",
];
export const DR_INFRA_PERMISSIONS_SYNC_LOCATIONS = ["dr_infra.sync_locations"];
export const DR_INFRA_PERMISSIONS_SYNC_SUBSYSTEM_SYSTEM = [
  "dr_infra.sync_subsystems_systems",
];

export const DR_SAFE_PERMISSIONS_FOR_MARK_DUPLICATE = [
  "dr_safe.can_mark_duplicate",
];
export const DR_SECURE_PERMISSIONS_FOR_MARK_DUPLICATE = [
  "dr_secure.can_mark_duplicate",
];

// IFEEDBACK
export const IFEEDBACK_PERMISSIONS_FOR_EXPORT = [
  "ifeedback.limited_export_ifeedback",
  "ifeedback.export_ifeedback",
];

export const IFEEDBACK_PERMISSIONS_FOR_UPDATE_STATUS = [
  "ifeedback.update_ifeedback_status",
  "ifeedback.limited_update_ifeedback_status",
];

export const IFEEDBACK_PERMISSIONS_FOR_ROUTE_DEPARTMENT = [
  "ifeedback.limited_route_ifeedback_to_other_department",
  "ifeedback.route_ifeedback_to_other_department",
];

export const IFEEDBACK_PERMISSIONS_FOR_CHANGE_MATTER_TYPE = [
  "ifeedback.change_ifeedback_matter_type",
  "ifeedback.limited_change_ifeedback_matter_type",
];

export const IFEEDBACK_PERMISSIONS_FOR_SHARE = [
  "ifeedback.share_ifeedback",
  "ifeedback.limited_share_ifeedback",
];

export const IFEEDBACK_ADD_INTERNAL_NOTE = [
  "ifeedback.add_ifeedback_internal_role",
  "ifeedback.limited_add_ifeedback_internal_role",
];

export const IFEEDBACK_ADD_UPDATE = [
  "ifeedback.add_ifeedback_updates",
  "ifeedback.limited_add_ifeedback_updates",
];

export const IFEEDBACK_DELETE = [
  "ifeedback.limited_delete_ifeedback",
  "ifeedback.delete_ifeedback",
];

// ADMIN TOOLS
export const ADMIN_TOOLS_ADD_EDIT_MATTER = [
  "admin_tools.add_edit_ifeedback_matter",
];

export const ADMIN_TOOLS_DELETE_MATTER = [
  "admin_tools.delete_ifeedback_matter",
];

// INBOX
export const INBOX_ADD_PERMISSIONS = [
  "comms.add_comms",
  "comms.limited_access_to_add_comms",
];

export const INBOX_APPROVE_MESSAGE_PERMISSIONS = [
  "comms.approve_message",
  "comms.limited_access_to_approve_message",
];

export const INBOX_EXPORT_PERMISSIONS = [
  "comms.export_comms",
  "comms.limited_access_to_export_comms",
];

export const INBOX_RECALL_MESSAGE_PERMISSIONS = [
  "comms.recall_message",
  "comms.limited_access_to_recall_message",
];

export const INBOX_ARCHIVE_MESSAGE_PERMISSIONS = [
  "comms.archive_message",
  "comms.limited_archive_message",
];

export const INBOX_DUPLICATE_MESSAGE_PERMISSIONS = [
  "comms.duplicate_message",
  "comms.limited_access_to_duplicate_message",
];

export const INBOX_DELETE_MESSAGE_PERMISSIONS = [
  "comms.delete_message",
  "comms.limited_access_to_delete_message",
];

export const INBOX_RESUBMIT_MESSAGE_PERMISSIONS = [
  "comms.resubmit_message",
  "comms.limited_access_to_resubmit_message",
];

export const USER_MGMT_EDIT_GROUP = ["user_management.add_edit_group"];

export const USER_MGMT_DELETE_GROUP = ["user_management.delete_group"];

export const USER_MGMT_EXPORT_USER = ["user_management.export_user"];

export const USER_MGMT_SYNC_USER = ["user_management.sync_user"];

export const ADMIN_TOOLS_ADD_EDIT_ROLE = [
  "admin_tools.add_edit_comms_category",
];

export const ADMIN_TOOLS_DELETE_ROLE = ["admin_tools.delete_comms_category"];

export const ADMIN_TOOLS_ACCESS_FOB_EMAIL_GROUPS = [
  "admin_tools.access_FOB_email_groups"
]

export const ADMIN_TOOLS_FOB_ADD_EDIT_EMAIL_GROUP = [
  "admin_tools.add_edit_FOB_email_groups",
];

export const ADMIN_TOOLS_FOB_DELETE_EMAIL_GROUP = ["admin_tools.delete_FOB_email_groups"];

export const ADMIN_TOOLS_ACCESS_FOB_EMAIL_TEMPLATES = [
  "admin_tools.access_FOB_email_templates"
]

export const ADMIN_TOOLS_FOB_ADD_EDIT_EMAIL_TEMPLATE = [
  "admin_tools.add_edit_FOB_email_templates",
];

export const ADMIN_TOOLS_FOB_DELETE_EMAIL_TEMPLATE = [
  "admin_tools.delete_FOB_email_templates",
];

export const TEL_DIR_PERMISSIONS_FOR_ACCESS_DEPOT_SUBDEPOT = [
  "telephone_directory.access_depot_subdepot_listing",
];

export const TEL_DIR_PERMISSIONS_FOR_ADD_DEPOT_SUBDEPOT = [
  "telephone_directory.add_depot_subdepot",
];

export const TEL_DIR_PERMISSIONS_FOR_DELETE_DEPOT_SUBDEPOT = [
  "telephone_directory.delete_depot_subdepot",
];

export const TEL_DIR_PERMISSIONS_FOR_ACCESS_DEPARTMENT = [
  "telephone_directory.access_department_listing",
];

export const TEL_DIR_PERMISSIONS_FOR_ADD_DEPARTMENT = [
  "telephone_directory.add_department",
];

export const TEL_DIR_PERMISSIONS_FOR_DELETE_DEPARTMENT = [
  "telephone_directory.delete_department",
];

export const TEL_DIR_PERMISSIONS_FOR_ACCESS_DIRECTORY = [
  "telephone_directory.access_directory_listing",
];

export const TEL_DIR_PERMISSIONS_FOR_ADD_DIRECTORY = [
  "telephone_directory.add_directory",
];

export const TEL_DIR_PERMISSIONS_FOR_DELETE_DIRECTORY = [
  "telephone_directory.delete_directory",
];

export const ADMIN_TOOLS_ISAFE_SAVE_SAFETY_MESSAGE = [
  "admin_tools.save_isafe_safety_message",
];

export const MY_IDEAS_DELETE = [
  "my_ideas.limited_delete_ideas",
  "my_ideas.delete_ideas",
];
export const FOB_SUMMARY_OF_CHECK_CLOSE_CASE =[
  "fob.access_summary_check_close_case"
]

export const FOB_SUMMARY_OF_CHECK_REOPEN_CASE =[
  "fob.access_summary_check_reopen_case"
]


const userPermissionKey = "user_permissions";

export const saveUserPermission = (result) => {
  localStorage.setItem(userPermissionKey, JSON.stringify(result));
};

export const getUserPermission = () => {
  return JSON.parse(localStorage.getItem(userPermissionKey));
};

export const checkPermission = (userPermission, requiredPermission) => {
  console.log(userPermission, requiredPermission);
  console.log(userPermission[requiredPermission]);
  if (
    requiredPermission in userPermission &&
    userPermission[requiredPermission] === true
  ) {
    return true;
  }
  return false;
};

export const checkMultiPermission = (userPermission, requiredPermission) => {
  let flag = false;

  for (let i = 0; i < requiredPermission.length; i++) {
    if (checkPermission(userPermission, requiredPermission[i])) {
      flag = true;
      break;
    }
  }
  return flag;
};
