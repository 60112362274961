import { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import RefreshIcon from "../../../../assets/header/refresh.svg";
import "../../communication.css";
import "./table-recipients-custom.css";
import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import RecipientsGroupIcon from "../../../../assets/communication/RecipientsUsers.svg";
import OpenIcon from "../../../../assets/communication/OpenIcon.svg";
import AcknowledgeIcon from "../../../../assets/communication/AcknowledgeIcon.svg";
import ThumbsupIcon from "../../../../assets/communication/thumbsup.svg";
import HappyIcon from "../../../../assets/communication/happy.svg";
import WowIcon from "../../../../assets/communication/wow.svg";
import ClapIcon from "../../../../assets/communication/clap.svg";
import OTWIcon from "../../../../assets/communication/otw.svg";
import AVAILIcon from "../../../../assets/communication/avail.svg";
import NOTAVAILIcon from "../../../../assets/communication/not_avail.svg";
import ARRIVEDIcon from "../../../../assets/communication/arrived.svg";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { CSVLink } from "react-csv";
import { exportToCSVRecipients, getCommRecipientsFile} from "../../../../store/notification";
import { useDispatch } from "react-redux";

const initialSort = [
  {
    field: "name",
    dir: "desc",
  },
];

const initialDataState = {
  skip: 0,
  take: 1000,
};

export const RecipientsDetails = ({
  overview_data,
  data,
  onChange,
  users,
  onRefresh = () => {},
}) => {
  // Pagination State
  const [sort, setSort] = useState(initialSort);
  const [page, setPage] = useState(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState();

  const onPageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take =
      targetEvent.value === "All"
        ? orderBy(users, sort).length
        : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const dispatch = useDispatch();
  const exportLink = useRef(null);
  const [exportData, setExportData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (exportData.length > 0) {
      console.log("ExportExcel ", exportData);
      exportLink.current.link.click();
    }
  }, [exportData]);

  const onClickExportButton = () => {
    setLoading(true);
    dispatch(exportToCSVRecipients(data.id)).then((res) => {
      console.log("Export method res-", res.payload);
      let data = res.payload;
      setExportData(data);
      setLoading(false);
    });
  };
  const onClickOpenFile = (key)=>{
    console.log(key);
    const data = { key };
    dispatch(getCommRecipientsFile(data)).then((res)=>{
      console.log(res.payload.data);
      if(res.payload.data){
        window.open(res.payload.data);
      }
    })
  };
  const customDateCell = (props) => {
    if (props.dataItem[props.field] !== "") {
      return (
        <td style={{background: "transparent"}}>
          <span style={{textDecoration: "underline",fontWeight:700,color: "#781D7E"}} onClick={()=> onClickOpenFile(props.dataItem[props.field])}>{props.dataItem[props.field]}</span>
        </td>
      );
    }
    return <td style={{background: "transparent"}}>{props.dataItem[props.field]}</td>;
  };
  console.log(overview_data);

  return (
    <>
      <div className="recall-tab-div">
        <div className="recall-tab-group">
          <a onClick={() => onChange(0)}>Notification</a>
          <a className="selected-tab">Recipients</a>
        </div>
        <div style={{ display: "flex", columnGap: 10 }}>
          <Button onClick={onRefresh} title="Refresh" className="button2">
            <img src={RefreshIcon} alt="refresh-icon" />
          </Button>
          <CSVLink
            uFEFF={false}
            asyncOnClick={true}
            data={exportData}
            filename="export_recipient"
            ref={exportLink}
            hidden
          >
            Download
          </CSVLink>
          <Button disabled={loading}>
            <Icon name="export" size="xlarge" onClick={onClickExportButton} />
            {loading && <div>Loading...</div>}
          </Button>
        </div>
      </div>
      <div className="recall-info">
        <div className="recall-recipents-button-row">
          <div className="recipients-recall-btn recall-selected-btn">
            <img src={RecipientsGroupIcon} />
            <div className="recipients-number-label-div">
              <span className="recipients-btn-label-number recall-selected-label">
                {overview_data.total_recipient}
              </span>
            </div>
            <span className="recipients-btn-label recall-selected-label">
              Recipients
            </span>
          </div>
          <div className="recipients-recall-btn">
            <img src={OpenIcon} />
            <span className="recipients-btn-label-number">
              {Math.round(overview_data.opened_percentage)}%
            </span>
            <span className="recipients-btn-label">
              {overview_data.total_opened}&nbsp;Opened
            </span>
          </div>
          <div className="recipients-recall-btn">
            <img src={AcknowledgeIcon} />
            {!overview_data.ack_required ? (
              <>
                <span className="recipients-btn-label text-align-center">
                  Not Required
                </span>
              </>
            ) : (
              <>
                <span className="recipients-btn-label-number">
                  {Math.round(overview_data.ack_percentage)}%
                </span>
                <span className="recipients-btn-label">
                  {overview_data.total_ack}&nbsp;Ack
                </span>
              </>
            )}
          </div>

          {overview_data.cat_code != "EM" ? (
            <>
              <div className="recipients-recall-btn">
                <img src={ThumbsupIcon} />
                <span className="recipients-btn-label-number">
                  {overview_data.total_thumbsup}
                </span>
                <span className="recipients-btn-label">Thumbs Up</span>
              </div>
              <div className="recipients-recall-btn">
                <img src={HappyIcon} />
                <span className="recipients-btn-label-number">
                  {overview_data.total_happy}
                </span>
                <span className="recipients-btn-label">Happy</span>
              </div>
              <div className="recipients-recall-btn">
                <img src={WowIcon} />
                <span className="recipients-btn-label-number">
                  {overview_data.total_wow}
                </span>
                <span className="recipients-btn-label">Wow</span>
              </div>
              <div className="recipients-recall-btn">
                <img src={ClapIcon} />
                <span className="recipients-btn-label-number">
                  {overview_data.total_clap}
                </span>
                <span className="recipients-btn-label">Clap</span>
              </div>
            </>
          ) : (
            <>
              <div className="recipients-recall-btn">
                <img src={OTWIcon} />
                <span className="recipients-btn-label-number">
                  {overview_data.total_otw}
                </span>
                <span className="recipients-btn-label">On the way</span>
              </div>
              <div className="recipients-recall-btn">
                <img src={AVAILIcon} />
                <span className="recipients-btn-label-number">
                  {overview_data.total_avail}
                </span>
                <span className="recipients-btn-label">Available</span>
              </div>
              <div className="recipients-recall-btn">
                <img src={NOTAVAILIcon} />
                <span className="recipients-btn-label-number">
                  {overview_data.total_not_avail}
                </span>
                <span className="recipients-btn-label text-center-align">
                  Not Available
                </span>
              </div>
              <div className="recipients-recall-btn">
                <img src={ARRIVEDIcon} />
                <span className="recipients-btn-label-number">
                  {overview_data.total_arrived}
                </span>
                <span className="recipients-btn-label">Arrived</span>
              </div>
            </>
          )}
        </div>
        {overview_data.total_process != "draft" ? (
          <>
            <div>
              Total Processed: {overview_data.total_process}/
              {overview_data.total_recipient}
            </div>
          </>
        ) : (
          <></>
        )}
        {console.log(users, sort)}
        <div className="recall-recipients-table">
          <Grid
            data={orderBy(users, sort).slice(page.skip, page.skip + page.take)}
            style={{ height: "500px", }}            
            sortable={true}
            skip={page.skip}
            take={page.take}
            total={orderBy(users, sort).length}
            pageable={{
              buttonCount: 4,
            }}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            onPageChange={onPageChange}
          >
            <GridColumn field="sap_no" title="SAP NO" width={100} />
            <GridColumn field="name" title="Name"  width={150}/>
            <GridColumn field="designation" title="Designation" width={120} />
            <GridColumn field="department" title="Department" width={130}/>
            <GridColumn field="file1" title="Attachment 1" width={140} cell={customDateCell} />
            <GridColumn field="file2" title="Attachment 2" width={140} cell={customDateCell} />
            <GridColumn field="file3" title="Attachment 3" width={140} cell={customDateCell} />
            
            <GridColumn title="Open" field="is_open" width={80} />
            <GridColumn
              title="Acknowledgement"
              width={170}
              field="is_ack"              
              cell={(props) => (
                <td className="k-table-td">
                  <>
                    {props.dataItem.is_ack}
                    <br />
                    {props.dataItem.acknowledgement_datetime
                      ? `(${props.dataItem.acknowledgement_datetime})`
                      : null}
                  </>
                </td>
              )}
            />
            <GridColumn
              field="reaction"
              title="Reaction"
              width={100}
              cell={(props) => (
                <td className="k-table-td">
                  {props.dataItem.reaction === "happy" && (
                    <img src={HappyIcon} width={24} height={24} />
                  )}
                  {props.dataItem.reaction === "thumbsup" && (
                    <img src={ThumbsupIcon} width={24} height={24} />
                  )}
                  {props.dataItem.reaction === "wow" && (
                    <img src={WowIcon} width={24} height={24} />
                  )}
                  {props.dataItem.reaction === "clap" && (
                    <img src={ClapIcon} width={24} height={24} />
                  )}
                  {props.dataItem.reaction === "otw" && (
                    <img src={OTWIcon} width={24} height={24} />
                  )}
                  {props.dataItem.reaction === "avail" && (
                    <img src={AVAILIcon} width={24} height={24} />
                  )}
                  {props.dataItem.reaction === "not_avail" && (
                    <img src={NOTAVAILIcon} width={24} height={24} />
                  )}
                  {props.dataItem.reaction === "arrived" && (
                    <img src={ARRIVEDIcon} width={24} height={24} />
                  )}
                </td>
              )}
            />
            <GridColumn title="Status" field="status" width={100} />
          </Grid>
          {/* {isReachBottom && (
            <div className="bottom-wrapper">
              <div
                className="load-more"
                onClick={() => {
                  setPage((prev) => prev + 1);
                  setReachBottom(false);
                }}
              >
                Load More
              </div>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};
