import { Dashboard } from "./dashboard";

function DashboardForm(props) {
  return (
    <>
      <Dashboard />
    </>
  );
}
export default DashboardForm;
