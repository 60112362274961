import { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteBSQCPeriodByID,
  DeleteItemBSQCPeriod,
  ExportFOBCheckPeriodByID,
  GetFOBPeriod,
  GetFOBStopBusList,
  PublishCheckPeriod,
} from "../../../../../services/feedbackOnBus";
import moment from "moment";
import { useJsonToCsv } from "react-json-csv";
import { onSelectCheckPeriod } from "../../../../../store/feedback-on-bus";
import { useNavigate } from "react-router";
import { useMasterData, useSelectMasterData } from "../../actions";
import { useModal } from "../../../../../libs/hooks/use-modal";

export const useAction = () => {
  const { saveAsCsv } = useJsonToCsv();
  const { refetch } = useMasterData();
  const { listOfPeriod } = useSelectMasterData();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formMode, setFormMode] = useState("");
  const [initForm, setInitForm] = useState({});
  const [bsqcPeriod, setBsqcPeriod] = useState([]);
  const [textForm, setTextForm] = useState({
    am_text: "",
    pm_text: "",
  });

  const [isFetching, setIsFetching] = useState(true);
  const [isFetchDelete, setIsFetchDelete] = useState();
  const [isFetchUnpublish, setIsFetchUnpublish] = useState();
  const { selectedCheckPeriod } = useSelector((state) => state.feedbackOnBus);
  const [createdDateFrom, setCreatedDateFrom] = useState("");
  const [createdDateTo, setCreatedDateTo] = useState("");

  const [bsqcPeriodName, setBSQCPeriodName] = useState("");
  const [list, setList] = useState([]);
  const [hasApproved, setHasApproved] = useState(false);
  const [hasPublish, setHasPublish] = useState(false);

  const GetDetailPeriod = async () => {
    setIsFetching(true);
    const [err, response] = await GetFOBPeriod(selectedCheckPeriod);
    const [errList, responseList] = await GetFOBStopBusList(
      selectedCheckPeriod
    );

    if (!err) {
      setBSQCPeriodName(response?.data?.name);
      setCreatedDateFrom(moment(response?.data?.start_date).toDate());
      setCreatedDateTo(moment(response?.data?.end_date).toDate());
      // setHasApproved(response?.data?.is_approved ?? false);
      setHasApproved(
        response && response.is_approved ? response.is_approved : false
      );
      setHasPublish(response?.data?.is_published ?? false);
    }

    if (!errList) {
      const res = responseList?.data?.items;
      const { am_text, pm_text } = responseList?.data;
      const newList = !!res?.length
        ? res.map((item, idx) => ({
            ...item,
            "basicinfo:no": idx + 1,
          }))
        : [];

      setTextForm({ am_text, pm_text });
      setList(newList);
    }

    setIsFetching(false);
  };

  const refetchDetailChecker = () => GetDetailPeriod();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (selectedCheckPeriod) {
      GetDetailPeriod();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCheckPeriod]);

  const onRefresh = () => {
    GetDetailPeriod();
    refetch();
  };

  useEffect(() => {
    const getListBSQCPeriod = async () => {
      const data = listOfPeriod ?? [];
      const newListPeriod = (data ?? [])
        ?.filter(({ is_deleted }) => !is_deleted)
        ?.map(({ name, pk, updated_on }) => ({
          label: `${name} - ${moment(updated_on).format(
            "MMM DD YYYY hh:mm A"
          )}`,
          id: pk,
          isSelected: true,
          data: { name, pk },
        }));

      setBsqcPeriod(newListPeriod);

      setIsFetching(false);
    };

    getListBSQCPeriod();
  }, [listOfPeriod]);

  const onUpdatePartial = (data) => {
    const updated = list.map((itm) => {
      if (itm["basicinfo:no"] === data["basicinfo:no"]) return data;
      return itm;
    });

    setList(updated);
  };

  const onAdd = (data) => {
    const idx = list[list.length - 1]?.["basicinfo:no"];
    setList([
      ...list,
      {
        ...data,
        "basicinfo:no": idx + 1,
      },
    ]);
  };

  const onDeleteItem = (idx) => {
    const updated = list.filter((itm) => {
      if (itm["basicinfo:no"] !== idx) return itm;
    });

    setList(updated);
  };

  const onChangeDate = (date, type = "start") => {
    if (type === "start") {
      setCreatedDateFrom(date);

      return;
    }

    setCreatedDateTo(date);
  };

  const onChangeName = (name) => setBSQCPeriodName(name);

  const listOfCheckPeriod = useMemo(
    () =>
      !!list?.length
        ? list.map((item, idx) => ({
            ...item,
            "basicinfo:no": idx + 1,
          }))
        : [],
    [list]
  );

  const onSetMode = (mode, initForm) => {
    setFormMode(mode);
    setInitForm(initForm);
  };

  const onClearMode = () => {
    setFormMode("");
    setInitForm({});
  };

  const onSelectPeriod = useCallback(
    (id) => {
      dispatch(onSelectCheckPeriod(id ?? ""));
      navigate(`/feedback-on-bus/bsqc-checker/${id ?? ""}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onDeleteBSQCPeriod = useCallback(
    async (cb) => {
      setIsFetchDelete(true);
      if (selectedCheckPeriod) {
        const [err, response] = await DeleteBSQCPeriodByID(selectedCheckPeriod);

        if (!err && response) {
          const findIndex = bsqcPeriod?.findIndex(
            ({ id }) => id === selectedCheckPeriod
          );

          refetch();
          setIsFetchDelete(false);
          if (typeof cb === "function") cb();

          if (findIndex === 0 && bsqcPeriod?.length === 1) {
            onSelectPeriod("");
          }

          if (findIndex === 1 && bsqcPeriod?.length >= 2) {
            onSelectPeriod(bsqcPeriod[0]?.id);
          } else {
            onSelectPeriod(bsqcPeriod[findIndex + 1]?.id);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bsqcPeriod, selectedCheckPeriod]
  );

  const selectedPeriod = useMemo(
    () => bsqcPeriod?.filter(({ id }) => selectedCheckPeriod === id) ?? [],
    [selectedCheckPeriod, bsqcPeriod]
  );

  const onExport = useCallback(
    async () => {
      const { id } = selectedPeriod?.[0] ?? { id: "" };
      const [err, response] = await ExportFOBCheckPeriodByID(id);
      if (err) return;

      const headers = response?.data[0] ?? [];
      const data = (response?.data ?? [])?.slice(1);
      console.log(response);

      let fields = {};

      headers?.map((fieldItem) => {
        const head = `${fields[fieldItem]}`;
        if (fields[head]) {
          if (fields["Direction"]) {
            fields[`${fieldItem} `] = fieldItem;
          } else if (fields["Time"]) {
            fields[`${fieldItem} `] = fieldItem;
          } else {
            fields[`${fieldItem} `] = fieldItem;
          }
        } else {
          fields[`${fieldItem}`] = fieldItem;
        }
      });

      const items = data?.map((item = [], key) => {
        let newObj = [];

        item.map((idx, itmKey) => {
          const head = `${headers[itmKey]}`;
          console.log(newObj[head]);
          if (newObj[head]) {
            if (newObj["Direction"]) {
              newObj = {
                ...newObj,
                [`${headers[itmKey]} `]:
                  typeof idx === "string" ? idx?.replace(",", "") : idx,
              };
            } else if (newObj["Time"]) {
              newObj = {
                ...newObj,
                [`${headers[itmKey]} `]:
                  typeof idx === "string" ? idx?.replace(",", "") : idx,
              };
            } else {
              newObj = {
                ...newObj,
                [`${headers[itmKey]} `]:
                  typeof idx === "string" ? idx?.replace(",", "") : idx,
              };
            }
          } else {
            newObj = {
              ...newObj,
              [`${headers[itmKey]}`]:
                typeof idx === "string" ? idx?.replace(",", "") : idx,
            };
          }
          // newObj = {
          //   ...newObj,
          //   [`${headers[itmKey]}`]:
          //     typeof idx === "string" ? idx?.replace(",", "") : idx,
          // };
          // newObj.push({
          //   [`${headers[itmKey]?.split(" ")?.join("-")}`]:
          //     typeof idx === "string" ? idx?.replace(",", "") : idx,
          // });
        });
        return newObj;
      });
      console.log(items, fields);

      await saveAsCsv({ data: items, fields, filename: "bsqc-check-period" });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPeriod]
  );

  const onUnpublish = useCallback(
    async (cb) => {
      setIsFetchUnpublish(true);
      const payload = {
        id: selectedCheckPeriod,
        is_published: false,
      };

      const [err, response] = await PublishCheckPeriod(
        selectedCheckPeriod,
        payload
      );

      if (response && !err) {
        refetch();
        refetchDetailChecker();
      }

      setIsFetchUnpublish(false);
      if (typeof cb === "function") cb();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCheckPeriod]
  );

  return {
    hasPublish,
    hasApproved,
    textForm,
    bsqcPeriod,
    selectedPeriod: selectedPeriod?.[0],
    bsqcPeriodName,
    onSelectPeriod,
    onDeleteBSQCPeriod,
    formMode,
    isFetching,
    isFetchDelete,
    isFetchUnpublish,
    list: listOfCheckPeriod,
    initForm,
    createdDateFrom,
    createdDateTo,
    onRefresh,
    onChangeDate,
    onChangeName,
    onSetMode,
    onAdd,
    onUpdatePartial,
    onClearMode,
    onDeleteItem,
    onExport,
    onUnpublish,
    refetchDetailChecker,
  };
};

export const useDeleteItem = () => {
  const { selectedCheckPeriod } = useSelector((state) => state.feedbackOnBus);
  const { visible: visibleDeleteItemPopup, onToggle: onToggleDeleteItem } =
    useModal();
  const { visible: isSuccess, onToggle: onToggleSuccess } = useModal();
  const [errors, setErrors] = useState("");
  const [detail, setDetail] = useState({});

  // Reset state when close popup
  useEffect(() => {
    if (!visibleDeleteItemPopup) {
      setDetail({});
    }
  }, [visibleDeleteItemPopup]);

  const onOpenItem = (data) => {
    setDetail(data);
  };

  const onSubmit = async (cb = () => {}) => {
    console.log("this one working");
    const payload = {
      id: selectedCheckPeriod,
      depot: detail?.depot,
      service: detail?.svc,
      interchange: detail?.interchange,
    };

    const [err, response] = await DeleteItemBSQCPeriod(payload);

    if (err || !response) {
      setErrors(err?.message);

      return;
    }

    onToggleSuccess();
    if (typeof cb === "function") cb();
  };

  const onToggleErrorPopup = () => setErrors("");

  return {
    isOpen: visibleDeleteItemPopup,
    isSuccess,
    detail,
    errors,
    onToggle: onToggleDeleteItem,
    onOpen: onOpenItem,
    onSubmit,
    onToggleSuccess,
    onToggleErrorPopup,
  };
};
