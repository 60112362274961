import React, { forwardRef} from "react";
import { TextBox,InputSuffix } from "@progress/kendo-react-inputs";
import "./custom.css";
import { Icon } from "@progress/kendo-react-common";

export const CustomDatePicker = forwardRef(({ value, onClick,iconColor, style = {} }, ref) => (
    <TextBox style={style} className="info-datetime-picker" onClick={onClick} ref={ref} value={value} suffix={() => (
      <React.Fragment>
        <InputSuffix>
          <Icon name="calendar" onClick={onClick} style={iconColor? {color:iconColor}:null}></Icon>
        </InputSuffix>
      </React.Fragment>
    )} />
  ));