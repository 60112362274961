import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { Dialog } from "@progress/kendo-react-dialogs";
// components
import DrInfraListHeader from "./dr_infra_list_header";
import DrInfraListBody from "./dr_infra_list_body";
// css
import "./dr_infra_list.css";
// store
import {
  fetchAllDRInfraIncidents,
  fetchDRInfraSearchResult,
  fetchDRInfraFilterResult,
  fetchDRInfraSortResult,
  changeDRInfraIncidentStatus,
} from "../../../../store/drInfraIncidient";
import {
  getUserPermission,
  checkMultiPermission,
} from "../../../../config/permission_helper";
import Cross_icon from "../../../../assets/mic/cross_icon.svg";

import { DR_INFRA_PERMISSIONS_FOR_UPDATE_STATUS } from "../../../../config/permission_helper";
import { getCurrentLoggedInSessionToken } from "../../../../config/userPool";
import { Loader } from "@progress/kendo-react-indicators";
import api from "../../../../config/api";

const DrInfraList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDrSearchDialog, setShowDrSearchDialog] = useState(false);
  const [showDrFilterDialog, setShowDrFilterDialog] = useState(false);
  const [incidentList, setIncidentList] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [isSearch, setIsSearch] = useState("");
  const [filterResult, setFilterResult] = useState([]);
  const [sortResult, setSortResult] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showAlert, setshowAlert] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [infraDelete, setInfraDelete] = useState({
    isDelete: false,
    deleteId: "",
  });
  const { drInfraIncidentList, loading, error } = useSelector(
    (state) => state.drInfraIncident
  );
  const incidentStatuses = ["Submitted", "Checked", "Verified", "Completed"];
  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
  const [currentIncidentStatus, setCurrentIncidentStatus] = useState("");
  const [currentIncidentCaseRefNo, setCurrentIncidentCaseRefNo] = useState("");
  const [actionMessage, setActionMessage] = useState("");
  const handleOpenStatusDialog = (case_ref_no, status) => {
    console.log("click status-", case_ref_no, status);
    setCurrentIncidentStatus(status);
    setCurrentIncidentCaseRefNo(case_ref_no);
    setShowChangeStatusDialog(true);
  };
  const handleSaveIncidentStatus = () => {
    dispatch(
      changeDRInfraIncidentStatus({
        case_ref_no: currentIncidentCaseRefNo,
        incidentStatus: currentIncidentStatus.toLowerCase(),
      })
    )
      .then((result) => {
        console.log("status RES-", result);
        if (result.payload.status == "success") {
          setActionMessage("Updated Status");
          setshowAlert(true);
        } else {
          setActionMessage(result.payload.message);
          setshowAlert(true);
        }
      })
      .then(() => {
        dispatch(fetchAllDRInfraIncidents());
      });
    setShowChangeStatusDialog(false);
  };
  useEffect(() => {
    if (drInfraIncidentList) {
      setIncidentList(drInfraIncidentList);
    }
  }, [drInfraIncidentList]);

  useEffect(() => {
    dispatch(fetchAllDRInfraIncidents());
  }, []);

  const handleSearchAction = async (caseRefNo, createdBy, updatedBy) => {
    console.log(caseRefNo, createdBy, updatedBy);
    const res = await dispatch(
      fetchDRInfraSearchResult({ caseRefNo, createdBy, updatedBy })
    );
    if (res.payload.length > 0) {
      setSearchResult(res.payload);
      setIsSearch("search");
      setErrorMessage("");
    } else {
      setSearchResult([]);
      setIncidentList([]);
      setErrorMessage("");
    }
    setShowDrSearchDialog(false);
  };
  const handleFilterAction = async (
    status,
    createdDateFrom,
    createdDateTo,
    updateddDateFrom,
    updateddDateTo
  ) => {
    console.log(
      status,
      createdDateFrom,
      createdDateTo,
      updateddDateFrom,
      updateddDateTo
    );
    const res = await dispatch(
      fetchDRInfraFilterResult({
        status: status,
        start_date: createdDateFrom,
        end_date: createdDateTo,
        updateon_startdate: updateddDateFrom,
        updateon_enddate: updateddDateTo,
      })
    );
    console.log("Res Filter Payload-", res.payload);
    if (res.payload.length > 0) {
      setFilterResult(res.payload);
      setIsSearch("filter");
      setErrorMessage("");
    } else {
      setFilterResult([]);
      setIncidentList([]);
      setErrorMessage("");
    }
    setShowDrFilterDialog(false);
  };
  const handleSortAction = async (sortBy, orderBy) => {
    console.log(sortBy, orderBy);
    const res = await dispatch(
      fetchDRInfraSortResult({ sortby: sortBy, orderby: orderBy })
    );
    console.log("Res Sortby Payload-", res.payload);
    if (res.payload.length > 0) {
      setSortResult(res.payload);
      setIsSearch("sort");
      setErrorMessage("");
    } else {
      setFilterResult([]);
      setIncidentList([]);
    }
  };

  const shouldShowUpdateStatusButton = () => {
    const userPermissions = getUserPermission();
    return checkMultiPermission(
      userPermissions,
      DR_INFRA_PERMISSIONS_FOR_UPDATE_STATUS
    )
      ? true
      : false;
  };

  const DeleteCall = (val) => {
    setInfraDelete({
      ...infraDelete,
      isDelete: true,
      deleteId: val.dataItem.case_ref_no,
    });
  };

  const Delete = async () => {
    const token = getCurrentLoggedInSessionToken();
    setLoading(true);
    setInfraDelete({
      ...infraDelete,
      isDelete: false,
    });
    const results = await api.remove(
      `/api-dr/dr-infra/incidents/${infraDelete.deleteId}`,
      JSON.stringify({ id: infraDelete.deleteId }),
      token
    );
    if (results.status === "success") {
      setLoading(false);
      setInfraDelete({
        ...infraDelete,
        deleteId: "",
        isDelete: false,
      });
      dispatch(fetchAllDRInfraIncidents());
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="dr-infra-list-wrapper">
      {Loading && (
        <Loader
          type="converging-spinner"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ffffff4d",
            position: "absolute",
            zIndex: "999",
          }}
        />
      )}
      {showAlert && (
        <Dialog
          className="alert-form"
          title={"Alert"}
          width={400}
          // height={280}
          onClose={() => setshowAlert(false)}
        >
          <p>{actionMessage}</p>
        </Dialog>
      )}
      <DrInfraListHeader
        showDrSearchDialog={showDrSearchDialog}
        onSearchBtnClicked={() => setShowDrSearchDialog(true)}
        onSearchDialogClose={() => setShowDrSearchDialog(false)}
        onSearchAction={(caseRefNo, createdBy, updatedBy) =>
          handleSearchAction(caseRefNo, createdBy, updatedBy)
        }
        showDrFilterDialog={showDrFilterDialog}
        onFilterBtnClicked={() => setShowDrFilterDialog(true)}
        onFilterDialogClose={() => setShowDrFilterDialog(false)}
        onFilterAction={(
          status,
          createdDateFrom,
          createdDateTo,
          updateddDateFrom,
          updateddDateTo
        ) =>
          handleFilterAction(
            status,
            createdDateFrom,
            createdDateTo,
            updateddDateFrom,
            updateddDateTo
          )
        }
      />
      {errorMessage && (
        <div className="error">
          <p style={{ color: "red" }}> {errorMessage} </p>
        </div>
      )}
      <DrInfraListBody
        loading={loading}
        records={
          isSearch == "search"
            ? searchResult
            : isSearch == "filter"
            ? filterResult
            : isSearch == "sort"
            ? sortResult
            : incidentList
        }
        onSortAction={(sortBy, orderBy) => handleSortAction(sortBy, orderBy)}
        openStatusDialog={handleOpenStatusDialog}
        onToggleDialog={() => setShowChangeStatusDialog(false)}
        incidentStatuses={incidentStatuses}
        currentIncidentStatus={currentIncidentStatus}
        onChangeIncidentStatus={(e) => setCurrentIncidentStatus(e.target.value)}
        onSaveIncidentStatus={handleSaveIncidentStatus}
        showChangeStatusDialog={showChangeStatusDialog}
        showUpdateStatusButton={shouldShowUpdateStatusButton()}
        DeleteCall={DeleteCall}
      />
      {infraDelete.isDelete && (
        <Dialog>
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                right: "10px",
                top: "15px",
                cursor: "pointer",
              }}
              onClick={() =>
                setInfraDelete({
                  ...infraDelete,
                  isDelete: false,
                  deleteId: "",
                })
              }
            >
              <img src={Cross_icon} />
            </div>
          </div>
          <div className="dr_infra_delete_popup_box">
            <div className="dr_infra_delete_title">
              Are you sure, you want to delete {infraDelete.deleteId}
            </div>
            <div className="dr_infra_delete_footer_box">
              <div
                className="dr_infra_delete_footer_cancel"
                onClick={() =>
                  setInfraDelete({
                    ...infraDelete,
                    isDelete: false,
                    deleteId: "",
                  })
                }
              >
                Cancel
              </div>
              <div className="dr_infra_delete_footer_delete" onClick={Delete}>
                Delete
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default DrInfraList;
