import { DialogActionsBar } from "@progress/kendo-react-dialogs";

import Input from "../../../../../components/input";
import Drawer from "../../../../../components/drawer";
import Button from "../../../../../components/button";
import { useState } from "react";
import { useMICContext } from "../../action";

const Component = props => {
  const { actions, search } = useMICContext();
  const [creatorName, setCreatorName] = useState('');

  const onChange = e => setCreatorName(e?.target?.value ?? "");
  const onSubmit = () => {
    actions?.onSearch(creatorName);
    props?.onClose();
  }

  return (
    <Drawer
      onClose={props?.onClose}
      dialogComponents={() => (
        <DialogActionsBar>
          <Button onClick={props?.onClose}>Cancel</Button>
          <Button onClick={onSubmit} secondary>Submit</Button>
        </DialogActionsBar>
      )}
    >
      <div style={{ margin: 10 }}>
        <h2>Search</h2>

        <div>
          <h3>by creator's name</h3>
          <Input defaultValue={search} name="creator-name" onChange={onChange} />
        </div>
      </div>
    </Drawer>
  )
}

export default Component;
