import React, { useState, useEffect } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPermissions } from "../../store/groupMgmt";
// kendo
import { Checkbox } from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
// css
import "./dialog.css";

export const PermissionListDialog = (props) => {
  const dispatch = useDispatch();
  const [selectedPermissions, setSelectedPermissions] = useState(
    props.defaultSelected
  );
  const { all_permissions_loading, all_permissions } = useSelector(
    (state) => state.groupMgmt
  );

  useEffect(() => {
    dispatch(fetchAllPermissions());
  }, []);

  const handleSelected = (e, item) => {
    console.log("handleSelected:", item);
    let updatedList = [...selectedPermissions];
    if (e.target.value) {
      updatedList.push(item.name);
    } else {
      updatedList = updatedList.filter((x) => x !== item.name);
    }
    setSelectedPermissions(updatedList);
  };

  const handleSubmitPermissions = () => {
    let allPermissions = JSON.parse(JSON.stringify(all_permissions));
    let selected = [];
    for (let i = 0; i < allPermissions.length; i++) {
      let check = allPermissions[i].permissions.filter((x) =>
        selectedPermissions.includes(x.name)
      );
      if (check.length > 0) {
        selected.push({
          code: allPermissions[i].code,
          module_name: allPermissions[i].module_name,
          permissions: check,
        });
      }
    }
    props.callback(selected);
  };

  const isChecked = (item) => {
    return selectedPermissions.includes(item.name);
  };

  return (
    <Dialog
      title={`Please select permission(s): ${selectedPermissions.length} selected`}
      width={1080}
      height={720}
      onClose={props.onClickNo}
      className="permission-dialog"
    >
      <div className="permission-list-div">
        {all_permissions_loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "350px",
            }}
          />
        ) : (
          all_permissions.map((v) => (
            <div className="permission-item">
              <p className="permission-item-title">{v.module_name}</p>
              <div className="permission-item-permission-group">
                {v.permissions.map((pp) => (
                  <p>
                    <Checkbox
                      label={pp.description + " (" + pp.name + ")"}
                      onChange={(e) => handleSelected(e, pp)}
                      checked={isChecked(pp)}
                    />
                  </p>
                ))}
              </div>
            </div>
          ))
        )}
      </div>
      <DialogActionsBar>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base permission-cancel-button"
          onClick={props.onClickNo}
        >
          No
        </button>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base permission-submit-button"
          onClick={handleSubmitPermissions}
        >
          Yes
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};
