import React from "react";
// kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
// icon
import CrossIcon from "../../../../assets/dr_safe/cross_gray_icon.png";
// css
import "./ifeedback_details.css";

const IFeedbackDetailsStatusDialog = ({
  onToggleDialog,
  ifeedbackStatuses,
  currentiFeedbackStatus,
  onChangeiFeedbackStatus,
  onSaveiFeedbackStatus,
}) => {
  return (
    <Dialog>
      <div className="ifeedback-details-status-dialog">
        <div
          style={{
            position: "absolute",
            top: "15px",
            right: "13px",
            cursor: "pointer",
          }}
          onClick={onToggleDialog}
        >
          <img src={CrossIcon} />
        </div>
        <div className="ifeedback-details-status-dialog-title">Filter</div>
        <div className="ifeedback-details-status-dialog-dropdown">
          {/* <DropDownList
            data={ifeedbackStatuses}
            value={currentiFeedbackStatus}
            onChange={onChangeiFeedbackStatus}
            className="ifeedback-details-status-dialog-dropdown-value"
            style={{
              width: "100%",
            }}
          /> */}
          {console.log('ifeedbackStatuses-------',ifeedbackStatuses)}
          <select onChange={onChangeiFeedbackStatus} className='ifeedback-details-status-dialog-dropdown-value select-case-dropdown'
            style={{
                width: "100%",
              }}>
                  <option key={'currentiFeedbackStatus'} value={'currentiFeedbackStatus'}>{currentiFeedbackStatus}</option>
                  {ifeedbackStatuses.map(
                      (item,i) => (
                          <option>{item}</option>
                      )
                  )}
          </select>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="ifeedback-details-status-dialog-button"
            onClick={onSaveiFeedbackStatus}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default IFeedbackDetailsStatusDialog;
