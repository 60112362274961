import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { TextArea } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
// icon
import CrossIcon from "../../../../assets/dr_safe/cross_gray_icon.png";
// css
import "./dr_safe_details.css";
// routes
import { DR_SAFE_INCIDENT_DETAILS } from "../../../../routes";
// components
import DrTable from "../../../../components/drTable/dr_table";

const DrSafeDetailsMarkAsDuplicateDialog = ({  
  onToggleDialog, 
  onSubmitMarkAsDuplicate, 
  parentCasesList,
}) => {
  const navigate = useNavigate();
  const [markduplicate, setMarkDuplicate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const onMarkAsDuplicateChange = (e) => {
    setMarkDuplicate(e.target.value);
  };
  const [currentParentCase, setCurrentParentCase] = useState("");
  const onChangeParentCaseList = (e) => {
    setCurrentParentCase(e.target.value);
  };
  const onSubmit = () => {
    if(markduplicate == ''){
      setErrorMessage("Please fill out the below field")
    }
    else
    {
      onSubmitMarkAsDuplicate(currentParentCase,markduplicate);
    }
  };
  return (
    <Dialog>
      <div className="dr-safe-mark-as-duplicate-dialog">
        <div
          style={{
            position: "absolute",
            top: "15px",
            right: "13px",
            cursor: "pointer",
          }}
          onClick={onToggleDialog}
        >
        <img src={CrossIcon} />
        </div>
        <div className="dr-safe-details-status-dialog-title">Mark As Duplicate</div>
        <div className="dr-safe-details-status-dialog-dropdown">
          {/* <DropDownList
            data={parentCasesList}
            value={currentParentCase}
            onChange={onChangeParentCaseList}
            textField="case_ref_no"
            dataItemKey="case_ref_no"
            className="dr-safe-details-status-dialog-dropdown-value"
            style={{
              width: "100%",
            }}
          /> */}
          <select onChange={onChangeParentCaseList} className='dr-safe-details-status-dialog-dropdown-value select-case-dropdown'
            style={{
                width: "100%",
              }}>
                <option key={'currentParentCase'} value={'currentParentCase'}>{currentParentCase}</option>
                {parentCasesList.map(
                    ({ case_ref_no, i }) => (
                        <option key={case_ref_no} value={case_ref_no}>{case_ref_no}</option>
                    )
                )}
            </select>
        </div>
      <div className="dr-safe-details-status-dialog-dropdown"></div>

        {errorMessage && <div className="error"><p style={{color: "red"}}> {errorMessage} </p></div>}
        <div className="dr-safe-details-updates-input-wrapper">
          
          <TextArea className="dr-safe-mark-as-duplicate-updates-input" onChange={onMarkAsDuplicateChange} 
            />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="dr-safe-details-status-dialog-button"
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DrSafeDetailsMarkAsDuplicateDialog;
