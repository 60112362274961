import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "@progress/kendo-react-dialogs";
// components
import DrSafeListHeader from "./dr_safe_list_header";
import DrSafeListBody from "./dr_safe_list_body";
// css
import "./dr_safe_list.css";
// store
import {
  fetchAllDRSafeIncidents,
  fetchDRSafeSearchResult,
  fetchDRSafeFilterResult,
  fetchDRSafeSortResult,
  changeDRSafeIncidentStatus,
} from "../../../../store/drSafeIncident";

import {
  getUserPermission,
  checkMultiPermission,
} from "../../../../config/permission_helper";
import Cross_icon from "../../../../assets/mic/cross_icon.svg";
import { DR_SAFE_PERMISSIONS_FOR_UPDATE_STATUS } from "../../../../config/permission_helper";
import { Loader } from "@progress/kendo-react-indicators";
import { getCurrentLoggedInSessionToken } from "../../../../config/userPool";
import api from "../../../../config/api";

const DrSafeList = () => {
  const dispatch = useDispatch();
  const [showDrSearchDialog, setShowDrSearchDialog] = useState(false);
  const [showDrFilterDialog, setShowDrFilterDialog] = useState(false);
  const [incidentList, setIncidentList] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [isSearch, setIsSearch] = useState("");
  const [filterResult, setFilterResult] = useState([]);
  const [sortResult, setSortResult] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showAlert, setshowAlert] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [safeDelete, setSafeDelete] = useState({
    isDelete: false,
    deleteId: "",
  });
  const { drSafeIncidentList, loading, error } = useSelector(
    (state) => state.drSafeIncident
  );
  const incidentStatuses = [
    "Open",
    "Received",
    "Investigating",
    "Completed",
    "Closed",
  ];
  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
  const [currentIncidentStatus, setCurrentIncidentStatus] = useState("");
  const [currentIncidentCaseRefNo, setCurrentIncidentCaseRefNo] = useState("");
  const [actionMessage, setActionMessage] = useState("");
  const handleOpenStatusDialog = (case_ref_no, status) => {
    console.log("click status-", case_ref_no, status);
    setCurrentIncidentStatus(status);
    setCurrentIncidentCaseRefNo(case_ref_no);
    setShowChangeStatusDialog(true);
  };
  const handleSaveIncidentStatus = () => {
    console.log("handlesaveincidentstatus");
    dispatch(
      changeDRSafeIncidentStatus({
        case_ref_no: currentIncidentCaseRefNo,
        incidentStatus: currentIncidentStatus.toLowerCase(),
      })
    )
      .then((result) => {
        console.log("status RES-", result);
        if (result.payload.status == "success") {
          setActionMessage("Updated Status");
          setshowAlert(true);
        } else {
          setActionMessage(result.payload.message);
          setshowAlert(true);
        }
      })
      .then(() => {
        dispatch(fetchAllDRSafeIncidents());
      });
    setShowChangeStatusDialog(false);
  };
  useEffect(() => {
    if (drSafeIncidentList) {
      setIncidentList(drSafeIncidentList);
    }
  }, [drSafeIncidentList]);

  useEffect(() => {
    dispatch(fetchAllDRSafeIncidents());
  }, []);

  const handleSearchAction = async (
    caseRefNo,
    busRegNo,
    busService,
    incidentLocation,
    updatedBy
  ) => {
    console.log(caseRefNo, busRegNo, busService, incidentLocation, updatedBy);
    const res = await dispatch(
      fetchDRSafeSearchResult({
        caseRefNo,
        busRegNo,
        busService,
        incidentLocation,
        updatedBy,
      })
    );
    console.log("Res Payload-", res.payload);
    if (res.payload.length > 0) {
      setSearchResult(res.payload);
      setIsSearch("search");
      setErrorMessage("");
    } else {
      setSearchResult([]);
      setIncidentList([]);
      setErrorMessage("");
    }
    setShowDrSearchDialog(false);
  };

  const handleFilterAction = async (status, updatedDateFrom, updatedDateTo) => {
    console.log(status, updatedDateFrom, updatedDateTo);
    const res = await dispatch(
      fetchDRSafeFilterResult({
        status: status,
        updateon_startdate: updatedDateFrom,
        updateon_enddate: updatedDateTo,
      })
    );
    console.log("Res Filter Payload-", res.payload);
    if (res.payload.length > 0) {
      setFilterResult(res.payload);
      setIsSearch("filter");
      setErrorMessage("");
    } else {
      setFilterResult([]);
      setIncidentList([]);
      setErrorMessage("");
    }
    setShowDrFilterDialog(false);
  };

  const handleSortAction = async (sortBy, orderBy) => {
    console.log(sortBy, orderBy);
    const res = await dispatch(
      fetchDRSafeSortResult({ sortby: sortBy, orderby: orderBy })
    );
    console.log("Res Sortby Payload-", res.payload);
    if (res.payload.length > 0) {
      setSortResult(res.payload);
      setIsSearch("sort");
      setErrorMessage("");
    } else {
      setFilterResult([]);
      setIncidentList([]);
    }
  };
  const shouldShowUpdateStatusButton = () => {
    const userPermissions = getUserPermission();
    return checkMultiPermission(
      userPermissions,
      DR_SAFE_PERMISSIONS_FOR_UPDATE_STATUS
    )
      ? true
      : false;
  };
  const DeleteCall = (val) => {
    setSafeDelete({
      ...safeDelete,
      isDelete: true,
      deleteId: val.dataItem.case_ref_no,
    });
  };
  const Delete = async () => {
    const token = getCurrentLoggedInSessionToken();
    setLoading(true);
    setSafeDelete({
      ...safeDelete,
      isDelete: false,
    });
    const results = await api.remove(
      `/api-dr/dr-safe/incidents/${safeDelete.deleteId}`,
      JSON.stringify({ id: safeDelete.deleteId }),
      token
    );
    if (results.status === "success") {
      setLoading(false);
      setSafeDelete({
        ...safeDelete,
        deleteId: "",
        isDelete: false,
      });
      dispatch(fetchAllDRSafeIncidents());
    } else {
      setLoading(false);
    }
  };
  return (
    <div className="dr-safe-list-wrapper">
      {Loading && (
        <Loader
          type="converging-spinner"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ffffff4d",
            position: "absolute",
            zIndex: "999",
          }}
        />
      )}
      {showAlert && (
        <Dialog
          className="alert-form"
          title={"Alert"}
          width={400}
          // height={280}
          onClose={() => setshowAlert(false)}
        >
          <p>{actionMessage}</p>
        </Dialog>
      )}
      <DrSafeListHeader
        showDrSearchDialog={showDrSearchDialog}
        onSearchBtnClicked={() => setShowDrSearchDialog(true)}
        onSearchDialogClose={() => setShowDrSearchDialog(false)}
        onSearchAction={(
          caseRefNo,
          busRegNo,
          busService,
          incidentLocation,
          updatedBy
        ) =>
          handleSearchAction(
            caseRefNo,
            busRegNo,
            busService,
            incidentLocation,
            updatedBy
          )
        }
        showDrFilterDialog={showDrFilterDialog}
        onFilterBtnClicked={() => setShowDrFilterDialog(true)}
        onFilterDialogClose={() => setShowDrFilterDialog(false)}
        onFilterAction={(status, updatedDateFrom, updatedDateTo) =>
          handleFilterAction(status, updatedDateFrom, updatedDateTo)
        }
      />
      {errorMessage && (
        <div className="error">
          <p style={{ color: "red" }}> {errorMessage} </p>
        </div>
      )}
      <DrSafeListBody
        loading={loading}
        records={
          isSearch == "search"
            ? searchResult
            : isSearch == "filter"
            ? filterResult
            : isSearch == "sort"
            ? sortResult
            : incidentList
        }
        onSortAction={(sortBy, orderBy) => handleSortAction(sortBy, orderBy)}
        openStatusDialog={handleOpenStatusDialog}
        onToggleDialog={() => setShowChangeStatusDialog(false)}
        incidentStatuses={incidentStatuses}
        currentIncidentStatus={currentIncidentStatus}
        onChangeIncidentStatus={(e) => setCurrentIncidentStatus(e.target.value)}
        onSaveIncidentStatus={handleSaveIncidentStatus}
        showChangeStatusDialog={showChangeStatusDialog}
        showUpdateStatusButton={shouldShowUpdateStatusButton()}
        DeleteCall={DeleteCall}
      />
      {safeDelete.isDelete && (
        <Dialog>
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                right: "10px",
                top: "15px",
                cursor: "pointer",
              }}
              onClick={() =>
                setSafeDelete({
                  ...safeDelete,
                  isDelete: false,
                  deleteId: "",
                })
              }
            >
              <img src={Cross_icon} />
            </div>
          </div>
          <div className="dr_safe_delete_popup_box">
            <div className="dr_safe_delete_title">
              Are you sure, you want to delete {safeDelete.deleteId}
            </div>
            <div className="dr_safe_delete_footer_box">
              <div
                className="dr_safe_delete_footer_cancel"
                onClick={() =>
                  setSafeDelete({
                    ...safeDelete,
                    isDelete: false,
                    deleteId: "",
                  })
                }
              >
                Cancel
              </div>
              <div className="dr_safe_delete_footer_delete" onClick={Delete}>
                Delete
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default DrSafeList;
