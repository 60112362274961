// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const {
  IFEEDBACK_ENDPOINT,
  IFEEDBACK_MATTER_ENDPOINT,
  EXPORT_IFEEDBACK_ENDPOINT,
  // EXPORT_NOTIFICATION_ENDPOINT,
  AUDIT_ENDPOINT,
} = config.api_endpoint;

const initialState = {
  loading: false,
  remarks_updates_loading: false,
  internalnotes_loading: false,
  remarks_error: null,
  internalnotes_error: null,
  ifeedbackUpdates: [],
  ifeedbackInternalnotes: [],
  ndata_loading: false,
  ifeedback_ids: [],
  ifeedback_next_key: {},
  ifeedback_data: [],
  error: null,
  ndata_error: null,
  // overview_loading: false,
  // overview_data: [],
  // overview_error: null,
  // export_data: [],
  // export_loading: false,
  // export_error: [],
  detail_data: [],
  detail_loading: false,
  audit_loading: false,
  audit_data: [],
  audit_error: false,
  route_groups: [],
  route_groups_loading: false,
  route_group_error: null,
  matter_loading: false,
  matters: [],
  matter_error: null,
  detail_audits_loading: false,
  detail_audits: [],
  detail_audits_error: false,
  refresh_audit: false,
};
const status_values = {
  open: "Open",
  investigating: "Investigating",
  closed: "Closed",
  // pendingapproval: "Pending Approval",
  // rejected: "Rejected",
  // archived: "Archived",
  // recall: "Recall",
  // approved: "Approved",
};
export const fetchiFeedbackById = createAsyncThunk(
  "ifeedback/fetchiFeedbackById",
  async (ifeedback_id, { rejectWithValue }) => {
    console.log(3456789);
    try {
      const token = getCurrentLoggedInSessionToken();
      console.log(token);
      console.log(ifeedback_id);
      const results = await api.get(
        `${IFEEDBACK_ENDPOINT}/${ifeedback_id}`,
        {},
        token
      );
      console.log(1234);
      return results.data;
    } catch (e) {
      return rejectWithValue(e.details);
    }
  }
);

// export const fetchNotificationOverviewById = createAsyncThunk(
//   "notification/fetchNotificationOverviewById",
//   async (notification_id, { rejectWithValue }) => {
//     try {
//       const token = getCurrentLoggedInSessionToken();
//       const results = await api.get(
//         `${NOTIFICATION_OVERVIEW_ENDPOINT}/${notification_id}`,
//         { },
//         token
//       );
//       let data = results.data;
//       return data
//     } catch (e) {
//       return rejectWithValue(e.details);
//     }
//   }
// );

export const fetchAlliFeedback = createAsyncThunk(
  "ifeedback/fetchAlliFeedback",
  async (query, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      console.log("Query- ", query.toString());
      const query_string = query.toString().replace("?", "");
      const results = await api.get(
        `${IFEEDBACK_ENDPOINT}?${query_string}`,
        {},
        token
      );
      console.log("All iFeedback get API Results-", results);
      let result_data = results.data;
      // result_data =result_data.map((v)=>(
      //   { ...v,
      //     total_read_count: `${v.total_read}/${v.total_recipient}`,
      //     ack_label: v.ack_required ? "Yes": "No",
      //     status_label: status_values[v.status],
      //     channel_label: v.channel.map((v)=>v.name).join(",")
      //   }
      //   ))
      console.log("RES DATA- ", result_data, "NnextKEY- ", results.next_key);
      return {
        data: result_data,
        next_key: results.next_key,
      };
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchiFeedbackRemarksUpdates = createAsyncThunk(
  "ifeedback/fetchiFeedbackRemarksUpdates",
  async ({ ifeedback_id }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        `${IFEEDBACK_ENDPOINT}/${ifeedback_id}/remarks/updates`,
        {},
        token
      );
      console.log("Remark res- ", results);
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchiFeedbackRemarksInternalnotes = createAsyncThunk(
  "ifeedback/fetchiFeedbackRemarksInternalnotes",
  async ({ ifeedback_id }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        `${IFEEDBACK_ENDPOINT}/${ifeedback_id}/remarks/internalnotes`,
        {},
        token
      );
      console.log("Remark res- ", results);
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const saveIfeedbackRemarksUpdates = createAsyncThunk(
  "ifeedback/saveIfeedbackRemarksUpdates",
  async ({ ifeedback_id, description, type }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      console.log("remarks requests- ", ifeedback_id, type, description);
      // const results = await api.get(IFEEDBACK_ENDPOINT+"?"+query_string, {}, token);
      const res = await api.post(
        `${IFEEDBACK_ENDPOINT}/${ifeedback_id}/remarks/${type}`,
        JSON.stringify({
          description: description,
        }),
        token
      );

      const results = await api.get(
        `${IFEEDBACK_ENDPOINT}/${ifeedback_id}/remarks/${type}`,
        {},
        token
      );

      console.log(results);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const saveIfeedbackRemarksInternalnotes = createAsyncThunk(
  "ifeedback/saveIfeedbackRemarksInternalnotes",
  async ({ ifeedback_id, description }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      console.log("remarks requests- ", ifeedback_id, description);
      const res = await api.post(
        `${IFEEDBACK_ENDPOINT}/${ifeedback_id}/remarks/internalnotes`,
        JSON.stringify({
          description: description,
        }),
        token
      );

      const results = await api.get(
        `${IFEEDBACK_ENDPOINT}/${ifeedback_id}/remarks/internalnotes`,
        {},
        token
      );

      console.log(results);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const saveIfeedbackShare = createAsyncThunk(
  "ifeedback/saveIncidentShare",
  async ({ ifeedback_id, emails }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      let email_list = emails.split(",");
      console.log("Share Api call: ", email_list);
      const res = await api.post(
        `${IFEEDBACK_ENDPOINT}/${ifeedback_id}/share`,
        JSON.stringify({
          emails: email_list,
        }),
        token
      );
      console.log("Share Result: ", res);
      return res;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const changeiFeedbackStatus = createAsyncThunk(
  "ifeedback/changeiFeedbackStatus",
  async ({ ifeedback_id, ifeedbackStatus }, { rejectWithValue }) => {
    try {
      console.log("RefNo- ", ifeedback_id, "Status- ", ifeedbackStatus);
      const token = getCurrentLoggedInSessionToken();
      const data = {};
      const res = await api.post(
        `${IFEEDBACK_ENDPOINT}/${ifeedback_id}/${ifeedbackStatus}`,
        JSON.stringify(data),
        token
      );
      console.log("iFeedback Status Update api result-", res);
      return res;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchiFeedbackRouteGroups = createAsyncThunk(
  "ifeedback/fetchiFeedbackRouteGroups",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        `${IFEEDBACK_ENDPOINT}/route-groups`,
        {},
        token
      );
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const saveiFeedbackGroup = createAsyncThunk(
  "ifeedback/saveiFeedbackGroup",
  async ({ ifeedback_id, group_id }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const res = await api.post(
        `${IFEEDBACK_ENDPOINT}/${ifeedback_id}/route`,
        JSON.stringify({
          group_id: group_id,
        }),
        token
      );
      console.log("Route to Other Dep API result- ", res);
      return res;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchiFeedbackMatters = createAsyncThunk(
  "ifeedback/fetchiFeedbackMatters",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(IFEEDBACK_MATTER_ENDPOINT, {}, token);
      console.log("All Matter- ", results);
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const saveiFeedbackMatter = createAsyncThunk(
  "ifeedback/saveiFeedbackMatter",
  async ({ ifeedback_id, no }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      console.log("Save iFeedback Matter no-", ifeedback_id, no);
      const res = await api.post(
        `${IFEEDBACK_ENDPOINT}/${ifeedback_id}/change-matter`,
        JSON.stringify({
          matter: no,
        }),
        token
      );
      console.log("Change Matter API result- ", res);
      return res;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);
export const exportToCSViFeedback = createAsyncThunk(
  "ifeedback/exportToCSViFeedback",
  async (data, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        EXPORT_IFEEDBACK_ENDPOINT,
        JSON.stringify(data),
        token
      );
      return results.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchiFeedbackAudit = createAsyncThunk(
  "ifeedback/fetchiFeedbackAudit",
  async (ifeedback_id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${AUDIT_ENDPOINT}?table_name=ifeedback&source_id=${ifeedback_id}&search=True`;
      const results = await api.get(url, {}, token);
      return results.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchiFeedbackDetailsAudit = createAsyncThunk(
  "ifeedback/fetchiFeedbackDetailsAudit",
  async (id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${AUDIT_ENDPOINT}?table_name=ifeedback&source_id=${id}&`;
      const results = await api.get(url, {}, token);
      return results.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.details);
    }
  }
);

// export const fetchNotificationAudit= createAsyncThunk(
//   "notification/fetchNotificationAudit",
//   async (notification_id, { rejectWithValue }) => {
//     try {
//       const token = getCurrentLoggedInSessionToken();
//       const url = `${AUDIT_NOTIFICATION_ENDPOINT}?table_name=ilink-dev-comm-notification&source_id=${notification_id}&search=True`
//       const results = await api.get(url, {}, token);
//       return results.data;
//     } catch (error) {
//       console.log(error);
//       return rejectWithValue(error.details);
//     }
//   }
// );

// export const fetchAllNotificationsSortBy = createAsyncThunk(
//   "notification/fetchAllNotificationsSortBy",
//   async (query, { rejectWithValue }) => {
//     try {
//       const token = getCurrentLoggedInSessionToken();
//       const query_string = query.toString().replace("?","");
//       console.log(query_string);
//       const results = await api.get(NOTIFICATION_ENDPOINT+"?"+query_string, {}, token);
//       console.log(results);
//       let result_data = results.data;
//       result_data =result_data.map((v)=>(
//         { ...v,
//           total_read_count: `${v.total_read}/${v.total_recipient}`,
//           ack_label: v.ack_required ? "Yes": "No",
//           status_label: status_values[v.status],
//           channel_label: v.channel.map((v)=>v.name).join(",")
//         }
//         ))
//       return result_data;
//     } catch (error) {
//       return rejectWithValue(error.details);
//     }
//   }
// );

const iFeedbackSlice = createSlice({
  name: "ifeedback",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchiFeedbackById.pending, (state) => {
        // state.ndata_loading = true;
        state.detail_loading = true;
      })
      .addCase(fetchiFeedbackById.fulfilled, (state, action) => {
        state.detail_loading = false;
        console.log(123);
        state.detail_data = action.payload;
        console.log(state.detail_data);
      })
      .addCase(fetchiFeedbackById.rejected, (state, action) => {
        state.detail_loading = false;
        // state.ndata_loading = false;
        state.ndata_error = action.error.message;
      })
      .addCase(fetchiFeedbackRemarksUpdates.pending, (state) => {
        console.log("fetch all dr safe incident remarks pending: ", state);
        state.remarks_updates_loading = true;
      })
      .addCase(fetchiFeedbackRemarksUpdates.fulfilled, (state, action) => {
        state.remarks_updates_loading = false;
        state.ifeedbackUpdates = action.payload;
      })
      .addCase(fetchiFeedbackRemarksUpdates.rejected, (state, action) => {
        state.remarks_updates_loading = false;
        state.remarks_error = action.error.message;
      })

      .addCase(fetchiFeedbackRemarksInternalnotes.pending, (state) => {
        console.log("fetch all dr safe incident remarks pending: ", state);
        state.internalnotes_loading = true;
      })
      .addCase(
        fetchiFeedbackRemarksInternalnotes.fulfilled,
        (state, action) => {
          state.internalnotes_loading = false;
          state.ifeedbackInternalnotes = action.payload;
        }
      )
      .addCase(fetchiFeedbackRemarksInternalnotes.rejected, (state, action) => {
        state.internalnotes_loading = false;
        state.internalnotes_error = action.error.message;
      })
      .addCase(saveIfeedbackRemarksUpdates.pending, (state) => {
        console.log("save dr safe incident remarks pending: ", state);
        state.remarks_updates_loading = true;
        state.refresh_audit = false;
      })
      .addCase(saveIfeedbackRemarksUpdates.fulfilled, (state, action) => {
        console.log("save dr safe incident remarks fulfilled: ", state, action);
        state.remarks_updates_loading = false;
        state.ifeedbackUpdates = action.payload;
        state.refresh_audit = true;
      })
      .addCase(saveIfeedbackRemarksUpdates.rejected, (state, action) => {
        console.log("save dr safe incident remarks rejected: ", state, action);
        state.remarks_updates_loading = false;
        state.remarks_error = action.error.message;
        state.refresh_audit = false;
      })
      .addCase(saveIfeedbackRemarksInternalnotes.pending, (state) => {
        console.log("save dr safe incident remarks pending: ", state);
        state.internalnotes_loading = true;
        state.refresh_audit = false;
      })
      .addCase(saveIfeedbackRemarksInternalnotes.fulfilled, (state, action) => {
        console.log("save dr safe incident remarks fulfilled: ", state, action);
        state.internalnotes_loading = false;
        state.ifeedbackInternalnotes = action.payload;
        state.refresh_audit = true;
      })
      .addCase(saveIfeedbackRemarksInternalnotes.rejected, (state, action) => {
        console.log("save dr safe incident remarks rejected: ", state, action);
        state.internalnotes_loading = false;
        state.internalnotes_error = action.error.message;
        state.refresh_audit = false;
      })
      .addCase(fetchiFeedbackRouteGroups.pending, (state) => {
        state.route_groups_loading = true;
      })
      .addCase(fetchiFeedbackRouteGroups.fulfilled, (state, action) => {
        state.route_groups_loading = false;
        state.route_groups = action.payload;
      })
      .addCase(fetchiFeedbackRouteGroups.rejected, (state, action) => {
        state.route_groups_loading = false;
        state.route_group_error = action.error.message;
      })
      .addCase(fetchiFeedbackMatters.pending, (state) => {
        state.matter_loading = true;
      })
      .addCase(fetchiFeedbackMatters.fulfilled, (state, action) => {
        state.matter_loading = false;
        state.matters = action.payload;
      })
      .addCase(fetchiFeedbackMatters.rejected, (state, action) => {
        state.matter_loading = false;
        state.matter_error = action.error.message;
      })
      .addCase(fetchiFeedbackAudit.pending, (state) => {
        state.audit_loading = true;
      })
      .addCase(fetchiFeedbackAudit.fulfilled, (state, action) => {
        state.audit_loading = false;
        state.audit_data = action.payload;
      })
      .addCase(fetchiFeedbackAudit.rejected, (state, action) => {
        state.audit_loading = false;
        state.audit_error = action.error.message;
      })
      .addCase(fetchiFeedbackDetailsAudit.pending, (state) => {
        state.detail_audits_loading = true;
      })
      .addCase(fetchiFeedbackDetailsAudit.fulfilled, (state, action) => {
        state.refresh_audit = false;
        state.detail_audits_loading = false;
        state.detail_audits = action.payload;
      })
      .addCase(fetchiFeedbackDetailsAudit.rejected, (state, action) => {
        state.refresh_audit = false;
        state.detail_audits_loading = false;
        state.detail_audits_error = action.error.message;
      })
      .addCase(fetchAlliFeedback.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAlliFeedback.fulfilled, (state, action) => {
        state.loading = false;
        state.ifeedback_ids = action.payload.data;
        console.log("fetchAlliFeedback fulFilled", action.payload.data);
        state.ifeedback_next_key = action.payload.next_key;
      })
      .addCase(fetchAlliFeedback.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(changeiFeedbackStatus.pending, (state) => {
        state.detail_loading = true;
      })
      .addCase(changeiFeedbackStatus.fulfilled, (state, action) => {
        state.detail_loading = false;
        console.log("Change Status fulFilled");
        state.detail_data = action.payload;
        console.log(state.detail_data);
      })
      .addCase(changeiFeedbackStatus.rejected, (state, action) => {
        state.detail_loading = false;
        state.ndata_error = action.error.message;
      });
  },
});

const iFeedbackReducer = iFeedbackSlice.reducer;
export default iFeedbackReducer;
