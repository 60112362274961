import { useCallback, useState } from "react";
import { ReUploadBSQCByID } from "../../../../../../../services/feedbackOnBus";
import { useSelector } from "react-redux";

export const useUpload = () => {
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('');

  const onAddFile = (e) =>{
    const file = e?.target?.files[0];

    setFile(file)
    setFilename(file?.name)
  };

  const onDeleteFile = () => {
    setFile(null);
    setFilename('');
  }

  return {
    file,
    filename,
    onAddFile,
    onDeleteFile
  }
}

export const useReploadBSQC = props => {
  const [isFetching, setIsFetching] = useState(false);
  const { selectedCheckPeriod } = useSelector((state) => state.feedbackOnBus);


  const onReUpload = useCallback(
    async () => {
      if (props?.file && props?.filename) {
        setIsFetching(true);
        const form = new FormData();

        form.append('file', props?.file);
        form.append('filename', props?.filename);

        const [err, response]= await ReUploadBSQCByID(selectedCheckPeriod, form);

        if (!err && response) {
          props?.onSuccess();
          setIsFetching(false);

          return;
        }

        props?.onError(err?.message);
        setIsFetching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props?.file, props?.filename, selectedCheckPeriod]
  );

  return {
    isFetching,
    onReUpload
  }
}