import React, { useState,useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { TableHeader } from "../../../../components/table/Tableheader";
import { CategoryTableBody } from "../../../../components/table/categoryTable";
import { fetchAllCategories,deleteCategory } from "../../../../store/category";
import { Loader } from "@progress/kendo-react-indicators";
import { ADMIN_TOOLS_CATEGORY_ADD, ADMIN_TOOLS_CATEGORY_LIST} from "../../../../routes";
import "../../admin_tools.css";
import { FilterCategory } from "../../../../components/dialog/category_filter";
import { SearchCategory } from "../../../../components/dialog/category_search";

export const CategoryList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let {search} = useLocation();
  const query = new URLSearchParams(search);
  const [showFilterDialog,setShowFilterDialog ] = useState(false);
  const [showSearchDialog ,setShowSearchDialog ] = useState(false);
  const [category_ids,setCategory_ids] = useState([]);
  const [all_category,setAllCategory] = useState([]);

  const { loading:category_loading } = useSelector((state) => state.category);
  const {delete_res,delete_loading,delete_error} = useSelector((state)=> state.category);

  useEffect(()=>{
    let results = all_category;
    if(query.toString()){
      if(query.get("ack_required") === "True"){
        results = results.filter((v)=> v.ack_required === true)
      }
      else if(query.get("ack_required" === "False")){
        results = results.filter((v)=> v.ack_required === false)
      }
      if(query.get("push_silently") === "True"){
        results = results.filter((v)=> v.push_silently === true)
      }
      else if(query.get("push_silently") === "False"){
        results = results.filter((v)=> v.push_silently === false)
      }
      if(query.get("name")){
        results = results.filter((v)=> v.name.toLowerCase().indexOf(query.get("name").toLowerCase())>-1)
      }
      if(query.get("sound")){
        results = results.filter((v)=> v.sound.toLowerCase().indexOf(query.get("sound").toLowerCase())>-1)
      }
    }
    setCategory_ids(results);
  },[all_category]);

  useEffect(() => {
    dispatch(fetchAllCategories()).then((res)=>{
      let results = res.payload
      setAllCategory(results);
    })
  }, []);

  const handleDeleteAction = ((id)=>{
    dispatch(deleteCategory(id)).then(()=>{
      dispatch(fetchAllCategories()).then((res)=>{
        let results = res.payload
        setAllCategory(results);
      });
    })
  })
  const handleFilterCallback = (data)=>{
    const {ack_required,is_all_ack,push_silently,is_all_pushsilently} = data;
    query.set("ack_required",ack_required);
    query.set("is_all_ack",is_all_ack);
    query.set("push_silently",push_silently);
    query.set("is_all_pushsilently",is_all_pushsilently);
    if(ack_required || push_silently){
      query.set("filter",true);
    }
    navigate(ADMIN_TOOLS_CATEGORY_LIST+"?"+query.toString());
    setShowFilterDialog(false);    
    handleFilteringData();   
  }
  const handleSearchCallback = (data)=>{
    console.log(data);
    const {name, sound }= data;
    query.set("name",name);
    query.set("sound",sound);
    if(name || sound){
      query.set("search",true);
    }
    navigate(ADMIN_TOOLS_CATEGORY_LIST+"?"+query.toString());
    setShowSearchDialog(false);
    handleFilteringData(); 
  }
  const handleClearFilterOptions = ()=>{
    query.delete("ack_required");
    query.delete("is_all_ack");
    query.delete("push_silently");
    query.delete("is_all_pushsilently");
    query.delete("filter");
    navigate(ADMIN_TOOLS_CATEGORY_LIST+"?"+query.toString());
    handleFilteringData();
  }
  const handleClearSearchOptions = ()=>{
    query.delete("name");
    query.delete("sound");
    query.delete("search");
    navigate(ADMIN_TOOLS_CATEGORY_LIST+"?"+query.toString());
    handleFilteringData();
  }
  const handleFilteringData = ()=>{
    let results = all_category;
    if(query.get("ack_required") === "True"){
      results = results.filter((v)=> v.ack_required === true)
    }
    else if(query.get("ack_required" === "False")){
      results = results.filter((v)=> v.ack_required === false)
    }
    if(query.get("push_silently") === "True"){
      results = results.filter((v)=> v.push_silently === true)
    }
    else if(query.get("push_silently") === "False"){
      results = results.filter((v)=> v.push_silently === false)
    }
    if(query.get("name")){
      results = results.filter((v)=> v.name.toLowerCase().indexOf(query.get("name").toLowerCase())>-1)
    }
    if(query.get("sound")){
      results = results.filter((v)=> v.sound.toLowerCase().indexOf(query.get("sound").toLowerCase())>-1)
    }
    setCategory_ids(results);
  }
  return (
    <div className="body">
      {showFilterDialog && (
        <FilterCategory
          setShowFilterDialog={setShowFilterDialog}
          onFiltercallback={handleFilterCallback}
      />
      )}
      {showSearchDialog && (
        <SearchCategory 
          setShowSearchDialog={setShowSearchDialog}
          onSearchcallback={handleSearchCallback}
        />
      )}
      <TableHeader 
        title = "Manage Category"
        onRefresh = {()=> {
          dispatch(
            fetchAllCategories()).then(
              (res)=>{
                let results = res.payload
                setAllCategory(results);
              }
            )
          }
        }
        loading={category_loading}
        onFilter={() => setShowFilterDialog(true)}
        onSearch={() => setShowSearchDialog(true)}
        addurl={ADMIN_TOOLS_CATEGORY_ADD}        
      />
      {(query.get("filter") || query.get("search")) && (
      <div className="table-filter-div">
        {query.get("filter")&& (
        <div className="table-filter-item">
          <div className="table-filter-title">Filter applied</div>
          <span className="table-filter-clear" onClick={handleClearFilterOptions} >Clear filter</span>
        </div>
        )}
        {query.get("search") && (
        <div className="table-filter-item">
          <div className="table-filter-title">Keyword search applied</div>
          <span className="table-filter-clear" onClick={handleClearSearchOptions}>Clear search</span>
        </div>
        )}
      </div>
      )}      
      {category_loading || delete_loading ? (
      <Loader
        type="infinite-spinner"
        style={{
          display: "flex",
          alignItems: "center",
          width: "40px",
          margin: "auto",
          justifyContent: "center",
          height: "100vh",
        }}
      />
      ):(
        <CategoryTableBody
          data={category_ids}
          handleDeleteAction={handleDeleteAction}
        />
      )}

    </div>
  );
};