import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "@progress/kendo-react-dialogs";
// components
import DrSecureDetailsContent from "./dr_secure_details_content";
import DrSecureDetailsUpdates from "./dr_secure_details_updates";
import DrSecureDetailsStatus from "./dr_secure_details_status";
import DrSecureDetailsActions from "./dr_secure_details_actions";
import DrSecureDetailsAudits from "./dr_secure_details_audits";
// css
import "./dr_secure_details.css";
import {
  fetchAllDRSecureIncidentDetails,
  fetchAllDRSecureIncidentRemarks,
  saveDRSecureIncidentRemarks,
  changeDRSecureIncidentStatus,
  fetchAllDRSecureIncidents,
  saveDRSecureMarkAsDuplicate,
  saveDRSecureIncidentShare,
  saveDRSecureIncidentGroup
} from "../../../../store/drSecureIncident";
import { fetchUserById } from "../../../../store/userMgmt";

import {
  getUserPermission,
  checkMultiPermission,
  DR_SECURE_PERMISSIONS_FOR_UPDATE_STATUS,
  DR_SECURE_PERMISSIONS_FOR_SUBMIT_UPDATE,
  DR_SECURE_PERMISSIONS_FOR_ROUTE,
  DR_SECURE_PERMISSIONS_FOR_SHARE,
  DR_SECURE_PERMISSIONS_FOR_MARK_DUPLICATE
} from "../../../../config/permission_helper";

const DrSecureDetails = () => {
  let { search } = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(search);
  const {
    drSecureIncidentDetails,
    details_loading: loading,
    details_error: error,
    drSecureIncidentRemarks,
    remarks_loading,
    remarks_error,
  } = useSelector((state) => state.drSecureIncident);
  const { user_details_loading, user_details } = useSelector((state) => state.userMgmt);
  const [audits, setAudits] = useState([]);
  const [incident, setIncident] = useState({});
  const [updates, setUpdates] = useState([]);
  const [locations, setLocations] = useState([]);
  const [actionMessage,setActionMessage] = useState("");
  const [showAlert,setshowAlert] = useState(false);
  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
  const [showChangeMarkAsDuplicateDialog, setshowChangeMarkAsDuplicateDialog] = useState(false);
  const [showChangeShareDialog, setshowChangeShareDialog] = useState(false);
  const [showRouteToDepeDialog,setShowRouteToDepDialog] = useState(false);
  const [createdByDetails, setCreatedByDetails] = useState({});
  const [updatedByDetails, setUpdatedByDetails] = useState({});

  const case_ref_no = query.get("case_ref_no")

  const incidentStatuses = ["Open", "Received", "Closed"];

  const [currentIncidentStatus, setCurrentIncidentStatus] = useState("");
  const handleChangeIncidentStatus = (e) => {
    setCurrentIncidentStatus(e.target.value);
  };
  const handleSaveIncidentStatus = () => {
    dispatch(
      changeDRSecureIncidentStatus({
        case_ref_no: incident.case_ref_no,
        incidentStatus: currentIncidentStatus.toLowerCase(),
      })
    ).then (result => {
      console.log("status RES-",result);
      if(result.payload.status == "success"){
        setActionMessage('Updated Status');
        setshowAlert(true);
      }
      else{
        setActionMessage(result.payload.message);
        setshowAlert(true);
      }
      })
    .then(()=>{
      dispatch(fetchAllDRSecureIncidentDetails(case_ref_no))
    });
    setShowChangeStatusDialog(false);
  };
  const handleUpdateStatusBtnClicked = () => {
    setCurrentIncidentStatus(incident.status);
    setShowChangeStatusDialog(true);
  };
  const handleChangeIncidentStatusDialogClose = () => {
    setShowChangeStatusDialog(false);
    setshowChangeMarkAsDuplicateDialog(false);
    setshowChangeShareDialog(false);
  };

  // Mark as duuplicate
  const handleMarkAsDuplicateBtnClicked = () => {
    setCurrentIncidentStatus(incident.status);
    setshowChangeMarkAsDuplicateDialog(true);
  };

  const handleRouteToDepAction = async(data)=>{
    setShowRouteToDepDialog(false);
    dispatch(saveDRSecureIncidentGroup({case_ref_no,group_id: data}))
    .then(result => {
      console.log("route to dep RES-",result);
      if(result.payload.status == "success"){
        setActionMessage('Successfully route to other department!');
        setshowAlert(true);
      }
      else{
        setActionMessage(result.payload.message);
        setshowAlert(true);
      }
    })
    .then(()=>{
      dispatch(fetchAllDRSecureIncidentDetails(case_ref_no))
    })
  }

  const saveMarkAsDuplicate = (currentParentCase,description) => {
    setshowChangeMarkAsDuplicateDialog(false);
    let case_ref_no = incident.case_ref_no;
    console.log("dr secure saveMarkAsDuplicate:", case_ref_no, description);
    dispatch(saveDRSecureMarkAsDuplicate({ case_ref_no, currentParentCase, description }))
    .then (result => {
      console.log("duplicate RES-",result);
      if(result.payload.status == "success"){
        setActionMessage('Duplicated Successfully!');
        setshowAlert(true);
      }
      else{
        setActionMessage(result.payload.message);
        setshowAlert(true);
      }
      })
    .then(()=>{
      dispatch(fetchAllDRSecureIncidentDetails(case_ref_no))});    
  };
  const [parentCasesList, setparentCasesList] = useState([]);
  console.log("Parent case List",parentCasesList);
  const { drSecureIncidentList, parentcaseloading } = useSelector(
    (state) => state.drSecureIncident
  );
  useEffect(() => {
    console.log("Dr Secure Incident List:", drSecureIncidentList);
    setparentCasesList(drSecureIncidentList)
  }, [drSecureIncidentList]);

  useEffect(() => {
    dispatch(fetchAllDRSecureIncidents());
  }, []);
  // Mark as duuplicate
  // Share
  const handleShareBtnClicked = () => {
    setCurrentIncidentStatus(incident.status);
    setshowChangeShareDialog(true);
  };
  const saveShare=async(emails) =>{
    setshowChangeShareDialog(false);
    let case_ref_no = incident.case_ref_no;
    dispatch(saveDRSecureIncidentShare({ case_ref_no, emails }))
    .then(result => {
      console.log("Share RES- ",result);
      if (result.payload.status == "success"){
        setActionMessage('Submitted successfully!');
        setshowAlert(true);
      }
      else {
        setActionMessage(result.payload.message);
        setshowAlert(true);
      }
    })
    .catch(error => {setActionMessage('Share failed.')}); 
   
  };
  // Share
  const saveRemarks = (description) => {
    let case_ref_no = incident.case_ref_no;
    console.log("dr secure saveRemarks:", case_ref_no, description);
    dispatch(saveDRSecureIncidentRemarks({ case_ref_no, description }));
  };

  const shouldShowBtnUpdateStatus = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, DR_SECURE_PERMISSIONS_FOR_UPDATE_STATUS)) {
      return true;
    } else {
      return false;
    }
  };

  const shouldShowBtnUpdate = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, DR_SECURE_PERMISSIONS_FOR_SUBMIT_UPDATE)) {
      return true;
    } else {
      return false;
    }
  };

  const shouldShowBtnRoute = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, DR_SECURE_PERMISSIONS_FOR_ROUTE)) {
      return true;
    } else {
      return false;
    }
  };

  const shouldShowBtnShare = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, DR_SECURE_PERMISSIONS_FOR_SHARE)) {
      return true;
    } else {
      return false;
    }
  };

  const shouldShowBtnMarkDuplicate = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, DR_SECURE_PERMISSIONS_FOR_MARK_DUPLICATE)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    console.log("Dr Secure Incident Details:", drSecureIncidentDetails);

    if (drSecureIncidentDetails) {
      if(drSecureIncidentDetails[0]){
        setIncident(drSecureIncidentDetails[0]);
        setCurrentIncidentStatus(drSecureIncidentDetails[0].status);
      }
      if(drSecureIncidentDetails.length>0){
        if(drSecureIncidentDetails[0].created_by.username === drSecureIncidentDetails[0].updated_by.username){
          dispatch(fetchUserById(drSecureIncidentDetails[0].created_by.username));
        }else{
          dispatch(fetchUserById(drSecureIncidentDetails[0].created_by.username));
          dispatch(fetchUserById(drSecureIncidentDetails[0].updated_by.username));
        }
      }      
    }
  }, [drSecureIncidentDetails]);

  useEffect(() => {
    console.log("Dr Secure Incident Remarks:", drSecureIncidentRemarks);
    if (drSecureIncidentDetails) {
      setUpdates(drSecureIncidentRemarks);
    }
  }, [drSecureIncidentRemarks]);

  useEffect(() => {

    console.log("Incident Details query:", query.get("case_ref_no"));

    if (query.get("case_ref_no")) {
      dispatch(fetchAllDRSecureIncidentDetails(query.get("case_ref_no")));
      dispatch(fetchAllDRSecureIncidentRemarks(query.get("case_ref_no")));
    }
  }, []);
  useEffect(()=>{
    if(user_details.username && incident.created_by){
      if(user_details.username === incident.created_by.username){
        setCreatedByDetails(user_details);
      }
      if(user_details.username === incident.updated_by.username){
        setUpdatedByDetails(user_details);
      }      
    }
  },[user_details]);
  return (
    <div className="dr-secure-details-wrapper">
      <div className="dr-secure-details-left">
      {showAlert && (
                <Dialog
                    className="alert-form"
                    title={"Alert"}                    
                    width={400}
                    // height={280}
                    onClose={()=>setshowAlert(false)}
                >
                    <p>{actionMessage}</p>
                </Dialog>
        )}
        <DrSecureDetailsContent
          loading={loading}
          incident={incident}
          locations={locations}
          createdUser={createdByDetails}
          updatedUser={updatedByDetails}
        />
        {/* <hr className="dr-secure-details-content-details-hr"></hr> */}
        <DrSecureDetailsUpdates
          updates={updates}
          saveRemarks={saveRemarks}
          loading={remarks_loading}
          shouldShowBtnUpdate={shouldShowBtnUpdate()}
        />
      </div>
      <div className="dr-secure-details-right">
        <DrSecureDetailsStatus
          loading={loading}
          status={incident ? incident.status : ""}
        />
        <DrSecureDetailsActions
          case_ref_no={case_ref_no}          
          onToggleDialog={handleChangeIncidentStatusDialogClose}
          incidentStatuses={incidentStatuses}
          currentIncidentStatus={currentIncidentStatus}
          onChangeIncidentStatus={handleChangeIncidentStatus}
          onSaveIncidentStatus={handleSaveIncidentStatus}
          showChangeStatusDialog={showChangeStatusDialog}
          onUpdateStatusBtnClicked={handleUpdateStatusBtnClicked}
          showChangeMarkAsDuplicateDialog={showChangeMarkAsDuplicateDialog}
          onMarkAsDuplicateBtnClicked={handleMarkAsDuplicateBtnClicked}
          onSubmitMarkAsDuplicate={saveMarkAsDuplicate}
          parentCasesList={parentCasesList}
          showChangeShareDialog={showChangeShareDialog}
          onShareBtnClicked={handleShareBtnClicked}
          onSubmitShare={saveShare}
          // Route to Dept Obj
          showRouteToDepeDialog={showRouteToDepeDialog}
          onRouteToDepClose={()=>setShowRouteToDepDialog(false)}
          onRouteToDepOpen={()=>setShowRouteToDepDialog(true)}
          onRouteToDepAction={(res)=>handleRouteToDepAction(res)} 
          
          shouldShowBtnUpdateStatus={shouldShowBtnUpdateStatus()}
          shouldShowBtnRoute={shouldShowBtnRoute()}
          shouldShowBtnShare={shouldShowBtnShare()}
          shouldShowBtnMarkDuplicate={shouldShowBtnMarkDuplicate()}
        />
        <DrSecureDetailsAudits loading={loading} audits={audits} />
      </div>
    </div>
  );
};

export default DrSecureDetails;
