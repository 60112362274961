import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "@progress/kendo-react-indicators";
import config from "./config/config";
import api from "./config/api";
import {
  saveUserPermission,
  getUserPermission,
} from "./config/permission_helper";

import {
  DASHBOARD_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  INBOX_ROUTE,
  NEWSFEED_ROUTE,
  ADD_NOTIFICATION_INFORMATION,
  NOTIFICATION_RECALL_ROUTE,
  MIC_ROUTE,
  NOTIFICATION_RECALL_RECIPIENT_ROUTE,
  MIC_DETAILS_ROUTE,
  NOTIFICATION_VIEW_ROUTE,
  DR_SAFE_INCIDENT_LIST,
  DR_SAFE_INCIDENT_DETAILS,
  DR_SAFE_CONTACT_LIST,
  DR_SAFE_CONTACT_DETAILS,
  DR_SECURE_INCIDENT_LIST,
  DR_SECURE_INCIDENT_DETAILS,
  DR_SECURE_CONTACT_LIST,
  DR_SECURE_CONTACT_DETAILS,
  DR_INFRA_INCIDENT_LIST,
  DR_INFRA_INCIDENT_DETAILS,
  DR_INFRA_LOCATION_LIST,
  DR_INFRA_SUBSYSTEM_LIST,
  DR_INFRA_SYSTEM_LIST,
  ADMIN_TOOLS_CATEGORY_LIST,
  ADMIN_TOOLS_CATEGORY_DETAILS,
  ADMIN_TOOLS_CATEGORY_ADD,
  ADMIN_TOOLS_DIVISION_LIST,
  ADMIN_TOOLS_DIVISION_DETAILS,
  ADMIN_TOOLS_DIVISION_ADD,
  ADMIN_TOOLS_MATTER_LIST,
  ADMIN_TOOLS_MATTER_DETAILS,
  ADMIN_TOOLS_MATTER_ADD,
  ADMIN_TOOLS_MIC_DEPARTMENT,
  ADMIN_TOOLS_MIC_DEPARTMENT_ADD,
  ADMIN_TOOLS_MIC_TYPE_OF_IMPROVEMENT,
  ADMIN_TOOLS_MIC_TYPE_OF_IMPROVEMENT_ADD,
  IFEEDBACK_LIST,
  IFEEDBACK_VIEW_ROUTE,
  FEEDBACK_ON_BUS_SERVICE_CHECKER,
  FEEDBACK_ON_BUS_SERVICE,
  FEEDBACK_ON_BUS_SERVICE_CHECKER_LIST,
  FEEDBACK_ON_BUS_SERVICE_PREFERENCE,
  ADMIN_TOOLS_MIC_TYPE_OF_IMPROVEMENT_DETAILS,
  ADMIN_TOOLS_MIC_DEPARTMENT_DETAILS,
  FEEDBACK_ON_BUS_SERVICE_SERVICES,
  FEEDBACK_ON_BUS_SERVICE_APPROVED,
  USER_MANAGEMENT_USERS,
  USER_MANAGEMENT_USERS_DETAILS,
  USER_MANAGEMENT_GROUPS,
  USER_MANAGEMENT_GROUPS_ADD,
  USER_MANAGEMENT_GROUPS_DETAILS,
  TELEPHONE_DIRECTORY_DEPARTMENT_LIST,
  TELEPHONE_DIRECTORY_DEPARTMENT_DETAILS,
  TELEPHONE_DIRECTORY_DEPOT_LIST,
  TELEPHONE_DIRECTORY_DEPOT_ADD,
  TELEPHONE_DIRECTORY_CONTACT_LIST,
  TELEPHONE_DIRECTORY_CONTACT_ADD,
  TELEPHONE_DIRECTORY_CONTACT_DETAILS,
  TELEPHONE_DIRECTORY_DEPARTMENT_ADD,
  TELEPHONE_DIRECTORY_DEPOT_DETAILS,
  ADMIN_TOOLS_ROLE_LIST,
  ADMIN_TOOLS_ROLE_ADD,
  ADMIN_TOOLS_ROLE_DETAILS,
  ISAFE_MESSAGE,
  ADMIN_TOOLS_EMAIL_GROUP_LIST,
  ADMIN_TOOLS_EMAIL_GROUP_DETAILS,
  ADMIN_TOOLS_EMAIL_GROUP_ADD,
  ADMIN_TOOLS_EMAIL_TEMPLATE_LIST,
  ADMIN_TOOLS_EMAIL_TEMPLATE_DETAILS,
  ADMIN_TOOLS_EMAIL_TEMPLATE_ADD,
  FEEDBACK_ON_BUS_ASSIGNMENT,
  FEEDBACK_ON_BUS_SUMMARY_OF_CHECK,
  FEEDBACK_ON_BUS_REPORTS,
  POLICY_DOCUMENT_ROUTE,
  FEEDBACK_ON_BUS_ASSIGNMENT_LISTING,
  ADD_FEEDBACK_ON_BUS_ASSIGNMENT_LISTING,
  FEEDBACK_ON_BUS_SUMMARY_OF_CHECK_DETAILS,
  FEEDBACK_ON_BUS_SUMMARY_OF_CHECK_DETAILS_EMAIL,
} from "./routes";

import Login from "./views/authentication/login";
import ForgotPassword from "./views/authentication/forgotPassword";
import Dashboard from "./views/dashboard";
import logo from "./logo.svg";
import "./App.scss";

import Navigation from "./components/common/Navigation";
import { Inbox } from "./views/communication/inbox/index";
import { Newsfeed } from "./views/communication/newsfeed/newsfeed";
import { AddNotification } from "./views/communication/inbox/add_notification";
import { ViewNotification } from "./views/communication/inbox/view_notification";

// DR Safe
import DrSafeIncidentList from "./views/dr_safe/incident/list/index";
import DrSafeIncidentDetails from "./views/dr_safe/incident/details/index";
import DrSafeContactList from "./views/dr_safe/contact/list/index";
import DrSafeContactDetails from "./views/dr_safe/contact/details/index";

// DR Secure
import DrSecureIncidentList from "./views/dr_secure/incident/list/index";
import DrSecureIncidentDetails from "./views/dr_secure/incident/details/index";
import DrSecureContactList from "./views/dr_secure/contact/list/index";
import DrSecureContactDetails from "./views/dr_secure/contact/details/index";

import MICDetailPage from "./views/mic-pages/mic-details";
import MICPage from "./views/mic-pages/mic";

// BSQC
import BSQCBusCheckerPeriods from "./views/bsqc-pages/bus-checker-periods";
import CheckerPreferencePage from "./views/bsqc-pages/checkers-preference";
import SelectServices from "./views/bsqc-pages/select-services";
import ApprovedAssignment from "./views/bsqc-pages/approved-assignments";
import AssignmentListing from "./views/bsqc-pages/assignment-listing";
import SummaryOfCheck from "./views/bsqc-pages/summary-of-check";
import SummaryOfCheckDetails from "./views/bsqc-pages/summary-of-check/details";
import ReportsBSQC from "./views/bsqc-pages/reports";
import SummaryOfCheckDetail from "./views/bsqc-pages/summary-of-check/partials/feedback-detail";
import FobSummaryCheckEmailTemplate from "./views/bsqc-pages/summary-of-check/partials/feedback-detail/email_template";
// DR Infra
import DrInfraIncidentList from "./views/dr_infra/incident/list/index";
import DrInfraIncidentDetails from "./views/dr_infra/incident/details/index";
import { DrInfraLocationList } from "./views/dr_infra/location";
import { DrInfraSubSystemList } from "./views/dr_infra/subsystem";
import { DrInfraSystemList } from "./views/dr_infra/system";

import { CategoryList } from "./views/admin_tools/communication/category/index";
import { CategoryDetails } from "./views/admin_tools/communication/category/category_details";
import { AddCategory } from "./views/admin_tools/communication/category/category_add";

import { DivisionList } from "./views/admin_tools/communication/division";
import { DivisionDetails } from "./views/admin_tools/communication/division/division_detail";
import { AddDivision } from "./views/admin_tools/communication/division/division_add";

import AdminToolsMICDepartment from "./views/admin_tools/mic/department";
import AdminToolsMICDepartmentAdd from "./views/admin_tools/mic/department/add";

import AdminToolsMICTypeOfImprovement from "./views/admin_tools/mic/type-of-improvement";
import AdminToolsMICTypeOfImprovementAdd from "./views/admin_tools/mic/type-of-improvement/add";

// ifeedback
import { MatterList } from "./views/admin_tools/ifeedback/matter";
import { MatterDetails } from "./views/admin_tools/ifeedback/matter/matter_detail";
import { AddMatter } from "./views/admin_tools/ifeedback/matter/matter_add";
import { IFeedbackList } from "./views/feedback/ifeedback/list/index";
import { IFeedbackDetails } from "./views/feedback/ifeedback/details/index";

// User Management
import { UserManagementList } from "./views/user_management/list/index";
import { UserManagementForm } from "./views/user_management/form/index";
import { UserManagementDetails } from "./views/user_management/details/index";
// Group Management
import { GroupManagementList } from "./views/group_management/list/index";
import { GroupManagementAddNew } from "./views/group_management/add/index";
import { GroupManagementDetails } from "./views/group_management/details/index";

//telephone
import { DepotList } from "./views/telephone_directory/depot/list/index";
import { DepartmentList } from "./views/telephone_directory/department/list/index";
import { DepartmentDetails } from "./views/telephone_directory/department/detail/index";
import { ContactList } from "./views/telephone_directory/contact/list/index";
import { ContactDetails } from "./views/telephone_directory/contact/detail/index";
import { AddContact } from "./views/telephone_directory/contact/list/contact_list_add";
import { AddDepartment } from "./views/telephone_directory/department/list/department_list_add";
import { AddDepot } from "./views/telephone_directory/depot/list/depot_list_add";
import { DepotDetails } from "./views/telephone_directory/depot/detail/index";

// Admin tool role
import { CommRoleList } from "./views/admin_tools/communication/role/list";
import { CommRoleDetails } from "./views/admin_tools/communication/role/details";
import { CommRoleAddNew } from "./views/admin_tools/communication/role/add";

import { ISafe } from "./views/admin_tools/isafe/index";
// Email Group
import { EmailGroupList } from "./views/admin_tools/feedback_bus_service/email_group/list";
import { EmailGroupDetails } from "./views/admin_tools/feedback_bus_service/email_group/details";
import { EmailGroupAddNew } from "./views/admin_tools/feedback_bus_service/email_group/add";

// Email Template
import { EmailTemplateList } from "./views/admin_tools/feedback_bus_service/email_template/list";
import { EmailTemplateDetails } from "./views/admin_tools/feedback_bus_service/email_template/details";
import { EmailTemplateAddNew } from "./views/admin_tools/feedback_bus_service/email_template/add";

import {
  getCurrentLoggedInSessionTokenWithPromise,
  cognitoUser,
} from "./config/userPool";
import PolicyDocument from "./views/policy_document";
import { Assignment, FOBAssignmentListing } from "./views/Assignment";
import { AssignmentCreate } from "./views/Assignment/assignmentCreate";

function App() {
  const navigate = useNavigate();
  let location = useLocation();
  let selected = location.pathname.split("/")[1] || "login";
  const [login, setLogin] = useState(false);
  const [logged, setLogged] = useState(false);
  const [token, setToken] = useState();
  const { USER_PERMISSION_ENDPOINT } = config.api_endpoint;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function retrievePermissions() {
      let shouldSetLoading = false;
      if (!getUserPermission()) {
        shouldSetLoading = true;
      }
      if (shouldSetLoading) {
        setLoading(true);
      }
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      console.log("token: ", token);
      const results = await api.get(USER_PERMISSION_ENDPOINT, {}, token);
      console.log("PERMISSION RESULTS >> ", results);

      if (results.status_code === 200) {
        if (shouldSetLoading) {
          setLoading(false);
        }
        saveUserPermission(results.data);
      } else {
        if (shouldSetLoading) {
          setLoading(false);
        }
        console.log("Unable to fetch user permissions.");
      }
    }

    if (
      !localStorage.getItem("login") ||
      localStorage.getItem("login") === "false"
    ) {
      console.log(selected);
      setLogin(false);
      setLogged(false);
      if (selected === "forgot_password") {
        navigate("/forgot_password");
      } else {
        navigate("/");
      }
    } else if (localStorage.getItem("login") === "true") {
      retrievePermissions();
      if (selected == "login") {
        navigate(DASHBOARD_ROUTE);
      }
      setLogin(true);
      setLogged(true);
    }
  }, [selected]);
  return (
    <div className="App">
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            minHeight: "100%",
          }}
        />
      ) : login ? (
        <Navigation selected={selected}>
          <Routes>
            <Route path={DASHBOARD_ROUTE} element={<Dashboard />} />
            <Route path={INBOX_ROUTE} element={<Inbox />} />
            <Route path={NEWSFEED_ROUTE} element={<Newsfeed />} />
            <Route
              path={ADD_NOTIFICATION_INFORMATION}
              element={<AddNotification />}
            />
            <Route
              path={NOTIFICATION_VIEW_ROUTE}
              element={<ViewNotification />}
            />
            <Route
              path={DR_SAFE_INCIDENT_LIST}
              element={<DrSafeIncidentList />}
            />
            <Route
              path={DR_SAFE_INCIDENT_DETAILS}
              element={<DrSafeIncidentDetails />}
            />
            <Route
              path={DR_SAFE_CONTACT_LIST}
              element={<DrSafeContactList />}
            />
            <Route
              path={DR_SAFE_CONTACT_DETAILS}
              element={<DrSafeContactDetails />}
            />

            <Route
              path={DR_SECURE_INCIDENT_LIST}
              element={<DrSecureIncidentList />}
            />
            <Route
              path={DR_SECURE_INCIDENT_DETAILS}
              element={<DrSecureIncidentDetails />}
            />
            <Route
              path={DR_SECURE_CONTACT_LIST}
              element={<DrSecureContactList />}
            />
            <Route
              path={DR_SECURE_CONTACT_DETAILS}
              element={<DrSecureContactDetails />}
            />
            <Route
              path={DR_INFRA_INCIDENT_LIST}
              element={<DrInfraIncidentList />}
            />
            <Route
              path={DR_INFRA_INCIDENT_DETAILS}
              element={<DrInfraIncidentDetails />}
            />
            <Route
              path={DR_INFRA_LOCATION_LIST}
              element={<DrInfraLocationList />}
            />
            <Route
              path={DR_INFRA_SUBSYSTEM_LIST}
              element={<DrInfraSubSystemList />}
            />
            <Route
              path={DR_INFRA_SYSTEM_LIST}
              element={<DrInfraSystemList />}
            />
            <Route path={MIC_ROUTE} element={<MICPage />} />
            <Route path={MIC_DETAILS_ROUTE} element={<MICDetailPage />} />

            {/* BSQC Pages */}
            <Route
              path={FEEDBACK_ON_BUS_SERVICE}
              element={<BSQCBusCheckerPeriods />}
            />
            <Route
              exact
              path={FEEDBACK_ON_BUS_SERVICE_CHECKER}
              element={<BSQCBusCheckerPeriods />}
            />

            <Route
              path={FEEDBACK_ON_BUS_SERVICE_CHECKER_LIST}
              element={<BSQCBusCheckerPeriods />}
            />

            <Route
              exact
              path={FEEDBACK_ON_BUS_SERVICE_PREFERENCE}
              element={<CheckerPreferencePage />}
            />

            <Route
              exact
              path={FEEDBACK_ON_BUS_SERVICE_SERVICES}
              element={<SelectServices />}
            />

            <Route
              exact
              path={FEEDBACK_ON_BUS_SERVICE_APPROVED}
              element={<ApprovedAssignment />}
            />

            <Route
              exact
              path={FEEDBACK_ON_BUS_ASSIGNMENT}
              element={<AssignmentListing />}
            />

            <Route
              exact
              path={FEEDBACK_ON_BUS_SUMMARY_OF_CHECK}
              element={<SummaryOfCheck />}
            />
            <Route
              // exact
              path={FEEDBACK_ON_BUS_SUMMARY_OF_CHECK_DETAILS}
              element={<SummaryOfCheckDetail />}
            />
            <Route
              // exact
              path={FEEDBACK_ON_BUS_SUMMARY_OF_CHECK_DETAILS_EMAIL}
              element={<FobSummaryCheckEmailTemplate />}
            />
            <Route
              exact
              path={FEEDBACK_ON_BUS_REPORTS}
              element={<ReportsBSQC />}
            />

            <Route
              path={FEEDBACK_ON_BUS_ASSIGNMENT_LISTING}
              element={<FOBAssignmentListing />}
            />

            <Route
              path={ADD_FEEDBACK_ON_BUS_ASSIGNMENT_LISTING}
              element={<AssignmentCreate />}
            />

            {/* Admin Tools */}
            <Route
              path={ADMIN_TOOLS_CATEGORY_LIST}
              element={<CategoryList />}
            />
            <Route
              path={ADMIN_TOOLS_CATEGORY_DETAILS}
              element={<CategoryDetails />}
            />
            <Route
              exact
              path={ADMIN_TOOLS_CATEGORY_ADD}
              element={<AddCategory />}
            />
            <Route
              path={ADMIN_TOOLS_DIVISION_LIST}
              element={<DivisionList />}
            />
            <Route
              path={ADMIN_TOOLS_DIVISION_DETAILS}
              element={<DivisionDetails />}
            />
            <Route path={ADMIN_TOOLS_DIVISION_ADD} element={<AddDivision />} />
            <Route path={ISAFE_MESSAGE} element={<ISafe />} />
            <Route path={ADMIN_TOOLS_MATTER_LIST} element={<MatterList />} />
            <Route
              path={ADMIN_TOOLS_MATTER_DETAILS}
              element={<MatterDetails />}
            />

            <Route
              path={ADMIN_TOOLS_MIC_DEPARTMENT}
              element={<AdminToolsMICDepartment />}
            />

            <Route
              path={ADMIN_TOOLS_MIC_DEPARTMENT_ADD}
              element={<AdminToolsMICDepartmentAdd />}
            />

            <Route
              path={ADMIN_TOOLS_MIC_DEPARTMENT_DETAILS}
              element={<AdminToolsMICDepartmentAdd />}
            />

            <Route
              path={ADMIN_TOOLS_MIC_TYPE_OF_IMPROVEMENT}
              element={<AdminToolsMICTypeOfImprovement />}
            />

            <Route
              path={ADMIN_TOOLS_MIC_TYPE_OF_IMPROVEMENT_ADD}
              element={<AdminToolsMICTypeOfImprovementAdd />}
            />

            <Route
              path={ADMIN_TOOLS_MIC_TYPE_OF_IMPROVEMENT_DETAILS}
              element={<AdminToolsMICTypeOfImprovementAdd />}
            />

            <Route path={IFEEDBACK_LIST} element={<IFeedbackList />} />
            <Route path={IFEEDBACK_VIEW_ROUTE} element={<IFeedbackDetails />} />
            <Route path={ADMIN_TOOLS_MATTER_ADD} element={<AddMatter />} />
            {/* <Route path={IFEEDBACK_LIST} element={<IFeedbackList />} />
            <Route path={IFEEDBACK_VIEW_ROUTE} element={<IFeedbackDetails />} /> */}

            <Route
              path={USER_MANAGEMENT_USERS}
              element={<UserManagementList />}
            />
            <Route
              path={USER_MANAGEMENT_USERS_DETAILS}
              element={<UserManagementDetails />}
            />
            <Route
              path={USER_MANAGEMENT_GROUPS}
              element={<GroupManagementList />}
            />
            <Route
              path={USER_MANAGEMENT_GROUPS_ADD}
              element={<GroupManagementAddNew />}
            />
            <Route
              path={USER_MANAGEMENT_GROUPS_DETAILS}
              element={<GroupManagementDetails />}
            />

            <Route path={ADMIN_TOOLS_ROLE_LIST} element={<CommRoleList />} />
            <Route
              path={ADMIN_TOOLS_ROLE_DETAILS}
              element={<CommRoleDetails />}
            />
            <Route path={ADMIN_TOOLS_ROLE_ADD} element={<CommRoleAddNew />} />

            <Route
              path={TELEPHONE_DIRECTORY_DEPOT_LIST}
              element={<DepotList />}
            />
            <Route
              path={TELEPHONE_DIRECTORY_DEPOT_ADD}
              element={<AddDepot />}
            />
            <Route
              path={TELEPHONE_DIRECTORY_DEPOT_DETAILS}
              element={<DepotDetails />}
            />
            <Route
              path={TELEPHONE_DIRECTORY_DEPARTMENT_LIST}
              element={<DepartmentList />}
            />
            <Route
              path={TELEPHONE_DIRECTORY_DEPARTMENT_ADD}
              element={<AddDepartment />}
            />
            <Route
              path={TELEPHONE_DIRECTORY_DEPARTMENT_DETAILS}
              element={<DepartmentDetails />}
            />
            <Route
              path={TELEPHONE_DIRECTORY_CONTACT_LIST}
              element={<ContactList />}
            />
            <Route
              path={TELEPHONE_DIRECTORY_CONTACT_ADD}
              element={<AddContact />}
            />
            <Route
              path={TELEPHONE_DIRECTORY_CONTACT_DETAILS}
              element={<ContactDetails />}
            />

            <Route
              path={ADMIN_TOOLS_EMAIL_GROUP_LIST}
              element={<EmailGroupList />}
            />

            <Route
              path={ADMIN_TOOLS_EMAIL_GROUP_DETAILS}
              element={<EmailGroupDetails />}
            />

            <Route
              path={ADMIN_TOOLS_EMAIL_GROUP_ADD}
              element={<EmailGroupAddNew />}
            />

            <Route
              path={ADMIN_TOOLS_EMAIL_TEMPLATE_LIST}
              element={<EmailTemplateList />}
            />

            <Route
              path={ADMIN_TOOLS_EMAIL_TEMPLATE_DETAILS}
              element={<EmailTemplateDetails />}
            />

            <Route
              path={ADMIN_TOOLS_EMAIL_TEMPLATE_ADD}
              element={<EmailTemplateAddNew />}
            />
            <Route path={POLICY_DOCUMENT_ROUTE} element={<PolicyDocument />} />
          </Routes>
        </Navigation>
      ) : (
        <Routes>
          <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword />} />
          <Route exact={true} path={LOGIN_ROUTE} element={<Login />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
