// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { DIVISION_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: false,
  division_ids: [],
  error: null,
  detail_data: {},
  detail_loading: false,
  detail_error: null,
  delete_res: {},
  delete_loading: false,
  delete_error: null,
};

export const fetchAllDivisions = createAsyncThunk(
  "division/fetchAllDivisions",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(DIVISION_ENDPOINT, {}, token);
      // console.log(results);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchDivisionById = createAsyncThunk(
  "division/fetchDivisionById",
  async (division_id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        `${DIVISION_ENDPOINT}/${division_id}`,
        {},
        token
      );
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const updateDivision = createAsyncThunk(
  "division/updateDivision",
  async ({ data, division_id }, { rejectWithValue }) => {
    try {
      console.log(data);
      console.log(division_id);
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const res = await api.post(
        `${DIVISION_ENDPOINT}/${division_id}`,
        JSON.stringify(data),
        token
      );
      return res.data;
      // return {}
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const createDivision = createAsyncThunk(
  "division/createDivision",
  async ({ data }, { rejectWithValue }) => {
    try {
      console.log(data);
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const res = await api.post(
        `${DIVISION_ENDPOINT}`,
        JSON.stringify(data),
        token
      );
      return res.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const deleteDivision = createAsyncThunk(
  "division/deleteDivision",
  async (division_id, { rejectWithValue }) => {
    try {
      console.log(division_id);
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const res = await api.remove(
        `${DIVISION_ENDPOINT}/${division_id}`,
        JSON.stringify({ id: division_id }),
        token
      );
      return res.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

const DivisionSlice = createSlice({
  name: "division",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDivisions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllDivisions.fulfilled, (state, action) => {
        // console.log(action);
        state.loading = false;
        state.division_ids = action.payload;
      })
      .addCase(fetchAllDivisions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDivisionById.pending, (state) => {
        state.detail_loading = true;
      })
      .addCase(fetchDivisionById.fulfilled, (state, action) => {
        state.detail_loading = false;
        state.detail_data = action.payload;
      })
      .addCase(fetchDivisionById.rejected, (state, action) => {
        state.detail_loading = false;
        state.detail_error = action.error.message;
      })
      .addCase(updateDivision.pending, (state) => {
        state.detail_loading = true;
      })
      .addCase(updateDivision.fulfilled, (state, action) => {
        state.detail_loading = false;
        state.detail_data = action.payload;
      })
      .addCase(updateDivision.rejected, (state, action) => {
        state.detail_loading = false;
        state.detail_error = action.error.message;
      })
      .addCase(createDivision.pending, (state) => {
        state.detail_loading = true;
      })
      .addCase(createDivision.fulfilled, (state, action) => {
        state.detail_loading = false;
        state.detail_data = action.payload;
      })
      .addCase(createDivision.rejected, (state, action) => {
        state.detail_loading = false;
        state.detail_error = action.error.message;
      })
      .addCase(deleteDivision.pending, (state) => {
        state.delete_loading = true;
      })
      .addCase(deleteDivision.fulfilled, (state, action) => {
        state.delete_loading = false;
        state.delete_res = action.payload;
      })
      .addCase(deleteDivision.rejected, (state, action) => {
        state.delete_loading = false;
        state.delete_error = action.error.message;
      });
  },
});

const DivisionReducer = DivisionSlice.reducer;
export default DivisionReducer;
