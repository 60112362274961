import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
// icon
import CrossIcon from "../../../../assets/dr_safe/cross_gray_icon.png";
// css
import "./dr_infra_details.css";
import { fetchAllDRInfraRouteGroups } from "../../../../store/drInfraIncidient";
import { Loader } from "@progress/kendo-react-indicators";

const DrInfraDetailsRouteToDepDialog = ({
    onRouteToDepClose,
    onRouteToDepAction,
    case_ref_no,
}) => {
    const dispatch = useDispatch();
    const [selectedRouteGroup, setSelectedRouteGroup] = useState(null);
    const [btndisabled,setBtnDisabled] = useState(false);
    const { route_groups, route_groups_loading, route_group_error } = useSelector((state) => state.drInfraIncident);
    
    useEffect(() => {
        setBtnDisabled(true);
        console.log(case_ref_no);
        dispatch(fetchAllDRInfraRouteGroups())
    }, []);
    return (
        <Dialog title={" "} width={400} onClose={onRouteToDepClose} className="duplicate-dialog">
            {route_groups_loading ? (
                <Loader
                    type="infinite-spinner"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        width: "40px",
                        margin: "auto",
                        justifyContent: "center",
                    }}
                />
            ) : (
                <div className="duplicate-dialog-div">
                    <span className="dialog-duplicate-title">Route to Group</span>
                    <p>
                        {/* <DropDownList
                            defaultValue={"Select case ref no"}
                            className="dr-infra-details-status-dialog-dropdown-value select-case-dropdown"
                            data={route_groups}
                            textField="name"
                            dataItemKey="group_id"                            
                            onChange={(e)=> setSelectedRouteGroup(e.target.value.group_id)}
                        /> */}
                        <select onChange={(e)=> setSelectedRouteGroup(e.target.value)} className='dr-infra-details-status-dialog-dropdown-value select-case-dropdown'
                        style={{
                            width: "100%",
                          }}>
                            <option key={'default'} value={''}>Select</option>
                            {route_groups.map(
                                ({ group_id, name }) => (
                                    <option key={group_id} value={group_id}>{name}</option>
                                )
                            )}
                        </select>
                    </p>
                    <p>
                        <Button 
                            className="dr-infra-details-status-dialog-button" 
                            style={{ width: "100%" }} 
                            onClick={() => onRouteToDepAction(selectedRouteGroup)}
                            disabled={!selectedRouteGroup}
                        >Submit</Button>
                    </p>
                </div>
            )}

        </Dialog>
    );
};

export default DrInfraDetailsRouteToDepDialog;
