// kendo
import { Loader } from "@progress/kendo-react-indicators";

const DrSecureDetailsStatus = ({ loading, status }) => {
  return (
    <div className="dr-secure-details-status-wrapper">
      <div className="dr-secure-details-status-title">Status</div>
      <div>
        {loading ? (
          <Loader
            type="infinite-spinner"
            className="dr-secure-details-status-loader"
          />
        ) : (
          <div className="dr-secure-details-status-text">{status || "NA"}</div>
        )}
      </div>
    </div>
  );
};

export default DrSecureDetailsStatus;
