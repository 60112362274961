// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { GET_EMPLOYEE_INTERCHANGE_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: false,
  loading2: false,
  users: [],
  users2: [],
  error: null,
  error2: null,
};

export const fetchAllCommUsers = createAsyncThunk(
  "depot/fetchAllCommUsers",
  async (filters, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        GET_EMPLOYEE_INTERCHANGE_ENDPOINT,
        JSON.stringify(filters),
        token
      );
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllCommUsersByKey = createAsyncThunk(
  "depot/fetchAllCommUsersByKey",
  async (filters, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        GET_EMPLOYEE_INTERCHANGE_ENDPOINT,
        JSON.stringify(filters),
        token
      );
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const CommUserListSlice = createSlice({
  name: "depot",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCommUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllCommUsers.fulfilled, (state, action) => {
        // console.log(action);
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchAllCommUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAllCommUsersByKey.pending, (state) => {
        state.loading2 = true;
      })
      .addCase(fetchAllCommUsersByKey.fulfilled, (state, action) => {
        // console.log(action);
        state.loading2 = false;
        state.users2 = action.payload;
      })
      .addCase(fetchAllCommUsersByKey.rejected, (state, action) => {
        state.loading2 = false;
        state.error2 = action.error.message;
      });
  },
});

const CommUserListReducer = CommUserListSlice.reducer;
export default CommUserListReducer;
