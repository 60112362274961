import React, { useState } from "react";
import { Loader } from "@progress/kendo-react-indicators";
// kendo
import { TextArea } from "@progress/kendo-react-inputs";

import moment from "moment";

const DrSecureDetailsUpdates = ({ updates, saveRemarks, loading, shouldShowBtnUpdate }) => {
  const [remarks, setRemarks] = useState("");

  const onRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const btnAddClicked = () => {
    saveRemarks(remarks);
  };

  const detailsUpdate = (props) => (
    <div className="dr-secure-details-updates-single-div-wrapper">
      <div className="dr-secure-details-updates-single-div">
        <span className="dr-secure-details-updates-single-username">
          {props.created_by.name}
        </span>
        {props.role && (
          <span className="dr-secure-details-updates-single-role">
            {props.role}
          </span>
        )}
      </div>
      <div className="dr-secure-details-updates-single-div">
        <span className="dr-secure-details-updates-single-datetime">
          {moment(props.created_on).format("DD MMM YYYY, hh:mm A")}
        </span>
      </div>
      <div>
        <span className="dr-secure-details-updates-single-description">
          {props.description}
        </span>
      </div>
      <div>
      <div className="dr-secure-details-content-details-right-label attachment">
        {props.images &&
          props.images.map((image, ind) => {
            return <img src={image.url} key={ind} />;
          })}
      </div>
      </div>
    </div>
  );

  return (
    <div className="dr-secure-details-updates-wrapper">
      <div className="dr-secure-details-updates-title">Updates</div>
      {loading ? (
        <Loader
          type="infinite-spinner"
          className="dr-secure-details-update-loader"
        />
      ) : (
        <React.Fragment>
          {updates.length === 0 && <p>No available updates.</p>}
          {updates.length > 0 && (
            <div className="dr-secure-details-updates-list-wrapper">
              {updates.map((upd, ind) => {
                console.log("upd-",upd);
                return detailsUpdate(upd);
              })}
            </div>
          )}

          {shouldShowBtnUpdate && (
            <div className="dr-secure-details-updates-input-wrapper">
              <p className="dr-secure-details-updates-input-wrapper-title">
                Add Updates
              </p>
              <TextArea
                className="dr-secure-details-updates-input"
                onChange={onRemarksChange}
              />
              <div
                className="dr-secure-details-updates-input-wrapper-btn"
                onClick={btnAddClicked}
              >
                Add
              </div>
            </div>
          )}
          
        </React.Fragment>
      )}
    </div>
  );
};

export default DrSecureDetailsUpdates;
