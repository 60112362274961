import { Dialog,DialogActionsBar } from "@progress/kendo-react-dialogs";

import Input from "../../../../components/input";
import Drawer from "../../../../components/drawer";
import Button from "../../../../components/button";
import { useState } from "react";

const DrLocationListSearchDialog = ({onToggleDialog, onSubmitShare}) => {
  const [locationCategory, setLocationCategory] = useState('');
  const [locationName, setLocationName] = useState('');
  const [locationRailLineId, setLocationRailLineId] = useState('');

  const onLocationCategoryChange = e => {
    setLocationCategory(e.target.value);
  };
  const onLocationNameChange = e => {
    setLocationName(e.target.value);
  };
  const onLocationRailLineIdChange = e => {
    setLocationRailLineId(e.target.value);
  };
  const onSubmit = () => {
    onSubmitShare(locationCategory, locationName, locationRailLineId)
  }

  return (
    <Dialog
        className="search-notification"
        title={" "}
        // width={500}
        height={"100%"}
        onClose={onToggleDialog}
        >
        <div className="search-body">
            <div className="noti-dialog-title dialog-row">Search</div>
            <div className="dialog-row">
                <span className="search-row-title">Location Category</span>
                <Input className="search-input" onChange={onLocationCategoryChange}/>
            </div>
            <div className="dialog-row" style={{height:"80%"}}>
                <span className="search-row-title">Location Name</span>
                <Input className="search-input" onChange={onLocationNameChange}/>
            </div>
            <div className="dialog-row" style={{height:"80%"}}>
                <span className="search-row-title">Rail Line ID</span>
                <Input className="search-input" onChange={onLocationRailLineIdChange}/>
            </div>
        </div>
        <DialogActionsBar>
            <Button className="search-button cancel-btn" onClick={onToggleDialog}>Cancel</Button>
            <Button className="search-button search-btn" onClick={onSubmit}>Submit</Button>
        </DialogActionsBar>
    </Dialog>          
  );
};

export default DrLocationListSearchDialog;
