// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const { DR_INFRA_INCIDENT_ENDPOINT,DR_INFRA_GET_GROUPS } = config.api_endpoint;

const initialState = {
  loading: false,
  details_loading: false,
  remarks_loading: false,
  drInfraIncidentList: [],
  drInfraIncidentDetails: [],
  drInfraIncidentRemarks: [],
  error: null,
  details_error: null,
  remarks_error: null,
  route_groups: [],
  route_groups_loading: false,
  route_group_error: null,
};

export const fetchAllDRInfraIncidents = createAsyncThunk(
  "dr_infra_incidents/fetchAllIncidents",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(`${DR_INFRA_INCIDENT_ENDPOINT}?submitter=all`, {}, token);
      let finalData = [];
      console.log("DR infra List Results:",results);
      for (let i = 0; i < results.data.length; i++) {
        let copied = results.data[i];
        for (const [key, value] of Object.entries(copied.status)) {
          copied["status"] = value;
        }
        finalData.push(copied);
      }
      console.log("DR infra Final Data: ", finalData);
      return finalData;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllDRInfraIncidentDetails = createAsyncThunk(
  "dr_infra_incidents/fetchAllIncidentDetails",
  async (case_ref_no, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        `${DR_INFRA_INCIDENT_ENDPOINT}/${case_ref_no}`,
        {},
        token
      );
      console.log(results.data)
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const changeDRInfraIncidentStatus = createAsyncThunk(
  "dr_infra_incidents/changeIncidentStatus",
  async ({ case_ref_no, incidentStatus }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const data = {}
      const results = await api.post(
        `${DR_INFRA_INCIDENT_ENDPOINT}/${case_ref_no}/${incidentStatus}`,
        JSON.stringify(data),
        token
      );
      console.log("DR infra Status API Result",results);
      return results;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllDRInfraIncidentRemarks = createAsyncThunk(
  "dr_infra_incidents/fetchAllIncidentRemarks",
  async (case_ref_no, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        `${DR_INFRA_INCIDENT_ENDPOINT}/${case_ref_no}/remarks`,
        {},
        token
      );
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const saveDRInfraIncidentRemarks = createAsyncThunk(
  "dr_infra_incidents/saveIncidentRemarks",
  async ({ case_ref_no, description }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const res = await api.post(
        `${DR_INFRA_INCIDENT_ENDPOINT}/${case_ref_no}/remarks`,
        JSON.stringify({
          description: description,
        }),
        token
      );
      const results = await api.get(
        `${DR_INFRA_INCIDENT_ENDPOINT}/${case_ref_no}/remarks`,
        {},
        token
      );
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const saveDRInfraIncidentGroup = createAsyncThunk(
  "dr_infra_incidents/saveDRInfraIncidentGroup",
  async ({ case_ref_no, group_id }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const res = await api.post(
        `${DR_INFRA_INCIDENT_ENDPOINT}/${case_ref_no}/route`,
        JSON.stringify({
          group_id: group_id,
        }),
        token
      );
      return res;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const saveDRInfraMarkAsDuplicate = createAsyncThunk(
  "dr_Infra_incidents/saveIncidentMarkAsDuplicate",
  async ({ case_ref_no, currentParentCase, description }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      console.log("Duplicate API request:",case_ref_no,' ',currentParentCase,' ',description)
      const results = await api.post(
        `${DR_INFRA_INCIDENT_ENDPOINT}/${case_ref_no}/duplicated`,
        JSON.stringify({
          parent_case_ref_no: currentParentCase,
          remark: description,
        }),
        token
      );
      console.log("Duplicated API result: ", results);
      return results;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

// SHARE
export const saveDRInfraIncidentShare = createAsyncThunk(
  "dr_Infra_incidents/saveIncidentShare",
  async ({ case_ref_no, emails }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      let email_list=emails.split(",")
      console.log("Share Api call: ",email_list);
      const res = await api.post(
        `${DR_INFRA_INCIDENT_ENDPOINT}/${case_ref_no}/share`,
        JSON.stringify({
          emails: email_list,
        }),
        token
      );
      console.log("Share Result: ", res);
      return res;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
)
export const fetchAllDRInfraRouteGroups = createAsyncThunk(
  "dr_infra_incidents/fetchAllDRInfraRouteGroups",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(DR_INFRA_GET_GROUPS, {}, token);
      // console.log(results.data);
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchDRInfraSearchResult = createAsyncThunk(
  "dr_infra_incidents/fetchDRInfraSearchResult",
  async ({caseRefNo, createdBy, updatedBy}, { rejectWithValue }) => {
    try {
      console.log(caseRefNo,createdBy,updatedBy)
      const token = getCurrentLoggedInSessionToken();
      let all="all";
      const results = await api.get(`${DR_INFRA_INCIDENT_ENDPOINT}`,{submitter:all,case_ref_no:caseRefNo,created_by:createdBy,updated_by:updatedBy},token);
      console.log("Search API result ",results);
      let responseData = [];
      for (let i = 0; i < results.data.length; i++) {
        let copied = results.data[i];
        for (const [key, value] of Object.entries(copied.status)) {
          copied["status"] = value;
        }
        responseData.push(copied);
      }
      return responseData;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchDRInfraFilterResult = createAsyncThunk(
  "dr_safe_incidents/fetchDRInfraFilterResult",
  async ({status, start_date,end_date,updateon_startdate, updateon_enddate}, { rejectWithValue }) => {
    try {
      console.log(status,start_date, end_date, updateon_startdate, updateon_enddate)
      const token = getCurrentLoggedInSessionToken();
      let all="all";
      const results = await api.get(`${DR_INFRA_INCIDENT_ENDPOINT}`,{submitter:all,status:status,start_date:start_date,end_date:end_date,updated_on_start_date:updateon_startdate,updated_on_end_date:updateon_enddate},token);
      console.log("Filter API result ",results)
      let responseData = [];
      if (results.data){
        for (let i = 0; i < results.data.length; i++) {
          let copied = results.data[i];
          for (const [key, value] of Object.entries(copied.status)) {
            copied["status"] = value;
          }
          responseData.push(copied);
        }
      }
      return responseData;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchDRInfraSortResult = createAsyncThunk(
  "dr_safe_incidents/fetchDRInfraSortResult",
  async ({sortby,orderby}, { rejectWithValue }) => {
    try {
      console.log(sortby,orderby);
      const token = getCurrentLoggedInSessionToken();
      let all="all";
      const results = await api.get(`${DR_INFRA_INCIDENT_ENDPOINT}`,{submitter:all,sort_by:sortby,order:orderby},token);
      console.log("SortBy API result ",results)
      let responseData = [];
      for (let i = 0; i < results.data.length; i++) {
        let copied = results.data[i];
        for (const [key, value] of Object.entries(copied.status)) {
          copied["status"] = value;
        }
        responseData.push(copied);
      }
      return responseData;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);
const drInfraIncidentSlice = createSlice({
  name: "dr_infra_incidents",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDRInfraIncidents.pending, (state) => {
        // console.log("fetch all dr infra incidents pending: ", state);
        state.loading = true;
      })
      .addCase(fetchAllDRInfraIncidents.fulfilled, (state, action) => {
        // console.log("fetch all dr infra incidents fulfilled: ", state, action);
        state.loading = false;
        state.drInfraIncidentList = action.payload;
      })
      .addCase(fetchAllDRInfraIncidents.rejected, (state, action) => {
        // console.log("fetch all dr infra incidents rejected: ", state, action);
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAllDRInfraIncidentDetails.pending, (state) => {
        // console.log("fetch all dr infra incident details pending: ", state);
        state.details_loading = true;
      })
      .addCase(fetchAllDRInfraIncidentDetails.fulfilled, (state, action) => {
        state.details_loading = false;
        state.drInfraIncidentDetails = action.payload;
      })
      .addCase(fetchAllDRInfraIncidentDetails.rejected, (state, action) => {
        state.details_loading = false;
        state.details_error = action.error.message;
      })
      .addCase(fetchAllDRInfraIncidentRemarks.pending, (state) => {
        state.remarks_loading = true;
      })
      .addCase(fetchAllDRInfraIncidentRemarks.fulfilled, (state, action) => {
        state.remarks_loading = false;
        state.drInfraIncidentRemarks = action.payload;
      })
      .addCase(fetchAllDRInfraIncidentRemarks.rejected, (state, action) => {
        state.remarks_loading = false;
        state.remarks_error = action.error.message;
      })
      .addCase(fetchAllDRInfraRouteGroups.pending, (state) => {
        state.route_groups_loading = true;
      })
      .addCase(fetchAllDRInfraRouteGroups.fulfilled, (state, action) => {
        state.route_groups_loading = false;
        state.route_groups = action.payload;
      })
      .addCase(fetchAllDRInfraRouteGroups.rejected, (state, action) => {
        state.route_groups_loading = false;
        state.route_group_error = action.error.message;
      })      
      .addCase(saveDRInfraIncidentRemarks.pending, (state) => {
        state.remarks_loading = true;
      })
      .addCase(saveDRInfraIncidentRemarks.fulfilled, (state, action) => {
        state.remarks_loading = false;
        state.drInfraIncidentRemarks = action.payload;
      })
      .addCase(saveDRInfraIncidentRemarks.rejected, (state, action) => {
        state.remarks_loading = false;
        state.remarks_error = action.error.message;
      })
      .addCase(saveDRInfraIncidentGroup.pending, (state) => {
        state.details_loading = true;
      })
      .addCase(saveDRInfraIncidentGroup.fulfilled, (state, action) => {
        state.details_loading = false;
        state.drInfraIncidentDetails = action.payload;
      })
      .addCase(saveDRInfraIncidentGroup.rejected, (state, action) => {
        state.details_loading = false;
        state.details_error = action.error.message;
      })      
      .addCase(changeDRInfraIncidentStatus.pending, (state) => {
        state.details_error = true;
      })
      .addCase(changeDRInfraIncidentStatus.fulfilled, (state, action) => {
        state.details_loading = false;
        state.drInfraIncidentDetails = action.payload;
      })
      .addCase(changeDRInfraIncidentStatus.rejected, (state, action) => {
        // console.log("change dr infra incident status rejected: ", state, action);
        state.details_loading = false;
        state.details_error = action.error.message;
      })
      // search
      // .addCase(fetchDRInfraSearchResult.pending, (state) => {
      //   // console.log("fetch all dr infra incidents pending: ", state);
      //   state.loading = true;
      // })
      // .addCase(fetchDRInfraSearchResult.fulfilled, (state, action) => {
      //   // console.log("fetch all dr infra incidents fulfilled: ", state, action);
      //   state.loading = false;
      //   state.drInfraIncidentList = action.payload;
      // })
      // .addCase(fetchDRInfraSearchResult.rejected, (state, action) => {
      //   // console.log("fetch all dr infra incidents rejected: ", state, action);
      //   state.loading = false;
      //   state.error = action.error.message;
      // });
      
  },
});

const drInfraIncidentReducer = drInfraIncidentSlice.reducer;
export default drInfraIncidentReducer;
