import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
// redux
import { useDispatch, useSelector } from "react-redux";
import { createGroup } from "../../../store/groupMgmt";
// kendo
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
// routes
import { USER_MANAGEMENT_GROUPS } from "../../../routes";
// component
import { GroupManagementForm } from "../form";
// image
import ArrorRightIcon from "../../../assets/common/arrao-right.svg";
import BackIcon from "../../../assets/communication/Back.svg";
import EditIcon from "../../../assets/common/edit_icon.svg";
// css
import "../group_management.css";

export const GroupManagementAddNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redux
  const { create_group, create_group_error, create_group_loading } =
    useSelector((state) => state.groupMgmt);

  // state
  const [loading, setLoading] = useState(false);

  // method
  const handleSaveGroup = (data) => {
    console.log("handleSaveGroup: ", data);
    dispatch(createGroup(data)).then(() => {
      setLoading(false);
      navigate(USER_MANAGEMENT_GROUPS);
    });
  };
  return (
    <>
      <div className="group-detail-body">
        {loading || create_group_loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <>
            <div className="group-detail-header">
              <div
                className="group-detail-group-div"
                onClick={() => navigate(USER_MANAGEMENT_GROUPS)}
              >
                <img src={BackIcon} className="group-detail-icon" />
                <span className="group-detail-label">Back</span>
              </div>
            </div>
            <div className="group-detail-content">
              <GroupManagementForm
                data={null}
                isEdit={true}
                setIsEdit={true}
                setLoading={setLoading}
                submitForm={handleSaveGroup}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
