import React, { useEffect, useState } from "react";

import { useNavigate, useLocation } from "react-router";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";

import "./table.css";
import {
  NOTIFICATION_VIEW_ROUTE,
  ADD_NOTIFICATION_INFORMATION,
  INBOX_ROUTE,
} from "../../routes";

import api from "../../config/api";
import config from "../../config/config";
import { getCurrentLoggedInSessionToken } from "../../config/userPool";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Confirmation } from "../../components/dialog/confirmation";
import { Pager } from "@progress/kendo-react-data-tools";

const initialDataState = {
  skip: 0,
  take: 10,
};

const { NOTIFICATION_ACTION_ENDPOINT, NOTIFICATION_ENDPOINT } =
  config.api_endpoint;

const itemRender = (li, itemProps) => {
  const index = itemProps.index;
  const itemChildren = (
    <span
      style={{
        display: "flex",
      }}
    >
      {li.props.children}
    </span>
  );
  return React.cloneElement(li, li.props, itemChildren);
};

const valueRender = (element, value) => {
  if (!value) {
    return element;
  }
  const children = [
    <span>&nbsp;&nbsp;&nbsp;&nbsp; {element.props.children}</span>,
  ];
  return React.cloneElement(
    element,
    {
      ...element.props,
    },
    children
  );
};

const rowRender = (trElement, props) => {
  const trProps = {
    style: { backgroundColor: "#FFFFFF" },
  };
  return React.cloneElement(
    trElement,
    {
      ...trProps,
    },
    trElement.props.children
  );
};

export const NotificationTable = (props) => {
  const navigate = useNavigate();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const initialSort = [
    {
      field: props.sortby,
      dir: props.sort_with,
    },
  ];
  const [page, setPage] = React.useState(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState();
  const [sort, setSort] = React.useState(initialSort);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);
  // const pageChange = (event) => {
  //   const targetEvent = event.targetEvent;
  //   const take = targetEvent.value === "All" ? props.data.length : event.page.take;
  //   if (targetEvent.value) {
  //     setPageSizeValue(targetEvent.value);
  //   }
  //   setPage({
  //     ...event.page,
  //     take,
  //   });
  // };
  const onItemActionChange = (e, id) => {
    const value = e.target.value;
    props.setTableLoading(true);
    if (value === "View") {
      navigate(`${NOTIFICATION_VIEW_ROUTE}?id=${id}`);
    } else if (value === "Duplicate") {
      query.set("copyId", id);
      query.set("state", 0);
      navigate(ADD_NOTIFICATION_INFORMATION + "?" + query.toString());
    } else if (
      value == "Delete" ||
      value === "Recall Message" ||
      value === "Archive"
    ) {
      props.setTableLoading(false);
      setSelectedId(id);
      setSelectedAction(value);
      setShowPrompt(true);
    } else if (value === "Publish") {
      ApproveRejectItem({ notification_id: id, id: "publish" });
    } else if (value === "Approve") {
      ApproveRejectItem({ notification_id: id, id: "approve" });
    } else if (value == "Resubmit") {
      ApproveRejectItem(
        { notification_id: id, id: "resubmit" },
        ADD_NOTIFICATION_INFORMATION + "?id=" + id + "&state=" + 0
      );
    } else if (value === "Reject") {
      ApproveRejectItem({ notification_id: id, id: "reject" });
    }
  };
  const DeleteItem = (id) => {
    const token = getCurrentLoggedInSessionToken();
    const body = {
      id: id,
    };
    api
      .remove(`${NOTIFICATION_ENDPOINT}/${id}`, JSON.stringify(body), token)
      .then((res) => {
        if (res.status === "success") {
          console.log("Success");
          props.setShowDialog("Deleted Successfully.");
          props.loadData();
          props.setTableLoading(false);
        } else {
          alert("error");
        }
      });
  };
  const ApproveRejectItem = (data, redirect_to = null) => {
    const token = getCurrentLoggedInSessionToken();
    api
      .post(NOTIFICATION_ACTION_ENDPOINT, JSON.stringify(data), token)
      .then((res) => {
        if (res.status === "success") {
          console.log(res);
          if (redirect_to) {
            navigate(redirect_to);
          } else {
            props.loadData();
            props.setTableLoading(false);
          }
        }
      });
  };
  const handleConfirmYes = () => {
    props.setTableLoading(true);
    if (selectedId && selectedAction) {
      if (selectedAction == "Delete") {
        DeleteItem(selectedId);
      } else if (selectedAction === "Recall Message") {
        ApproveRejectItem({ notification_id: selectedId, id: "recall" });
      } else if (selectedAction === "Archive") {
        ApproveRejectItem({ notification_id: selectedId, id: "archive" });
      }
    }
  };
  const onSortChange = (e) => {
    console.log(e.sort);
    const value = e.sort[0];
    if (value) {
      props.setSortBy(value.field);
      props.setSortWith(value.dir === "asc");
      props.onSortChangeCallback(value);
    }
    setSort(e.sort);
  };
  const handleNextPage = () => {
    console.log(props.pagenumber);
    props.handlenextpagecallback();
  };
  const handlePreviousPage = () => {
    props.handlePreviouscallback();
  };
  const selectedPage = {
    backgroundColor: "#EB6E1F",
  };
  useEffect(()=>{
    console.log(props.pagenumber);    
    console.log('PageNumber Changed');
    setPage({
      ...page,
      skip:props.pagenumber*10,
    })
  },[props.pagenumber])
  // console.log(page.skip);
  // console.log(page.take);
  // console.log(props.data.length / page.take);
  return (
    <div className="tbody-content">
      {showPrompt && (
        <Confirmation
          title="Confirm"
          onClickYes={handleConfirmYes}
          onClickNo={() => setShowPrompt(false)}
        />
      )}
      <Grid
        className="notification-table"
        // data={props.data}
        data={props.data.slice(page.skip, page.take + page.skip)}
        skip={page.skip}
        take={page.take}
        total={props.data.length}
        rowRender={rowRender}
        sortable={true}
        sort={sort}
        onSortChange={onSortChange}
        pageable={false}
      >
        <GridColumn
          field="publish_on"
          title="Scheduled / Published On"
          width={150}
        />
        <GridColumn
          field="publish_end"
          title="Scheduled / Published End"
          width={150}
        />
        <GridColumn
          field="category_name"
          title="Category"
          width={130}
          sortable={false}
        />
        <GridColumn field="title_en" title="Title" sortable={false} />
        <GridColumn
          className="td-align-center"
          field="ack_label"
          title="Ack Required"
          width={113}
          sortable={false}
        />
        <GridColumn
          className="td-align-center"
          title="Total Read"
          width={90}
          field="total_read_count"
          sortable={false}
        />
        <GridColumn
          width={110}
          title="Channel"
          field="channel_label"
          sortable={false}
        />
        <GridColumn
          field="created_by.name"
          title="Created By"
          sortable={false}
        />
        <GridColumn field="created_on" title="Created On" />
        <GridColumn
          width={90}
          field="status_label"
          title="Status"
          sortable={false}
        />
        <GridColumn
          width={130}
          sortable={false}
          field="Action"
          cell={(props) => {
            // console.log(props);
            return (
              <td>
                <DropDownList
                  size={"large"}
                  fillMode={"solid"}
                  rounded={"large"}
                  // className="action-btn-dropdown"
                  data={props.dataItem.action.map((v) => v.name)}
                  valueRender={valueRender}
                  itemRender={itemRender}
                  defaultValue={"Action"}
                  onChange={(e) => onItemActionChange(e, props.dataItem.id)}
                  style={{
                    fontSize: "15px",
                    backgroundColor: "#EB6E1F",
                    color: "white",
                    textAlign: "center",
                    borderRadius: "8px",
                  }}
                />
              </td>
            );
          }}
        />
      </Grid>
      <div className="table-custom-pagination">
        <div
          class="k-pager k-pager-md k-grid-pager"
          role="application"
          aria-roledescription="pager"
          style={{ border: "none" }}
        >
          <div class="k-pager-numbers-wrap">
            <button
              title="Go to the previous page"
              role="button"
              aria-disabled="true"
              class="k-button k-button-md k-button-flat k-button-flat-base k-icon-button k-pager-nav"
              disabled={!props.is_previous}
              onClick={handlePreviousPage}
            >
              <span
                class="k-icon k-i-caret-alt-left k-button-icon"
                role="presentation"
              ></span>
            </button>
            <div class="k-pager-numbers">
              {props.data.length > 0 && (
                <>
                  {props.length > 10 ? (
                    <></>
                  ) : (
                    <>
                      {props.alldata.map((v, i) => (
                        <button
                          role="button"
                          aria-label="undefined 1"
                          aria-current="true"
                          class={`k-button k-button-md k-button-flat k-button-flat-primary ${props.pagenumber === i ? `k-selected` : ""
                            }`}
                          onClick={() => props.handlePageChange(i)}
                        >
                          <span class="k-button-text">{i + 1}</span>
                        </button>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
            <button
              title="Go to the next page"
              role="button"
              aria-disabled="true"
              class="k-button k-button-md k-button-flat k-button-flat-base k-icon-button k-pager-nav"
              disabled={!props.next_key.publish_on}
              onClick={handleNextPage}
            >
              <span
                class="k-icon k-i-caret-alt-right k-button-icon"
                role="presentation"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
