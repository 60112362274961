import React, { useState, useEffect } from "react";
// kendo
import { Checkbox } from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
// api
import { GetUsers } from "../../../../services/user";
// css
import "../../admin_tools.css";

export const UserListDialog = (props) => {
  const [selectedUsers, setSelectedUsers] = useState(props.defaultSelected);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const GetUserList = async () => {
      const [err, response] = await GetUsers();

      if (!err && response) {
        setUsers(response?.data);
      }
      setLoading(false);
    };

    GetUserList();
  }, []);
  const handleSelected = (e, val) => {
    var updatedList = [...selectedUsers];
    if (e.target.value) {
      updatedList = [...selectedUsers, val];
    } else {
      var selected_index = selectedUsers
        .map((v) => v.username)
        .indexOf(val.username);
      updatedList.splice(selected_index, 1);
    }
    setSelectedUsers(updatedList);
  };
  const handleSubmitUsers = () => {
    props.callback(selectedUsers);
  };
  const isChecked = (item) =>
    selectedUsers.map((v) => v.username).includes(item) ? true : false;
  return (
    <Dialog
      title={`Please select users(s): ${selectedUsers.length} selected`}
      width={500}
      height={500}
      onClose={props.onClickNo}
      className="feedback-email-group-users-dialog"
    >
      <div className="feedback-email-group-users-list-div">
        {loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "350px",
            }}
          />
        ) : (
          users.map((v) => (
            <Checkbox
              label={v.name}
              onChange={(e) => handleSelected(e, v)}
              checked={isChecked(v.username)}
            />
          ))
        )}
      </div>
      <DialogActionsBar>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base feedback-email-group-users-cancel-button"
          onClick={props.onClickNo}
        >
          No
        </button>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base feedback-email-group-users-submit-button"
          onClick={handleSubmitUsers}
        >
          Yes
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};
