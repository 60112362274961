import React, { useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import moment from "moment";
// css
import "./dr_contact_table.css";

const DrContactTable = ({ data, actionDropdownClicked }) => {
  const initialDataState = {
    skip: 0,
    take: 5,
  };
  const initialSort = [
    {
      field: "contact_name",
      dir: "asc",
    },
  ];
  const [page, setPage] = useState(initialDataState);
  const [sort, setSort] = useState(initialSort);
  const [pageSizeValue, setPageSizeValue] = React.useState();
  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === "All" ? 77 : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const customDateCell = (props) => {
    if (props.dataItem[props.field] !== "") {
      return (
        <td>
          {moment(props.dataItem[props.field]).format("DD MMM YYYY, hh:mm A")}
        </td>
      );
    }
    return <td>{props.dataItem[props.field]}</td>;
  };

  return (
    <React.Fragment>
      {data && (
        <Grid
          style={{
            height: "420px",
          }}
          data={data.slice(page.skip, page.take + page.skip)}
          skip={page.skip}
          take={page.take}
          total={data.length}
          pageable={{
            buttonCount: 4,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
          }}
          onPageChange={pageChange}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
          className="drContactTable"
        >
          <GridColumn
            key={0}
            title={"Contact Name"}
            field={"contact_name"}
            width={"200px"}
          />
          <GridColumn key={1} title={"Contact No."} field={"contact_number"} />
          <GridColumn key={2} title={"Created By"} field={"created_by.name"} />
          <GridColumn
            key={3}
            title={"Created On"}
            field={"created_on"}
            cell={customDateCell}
          />
          <GridColumn key={4} title={"Updated By"} field={"updated_by.name"} />
          <GridColumn
            key={5}
            title={"Updated On"}
            field={"updated_on"}
            cell={customDateCell}
          />
          <GridColumn
            key={6}
            title={"Action"}
            cell={(props) => (
              <td>
                <DropDownList
                  className="dr-safe-list-column-action"
                  data={["Edit", "Delete"]}
                  defaultValue={"Action"}
                  onChange={(e) => actionDropdownClicked(e, props)}
                />
              </td>
            )}
          />
        </Grid>
      )}
    </React.Fragment>
  );
};

export default DrContactTable;
