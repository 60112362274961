export const STATUS = [
  { id: 'notvalid', label: 'Not Valid' },
  { id: 'valid', label: 'Valid' },
  { id: 'kiv', label: 'KIV' },
  { id: 'implementing', label: 'Implementing' },
  { id: 'implemented', label: 'Implemented' },
]

export const VISIBILITY = [
  {
    label: 'Published',
    id: 'published'
  },
  {
    label: 'Unpublished',
    id: 'unpublished'
  }
]

export const CREATED_ON = {
  ALL: 'all',
  RANGE: 'range'
}