import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  createRef,
} from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import "../../communication.css";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import {
  RadioButton,
  Checkbox,
  TextBox,
  InputSuffix,
} from "@progress/kendo-react-inputs";
import { Icon } from "@progress/kendo-react-common";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { TimePicker, start } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import NextIcon from "../../../../assets/communication/NextIcon.svg";
import { fetchAllCategories } from "../../../../store/category";
import { fetchAllLinkToTypes } from "../../../../store/link_to";
import { fetchAllChannels } from "../../../../store/channels";
import { fetchNotificationRecipients } from "../../../../store/notification";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../../../config/userPool";
import api from "../../../../config/api";
import config from "../../../../config/config";
import { Loader } from "@progress/kendo-react-indicators";
import moment from "moment";
import "../../communication.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CustomDatePicker } from "../../../../components/custom/datepicker";

const { NOTIFICATION_ENDPOINT } = config.api_endpoint;

const {
  Bold,
  Italic,
  Underline,
  AlignCenter,
  AlignRight,
  AlignJustify,
  OrderedList,
  UnorderedList,
} = EditorTools;

const Priority_options = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const InformationForm = (props) => {
  const dispatch = useDispatch();
  let { search } = useLocation();
  const chinese_editor = createRef();
  const query = new URLSearchParams(search);

  const [selectedChannel, setSelectedChannel] = useState([]);
  const [title_en, setTitle_en] = useState("");
  const [title_zh, setTitle_zh] = useState("");
  const [message_en, setMessage_en] = useState("");
  const [message_zh, setMessage_zh] = useState("");
  const [high_priority_flag, setHigh_priority_flag] = useState(false);
  const [category_id, setCategoryId] = useState("");
  const [link_to, setlink_to] = useState("");
  const [link_to_type, setlink_to_type] = useState("URL");
  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");
  const [start_time, setstart_time] = useState("");
  const [end_time, setend_time] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [autoSaveOn, setAutoSaveOn] = useState(false);
  const [timer, setTimer] = useState(0);
  const [errors, setErrors] = useState({
    selectedChannel: false,
    title_en: false,
    title_zh: false,
    message_en: false,
    message_zh: false,
    high_priority_flag: false,
    category_id: false,
    link_to: false,
  });
  const [notification_data, setNotificationData] = useState(false);
  const [showRequiredFieldError, setShowRequiredFieldError] = useState(false);
  const minMinute = new Date(new Date().getTime() + 15 * 60000);
  const { channel_ids, loading: channel_loading } = useSelector(
    (state) => state.channel
  );
  const { category_ids, loading: category_loading } = useSelector(
    (state) => state.category
  );
  const { link_to_types, loading: link_to_loading } = useSelector(
    (state) => state.link_to_type
  );
  const { recipients, recipient_loading, recipient_error } = useSelector(
    (state) => state.notification
  );

  // useRef value to check autoSaveOn
  // to save the data if autoSaveOn is true when component unloads
  const autoSaveOnRef = useRef();
  autoSaveOnRef.current = autoSaveOn;

  const dataFromPage = useRef({});
  const dataFromAPI = useRef({});

  const setDataToRef = (attr, val) => {
    setAutoSaveOn(true);
    autoSaveOnRef.current = true;
    console.log("setDataToRef:", attr, val);
    dataFromPage.current[attr] = val;
    console.log("setDataToRef:", dataFromPage.current);
  };

  const notification__id = query.get("id", false);
  const copyId = query.get("copyId", false);

  const prepareFormData = (param) => {
    let fdata = {};
    fdata.start_date = param.start_date
      ? moment(param.start_date, "YYYY-MM-DD").format("YYYY-MM-DD")
      : "";
    fdata.start_time = param.start_time
      ? moment(param.start_time, "HH:mm:ss").format("hh:mmA")
      : "";
    fdata.end_date = param.end_date
      ? moment(param.end_date, "YYYY-MM-DD").format("YYYY-MM-DD")
      : "";
    fdata.end_time = param.end_time
      ? moment(param.end_time, "HH:mm:ss").format("hh:mmA")
      : "";
    fdata.status = "draft";
    console.log({ ...param, ...fdata });
    return { ...param, ...fdata };
  };

  const prepareAPIData = (param) => {
    let data = JSON.parse(JSON.stringify(param));
    data.home_banner_image = data.home_banner_image
      ? data.home_banner_image.map((v) => ({
          name: v.name,
          url: v.url,
        }))
      : [];
    data.splash_image = data.splash_image
      ? data.splash_image.map((v) => ({
          name: v.name,
          url: v.url,
        }))
      : [];
    data.message_image = data.message_image
      ? data.message_image.map((v) => ({
          name: v.name,
          url: v.url,
        }))
      : [];
    data.message_video = data.message_video
      ? data.message_video.map((v) => ({
          name: v.name,
          url: v.url,
        }))
      : [];
    data.popup_image = data.popup_image
      ? data.popup_image.map((v) => ({
          name: v.name,
          url: v.url,
        }))
      : [];
    data.message_attachment = data.message_attachment
      ? data.message_attachment.map((v) => ({
          name: v.name,
          url: v.url,
        }))
      : [];
    // data.recipient = recipients.map((v) => ({
    //   id: v["sap_no"],
    //   name: v["name"],
    // }));
    return data;
  };

  useEffect(() => {
    localStorage.removeItem("inbox_save_clicked");

    dispatch(fetchAllCategories());
    dispatch(fetchAllLinkToTypes());
    dispatch(fetchAllChannels());
    if (copyId) {
      props.setLoading(true);
      load_data(copyId);
    } else if (notification__id) {
      dispatch(fetchNotificationRecipients(notification__id));
      props.setLoading(true);
      load_data(notification__id);
    } else {
      props.setLoading(false);
    }

    return () => {
      // when the component is unloaded & autoSaveOn is true, save to backend
      // cos between each setInterval of timer, user can go to anther page and data will be lost
      console.log("INFORMATION FORM UNLOADED.");
      console.log("autoSaveOnRef:", autoSaveOnRef.current);

      let data_from_page = prepareFormData(dataFromPage.current);
      let data_from_api = prepareAPIData(dataFromAPI.current);

      console.log("data to save:", data_from_page);
      console.log("data from api:", data_from_api);

      const flag1 = checkRequiredFields(data_from_page);
      const flag2 = checkRequiredFields(data_from_api);

      console.log("flag1:", flag1);
      console.log("flag2:", flag2);

      let flagFromLocal = localStorage.getItem("inbox_save_clicked");
      if (
        autoSaveOnRef.current === true &&
        flagFromLocal === null &&
        (flag1 || flag2)
      ) {
        saveData(notification__id, copyId, data_from_page, data_from_api, true);
        console.log("saved data before unload.");
      }
      if (flagFromLocal) {
        localStorage.removeItem("inbox_save_clicked");
      }
    };
  }, []);

  const saveData = (
    notification__id,
    copy__id,
    data_from_page,
    data_from_api,
    addLoading
  ) => {
    if (notification__id || copy__id) {
      let update_body = { ...data_from_api, ...data_from_page };

      if (notification__id) {
        update_body.id = notification__id;
      }

      console.log(update_body);
      props.callbackAutoSave(update_body, addLoading);
    } else {
      console.log(data_from_page);
      props.callbackAutoSave(data_from_page, addLoading);
    }
  };
  const checkRequiredFields = (data) => {
    const requiredFields = [
      // "title_en",
      "message_en",
      "category_id",
      "start_date",
      "start_time",
      "end_date",
      "end_time",
    ];
    let flag = true;
    requiredFields.forEach((requiredField) => {
      if (!requiredField in data) {
        flag = false;
      } else {
        if (!data[requiredField]) {
          flag = false;
        }
      }
    });
    return flag;
  };

  useEffect(() => {
    console.log("USE EFFECT TIMER:", timer);
    const interval = setInterval(() => {
      console.log("SET INTERVAL TIMER:", timer + 1, autoSaveOnRef.current);
      setTimer(timer + 1);
      if (autoSaveOnRef.current) {
        console.log(moment().format());
        let info_data = prepareFormData(dataFromPage.current);
        let update_body = prepareAPIData(dataFromAPI.current);
        console.log("info_data:", info_data);
        console.log("update_body:", update_body);
        const flag1 = checkRequiredFields(info_data);
        const flag2 = checkRequiredFields(update_body);
        console.log("flag1:", flag1);
        console.log("flag2:", flag2);
        if (flag1 || flag2) {
          saveData(notification__id, copyId, info_data, update_body);
        }
      }
    }, 20000);
    return () => clearInterval(interval);
  }, [timer]);

  const GetUpdate_body = () => {
    console.log(notification_data);
    // let recipients_list = recipients.map((v) => ({
    //   id: v["sap_no"],
    //   name: v["name"],
    // }));
    return {
      title_en: notification_data.title_en,
      title_zh: notification_data.title_zh,
      message_en: notification_data.message_en,
      message_zh: notification_data.message_zh,
      start_date: notification_data.start_date,
      start_time: notification_data.start_time,
      end_date: notification_data.end_date,
      end_time: notification_data.end_time,
      channel: notification_data.channel,
      high_priority_flag: notification_data.high_priority_flag,
      status: "draft",
      link_to: notification_data.link_to,
      link_to_type: notification_data.link_to_type,
      category_id: notification_data.category_id,
      depot: notification_data.depot,
      division: notification_data.division,
      interchange: notification_data.interchange,
      home_banner_image: notification_data.home_banner_image
        ? notification_data.home_banner_image.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      splash_image: notification_data.splash_image
        ? notification_data.splash_image.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      message_image: notification_data.message_image
        ? notification_data.message_image.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      message_video: notification_data.message_video
        ? notification_data.message_video.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      popup_image: notification_data.popup_image
        ? notification_data.popup_image.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      message_attachment: notification_data.message_attachment
        ? notification_data.message_attachment.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      recipient: notification_data.recipient,
    };
  };
  const load_data = async (recId) => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    await api
      .get(`${NOTIFICATION_ENDPOINT}/${recId}`, {}, token)
      .then((res) => {
        if (res.status === "success") {
          const data = res.data;
          if (
            data.channel.length == 1 &&
            data.channel.some((e) => e.id == "SplashScreen")
          ) {
            props.setIsSplashOnly(true);
          }
          setNotificationData(data);
          dataFromAPI.current = data;
          dataFromPage.current = data;
          console.log("DATA >>> ", data);
          const start_time_format = moment(data.start_time, "hh:mmA").format(
            "YYYY-MM-DD HH:mm:ss"
          );
          const end_time_format = moment(data.end_time, "hh:mmA").format(
            "YYYY-MM-DD HH:mm:ss"
          );
          setSelectedChannel(data.channel);
          setTitle_en(data.title_en);
          setTitle_zh(data.title_zh);
          setMessage_en(data.message_en);
          setMessage_zh(data.message_zh);
          setHigh_priority_flag(data.high_priority_flag);
          setCategoryId(data.category_id);
          setlink_to_type(data.link_to_type);
          setlink_to(data.link_to);
          setstart_date(data.start_date ? new Date(data.start_date) : null);
          setend_date(data.end_date ? new Date(data.end_date) : null);
          setstart_time(data.start_time ? new Date(start_time_format) : null);
          setend_time(data.end_time ? new Date(end_time_format) : null);
        }
        props.setLoading(false);
      });
  };
  const handleCheck = (e, value) => {
    var updatedList = [...selectedChannel];
    if (e.target.value) {
      updatedList = [...selectedChannel, value];
    } else {
      var selected_index = selectedChannel.map((v) => v.id).indexOf(value.id);
      updatedList.splice(selected_index, 1);
    }
    if (
      updatedList.length == 1 &&
      updatedList.some((e) => e.id == "SplashScreen")
    ) {
      props.setIsSplashOnly(true);
    } else {
      props.setIsSplashOnly(false);
    }
    setSelectedChannel(updatedList);
    setDataToRef("channel", updatedList);
  };

  const onChangeHighPriorityFlag = (e) => {
    setHigh_priority_flag(e.value);
    setDataToRef("high_priority_flag", e.value);
  };

  const set_data_forChinese = () => {
    setMessage_zh(getHtml());
    setDataToRef("message_zh", getHtml());
  };

  const getHtml = () => {
    if (chinese_editor) {
      console.log(chinese_editor.current.view.state);
      const view = chinese_editor.current.view;
      if (view) {
        return EditorUtils.getHtml(view.state);
      }
    }
  };
  const GetFormData = () => {
    return {
      title_en: title_en,
      title_zh: title_zh,
      message_en: message_en,
      message_zh: getHtml(),
      start_date: start_date
        ? moment(start_date, "YYYY-MM-DD").format("YYYY-MM-DD")
        : "",
      start_time: start_time
        ? moment(start_time, "HH:mm:ss").format("hh:mmA")
        : "",
      end_date: end_date
        ? moment(end_date, "YYYY-MM-DD").format("YYYY-MM-DD")
        : "",
      end_time: end_time ? moment(end_time, "HH:mm:ss").format("hh:mmA") : "",
      channel: selectedChannel,
      high_priority_flag: high_priority_flag,
      status: "draft",
      link_to: link_to,
      link_to_type: link_to_type,
      category_id: category_id,
    };
  };
  const handleSubmit = (e, isSave) => {
    // await set_data_forChinese();
    setMessage_zh(getHtml());
    setDataToRef("message_zh", getHtml());
    console.log("handleSubmit");
    let info_data = GetFormData();
    let flag = checkRequiredFields(info_data);
    console.log("handleSubmit flag:", flag, info_data);

    if (!checkRequiredFields(info_data)) {
      setShowRequiredFieldError(true);
    } else {
      setDisabled(true);
      localStorage.setItem("inbox_save_clicked", true);
      props.setLoading(true);
      if (notification__id) {
        let update_body = GetUpdate_body();
        update_body = { ...update_body, ...info_data };
        update_body.id = notification__id;
        console.log(update_body);
        props.submitcallback(update_body, isSave);
      } else if (copyId) {
        let update_body = GetUpdate_body();
        update_body = { ...update_body, ...info_data };
        console.log(update_body);
        props.submitcallback(update_body, isSave);
      } else {
        console.log(info_data);
        props.submitcallback(info_data, isSave);
      }
    }
  };

  const isChecked = (item) =>
    selectedChannel.map((v) => v.id).includes(item) ? true : false;

  return (
    <>
      {showRequiredFieldError && (
        <Dialog
          className="information-details-alert-form"
          title={"Alert"}
          onClose={() => setShowRequiredFieldError(false)}
        >
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            Please fill in all the required fields.
          </p>
        </Dialog>
      )}
      {props.loading ||
      category_loading ||
      channel_loading ||
      link_to_loading ||
      recipient_loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <form onSubmit={(e) => e.preventDefault()}>
          {/* <form onChange={() => setAutoSaveOn(true)}> */}
          <div className="message-body">
            <p className="noti-form-title">Channels</p>
            <ul className="channel-list">
              {channel_ids.map((val) => (
                <li>
                  <Checkbox
                    key={val.id}
                    style={{ border: "1px solid #CCCCCC" }}
                    name={val.id}
                    value={val.id}
                    onChange={(e) => handleCheck(e, val)}
                    checked={isChecked(val.id)}
                  />
                  <label>{val.name}</label>
                </li>
              ))}
            </ul>
          </div>
          <div className="information-message">
            <p className="noti-form-title">
              Title in English
              {/* <span style={{ color: "red" }}>*</span> */}
            </p>
            <div>
              <TextBox
                value={title_en}
                onChange={(e) => {
                  setTitle_en(e.target.value);
                  setDataToRef("title_en", e.target.value);
                }}
                style={{
                  width: "99%",
                }}
                className="info-textbox"
              />
            </div>
            <p className="noti-form-title">Title in Chinese</p>
            <div>
              <TextBox
                value={title_zh}
                onChange={(e) => {
                  setTitle_zh(e.target.value);
                  setDataToRef("title_zh", e.target.value);
                }}
                style={{
                  width: "99%",
                }}
                className="info-textbox"
              />
            </div>
            <p className="noti-form-title">
              Message in English <span style={{ color: "red" }}>*</span>
            </p>
            <div>
              <Editor
                tools={[
                  [
                    Bold,
                    Italic,
                    Underline,
                    AlignCenter,
                    AlignJustify,
                    AlignRight,
                    OrderedList,
                    UnorderedList,
                  ],
                ]}
                className="info-editor"
                defaultContent={message_en}
                value={message_en}
                onChange={(e) => {
                  setMessage_en(e.html);
                  setDataToRef("message_en", e.html);
                }}
              />
            </div>
            <p className="noti-form-title">Message in Chinese</p>
            <div>
              <Editor
                tools={[
                  [
                    Bold,
                    Italic,
                    Underline,
                    AlignCenter,
                    AlignJustify,
                    AlignRight,
                    OrderedList,
                    UnorderedList,
                  ],
                ]}
                id="Chinese_textbox"
                className="info-editor"
                defaultContent={message_zh}
                ref={chinese_editor}
                // value={message_zh}
                // onChange={(e) => {
                //   console.log(getHtml());
                //   // setMessage_zh(getHtml());
                //   // setDataToRef("message_zh", getHtml());
                // }}
              />
            </div>
            <p className="noti-form-title">High Priority Flag</p>
            <div className="info-radio-group">
              {Priority_options.map((val) => (
                <RadioButton
                  key={val.value}
                  className="info-radio-btn"
                  label={val.label}
                  name="high_priority_flag"
                  value={val.value}
                  onChange={onChangeHighPriorityFlag}
                  checked={high_priority_flag === val.value}
                />
              ))}
            </div>
            <div>
              <ul className="info-category-section">
                <li style={{ width: "45%" }}>
                  <p className="noti-form-title">
                    Category <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className="info-select">
                    <select
                      className="info-category-dropdown"
                      defaultValue={category_id}
                      onChange={(e) => {
                        console.log(category_ids);
                        setCategoryId(e.target.value);
                        setDataToRef("category_id", e.target.value);
                      }}
                    >
                      <option key="all" value="">
                        Select Category
                      </option>
                      {category_ids.map((v) => (
                        <option key={v.id} value={v.id}>
                          {v.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </li>
                <li style={{ width: "10%" }}>
                  <p className="noti-form-title">Link to</p>
                  <div className="info-select">
                    <select
                      className="info-category-dropdown"
                      // style={{ width: "100%"}}
                      onChange={(e) => {
                        setlink_to_type(e.target.value);
                        setDataToRef("link_to_type", e.target.value);
                      }}
                    >
                      {link_to_types.map((v) => (
                        <option key={v.id} value={v.id}>
                          {v.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </li>
                <li style={{ width: "45%" }}>
                  <p className="noti-form-title">&nbsp;</p>
                  <TextBox
                    placeholder="https://"
                    value={link_to}
                    onChange={(e) => {
                      setlink_to(e.target.value);
                      setDataToRef("link_to", e.target.value);
                    }}
                  />
                </li>
              </ul>
            </div>
            <p className="noti-form-title">
              Publish Rules
              <span className="info-category-infomsg">
                &nbsp;(applies to all messages in all channels except Inbox)
              </span>
            </p>
            <div className="publish-rules-div">
              <div className="publish-rules-row">
                <table className="publish-rules-table">
                  <tr>
                    <td colSpan={2}>
                      <label>
                        Start <span style={{ color: "red" }}>*</span>
                      </label>
                    </td>
                    <td colSpan={2}>
                      <span>
                        End <span style={{ color: "red" }}>*</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: 15 }}>
                      <DatePicker
                        selected={start_date}
                        minDate={new Date()}
                        onChange={(date) => {
                          setstart_date(date);
                          setDataToRef("start_date", date);
                        }}
                        dateFormatCalendar="MMMM"
                        customInput={<CustomDatePicker />}
                      />
                    </td>
                    {console.log(new Date(minMinute))}
                    <td style={{ paddingRight: 15 }}>
                      <TimePicker
                        placeholder={""}
                        className="info-datetime-picker keyboardInputDisable"
                        defaultValue={start_time}
                        value={start_time}
                        // min={""}
                        min={
                          start_date === ""
                            ? minMinute
                            : moment(start_date).format("DD-MM-YYYY") ===
                              moment(new Date()).format("DD-MM-YYYY")
                            ? minMinute
                            : new Date("11-11-2023")
                          // : new Date(new Date().getTime() + 3 * 24 * 3600000)
                        }
                        steps={{
                          hour: 1,
                          minute: 15,
                          // second: 30,
                        }}
                        onChange={(e) => {
                          setstart_time(e.target.value);
                          setDataToRef("start_time", e.target.value);
                        }}
                        // required
                      />
                    </td>
                    <td style={{ paddingRight: 15 }}>
                      <DatePicker
                        selected={end_date}
                        minDate={new Date()}
                        onChange={(date) => {
                          setend_date(date);
                          setDataToRef("end_date", date);
                        }}
                        dateFormatCalendar="MMMM"
                        customInput={<CustomDatePicker />}
                      />
                      {/* <DatePicker
                          format={"dd/MMM/yyyy"}
                          placeholder={""}
                          className="info-datetime-picker"
                          defaultValue={end_date}
                          value={end_date}
                          onChange={(e) => {setend_date(e.target.value);setAutoSaveOn(true)}}
                        // required
                        /> */}
                    </td>
                    <td>
                      <TimePicker
                        placeholder={""}
                        className="info-datetime-picker keyboardInputDisable"
                        defaultValue={end_time}
                        steps={{
                          hour: 1,
                          minute: 15,
                          // second: 30,
                        }}
                        min={
                          end_date === ""
                            ? minMinute
                            : moment(end_date).format("DD-MM-YYYY") ===
                              moment(new Date()).format("DD-MM-YYYY")
                            ? minMinute
                            : new Date("11-11-2023")
                        }
                        value={end_time}
                        onChange={(e) => {
                          setend_time(e.target.value);
                          setDataToRef("end_time", e.target.value);
                        }}
                        // required
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="footer no-pad-left pad-bottom-20">
            <div className="footer-left-btn-groups">
              <Button
                className="footer-save-btn"
                onClick={(e) => {
                  handleSubmit(e, true);
                }}
                disabled={disabled}
              >
                Save
              </Button>
            </div>
            <div className="footer-right">
              <Button
                className="footer-next-btn"
                onClick={(e) => {
                  handleSubmit(e, false);
                }}
                disabled={disabled}
              >
                Next
                <Icon>
                  <img alt="" src={NextIcon} />
                </Icon>
              </Button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};
