import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Loader } from "@progress/kendo-react-indicators";
import moment from "moment";
import React, { useState } from "react";
import Hide_icon from "../../assets/authentication/hide_icon.svg";
import UNHide_icon from "../../assets/authentication/unhide_icon.svg";
import config from "../../config/config";
import api from "../../config/api";
import validator from "validator";
import { getCurrentLoggedInSessionToken } from "../../config/userPool";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useNavigate } from "react-router";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(false);
  const [showVerifyError, setShowVerifyError] = useState(false);
  const [showPassnotsame, setShowPassnotsame] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showPassToFollow, setShowPassToFollow] = useState(false);
  const [showUnSuccess, setShowUnSuccess] = useState(false);
  const [showApiError, setShowApiError] = useState("");
  const [userDetail, setUserDetail] = useState({
    username: "",
    nricfin: "",
    dob: "",
    password: "",
    confirmPassword: "",
  });
  const [hide, setHide] = useState({
    password: true,
    confirmpassword: true,
  });

  const { VERIFY_USER_ENDPOINT, CHANGE_FORGOTPASSWORD_ENDPOINT } =
    config.api_endpoint;

  const SearchUser = () => {
    setLoading(true);
    const token = getCurrentLoggedInSessionToken();
    const data = {
      username: userDetail.username,
      nricfin: userDetail.nricfin,
      dob: userDetail.dob,
    };
    api.post(VERIFY_USER_ENDPOINT, JSON.stringify(data), token).then((res) => {
      console.log(res);
      if (res.status_code === 200) {
        if (res.status === "success") {
          setUserData(true);
          setLoading(false);
        }
      }
      if (res.status_code === 400) {
        setShowVerifyError(true);
        setLoading(false);
        setTimeout(() => {
          setShowVerifyError(false);
        }, 6000);
      }
    });
  };

  const checkPassword = () => {
    if (
      validator.isStrongPassword(userDetail.password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
      })
    ) {
      return true;
    } else {
      return false;
    }
  };

  const ChangePassword = () => {
    setLoading(true);
    if (showPassnotsame) {
      setLoading(false);
      return;
    }
    if (!checkPassword()) {
      setLoading(false);
      setShowPassToFollow(true);
      setTimeout(() => {
        setShowPassToFollow(false);
      }, 6000);
      return;
    }
    const token = getCurrentLoggedInSessionToken();
    const data = {
      username: userDetail.username,
      nricfin: userDetail.nricfin,
      dob: userDetail.dob,
      new_password1: userDetail.password,
      new_password2: userDetail.confirmPassword,
    };
    console.log(data);
    api
      .post(CHANGE_FORGOTPASSWORD_ENDPOINT, JSON.stringify(data), token)
      .then((res) => {
        console.log(res);
        if (res.status_code === 200) {
          if (res.status === "success") {
            setShowSuccess(true);
            navigate("/");
            setLoading(false);
          }
        } else {
          setShowUnSuccess(true);
          setShowApiError(res.message);
          setTimeout(() => {
            setShowUnSuccess(false);
          }, 6000);
          setLoading(false);
        }
      });
  };

  return (
    <>
      {loading && (
        <Loader
          type="converging-spinner"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ffffff4d",
            position: "absolute",
            zIndex: "999",
          }}
        />
      )}
      {showVerifyError && (
        <div className="forgot_password_error_box forgot_password_error_movedown">
          You have entered the wrong Emp No/BC No, NRIC (Last 4 characters) or
          DOB. Please try again!{" "}
        </div>
      )}
      {showPassToFollow && (
        <div className="forgot_password_error_box forgot_password_error_movedown">
          Password must be at least 8 Characters, a number, uppercase and
          lowercase.
        </div>
      )}
      {showUnSuccess && (
        <div className="forgot_password_error_box forgot_password_error_movedown">
          Change password unsuccessful. Please try again later! <br />
          {showApiError}
        </div>
      )}
      {userData ? (
        <div className="forgot_password_main_box">
          <div className="forgot_password_title">
            Provide the required information to reset the password.
          </div>
          <div
            className="forgot_password_detail_main_box"
            style={{ gap: "0px" }}
          >
            <div
              className="forgot_password_detail_title"
              style={{ marginBottom: "15px" }}
            >
              New Password
            </div>
            <div style={{ width: "100%", position: "relative" }}>
              <div
                style={{
                  backgroundImage: hide.password
                    ? `url(${Hide_icon})`
                    : `url(${UNHide_icon})`,
                  cursor: "pointer",
                }}
                onClick={() =>
                  setHide({
                    ...hide,
                    password: !hide.password,
                  })
                }
                className="forgot_password_icon"
              />
            </div>
            <input
              className="forgot_password_detail_field"
              placeholder="Enter Password."
              type={hide.password ? "password" : "text"}
              onChange={(e) => {
                if (userDetail.confirmPassword !== e.target.value) {
                  setShowPassnotsame(true);
                } else {
                  setShowPassnotsame(false);
                }
                setUserDetail({
                  ...userDetail,
                  password: e.target.value,
                });
              }}
            />
          </div>
          <div
            className="forgot_password_detail_main_box"
            style={{ gap: "0px" }}
          >
            <div
              className="forgot_password_detail_title"
              style={{ marginBottom: "15px" }}
            >
              Confirm Password
            </div>
            <div style={{ width: "100%", position: "relative" }}>
              <div
                style={{
                  backgroundImage: hide.confirmpassword
                    ? `url(${Hide_icon})`
                    : `url(${UNHide_icon})`,
                  cursor: "pointer",
                }}
                onClick={() =>
                  setHide({
                    ...hide,
                    confirmpassword: !hide.confirmpassword,
                  })
                }
                className="forgot_password_icon"
              />
            </div>
            <input
              className="forgot_password_detail_field"
              placeholder="Enter Confirm password."
              type={hide.confirmpassword ? "password" : "text"}
              onChange={(e) => {
                if (userDetail.password !== e.target.value) {
                  setShowPassnotsame(true);
                } else {
                  setShowPassnotsame(false);
                }
                setUserDetail({
                  ...userDetail,
                  confirmPassword: e.target.value,
                });
              }}
            />
            {showPassnotsame && (
              <div className="forgot_password_error">
                New Password and Confirm New Password must be same.
              </div>
            )}
          </div>
          <div className="forgot_password_hint_main_box">
            <div className="forgot_password_hint_title">Password Hint</div>
            <div className="forgot_password_hint_sub_title">
              All Passwords shall include three of the following:
            </div>
            <div className="forgot_password_hint_list">
              <ul>
                <li>At least 8 characters</li>
                <li>A number</li>
                <li>Uppercase and Lowercase</li>
              </ul>
            </div>
            <div className="forgot_password_hint_footer">
              All passwords shall not be same as your last 6 passwords or use
              three or more consecutive characters from your Login ID or Full
              Name.
            </div>
          </div>
          <div className="forgot_password_footer_main_box">
            <div
              className="forgot_password_footer_button forgot_password_footer_cancel"
              onClick={() => {
                setUserData(false);
                setUserDetail({
                  ...userDetail,
                  username: "",
                  nricfin: "",
                  dob: "",
                  password: "",
                  confirmPassword: "",
                });
              }}
            >
              Cancel
            </div>
            <div
              className="forgot_password_footer_button forgot_password_footer_proceed"
              onClick={ChangePassword}
            >
              Proceed
            </div>
          </div>
        </div>
      ) : (
        <div className="forgot_password_main_box">
          <div className="forgot_password_title">
            Provide the required information to reset the password.
          </div>
          <div className="forgot_password_detail_main_box">
            <div className="forgot_password_detail_title">
              Emp No (BC) / SAP No.
            </div>
            <input
              className="forgot_password_detail_field"
              placeholder="Enter Emp No (BC) / SAP No."
              onChange={(e) => {
                setUserDetail({
                  ...userDetail,
                  username: e.target.value,
                });
              }}
            />
          </div>
          <div className="forgot_password_detail_main_box">
            <div className="forgot_password_detail_title">
              NRIC/FIN (Last 4 characters)
            </div>
            <input
              className="forgot_password_detail_field"
              placeholder="Enter NRIC/FIN (Last 4 characters)"
              onChange={(e) => {
                setUserDetail({
                  ...userDetail,
                  nricfin: e.target.value,
                });
              }}
            />
          </div>
          <div className="forgot_password_detail_main_box">
            <div className="forgot_password_detail_title">Birthdate</div>
            {/* <input
          className="forgot_password_detail_field forgot_password_date"
          type="date"
        /> */}
            <DatePicker
              format={"yyyy-MM-dd"}
              placeholder={"YYYY-MM-DD"}
              onChange={(e) => {
                setUserDetail({
                  ...userDetail,
                  dob: moment(e.target.value).format("YYYY-MM-DD"),
                });
              }}
            />
          </div>
          <div className="forgot_password_footer_main_box">
            <div
              className="forgot_password_footer_button forgot_password_footer_cancel"
              onClick={() => navigate("/")}
            >
              Cancel
            </div>
            <div
              className="forgot_password_footer_button forgot_password_footer_proceed"
              onClick={SearchUser}
            >
              Proceed
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
