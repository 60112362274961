import { Dialog,DialogActionsBar } from "@progress/kendo-react-dialogs";

import Input from "../../../../components/input";
import Drawer from "../../../../components/drawer";
import Button from "../../../../components/button";
import { useState } from "react";

const DrSystemListSearchDialog = ({onToggleDialog, onSubmitShare}) => {
  const [systemName, setSystemName] = useState('');
  const [subSubSystemName, setSubSystemName] = useState('');

  const onSystemNameChange = e => {
    setSystemName(e.target.value);
  };
  const onSubSystemNameChange = e => {
    setSubSystemName(e.target.value);
  };
  
  const onSubmit = () => {
    onSubmitShare(systemName, subSubSystemName)
  }

  return (
    <Dialog
        className="search-notification"
        title={" "}
        // width={500}
        height={"100%"}
        onClose={onToggleDialog}
        >
        <div className="search-body">
            <div className="noti-dialog-title dialog-row">Search</div>
            <div className="dialog-row">
                <span className="search-row-title">System</span>
                <Input className="search-input" onChange={onSystemNameChange}/>
            </div>

            <div className="dialog-row">
                <span className="search-row-title">Sub System</span>
                <Input className="search-input" onChange={onSubSystemNameChange}/>
            </div>
        </div>
        <DialogActionsBar>
            <Button className="search-button cancel-btn" onClick={onToggleDialog}>Cancel</Button>
            <Button className="search-button search-btn" onClick={onSubmit}>Submit</Button>
        </DialogActionsBar>
    </Dialog>          
  );
};

export default DrSystemListSearchDialog;
