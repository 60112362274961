import { createSlice } from "@reduxjs/toolkit"
import { FEEDBACK_ON_BUS_REDUCER, STEP_BUS_CHECKER_PERIODS } from "./constant"

const initialState = {
  step: STEP_BUS_CHECKER_PERIODS.SELECT_PERIOD,
  selectedCheckPeriod: '',
  listOfPeriod: [],
}

const reducers = {
  onChangeStep: (state, action) => {
    const newStep = action?.payload;
    if (Object.values(STEP_BUS_CHECKER_PERIODS).includes(newStep)) {
      state.step = newStep
    }
  },
  onSelectCheckPeriod: (state, action) => {
    const selectedPeriod = action?.payload;
    state.selectedCheckPeriod = selectedPeriod
  },
  onSetListOfPeriod: (state, action) => {
    const list = action?.payload ?? [];

    state.listOfPeriod = list;
  }
}

const feedbackOnBus = createSlice({
  name: FEEDBACK_ON_BUS_REDUCER,
  initialState,
  reducers
})

export const { onChangeStep, onSelectCheckPeriod, onSetListOfPeriod } = feedbackOnBus.actions
export default feedbackOnBus.reducer;