import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";

const useBackListener = (callback, currentLocation) => {
  const navigator = useContext(UNSAFE_NavigationContext).navigator;

  useEffect(() => {
    const listener = ({ location, action }) => {
      if (action === "POP") {
        callback({ location, action });
      }

      if (action === 'PUSH' && location.pathname !== currentLocation) {
        callback({ location, action });
      }
    };

    return navigator.listen(listener);
  }, [callback, currentLocation, navigator]);
};

export default useBackListener;
