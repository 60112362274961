// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { FEEDBACK_ON_BUS_EMAIL_ADDRESS_SEARCH_ENDPOINT } =
  config.api_endpoint;

const initialState = {
  loading: false,
  email_address: [],
  error: null,
};

export const fetchFOBEmailAddress = createAsyncThunk(
  "fobemailaddress/fetchFOBEmailAddress",
  async (search, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        FEEDBACK_ON_BUS_EMAIL_ADDRESS_SEARCH_ENDPOINT + "/?search="+ search,
        {},
        token
      );
      console.log("fetchFOBEmailAddress Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);


const FOBEmailAddressSlice = createSlice({
  name: "fobemailaddress",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFOBEmailAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFOBEmailAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.email_address = action.payload;
      })
      .addCase(fetchFOBEmailAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const FOBEmailAddressReducer = FOBEmailAddressSlice.reducer;
export default FOBEmailAddressReducer;
