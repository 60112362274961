import { useEffect, useState } from "react";
import { Navigate, useNavigate,useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { DepotListHeader } from './depot_list_header';
import '../../telephone_directory.css';
import DepotListBody from './depot_list_body';
import { fetchAllTelephoneDepotsSubDepot,deleteDepot } from '../../../../store/telephoneDirectory';
import { TELEPHONE_DIRECTORY_DEPOT_ADD, TELEPHONE_DIRECTORY_DEPOT_LIST } from '../../../../routes';
import { DepotSearch } from './depot_search';

import {
  getUserPermission,
  checkMultiPermission,
} from "../../../../config/permission_helper";
import {
  TEL_DIR_PERMISSIONS_FOR_ADD_DEPOT_SUBDEPOT,
  TEL_DIR_PERMISSIONS_FOR_DELETE_DEPOT_SUBDEPOT,
} from "../../../../config/permission_helper";

export const DepotList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const [directoryList, setDirectoryList] = useState([]);
    const [showSearchDialog ,setShowSearchDialog ] = useState(false);
    const { depotsSubDepotList, depot_subdepot_loading, depot_error } = useSelector(
        (state) => state.telephone_directory);
    useEffect(() => {
    dispatch(fetchAllTelephoneDepotsSubDepot());
    }, []);
    console.log('Fetch All Depots--',depotsSubDepotList);
    useEffect(() => {
    if (depotsSubDepotList) {
        let results = depotsSubDepotList;
        if(query.get("name")){
          results = results.filter((v)=> v.depot.toLowerCase().indexOf(query.get("name").toLowerCase())>-1)
        }
        if(query.get("acronym")){
          results = results.filter((v)=> v.acronym.toLowerCase().indexOf(query.get("acronym").toLowerCase())>-1)
        }
        if(query.get("parent_depot")){
          results = results.filter((v)=> v.parent_depot).filter((v)=> v.parent_depot.toLowerCase().indexOf(query.get('parent_depot').toLowerCase())>-1)
        }
        setDirectoryList(results);
    }
    }, [depotsSubDepotList]);

    const handleDeleteAction = ((id)=>{
        dispatch(deleteDepot(id)).then(()=>{
          dispatch(fetchAllTelephoneDepotsSubDepot()).then((res)=>{
            setDirectoryList(res.payload);
          });
        })
      });
    const handleSearchCallback = (data)=>{
      console.log('Search DATA>>>>>',data);
      const {
        name,
        acronym,
        parent_depot
      } = data;
      query.delete("search");
      query.set("name",name);
      query.set("acronym",acronym);
      query.set("parent_depot",parent_depot);
      if(name || acronym || parent_depot){
        query.set("search",true);
      }
      navigate(TELEPHONE_DIRECTORY_DEPOT_LIST+"?"+ query.toString());
      setShowSearchDialog(false);
      handleFilterAction();
    };
    const handleFilterAction = ()=>{
      console.log("handleFilterAction",depotsSubDepotList)
      let results = depotsSubDepotList;
      
      if(query.toString()){
        // if(query.get("created_on_start")){
        //   const formatted_created_on = new Date(moment(query.get("created_on_start")))
        //   results = results.filter((v)=> new Date(moment(v.created_on,"DD MMM YYYY,hh:mma"))>= formatted_created_on)
        // }
        // if(query.get("created_on_end")){
        //   const formatted_created_end = new Date(moment(query.get("created_on_end")).hours(23).minutes(59).seconds(59))
        //   results = results.filter((v)=> new Date(moment(v.created_on,"DD MMM YYYY,hh:mma"))<= formatted_created_end)
        // }
        // if(query.get("updated_on_start")){
        //   const formatted_updated_start = new Date(moment(query.get("updated_on_start")))
        //   results = results.filter((v)=> new Date(moment(v.updated_on,"DD MMM YYYY,hh:mma"))>= formatted_updated_start)
        // }
        // if(query.get("updated_on_end")){
        //   const formatted_updated_end = new Date(moment(query.get("updated_on_end")).hours(23).minutes(59).seconds(59))
        //   results = results.filter((v)=> new Date(moment(v.updated_on,"DD MMM YYYY,hh:mma"))<= formatted_updated_end)
        // }
        if(query.get("name")){
          results = results.filter((v)=> v.depot.toLowerCase().indexOf(query.get("name").toLowerCase())>-1)
        }
        if(query.get("acronym")){
          results = results.filter((v)=> v.acronym.toLowerCase().indexOf(query.get("acronym").toLowerCase())>-1)
        }
        if(query.get("parent_depot")){
          results = results.filter((v)=> v.parent_depot).filter((v)=> v.parent_depot.toLowerCase().indexOf(query.get('parent_depot').toLowerCase())>-1)
        }
      }
      setDirectoryList(results);
    };
    const handleClearSearchOptions = ()=>{
      query.delete("search");
      query.delete("name");
      query.delete("acronym");
      query.delete("parent_depot");
      navigate(TELEPHONE_DIRECTORY_DEPOT_LIST+"?"+query.toString());
      handleFilterAction();
    };

    const shouldHideAddButton = () => {
      const userPermissions = getUserPermission();
      return checkMultiPermission(userPermissions, TEL_DIR_PERMISSIONS_FOR_ADD_DEPOT_SUBDEPOT)
        ? false
        : true;
    };

    const shouldHideDeleteButton = () => {
      const userPermissions = getUserPermission();
      return checkMultiPermission(userPermissions, TEL_DIR_PERMISSIONS_FOR_DELETE_DEPOT_SUBDEPOT)
        ? false
        : true;
    };

    return(
        <div className="telephone-dir-list-wrapper">
          {showSearchDialog && (
                <DepotSearch
                setShowSearchDialog={setShowSearchDialog}
                onSearchcallback={handleSearchCallback}
                />
            )}
            <DepotListHeader
            loading={depot_subdepot_loading}
            addurl={TELEPHONE_DIRECTORY_DEPOT_ADD}
            onRefresh = {()=> {dispatch(fetchAllTelephoneDepotsSubDepot()).then((res)=>{
              setDirectoryList(res.payload);
            });}}
            onSearch={() => setShowSearchDialog(true)}
            hideaddbtn={shouldHideAddButton()}
            />
            {query.get("search") && (
            <div className="table-filter-item">
              <div className="table-filter-title">Keyword search applied</div>
              <span className="table-filter-clear" onClick={handleClearSearchOptions}>Clear search</span>
            </div>
            )}
            <DepotListBody
             loading = {false}
             data = {directoryList}
             handleDeleteAction={handleDeleteAction}
             hideDeleteButton={shouldHideDeleteButton()}
            />
        </div>
    );
    
};