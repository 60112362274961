import { Step } from "@progress/kendo-react-layout";
import "./custom.css";

export const CustomStep = (props) => {
    return (
      <Step {...props}>
        <span className="k-step-indicator custom-step">
          <span className="k-step-indicator-icon k-icon" />
        </span>
        <span
          className="k-step-label"
          style={{
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <span
            style={{ marginBottom: "5px",whiteSpace:"initial",width: "100%" }}
          >
            {props.desc}
          </span>
          <span
            className="custom-audit-name"
          >
            By {props.created_by.name}
          </span>
          <span
            style={{ marginBottom: "20px" }}
          >
            at {props.changed_at}
          </span>          
        </span>
      </Step>
    );
  };