import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "@progress/kendo-react-dialogs";
// components
import DrSafeDetailsContent from "./dr_safe_details_content";
import DrSafeDetailsUpdates from "./dr_safe_details_updates";
import DrSafeDetailsStatus from "./dr_safe_details_status";
import DrSafeDetailsActions from "./dr_safe_details_actions";
import DrSafeDetailsAudits from "./dr_safe_details_audits";
// css
import "./dr_safe_details.css";
import {
  fetchAllDRSafeIncidentDetails,
  fetchAllDRSafeIncidentRemarks,
  saveDRSafeIncidentRemarks,
  changeDRSafeIncidentStatus,
  fetchAllDRSafeIncidents,
  saveDRSafeMarkAsDuplicate,
  saveDRSafeIncidentShare,
  saveDRSafeIncidentGroup
} from "../../../../store/drSafeIncident";
import { fetchUserById } from "../../../../store/userMgmt";
import {
  getUserPermission,
  checkMultiPermission,
  DR_SAFE_PERMISSIONS_FOR_UPDATE_STATUS,
  DR_SAFE_PERMISSIONS_FOR_SUBMIT_UPDATE,
  DR_SAFE_PERMISSIONS_FOR_ROUTE,
  DR_SAFE_PERMISSIONS_FOR_SHARE,
  DR_SAFE_PERMISSIONS_FOR_MARK_DUPLICATE
} from "../../../../config/permission_helper";

const DrSafeDetails = () => {
  let { search } = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(search);
  const {
    drSafeIncidentDetails,
    details_loading: loading,
    details_error: error,
    drSafeIncidentRemarks,
    remarks_loading,
    remarks_error,
  } = useSelector((state) => state.drSafeIncident);
  const { user_details_loading, user_details } = useSelector((state) => state.userMgmt);
  const [audits, setAudits] = useState([]);
  const [incident, setIncident] = useState({});
  const [updates, setUpdates] = useState([]);
  const [locations, setLocations] = useState([]);
  const [actionMessage,setActionMessage] = useState("");
  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
  const [showChangeMarkAsDuplicateDialog, setshowChangeMarkAsDuplicateDialog] = useState(false);
  const [showChangeShareDialog, setshowChangeShareDialog] = useState(false);
  const [showRouteToDepeDialog,setShowRouteToDepDialog] = useState(false);
  const [showAlert,setshowAlert] = useState(false);
  const [createdByDetails, setCreatedByDetails] = useState({});
  const [updatedByDetails, setUpdatedByDetails] = useState({});

  const case_ref_no = query.get("case_ref_no")
  const incidentStatuses = [
    "Open",
    "Received",
    "Investigating",
    "Completed",
    "Closed"
  ];
  const handleRouteToDepAction = async(data)=>{
    setShowRouteToDepDialog(false);
    dispatch(saveDRSafeIncidentGroup({case_ref_no,group_id: data})
    ).then(result => {
        console.log("route to dep RES-",result);
        if(result.payload.status == "success"){
          setActionMessage('Successfully route to other department!');
          setshowAlert(true);
        }
        else{
          setActionMessage(result.payload.message);
          setshowAlert(true);
        }
      })
    .then(()=>{
      dispatch(fetchAllDRSafeIncidentDetails(case_ref_no))
    })
  }
  const [currentIncidentStatus, setCurrentIncidentStatus] = useState("");
  const handleChangeIncidentStatus = (e) => {
    setCurrentIncidentStatus(e.target.value);
  };
  const handleSaveIncidentStatus = () => {
    dispatch(
      changeDRSafeIncidentStatus({
        case_ref_no: incident.case_ref_no,
        incidentStatus: currentIncidentStatus.toLowerCase(),
      })
    ).then (result => {
        console.log("status RES-",result);
        if(result.payload.status == "success"){
          setActionMessage('Updated Status');
          setshowAlert(true);
        }
        else{
          setActionMessage(result.payload.message);
          setshowAlert(true);
        }
        })
      .then(()=>{
      dispatch(fetchAllDRSafeIncidentDetails(case_ref_no))
    });
    setShowChangeStatusDialog(false);
  };
  const handleUpdateStatusBtnClicked = () => {
    setCurrentIncidentStatus(incident.status);
    setShowChangeStatusDialog(true);
  };
  const handleChangeIncidentStatusDialogClose = () => {
    setShowChangeStatusDialog(false);
    setshowChangeMarkAsDuplicateDialog(false);
    setshowChangeShareDialog(false);
  };
// Mark as duplicate
  const handleMarkAsDuplicateBtnClicked = () => {
    setCurrentIncidentStatus(incident.status);
    setshowChangeMarkAsDuplicateDialog(true);
  };
  
  const saveMarkAsDuplicate = (currentParentCase,description) => {
    setshowChangeMarkAsDuplicateDialog(false);
    let case_ref_no = incident.case_ref_no;
    dispatch(saveDRSafeMarkAsDuplicate({ 
      case_ref_no, currentParentCase, description 
    })).then (result => {
        console.log("duplicate RES-",result);
        if(result.payload.status == "success"){
          setActionMessage('Duplicated Successfully!');
          setshowAlert(true);
        }
        else{
          setActionMessage(result.payload.message);
          setshowAlert(true);
        }
        })
      .then(()=>{
        dispatch(fetchAllDRSafeIncidentDetails(case_ref_no))
    });
    
  };
  const [parentCasesList, setparentCasesList] = useState([]);
  console.log("Parent case List",parentCasesList);
  const { drSafeIncidentList, parentcaseloading } = useSelector(
    (state) => state.drSafeIncident
  );
  useEffect(() => {
    console.log("Dr Safe Incident List:", drSafeIncidentList);
    setparentCasesList(drSafeIncidentList)
  }, [drSafeIncidentList]);

  useEffect(() => {
    dispatch(fetchAllDRSafeIncidents());
  }, []);

// Mark as duuplicate 
// Share
  const handleShareBtnClicked = () => {
    setCurrentIncidentStatus(incident.status);
    setshowChangeShareDialog(true);
  };
  const saveShare=(emails) =>{
    setshowChangeShareDialog(false);
    let case_ref_no = incident.case_ref_no;
    console.log("dr safe Share:", case_ref_no, emails);
    dispatch(saveDRSafeIncidentShare({ case_ref_no, emails }))
      .then(result => {
        console.log("share RES- ",result);
        if (result.payload.status == "success"){
          setActionMessage('Submitted successfully!');
          setshowAlert(true);
        }
        else {
          setActionMessage(result.payload.message);
          setshowAlert(true);
        }
      })
      .catch(error => {setActionMessage('Share failed.');
    });
  };
// Share
  const saveRemarks = (description) => {
    let case_ref_no = incident.case_ref_no;
    console.log("dr safe saveRemarks:", case_ref_no, description);

    dispatch(saveDRSafeIncidentRemarks({ case_ref_no, description }));
  };

  const shouldShowBtnUpdateStatus = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, DR_SAFE_PERMISSIONS_FOR_UPDATE_STATUS)) {
      return true;
    } else {
      return false;
    }
  };

  const shouldShowBtnUpdate = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, DR_SAFE_PERMISSIONS_FOR_SUBMIT_UPDATE)) {
      return true;
    } else {
      return false;
    }
  };

  const shouldShowBtnRoute = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, DR_SAFE_PERMISSIONS_FOR_ROUTE)) {
      return true;
    } else {
      return false;
    }
  };

  const shouldShowBtnShare = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, DR_SAFE_PERMISSIONS_FOR_SHARE)) {
      return true;
    } else {
      return false;
    }
  };

  const shouldShowBtnMarkDuplicate = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, DR_SAFE_PERMISSIONS_FOR_MARK_DUPLICATE)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    console.log("Dr Safe Incident Details:", drSafeIncidentDetails);
    if (drSafeIncidentDetails) {
      console.log(drSafeIncidentDetails);
      setIncident(drSafeIncidentDetails[0]);
      setCurrentIncidentStatus(drSafeIncidentDetails.status);
      if(drSafeIncidentDetails.length>0){
        if(drSafeIncidentDetails[0].created_by.username === drSafeIncidentDetails[0].updated_by.username){
          dispatch(fetchUserById(drSafeIncidentDetails[0].created_by.username));
        }else{
          dispatch(fetchUserById(drSafeIncidentDetails[0].created_by.username));
          dispatch(fetchUserById(drSafeIncidentDetails[0].updated_by.username));
        }
      }      
    }
  }, [drSafeIncidentDetails]);

  useEffect(() => {
    if (drSafeIncidentDetails) {
      setUpdates(drSafeIncidentRemarks);
    }
  }, [drSafeIncidentRemarks]);

  useEffect(() => {
    console.log("Incident Details query:", query.get("case_ref_no"));
    if (query.get("case_ref_no")) {
      dispatch(fetchAllDRSafeIncidentDetails(query.get("case_ref_no")));
      dispatch(fetchAllDRSafeIncidentRemarks(query.get("case_ref_no")));
    }
  }, []);
  useEffect(()=>{
    if(user_details.username && incident.created_by){
      if(user_details.username === incident.created_by.username){
        setCreatedByDetails(user_details);
      }
      if(user_details.username === incident.updated_by.username){
        setUpdatedByDetails(user_details);
      }      
    }
  },[user_details]);
  return (
    <div className="dr-safe-details-wrapper">
      <div className="dr-safe-details-left">
      {showAlert && (
                <Dialog
                    className="alert-form"
                    title={"Alert"}                    
                    width={400}
                    // height={280}
                    onClose={()=>setshowAlert(false)}
                >
                    <p>{actionMessage}</p>
                </Dialog>
        )}
        <DrSafeDetailsContent
          loading={loading || user_details_loading}
          incident={incident}
          locations={locations}
          createdUser={createdByDetails}
          updatedUser={updatedByDetails}
        />
        {/* <hr className="dr-safe-details-content-details-hr"></hr> */}
        <DrSafeDetailsUpdates
          updates={updates}
          saveRemarks={saveRemarks}
          loading={remarks_loading}
          shouldShowBtnUpdate={shouldShowBtnUpdate()}
        />
      </div>
      <div className="dr-safe-details-right">
        <DrSafeDetailsStatus
          loading={loading}
          status={incident ? incident.status : ""}
        />
        <DrSafeDetailsActions
          case_ref_no={case_ref_no}    
          onToggleDialog={handleChangeIncidentStatusDialogClose}
          incidentStatuses={incidentStatuses}
          currentIncidentStatus={currentIncidentStatus}
          onChangeIncidentStatus={handleChangeIncidentStatus}
          onSaveIncidentStatus={handleSaveIncidentStatus}
          showChangeStatusDialog={showChangeStatusDialog}
          onUpdateStatusBtnClicked={handleUpdateStatusBtnClicked}
          showChangeMarkAsDuplicateDialog={showChangeMarkAsDuplicateDialog}
          onMarkAsDuplicateBtnClicked={handleMarkAsDuplicateBtnClicked}
          onSubmitMarkAsDuplicate={saveMarkAsDuplicate}
          parentCasesList={parentCasesList}
          parentcaseloading={parentcaseloading}
          showChangeShareDialog={showChangeShareDialog}
          onShareBtnClicked={handleShareBtnClicked}
          onSubmitShare={saveShare}
          // Route to Dept Obj
          showRouteToDepeDialog={showRouteToDepeDialog}
          onRouteToDepClose={()=>setShowRouteToDepDialog(false)}
          onRouteToDepOpen={()=>setShowRouteToDepDialog(true)}
          onRouteToDepAction={(res)=>handleRouteToDepAction(res)}   
          
          shouldShowBtnUpdateStatus={shouldShowBtnUpdateStatus()}
          shouldShowBtnRoute={shouldShowBtnRoute()}
          shouldShowBtnShare={shouldShowBtnShare()}
          shouldShowBtnMarkDuplicate={shouldShowBtnMarkDuplicate()}
        />
        <DrSafeDetailsAudits loading={loading} audits={audits} />
      </div>
    </div>
  );
};

export default DrSafeDetails;
