// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const { DR_SECURE_INCIDENT_ENDPOINT,DR_SECURE_GET_GROUPS } = config.api_endpoint;

const initialState = {
  loading: false,
  details_loading: false,
  remarks_loading: false,
  drSecureIncidentList: [],
  drSecureIncidentDetails: [],
  drSecureIncidentRemarks: [],
  error: null,
  details_error: null,
  remarks_error: null,
  route_groups: [],
  route_groups_loading: false,
  route_group_error: null,    
};

export const fetchAllDRSecureIncidents = createAsyncThunk(
  "dr_secure_incidents/fetchAllIncidents",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(`${DR_SECURE_INCIDENT_ENDPOINT}?submitter=all`, {}, token);
      let finalData = [];
      console.log("DR secure List Results:",results);
      for (let i = 0; i < results.data.length; i++) {
        let copied = results.data[i];
        for (const [key, value] of Object.entries(copied.status)) {
          copied["status"] = value;
        }
        finalData.push(copied);
      }
      return finalData;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllDRSecureIncidentDetails = createAsyncThunk(
  "dr_secure_incidents/fetchAllIncidentDetails",
  async (case_ref_no, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        `${DR_SECURE_INCIDENT_ENDPOINT}/${case_ref_no}`,
        {},
        token
      );
      let finalData = [];
      let copied = results.data
      for (const [key, value] of Object.entries(copied.status)) {
          copied["status"] = value;
        }
        finalData.push(copied);
      console.log("DrSecure Detail- ",finalData);
      return finalData;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const changeDRSecureIncidentStatus = createAsyncThunk(
  "dr_secure_incidents/changeIncidentStatus",
  async ({ case_ref_no, incidentStatus }, { rejectWithValue }) => {
    try {
      console.log("RefNo- ",case_ref_no,"Status- ",incidentStatus);
      const token = getCurrentLoggedInSessionToken();
      const data = {}
      const res = await api.post(
        `${DR_SECURE_INCIDENT_ENDPOINT}/${case_ref_no}/${incidentStatus}`,
        JSON.stringify(data),
        token
      );
      console.log("DR secure status update api result- ",res);
      return res;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllDRSecureIncidentRemarks = createAsyncThunk(
  "dr_secure_incidents/fetchAllIncidentRemarks",
  async (case_ref_no, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        `${DR_SECURE_INCIDENT_ENDPOINT}/${case_ref_no}/remarks`,
        {},
        token
      );
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const saveDRSecureIncidentRemarks = createAsyncThunk(
  "dr_secure_incidents/saveIncidentRemarks",
  async ({ case_ref_no, description }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const res = await api.post(
        `${DR_SECURE_INCIDENT_ENDPOINT}/${case_ref_no}/remarks`,
        JSON.stringify({
          description: description,
        }),
        token
      );
      const results = await api.get(
        `${DR_SECURE_INCIDENT_ENDPOINT}/${case_ref_no}/remarks`,
        {},
        token
      );
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const saveDRSecureMarkAsDuplicate = createAsyncThunk(
  "dr_secure_incidents/saveIncidentMarkAsDuplicate",
  async ({ case_ref_no, currentParentCase, description }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      console.log("Duplicate API request:",case_ref_no,' ',currentParentCase,' ',description)
      const results = await api.post(
        `${DR_SECURE_INCIDENT_ENDPOINT}/${case_ref_no}/duplicated`,
        JSON.stringify({
          parent_case_ref_no: currentParentCase,
          remark: description,
        }),
        token
      );
      console.log("Duplicated API result: ", results);
      return results;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

// SHARE
export const saveDRSecureIncidentShare = createAsyncThunk(
  "dr_secure_incidents/saveIncidentShare",
  async ({ case_ref_no, emails }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      let email_list=emails.split(",")
      console.log("Share Api call: ",email_list);
      const res = await api.post(
        `${DR_SECURE_INCIDENT_ENDPOINT}/${case_ref_no}/share`,
        JSON.stringify({
          emails: email_list,
        }),
        token
      );
      console.log("Share Result: ", res);
      return res;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const saveDRSecureIncidentGroup = createAsyncThunk(
  "dr_secure_incidents/saveDRSecureIncidentGroup",
  async ({ case_ref_no, group_id }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const res = await api.post(
        `${DR_SECURE_INCIDENT_ENDPOINT}/${case_ref_no}/route`,
        JSON.stringify({
          group_id: group_id,
        }),
        token
      );
      return res;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllDRSecureRouteGroups = createAsyncThunk(
  "dr_secure_incidents/fetchAllDRSecureRouteGroups",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(DR_SECURE_GET_GROUPS, {}, token);
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchDRSecureSearchResult = createAsyncThunk(
  "dr_secure_incidents/fetchDRSecureSearchResult",
  async ({caseRefNo, busRegNo, busService, incidentLocation, updatedBy}, { rejectWithValue }) => {
    try {
      console.log(caseRefNo, busRegNo, busService, incidentLocation, updatedBy);
      const token = getCurrentLoggedInSessionToken();
      let all="all";
      const results = await api.get(`${DR_SECURE_INCIDENT_ENDPOINT}`,{submitter:all,case_ref_no:caseRefNo,bus_reg_no:busRegNo,bus_service:busService,incident_location:incidentLocation,updated_by:updatedBy},token);
      console.log("Search API result ",results);
      let responseData = [];
      for (let i = 0; i < results.data.length; i++) {
        let copied = results.data[i];
        for (const [key, value] of Object.entries(copied.status)) {
          copied["status"] = value;
        }
        responseData.push(copied);
      }
      return responseData;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchDRSecureFilterResult = createAsyncThunk(
  "dr_secure_incidents/fetchDRSecureFilterResult",
  async ({incident_type, status, updateon_startdate, updateon_enddate}, { rejectWithValue }) => {
    try {
      console.log(incident_type, status, updateon_startdate, updateon_enddate);
      const token = getCurrentLoggedInSessionToken();
      let all="all";
      const results = await api.get(`${DR_SECURE_INCIDENT_ENDPOINT}`,{submitter:all,incident_type:incident_type,status:status,updated_on_start_date:updateon_startdate,updated_on_end_date:updateon_enddate},token);
      console.log("Search API result ",results);
      let responseData = [];
      for (let i = 0; i < results.data.length; i++) {
        let copied = results.data[i];
        for (const [key, value] of Object.entries(copied.status)) {
          copied["status"] = value;
        }
        responseData.push(copied);
      }
      return responseData;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchDRSecureSortResult = createAsyncThunk(
  "dr_safe_incidents/fetchDRSecureSortResult",
  async ({sortby,orderby}, { rejectWithValue }) => {
    try {
      console.log(sortby,orderby);
      const token = getCurrentLoggedInSessionToken();
      let all="all";
      const results = await api.get(`${DR_SECURE_INCIDENT_ENDPOINT}`,{submitter:all,sort_by:sortby,order:orderby},token);
      console.log("SortBy API result ",results)
      let responseData = [];
      for (let i = 0; i < results.data.length; i++) {
        let copied = results.data[i];
        for (const [key, value] of Object.entries(copied.status)) {
          copied["status"] = value;
        }
        responseData.push(copied);
      }
      return responseData;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);
const drSecureIncidentSlice = createSlice({
  name: "dr_secure_incidents",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDRSecureIncidents.pending, (state) => {
        console.log("fetch all dr secure incidents pending: ", state);
        state.loading = true;
      })
      .addCase(fetchAllDRSecureIncidents.fulfilled, (state, action) => {
        console.log("fetch all dr secure incidents fulfilled: ", state, action);
        state.loading = false;
        state.drSecureIncidentList = action.payload;
      })
      .addCase(fetchAllDRSecureIncidents.rejected, (state, action) => {
        console.log("fetch all dr secure incidents rejected: ", state, action);
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAllDRSecureIncidentDetails.pending, (state) => {
        console.log("fetch all dr secure incident details pending: ", state);
        state.details_loading = true;
      })
      .addCase(fetchAllDRSecureIncidentDetails.fulfilled, (state, action) => {
        console.log(
          "fetch all dr secure incident details fulfilled: ",
          state,
          action
        );
        state.details_loading = false;
        state.drSecureIncidentDetails = action.payload;
      })
      .addCase(fetchAllDRSecureIncidentDetails.rejected, (state, action) => {
        console.log(
          "fetch all dr secure incident details rejected: ",
          state,
          action
        );
        state.details_loading = false;
        state.details_error = action.error.message;
      })
      .addCase(fetchAllDRSecureIncidentRemarks.pending, (state) => {
        console.log("fetch all dr secure incident remarks pending: ", state);
        state.remarks_loading = true;
      })
      .addCase(fetchAllDRSecureIncidentRemarks.fulfilled, (state, action) => {
        console.log(
          "fetch all dr secure incident remarks fulfilled: ",
          state,
          action
        );
        state.remarks_loading = false;
        state.drSecureIncidentRemarks = action.payload;
      })
      .addCase(fetchAllDRSecureIncidentRemarks.rejected, (state, action) => {
        console.log(
          "fetch all dr secure incident remarks rejected: ",
          state,
          action
        );
        state.remarks_loading = false;
        state.remarks_error = action.error.message;
      })
      .addCase(saveDRSecureIncidentRemarks.pending, (state) => {
        console.log("save dr secure incident remarks pending: ", state);
        state.remarks_loading = true;
      })
      .addCase(saveDRSecureIncidentRemarks.fulfilled, (state, action) => {
        console.log(
          "save dr secure incident remarks fulfilled: ",
          state,
          action
        );
        state.remarks_loading = false;
        state.drSecureIncidentRemarks = action.payload;
      })
      .addCase(saveDRSecureIncidentRemarks.rejected, (state, action) => {
        console.log(
          "save dr secure incident remarks rejected: ",
          state,
          action
        );
        state.remarks_loading = false;
        state.remarks_error = action.error.message;
      })
      .addCase(saveDRSecureIncidentGroup.pending, (state) => {
        state.details_loading = true;
      })
      .addCase(saveDRSecureIncidentGroup.fulfilled, (state, action) => {
        state.details_loading = false;
        state.drSecureIncidentDetails = action.payload;
      })
      .addCase(saveDRSecureIncidentGroup.rejected, (state, action) => {
        state.details_loading = false;
        state.details_error = action.error.message;
      })
      .addCase(fetchAllDRSecureRouteGroups.pending, (state) => {
        state.route_groups_loading = true;
      })
      .addCase(fetchAllDRSecureRouteGroups.fulfilled, (state, action) => {
        state.route_groups_loading = false;
        state.route_groups = action.payload;
      })
      .addCase(fetchAllDRSecureRouteGroups.rejected, (state, action) => {
        state.route_groups_loading = false;
        state.route_group_error = action.error.message;
      })      
      .addCase(changeDRSecureIncidentStatus.pending, (state) => {
        console.log("change dr secure incident status pending: ", state);
        state.details_loading = true;
      })
      .addCase(changeDRSecureIncidentStatus.fulfilled, (state, action) => {
        console.log(
          "change dr secure incident status fulfilled: ",
          state,
          action
        );
        state.details_loading = false;
        state.drSecureIncidentDetails = action.payload;
      })
      .addCase(changeDRSecureIncidentStatus.rejected, (state, action) => {
        console.log(
          "change dr secure incident status rejected: ",
          state,
          action
        );
        state.details_loading = false;
        state.details_error = action.error.message;
      });
  },
});

const drSecureIncidentReducer = drSecureIncidentSlice.reducer;
export default drSecureIncidentReducer;
