import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import qs from "qs";
import {
  GetCategory,
  GetIdeas,
  GetMostLikedIdeas,
  GetTypeImprovement,
} from "../../../services/ideas";
import { useLocation, useNavigate } from "react-router";
import useBackListener from "../../../libs/hooks/use-back-history";

const MICDashboardContext = createContext();

export const TABS = {
  ALL: "All",
  MOST_LIKED: "Most Liked",
};

export const MICProvider = (props) => {
  const controller = useRef(new AbortController());
  const hasInit = useRef();
  const navigate = useNavigate();
  const params = useLocation();
  const [data, setData] = useState([]);
  const [paginateInfo, setPaginateInfo] = useState({
    currentPage: 0,
    lastPage: 0,
  });
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [category, setCategory] = useState([]);
  const [typeImprovement, setTypeImprovement] = useState([]);
  const [isFetchingMasterData, setIsFetchingMasterData] = useState(true);
  const [countPerPage, setCountPerPage] = useState([]);

  const { tab: tabActive = TABS.ALL } = qs.parse(params.search?.replace("?", ""));

  const queries = useMemo(() => {
    const queryParams = params?.search?.replace("?", "");

    return qs.parse(queryParams);
  }, [params]);

  const onBuildQuery = useCallback((payload) => {
    const newPayload = {
      ...queries,
      typeImprovement:
        payload?.typeImprovement?.join(",") ?? (queries.typeImprovement || ""),
      category,
      nextKey: payload?.nextKey ?? (queries.nextKey || ""),
      currentPage: payload?.currentPage ?? (queries.currentPage || ""),
      lastPage: payload?.lastPage ?? (queries.lastPage || ""),
      tab: payload?.tab ?? (queries?.tab || "")
    };

    if (tabActive) {
      newPayload["tab"] = tabActive;
    }

    const queryBuild = qs.stringify(newPayload);

    navigate(`/mic?${queryBuild}`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, queries, tabActive]);

  const onFilterQuery = (payload = {}) => setFilter(payload);

  const onSetPagesItem = (pageNumber, totalCount) => setCountPerPage(prev => {
    const newCount = prev ?? []
    if (!prev?.[pageNumber ?? prev?.currentPage + 1]) {
      return [...prev, totalCount]
    }
    
    newCount[pageNumber ?? prev?.currentPage + 1] = totalCount;

    return newCount
  })

  const getIdeas = useCallback(
    async (params = {}, pageNumber = null, cb) => {
      setLoading(true);
      console.log('GET IDEAS');
      const [err, response] = await GetIdeas(params, { signal: controller.current.signal });
  
      if (err) {
        // toast error
      }
  
      console.log('INIT');
      setData(response?.data);
      setPaginateInfo((prev) => ({
        ...prev,
        totalCount: response?.total_count,
        nextKey: response?.next_key,
        currentPage: pageNumber ?? 0,
      }));
  
      onBuildQuery({
        tab: tabActive ?? TABS.ALL,
        currentPage: pageNumber ?? 0,
        totalCount: response?.total_count,
        nextKey: response?.next_key,
      });

      onSetPagesItem(pageNumber, response?.total_count);
  
      if (typeof cb === "function") cb();
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabActive]
  );

  const getMostLikedIdeas = async (params = {}, cb) => {
    setLoading(true);
    const [err, response] = await GetMostLikedIdeas(params);

    if (err) {
      // toast error
    }

    setData(response?.data);
    setPaginateInfo((prev) => ({
      ...prev,
      totalCount: response?.total_count,
      nextKey: response?.next_key,
      currentPage: 0,
    }));

    if (typeof cb === "function") cb();
    setLoading(false);
  };

  const onSearch = async (creatorName = "") => {
    setLoading(true);
    const payload = {
      ...filter,
      created_by: creatorName,
    };

    setSearch(creatorName);
    onFilter(payload);
  };

  const onFetchByPage = async (pageNumber = null) => {
    if (pageNumber < paginateInfo.lastPage + 1) {
      const payload = { currentPage: pageNumber };

      onBuildQuery(payload);
      setPaginateInfo((prev) => ({
        ...prev,
        ...payload
      }));

      return;
    }
    setLoading(true);

    const query = {
      created_by: search,
      ...filter,
    };
    const payload = {
      ...query,
      next_key: paginateInfo?.nextKey,
    };

    console.log('Execute next page: ', payload);
    const [err, response] = await GetIdeas(payload, { signal: controller.current.signal });

    if (err) {
      // toast error
    }

    setData((prev) => [...prev, ...(response?.data ?? [])]);
    onBuildQuery({
      totalCount: response?.total_count,
      nextKey: response?.next_key,
      currentPage: pageNumber ?? paginateInfo?.currentPage + 1,
    });

    setPaginateInfo((prev) => ({
      totalCount: response?.total_count,
      nextKey: response?.next_key,
      currentPage: pageNumber ?? paginateInfo?.currentPage + 1,
      ...(pageNumber > prev?.currentPage
        ? { lastPage: pageNumber ?? prev?.currentPage + 1 }
        : { lastPage: prev?.lastPage }),
    }));

    onSetPagesItem(pageNumber, response?.total_count);
    setLoading(false);
  };

  const onChangePage = (newPage) => {
    setPaginateInfo((prev) => ({
      ...prev,
      currentPage: newPage,
    }));
  };

  const onBackPage = () => {
    if (paginateInfo?.currentPage > 0) {
      setPaginateInfo((prev) => ({
        ...prev,
        currentPage: prev?.currentPage - 1,
      }));
    }
  };

  const onFilter = async (payload = {}) => {
    const [err, response] = await GetIdeas(payload);

    if (err) {
      // toast error
    }
    setData(response?.data);
    setCountPerPage([response?.data?.length]);
    setPaginateInfo({
      pageNumber: 0,
      lastPage: 0,
      currentPage: 0,
      totalCount: response?.total_count,
      nextKey: response?.next_key ?? "",
    });

    setLoading(false);
  };

  const onClearData = async (search = null, filter = null) => {
    setLoading(true);
    const payload = {
      created_by: search,
      ...filter,
    };

    if (search === "") {
      delete payload.created_by;

      setSearch("");
    }
    if (filter === "") {
      delete payload.group;
      delete payload.status;

      setFilter({});
    }

    const [err, response] = await GetIdeas(payload);

    if (!err && response) {
      setData(response?.data ?? []);
      setCountPerPage([response?.data?.length]);
      setPaginateInfo((prev) => ({
        totalCount: response?.total_count,
        nextKey: response?.next_key,
        currentPage: 0,
        pageNumber: 0,
        lastPage: 0,
      }));
    }

    setLoading(false);
  };

  useEffect(() => {

    const getMasterDataFilter = async () => {
      const [err, response] = await GetCategory();
      const [errImprovement, responseImprovement] = await GetTypeImprovement();

      if (!err) {
        setCategory(response?.data);
      }

      if (!errImprovement) setTypeImprovement(responseImprovement?.data);

      setIsFetchingMasterData(false);
    };

    getMasterDataFilter();

    if (!hasInit.current && params.pathname === '/mic') {
      if (tabActive === TABS.MOST_LIKED) {
        getMostLikedIdeas({}, () => {
          hasInit.current = true;
        });
      } else {
        getIdeas({}, null, () => {
          hasInit.current = true;
        });
      }
    }

    // return () => {
    //   controller.current.abort();
    // }
  }, [getIdeas, hasInit, params.pathname, tabActive]);

  // Cancelled all on going request while user move to other page
  useBackListener(() => controller.current.abort(), params.pathname);

  const onTabChange = (tab) => {
    navigate(`/mic?tab=${tab}`);
    if (tab === TABS.ALL) getIdeas();
    else getMostLikedIdeas();
  };
  
  const totalPrevItem = useMemo(
    () => {
      const total = countPerPage
        .filter((_, idx) => idx < paginateInfo?.currentPage)
        .reduce((prev, curr) => +prev + +curr, 0)

      return total === 0 ? 0 : total;
    },
    [countPerPage, paginateInfo?.currentPage]
  )

  const collectData = useMemo(() => {
    if (tabActive === TABS.MOST_LIKED) return data;

    const initialCountPage = countPerPage?.[0] || 10;
    const minPage = paginateInfo?.currentPage === 0 ? 0 : totalPrevItem;
    const maxPage = paginateInfo?.currentPage === 0 ? initialCountPage : totalPrevItem + countPerPage[paginateInfo?.currentPage];

    console.log('[DEBUG]: ', data, minPage, maxPage, countPerPage, paginateInfo);
    return data?.slice(
      minPage,
      maxPage
    );
  }, [tabActive, data, countPerPage, paginateInfo, totalPrevItem]);

  const contextValues = useMemo(
    () => {
      return {
        tab: tabActive,
        data: collectData,
        search,
        filter,
        paginateInfo,
        isShowFilterSearch: queries?.tab !== TABS.MOST_LIKED,
        masterData: {
          category,
          isFetching: isFetchingMasterData,
          typeImprovement,
        },
        isLoading,
        actions: {
          onTabChange,
          getIdeas,
          onSearch,
          onFetchByPage,
          onChangePage,
          onBackPage,
          onFilter,
          onFilterQuery,
          onClearData,
          onSetLoading: setLoading,
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data,
      tabActive,
      isLoading,
      isFetchingMasterData,
      category,
      paginateInfo,
      typeImprovement,
    ]
  );

  return (
    <MICDashboardContext.Provider value={contextValues}>
      {props?.children}
    </MICDashboardContext.Provider>
  );
};

export const useMICContext = () => useContext(MICDashboardContext);

export const useMICList = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const getIdeas = async () => {
    setLoading(true);

    const [err, response] = await GetIdeas();

    if (err) {
      // toast error
    }
    setData(response?.data);
    setLoading(false);
  };

  useEffect(() => {
    getIdeas();
  }, []);

  return { data, isLoading, onRefresh: getIdeas };
};

export const useMICDetails = () => {};
