// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const { CHANNELS_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: false,
  channel_ids: [],
  error: null,
};

export const fetchAllChannels = createAsyncThunk(
  "channel/fetchAllChannels",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(CHANNELS_ENDPOINT, {}, token);
      // console.log(results);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const ChannelSlice = createSlice({
  name: "channel",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllChannels.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllChannels.fulfilled, (state, action) => {
        // console.log(action);
        state.loading = false;
        state.channel_ids = action.payload;
      })
      .addCase(fetchAllChannels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const ChannelReducer = ChannelSlice.reducer;
export default ChannelReducer;
