// kendo
import { Loader } from "@progress/kendo-react-indicators";
// components
import DrStepper from "../../../../components/drStepper/dr_stepper";

const DrInfraDetailsAudits = ({ loading, audits }) => {
  return (
    <div className="dr-infra-details-audits-wrapper">
      <div className="dr-infra-details-audits-title">Activity Trail</div>

      {loading ? (
        <Loader
          type="infinite-spinner"
          className="dr-infra-audits-status-loader"
        />
      ) : audits.length > 0 ? (
        <DrStepper data={audits} />
      ) : (
        <p>No available audits.</p>
      )}
    </div>
  );
};

export default DrInfraDetailsAudits;
