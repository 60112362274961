import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import { TableHeader } from "../../components/table/Tableheader";
import { TableBody } from "../../components/table/TableBody";
import "./dashboard.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { NOTIFICATION_RECALL_ROUTE } from "../../routes";
import ComingSoon from "../../assets/dashboard/Dahboard_coming_soon.svg";

export const Dashboard = () => {
  const navigate = useNavigate();
  const onItemActionChange = (e, val) => {
    const { id } = val.dataItem;
    navigate(`${NOTIFICATION_RECALL_ROUTE}?id=${id}`);
  };

  const HEADER_DATA = useMemo(
    () => [
      {
        field: "publish_on",
        title: "Scheduled / Published On",
        style: {
          width: 200,
        },
      },
      {
        field: "category_name",
        title: "Category",
      },
      {
        field: "title_en",
        title: "Title",
      },
      {
        field: "ack_required",
        title: "Ack Required",
        cell: (props) => (
          <td style={{ textAlign: "center" }}>
            {props.dataItem.ack_required ? "Yes" : "No"}
          </td>
        ),
      },
      {
        field: "-",
        title: "Total Recipients",
      },
      {
        field: "created_by.name",
        title: "Created By",
      },
      {
        field: "created_on",
        title: "Created On",
      },
      {
        field: "status",
        title: "Status",
      },
      {
        field: "Action",
        style: { width: 160 },
        cell: (props) => (
          <td>
            <DropDownList
              style={{
                width: "140px",
                height: "48px",
                backgroundColor: "#EB6E1F",
                color: "white",
                textAlign: "center",
                borderRadius: "8px",
              }}
              defaultValue={"Action"}
              data={["Recall Message", "Archieved"]}
              onChange={(e) => onItemActionChange(e, props)}
            />
          </td>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="dashboard_main_box">
      {/* <TableHeader
        title = "Welcome to iLink Admin Portal"
      />
      <TableBody table={HEADER_DATA}/> */}
      {/* <h1 style={{textAlign:"center"}}>Welcome to iLink Admin Portal</h1> */}
      <div className="dashboard_title_box">
        <div className="dashboard_main_text">Welcome to iLink Admin Portal</div>
        <div className="dashboard_main_text">
          Watch this space for upcoming features in 2024
        </div>
      </div>
      <div>
        <img src={ComingSoon} />
      </div>
    </div>
  );
};
