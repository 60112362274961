// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { DESIGNATION_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: false,
  designations: [],
  error: null,
};

export const fetchAllDesignations = createAsyncThunk(
  "depot/fetchAllDesignations",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(DESIGNATION_ENDPOINT, {}, token);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const DesignationSlice = createSlice({
  name: "depot",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDesignations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllDesignations.fulfilled, (state, action) => {
        // console.log(action);
        state.loading = false;
        state.designations = action.payload;
      })
      .addCase(fetchAllDesignations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const DesignationReducer = DesignationSlice.reducer;
export default DesignationReducer;
