import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCategories } from "../../store/category";
// kendo
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox, Input, RadioButton } from "@progress/kendo-react-inputs";
import { Loader } from "@progress/kendo-react-indicators";
import { MultiSelect } from "@progress/kendo-react-dropdowns";

import "./notification.css";

export const FilterRole = (props) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  // redux
  const {
    category_ids,
    loading: category_loading,
    error: category_error,
  } = useSelector((state) => state.category);

  // state
  const [loading, setLoading] = useState(true);
  const [allCategories, setAllCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([]);

  // methods
  const handleClearFilter = () => {
    setSelectedCategory([]);
  };

  const onSubmitFilter = () => {
    let data = {
      category: selectedCategory,
    };
    console.log("onSubmitFilter:", data);
    props.onFiltercallback(data);
  };

  const handleCategoryDropdownChanged = (e) => {
    setSelectedCategory(e.target.value.slice(0, 3));
  };

  // useEffect
  useEffect(() => {
    if (category_ids) {
      let all_categories = ["All"];
      category_ids.filter((x) => all_categories.push(x.name));
      setAllCategories(all_categories);

      setLoading(false);
    }
  }, [category_ids]);

  useEffect(() => {
    if (query.toString()) {
      if (query.get("category")) {
        const category = query.get("category");
        console.log("filter category:", category);
        if (category) {
          setSelectedCategory(category.split(","));
        }
      }
    }
    dispatch(fetchAllCategories());
  }, []);

  return (
    <>
      <Dialog
        className="search-notification"
        title={" "}
        width={395}
        height={"100%"}
        onClose={() => props.setShowFilterDialog(false)}
      >
        {category_loading || loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <div className="search-body">
            <div className="filter-title">
              <div className="title">Filter</div>
              <div className="clear-filter" onClick={handleClearFilter}>
                <u>Clear Filter</u>
              </div>
            </div>
            <div className="filter-body">
              <p className="filter-sub-title">Category</p>
              <div>
                <div className="user-filter-select-multi">
                  <MultiSelect
                    className="user-filter-dropdown-multi"
                    style={{ width: "100%" }}
                    data={allCategories}
                    onChange={handleCategoryDropdownChanged}
                    value={selectedCategory}
                    placeholder="Select (max 3 items)"
                    disabled={category_loading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <DialogActionsBar>
          <Button
            className="search-button cancel-btn"
            onClick={() => props.setShowFilterDialog(false)}
          >
            Cancel
          </Button>
          <Button
            className="search-button search-btn"
            onClick={() => onSubmitFilter()}
            disabled={category_loading}
          >
            Submit
          </Button>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};
