import { useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { CSVLink } from "react-csv";

import ExportIcon from "../../assets/header/export.svg";
import { useEffect } from "react";

const Component = (props) => {
  const ref = useRef();
  const exportData = props?.data ?? [];
  const loading = props?.loading ?? false;
  const filename = props?.filename ?? "default-filename";

  useEffect(() => {
    if (ref.current && exportData.length) {
      ref.current.link.click();
    }
  }, [exportData.length, ref])

  const onClick = () => {
    if (typeof props?.onClick === 'function') {
      props?.onClick();
    }
  }

  return (
    <>
      <CSVLink
        uFEFF={false}
        asyncOnClick={true}
        data={exportData}
        filename={filename}
        ref={ref}
        hidden
      >
        Download
      </CSVLink>
      <Button
        disabled={loading}
        onClick={onClick}
        className="button2">
        <img src={ExportIcon} alt="copy-icon" />
      </Button>
    </>
  )
}

export default Component;
