import React, { useEffect, useState, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  GridColumn as Column,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { SelectUserGroups } from "./user_group";
import { SelectUsers } from "./select_users";
import { UploadExcels } from "./upload_excel";
import { RadioButton } from "@progress/kendo-react-inputs";
import { Icon } from "@progress/kendo-react-common";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import NextIcon from "../../../../assets/communication/NextIcon.svg";
import { Loader, Skeleton } from "@progress/kendo-react-indicators";
import api from "../../../../config/api";
import config from "../../../../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../../../config/userPool";
import {
  fetchNotificationById,
  fetchNotificationRecipients,
  getCommRecipientsFile
} from "../../../../store/notification";
import {
  fetchAllCommUsers,
  fetchAllCommUsersByKey,
} from "../../../../store/commUserList";
import moment from "moment";
const { NOTIFICATION_RECIPIENT_ENDPOINT, NOTIFICATION_ENDPOINT } =
  config.api_endpoint;

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const distribution = [
  {
    name: "Select User Group",
    value: "user_group",
  },
  {
    name: "Select User(s)",
    value: "users",
  },
  {
    name: "Upload Excel",
    value: "upload_excel",
  },
];
export const RecipientsForm = (props) => {
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [selected_dist, setSelected_dist] = useState("user_group");
  const [users, setUsers] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [autoSaveOn, setAutoSaveOn] = useState(false);
  const [selectedLoading, setSelectedLoading] = useState(false);
  const [textLoadin, setTextLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [skip, setSkip] = useState(0);
  const [sort, setSort] = React.useState([
    {
      field: "name",
      dir: "asc",
    },
  ]);
  const [dataState, setDataState] = React.useState(
    users.map((dataItem) => {
      let tmp = { selected: false };
      for (let i in dataItem) {
        tmp[i.toLowerCase()] = dataItem[i];
      }
      return tmp;
    })
  );
  const pageChange = (event) => {
    setSkip(event.page.skip);
  };
  const [selectedState, setSelectedState] = React.useState({});
  // const { notification_data, ndata_loading, ndata_error } = useSelector(
  //   (state) => state.notification
  // );
  const {
    users: comm_users,
    users2: comm_users_key,
    loading: commUsersLoading,
    loading2: commUsersKeyLoading,
  } = useSelector((state) => state.commUser);
  const [notification_data, setNotificationData] = useState();

  const notification__id = query.get("id");
  const copyId = query.get("copyId", false);

  // useRef value to check autoSaveOn
  // to save the data if autoSaveOn is true when component unloads
  const autoSaveOnRef = useRef();
  autoSaveOnRef.current = autoSaveOn;
  const recipientsRef = useRef({});
  const notificationDataRef = useRef({});
  const selectedStateRef = useRef({});

  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  const saveData = (
    notification_id,
    notification_data,
    users,
    selectedState,
    addLoading
  ) => {
    let user_ids = users.filter(
      (v) => v.id in selectedState && selectedState[v.id] === true
    );
    user_ids = user_ids.map((v) => {
      return {
        id: v.id,
        name: v.name,
        depot: v.depot || v.department,
        interchange: v.interchange,
        designation: v.designation,
        file1: v.file1,
        file2: v.file2,
        file3: v.file3
      };
    });
    console.log("Selected uids", user_ids);
    let payload = { ...notification_data, recipient: user_ids };
    if (notification_id) {
      payload.id = notification_id;
    }
    console.log("Payload in saveData:", payload);
    props.callbackAutoSave(payload, addLoading);
  };

  const prepareAPIData = (param) => {
    let data = JSON.parse(JSON.stringify(param));
    console.log("prepareAPIData:", data);
    data.home_banner_image = data.home_banner_image
      ? data.home_banner_image.map((v) => ({
          name: v.name,
          url: v.url,
        }))
      : [];
    data.splash_image = data.splash_image
      ? data.splash_image.map((v) => ({
          name: v.name,
          url: v.url,
        }))
      : [];
    data.message_image = data.message_image
      ? data.message_image.map((v) => ({
          name: v.name,
          url: v.url,
        }))
      : [];
    data.message_video = data.message_video
      ? data.message_video.map((v) => ({
          name: v.name,
          url: v.url,
        }))
      : [];
    data.popup_image = data.popup_image
      ? data.popup_image.map((v) => ({
          name: v.name,
          url: v.url,
        }))
      : [];
    data.message_attachment = data.message_attachment
      ? data.message_attachment.map((v) => ({
          name: v.name,
          url: v.url,
        }))
      : [];
    return data;
  };

  useEffect(() => {
    localStorage.removeItem("inbox_save_clicked");
    props.setLoading(true);
    return () => {
      /* // temporary block */
      // when the component is unloaded & autoSaveOn is true, save to backend
      // cos between each setInterval of timer, user can go to anther page and data will be lost
      let noti_data = prepareAPIData(notificationDataRef.current);
      console.log("DISTRIBUTION FORM UNLOADED.");
      console.log("autoSaveOnRef:", autoSaveOnRef.current);
      console.log("recipientsRef.current:", recipientsRef.current);
      console.log("notificationDataRef.current:", noti_data);
      console.log("selectedStateRef.current:", selectedStateRef.current);

      let flagFromLocal = localStorage.getItem("inbox_save_clicked");
      if (autoSaveOnRef.current === true && flagFromLocal === null) {
        saveData(
          notification__id,
          noti_data,
          recipientsRef.current,
          selectedStateRef.current,
          true
        );
        console.log("saved data before unload.");
      }
      if (flagFromLocal) {
        localStorage.removeItem("inbox_save_clicked");
      }
      /* temporary block  */
    };
  }, []);

  useEffect(() => {
    if (!props.autoSaveInProgress) {
      if (copyId) {
        load_data(copyId);
        // dispatch(fetchNotificationById(copyId));
      } else if (notification__id) {
        load_data(notification__id);
        // dispatch(fetchNotificationById(notification__id));
      }
    }
  }, [props.autoSaveInProgress]);
  /* // temporary block */
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(timer + 1);
      if (autoSaveOnRef.current) {
        console.log(moment().format());
        let users = recipientsRef.current;
        let update_body = prepareAPIData(notificationDataRef.current);
        let selectedState = selectedStateRef.current;
        saveData(notification__id, update_body, users, selectedState);
      }
    }, 20000);
    return () => clearInterval(interval);
  }, [timer]);
  /* temporary block */
  const load_data = async (recId) => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    await api
      .get(`${NOTIFICATION_ENDPOINT}/${recId}`, {}, token)
      .then((res) => {
        if (res.status === "success") {
          const data = res.data;
          setNotificationData(data);
          notificationDataRef.current = data;
          if(data.channel.length == 1 && data.channel.some(e=>e.id =="SplashScreen")){
            props.setIsSplashOnly(true);
          }
          let result = res.data.recipient.map((v) => ({
            id: v.id,
            name: v.name,
            depot: v.depot || v.department,
            interchange: v.interchange,
            designation: v.designation,
            file1: v.file1,
            file2: v.file2,
            file3: v.file3
          }));
          setUsers(result);
          recipientsRef.current = result;
          setDataState(
            result.map((dataItem) => {
              let tmp = { selected: false };
              for (let i in dataItem) {
                tmp[i.toLowerCase()] = dataItem[i];
              }
              return tmp;
            })
          );
          const newSelectedState = {};
          result.forEach((item) => {
            newSelectedState[idGetter(item)] = true;
          });
          setSelectedState(newSelectedState);
          selectedStateRef.current = newSelectedState;
        }
        props.setLoading(false);
      });

    // await api
    //   .get(`${NOTIFICATION_RECIPIENT_ENDPOINT}/${recId}`, {}, token)
    //   .then((res) => {
    //     console.log(res);
    //     if (res.status === "success") {
    //       let result = res.data.map((v) => ({
    //         sap: v.sap_no,
    //         name: v.name,
    //         depot: v.depot || v.department,
    //         interchange: v.interchange,
    //         designation: v.designation,
    //       }));
    //       setUsers(result);
    //       recipientsRef.current = result;
    //       setDataState(
    //         result.map((dataItem) => {
    //           let tmp = { selected: false };
    //           for (let i in dataItem) {
    //             tmp[i.toLowerCase()] = dataItem[i];
    //           }
    //           return tmp;
    //         })
    //       );
    //       const newSelectedState = {};
    //       result.forEach((item) => {
    //         newSelectedState[idGetter(item)] = true;
    //       });
    //       setSelectedState(newSelectedState);
    //       selectedStateRef.current = newSelectedState;
    //     }
    //     props.setLoading(false);
    //   });
  };
  const GetUpdate_body = () => {
    return {
      title_en: notification_data.title_en,
      title_zh: notification_data.title_zh,
      message_en: notification_data.message_en,
      message_zh: notification_data.message_zh,
      start_date: notification_data.start_date,
      start_time: notification_data.start_time,
      end_date: notification_data.end_date,
      end_time: notification_data.end_time,
      channel: notification_data.channel,
      high_priority_flag: notification_data.high_priority_flag,
      status: "draft",
      link_to: notification_data.link_to,
      link_to_type: notification_data.link_to_type,
      category_id: notification_data.category_id,
      depot: notification_data.depot,
      division: notification_data.division,
      interchange: notification_data.interchange,
      home_banner_image: notification_data.home_banner_image
        ? notification_data.home_banner_image.map((v) => ({
            url: v.url,
            name: v.name,
          }))
        : [],
      splash_image: notification_data.splash_image
        ? notification_data.splash_image.map((v) => ({
            url: v.url,
            name: v.name,
          }))
        : [],
      message_image: notification_data.message_image
        ? notification_data.message_image.map((v) => ({
            url: v.url,
            name: v.name,
          }))
        : [],
      message_video: notification_data.message_video
        ? notification_data.message_video.map((v) => ({
            name: v.name,
            url: v.url,
          }))
        : [],
      popup_image: notification_data.popup_image
        ? notification_data.popup_image.map((v) => ({
            url: v.url,
            name: v.name,
          }))
        : [],
      message_attachment: notification_data.message_attachment
        ? notification_data.message_attachment.map((v) => ({
            url: v.url,
            name: v.name,
          }))
        : [],
    };
  };
  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      console.log("change1");
      setSelectedState(newSelectedState);
      selectedStateRef.current = newSelectedState;
    },
    [selectedState]
  );
  const onHeaderSelectionChange = React.useCallback((event) => {
    console.log("changeall");
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
    selectedStateRef.current = newSelectedState;
    setSelectedLoading(false);
    console.log("onHeaderSelectionChange newSelectedState:", newSelectedState);
  }, []);
  const handleDistChange = (e) => {
    setSelected_dist(e.value);
    setAutoSaveOn(true);
    autoSaveOnRef.current = true;
    setSelectedState({});
    selectedStateRef.current = {};
    setUsers([]);
    recipientsRef.current = [];
    setDataState([]);
  };
  const onSubmitRecipients = (e, isSave) => {
    setDisabled(true);
    localStorage.setItem("inbox_save_clicked", true);
    props.setLoading(true);
    console.log("onSubmitRecipients users:", users);
    let user_ids = users.filter(
      (v) =>
        (v.id.toString() in selectedState &&
          selectedState[v.id.toString()] === true) ||
        (parseInt(v.id) in selectedState &&
          selectedState[parseInt(v.id)] === true)
    );
    console.log("onSubmitRecipients before user_ids:", user_ids);
    user_ids = user_ids.map((v) => {
      return {
        id: v.id,
        name: v.name,
        depot: v.depot || v.department,
        interchange: v.interchange,
        designation: v.designation,
        file1: v.file1,
        file2: v.file2,
        file3: v.file3
      };
    });
    console.log("onSubmitRecipients Selected uids", user_ids);
    let update_body = GetUpdate_body();
    update_body = { ...update_body, recipient: user_ids };
    if (notification__id) {
      update_body.id = notification__id;
    }
    console.log("onSubmitRecipients update:", update_body);
    props.setLoading(false);
    props.submitcallback(update_body, isSave);
  };
  const onChangecallback = async (data) => {
    console.log("onChangecallback:", data);
    let copiedData = [];
    data.map((dataItem) => {
      let tmp = {};
      for (let i in dataItem) {
        if (i.toLowerCase() === "sap") {
          tmp["id"] = dataItem[i];
        } else if (i.toLowerCase() === "department") {
          tmp["depot"] = dataItem[i];
        } else {
          tmp[i.toLowerCase()] = dataItem[i];
        }
      }
      copiedData.push(tmp);
    });
    setAutoSaveOn(true);
    autoSaveOnRef.current = true;
    setSelectedState({});
    selectedStateRef.current = {};
    setUsers(copiedData);
    recipientsRef.current = copiedData;
    console.log("copiedData:", copiedData);
    setDataState(
      copiedData.map((dataItem) => Object.assign({ selected: false }, dataItem))
    );
  };

  const [userGroupFilters, setUserGroupFilters] = useState({});
  const [userFilters, setUserFilters] = useState({});

  const handleResetUserList = () => {
    onChangecallback([]);
  };

  const handleUserGroupFilters = (filters) => {
    let copiedFilters = { ...userGroupFilters };
    console.log("filters:", filters);
    if (Object.keys(filters).length === 0) {
      setUserGroupFilters({});
    } else {
      for (let key in filters) {
        copiedFilters[key] = filters[key];
      }
      setUserGroupFilters(copiedFilters);
    }
  };

  const handleUserFilters = (filters) => {
    let copiedFilters = { ...userFilters };
    console.log("filters:", filters);
    for (let key in filters) {
      copiedFilters[key] = filters[key];
    }
    setUserFilters(copiedFilters);
  };

  useEffect(() => {
    console.log("user group filters:", userGroupFilters);
    if (
      userGroupFilters.interchange &&
      userGroupFilters.interchange.length > 0
    ) {
      dispatch(fetchAllCommUsers({ ...userGroupFilters, type: "user_group" }));
    } else if (
      userGroupFilters.interchange &&
      userGroupFilters.interchange.length === 0
    ) {
      onChangecallback([]);
    }
  }, [userGroupFilters]);

  useEffect(() => {
    if (userFilters.search_key) {
      console.log("user filters:", userFilters);
      dispatch(fetchAllCommUsersByKey({ ...userFilters, type: "user" }));
    }
  }, [userFilters]);

  useEffect(() => {
    console.log(
      "CHECK:",
      comm_users,
      userGroupFilters,
      comm_users &&
        userGroupFilters.interchange &&
        userGroupFilters.interchange.length > 0
    );
    if (
      comm_users &&
      userGroupFilters.interchange &&
      userGroupFilters.interchange.length > 0
    ) {
      console.log("comm users:", comm_users);
      setSelectedState({});
      selectedStateRef.current = {};
      setUsers([]);
      recipientsRef.current = [];
      setDataState([]);
      onChangecallback(comm_users);
    }
  }, [comm_users, userGroupFilters]);

  useEffect(() => {
    console.log(
      "CHECK:",
      comm_users_key,
      userFilters,
      comm_users_key && userFilters.search_key,
      commUsersKeyLoading
    );
    if (
      (comm_users_key && userFilters.search_key) ||
      (!comm_users_key && userFilters.search_key)
    ) {
      console.log("comm users:", comm_users_key);
      setSelectedState({});
      selectedStateRef.current = {};
      setUsers([]);
      recipientsRef.current = [];
      setDataState([]);
      if (comm_users_key) {
        onChangecallback(comm_users_key);
      }
    }
  }, [comm_users_key, userFilters]);

  // const loadingCell = (tdElement, props) => {
  //   const field = props.field || "";
  //   if (props.dataItem[field] === undefined) {
  //     // shows loading cell if no data
  //     return (
  //       <td>
  //         {" "}
  //         <Skeleton
  //           shape={"text"}
  //           style={{
  //             width: "100%",
  //           }}
  //         />
  //       </td>
  //     );
  //   } // default rendering for this cell

  //   return tdElement;
  // };
  // if (document.getElementsByClassName("k-checkbox-label")[0] !== undefined) {
  //   document.getElementsByClassName("k-checkbox-label")[0].textContent =
  //     "Select All";
  // }
  // const mouseOver = () => {
  //   console.log("call");
  // };
  // const mouseout = () => {
  //   console.log("out");
  // };
  // if (document.getElementsByClassName("k-checkbox")[0] !== undefined) {
  //   document
  //     .getElementsByClassName("k-checkbox")[0]
  //     .addEventListener("mouseover", mouseOver);
  //   document
  //     .getElementsByClassName("k-checkbox")[0]
  //     .addEventListener("mouseout", mouseout);
  // }
  const onClickOpenFile = (key)=>{
    console.log(key);
    const data = { key };
    dispatch(getCommRecipientsFile(data)).then((res)=>{
      console.log(res.payload.data);
      if(res.payload.data){
        window.open(res.payload.data);
      }
    })
  };
  const customDateCell = (props) => {
    if (props.dataItem[props.field] !== "") {
      return (
        <td style={{background: "transparent"}}>
          <span style={{textDecoration: "underline",fontWeight:700,color: "#781D7E"}} onClick={()=> onClickOpenFile(props.dataItem[props.field])}>{props.dataItem[props.field]}</span>
        </td>
      );
    }
    return <td style={{background: "transparent"}}>{props.dataItem[props.field]}</td>;
  };
  return (
    <>
      {props.loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div>
          <p className="distribution-title">Distribution</p>
          <ul className="distribution-list">
            {distribution.map((val) => (
              <li>
                <RadioButton
                  className="distribution-radio"
                  key={val.value}
                  label={val.name}
                  name="distribution"
                  value={val.value}
                  onChange={handleDistChange}
                  checked={selected_dist === val.value}
                />
              </li>
            ))}
          </ul>
          {selected_dist == "user_group" && (
            <SelectUserGroups
              onFilter={handleUserGroupFilters}
              resetUserList={handleResetUserList}
            />
          )}
          {selected_dist == "users" && (
            <SelectUsers onFilter={handleUserFilters} />
          )}
          {selected_dist == "upload_excel" && (
            <UploadExcels
              onChangeFile={(data) => onChangecallback(data)}
              setLoading={setFileUploadLoading}
            />
          )}
          {/* <div>*Please Select a Maximum of 4000 Records</div> */}
          <div>
            {(commUsersLoading ||
              commUsersKeyLoading ||
              fileUploadLoading ||
              selectedLoading) && (
              <div className="k-loading-mask">
                <span className="k-loading-text">Loading</span>
                <div className="k-loading-image"></div>
                <div className="k-loading-color"></div>
              </div>
            )}

            <div>Select All</div>
            <Grid
              data={
                dataState
                  ? dataState.map((item) => ({
                      ...item,
                      [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))
                  : []
              }
              style={{
                height: "300px",
              }}
              className="Scroll"
              dataItemKey={DATA_ITEM_KEY}
              selectedField={SELECTED_FIELD}
              selectable={{
                enabled: true,
                drag: false,
                cell: true,
                mode: "multiple",
              }}
              // pageSize={25}
              total={dataState.length}
              // skip={skip}
              // scrollable={"virtual"}
              // onPageChange={pageChange}
              pageable
              // cellRender={loadingCell}
              // sortable={true}
              onSelectionChange={onSelectionChange}
              onHeaderSelectionChange={onHeaderSelectionChange}
            >
              <Column
                field={SELECTED_FIELD}
                width="100px"
                headerSelectionValue={
                  dataState.length === 0
                    ? false
                    : dataState.findIndex(
                        (item) => !selectedState[idGetter(item)]
                      ) === -1
                }
              />
              <Column field="id" title="SAP No" />
              <Column field="name" title="Name" />
              {/* <Column field="division" title="Division" /> */}
              <Column field="depot" title="Department/Depot" />
              <Column field="interchange" title="Section/Interchange Group" />
              <Column field="designation" title="Designation" />
              {/*<Column field="leadership" title="Leadership Role" />
              <Column field="citizenship" title="Citizenship" />
              <Column field="nationality" title="Nationality" /> */}
              <Column field="file1" title="Attachment 1"  cell={customDateCell}/>
              <Column field="file2" title="Attachment 2"  cell={customDateCell}/>
              <Column field="file3" title="Attachment 3" cell={customDateCell}/>
            </Grid>
          </div>
          <div className="footer no-pad-left">
            <div className="footer-left-btn-groups">
              <Button
                className="footer-previous-btn font-family-poppins"
                onClick={props.backBtncallback}
              >
                Previous
              </Button>
              <Button
                className="footer-save-btn"
                onClick={(e) => onSubmitRecipients(e, true)}
                disabled={disabled}
              >
                Save
              </Button>
            </div>
            <div className="footer-right">
              <Button
                className="footer-next-btn"
                onClick={(e) => onSubmitRecipients(e, false)}
                disabled={disabled}
              >
                Next
                <Icon>
                  <img src={NextIcon} />
                </Icon>
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
