import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import "../communication.css";
import { AddNotificationHeader } from "./add_header";
import { AddNotificationSteps } from "./add_steps";
import { InformationForm } from "./information/information";
import { RecipientsForm } from "./recipients/recipients";
import { AttachmentForm } from "./attachment/attachment";
import { ReviewForm } from "./review/review";
import { INBOX_ROUTE, ADD_NOTIFICATION_INFORMATION } from "../../../routes";
import api from "../../../config/api";
import config from "../../../config/config";
import { getCurrentLoggedInSessionToken } from "../../../config/userPool";
import { Dialog } from "@progress/kendo-react-dialogs";
import moment from "moment";
import { updateNotification } from "../../../store/notification";
import { useDispatch, useSelector } from "react-redux";

const { NOTIFICATION_ENDPOINT } = config.api_endpoint;

export const AddNotification = () => {
  const navigate = useNavigate();
  let { search } = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(search);
  const [selected_tab, setSelectedTab] = useState(
    parseInt(query.get("state", 0))
  );
  const [timer, setTimer] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isSplashOnly, setIsSplashOnly] = useState(false);
  const [errors, setErrors] = useState(false);
  const [timeChange, setTimeChange] = useState(false);
  const [timeChangeData, setTimeChangeData] = useState(null);
  const [dateChange, setDateChange] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [showError, setShowError] = useState(false);
  const { ndata_loading, ndata_error, notification_data } = useSelector(
    (state) => state.notification
  );

  const backtoInformation = () => {
    query.set("state", 0);
    setSelectedTab(0);
    navigate(ADD_NOTIFICATION_INFORMATION + "?" + query.toString());
    setTimeChange(false);
    setDateChange(false);
  };

  const callbackMessageSubmit = (data, isSave) => {
    const token = getCurrentLoggedInSessionToken();
    const { channel } = data;
    dispatch(updateNotification(data)).then((res) => {
      const { payload } = res;
      if (payload.status === "success") {
        const { id } = payload.data;
        let is_skip = false;
        let tab = parseInt(selected_tab);
        if (
          channel.map((a) => a.id).includes("SplashScreen") &&
          channel.length === 1
        ) {
          tab += 2;
          is_skip = true;
        } else {
          tab += 1;
        }
        setSelectedTab(tab);
        if (isSave) {
          navigate_inbox();
        } else {
          query.set("id", id);
          query.set("state", tab);
          if (is_skip) {
            query.set("skip", is_skip);
          } else if (query.get("skip")) {
            query.delete("skip");
          }
          if (query.get("copyId")) {
            query.delete("copyId");
          }
          navigate(ADD_NOTIFICATION_INFORMATION + "?" + query.toString());
        }
      } else {
        console.error(res);
        setErrors(res.message);
      }
      setLoading(false);
    });
  };
  const navigate_inbox = () => {
    query.delete("id");
    query.delete("state");
    navigate(INBOX_ROUTE+"?"+query.toString());
  };

  const callback_handleBackBtn = (e) => {
    if (selected_tab === 0) {
      navigate_inbox();
    } else {
      if (query.get("skip") && selected_tab == 2) {
        query.set("state", selected_tab - 2);
        setSelectedTab(selected_tab - 2);
      } else {
        query.set("state", selected_tab - 1);
        setSelectedTab(selected_tab - 1);
      }
      navigate(ADD_NOTIFICATION_INFORMATION + "?" + query.toString());
    }
  };
  const callbackRecipientsSubmit = (data, isSave) => {
    const token = getCurrentLoggedInSessionToken();
    setLoading(false);
    console.log(data);
    api.post(NOTIFICATION_ENDPOINT, JSON.stringify(data), token).then((res) => {
      if (res.status === "success") {
        console.log(res);
        const { id } = res.data;
        let tab = selected_tab + 1;
        setSelectedTab(tab);
        if (isSave) {
          navigate_inbox();
        } else {
          query.set("state", tab);
          query.set("id", id);
          if (query.get("copyId")) {
            query.delete("copyId");
          }
          navigate(ADD_NOTIFICATION_INFORMATION + "?" + query.toString());
        }
      } else {
        console.error(res);
        setErrors(res.message);
        //  alert("error");
      }
      setLoading(false);
    });
  };

  const callbackAttachmentSubmit = (data, isSave) => {
    const token = getCurrentLoggedInSessionToken();
    api.post(NOTIFICATION_ENDPOINT, JSON.stringify(data), token).then((res) => {
      if (res.status === "success") {
        console.log(res);
        const { id } = res.data;
        let tab = selected_tab + 1;
        setSelectedTab(tab);
        if (isSave) {
          navigate_inbox();
        } else {
          query.set("state", tab);
          query.set("id", id);
          if (query.get("copyId")) {
            query.delete("copyId");
          }
          // navigate(ADD_NOTIFICATION_INFORMATION+"?id="+id+"&state="+tab.toString());
          navigate(ADD_NOTIFICATION_INFORMATION + "?" + query.toString());
        }
      } else {
        console.error(res);
        setErrors(res.message);
      }
      setLoading(false);
    });
  };
  const callbackReviewsubmit = (data) => {
    const token = getCurrentLoggedInSessionToken();
    if (data.start_date === moment(new Date()).format("YYYY-MM-DD")) {
      if (
        moment(data.start_time, "hh:mmA").format("HH:mm:ss") <
        moment(new Date(), "hh:mmA").format("HH:mm:ss")
      ) {
        setLoading(false);
        setTimeChange(true);
        setTimeChangeData(data);
      } else {
        CallAPI(data, token);
      }
    } else if (data.start_date < moment(new Date()).format("YYYY-MM-DD")) {
      setLoading(false);
      setDateChange(true);
      setTimeChangeData(data);
    } else {
      CallAPI(data, token);
    }
  };

  const compareDate = (data) => {
    if (
      new Date(
        `${data.start_date} ${moment(data.start_time, "hh:mmA").format(
          "HH:mm:ss"
        )}`
      ) <
      new Date(
        `${data.end_date} ${moment(data.end_time, "hh:mmA").format("HH:mm:ss")}`
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getDate = () => {
    return `The selected publishing time has already passed. Could we proceed by
            submitting the new publish time for ${moment(new Date()).format(
              "YYYY-MM-DD"
            )} ${getTime()}?`;
  };

  const SubmitToAPI = () => {
    console.log(timeChangeData);
    setLoading(true);
    const token = getCurrentLoggedInSessionToken();
    const data = {
      ...timeChangeData,
      start_time: getTime(),
    };
    console.log(data);
    setTimeChange(false);
    CallAPI(data, token);
  };

  const SubmitToAPIDateChange = () => {
    setLoading(true);
    const token = getCurrentLoggedInSessionToken();
    const data = {
      ...timeChangeData,
      start_date: moment(new Date()).format("YYYY-MM-DD"),
      start_time: getTime(),
    };
    setDateChange(false);
    CallAPI(data, token);
  };

  const CallAPI = (data, token) => {
    if (!compareDate(data)) return setShowError(true);
    api.post(NOTIFICATION_ENDPOINT, JSON.stringify(data), token).then((res) => {
      if (res.status === "success") {
        // localStorage.setItem("needReload");
        console.log(res);
        let tab = selected_tab + 1;
        setSelectedTab(tab);
        query.set("refresh",true);
        navigate_inbox();
      } else {
        setErrors(res.message);
      }
      setLoading(false);
    });
  };

  const getTime = () => {
    const minute_interval = ["00", "15", "30", "45"];
    let time = "";
    if (
      parseInt(moment(timeChangeData.start_time, "hh:mmA").format("HH")) ===
      new Date().getHours()
    ) {
      minute_interval.map((min) => {
        const Lessthan15 = parseInt(min) - new Date().getMinutes();
        if (Lessthan15 < 15) {
          time = `${new Date().getHours()}:${parseInt(min) + 15}`;
        }
      });
      if (new Date().getMinutes() >= 45) {
        time = `${new Date().getHours() + 1}:15`;
      }
    } else {
      console.log(new Date().getMinutes());
      minute_interval.map((min) => {
        const Lessthan15 = parseInt(min) - new Date().getMinutes();
        if (Lessthan15 < 15) {
          time = `${new Date().getHours()}:${parseInt(min) + 15}`;
        }
      });
      if (new Date().getMinutes() >= 45) {
        time = `${new Date().getHours() + 1}:15`;
      }
    }
    return moment(time, "HH:mm").format("hh:mmA");
  };

  const [autoSaveInProgress, setAutoSaveInProgress] = useState(false);

  const callbackAutoSave = (data, addLoading) => {
    console.log("Auto Saving....");
    if (addLoading) {
      setAutoSaveInProgress(true);
    }
    dispatch(updateNotification(data)).then((res) => {
      console.log("Auto Updated");
      setLastUpdate(moment().format("DD MMM YYYY hh:mm:ss"));
      const { id: update_id } = data;
      const { payload } = res;
      console.log("payload.status:", payload.status);
      setAutoSaveInProgress(false);
      console.log("set auto save in progress to false.");
      if (payload.status === "success") {
        const { id } = payload.data;
        if (!update_id) {
          query.set("id", id);
          if (query.get("copyId")) {
            query.delete("copyId");
          }
          navigate(ADD_NOTIFICATION_INFORMATION + "?" + query.toString());
        }
      } else {
        console.error(res);
        setErrors(res.message);
      }
    });
  };
  return (
    <>
      {errors && (
        <Dialog
          width={400}
          title={"Message"}
          onClose={() => setErrors(false)}
          className="error-message"
        >
          <p>{errors}</p>
        </Dialog>
      )}
      <AddNotificationHeader
        backBtncallback={(e) => callback_handleBackBtn(e)}
        lastUpdate={lastUpdate}
        ndata_loading={ndata_loading}
        state={selected_tab}
      />
      <AddNotificationSteps state={selected_tab} setState={setSelectedTab} isSplashOnly={isSplashOnly} />
      <div className="main" style={{ paddingLeft: 40 }}>
        {selected_tab === 0 && (
          <InformationForm
            submitcallback={callbackMessageSubmit}
            loading={loading}
            setLoading={setLoading}
            callbackAutoSave={callbackAutoSave}
            setIsSplashOnly={setIsSplashOnly}
          />
        )}
        {selected_tab === 1 && (
          <RecipientsForm
            loading={loading}
            setLoading={setLoading}
            submitcallback={(data, isSaveAndQuit) =>
              callbackRecipientsSubmit(data, isSaveAndQuit)
            }
            backBtncallback={(e) => callback_handleBackBtn(e)}
            callbackAutoSave={callbackAutoSave}
            autoSaveInProgress={autoSaveInProgress}
            setIsSplashOnly={setIsSplashOnly}
          />          
        )}
        {selected_tab === 2 && (
          <AttachmentForm
            loading={loading}
            setLoading={setLoading}
            submitcallback={(data, isSaveAndQuit) =>
              callbackAttachmentSubmit(data, isSaveAndQuit)
            }
            backBtncallback={(e) => callback_handleBackBtn(e)}
            setErrors={setErrors}
            callbackAutoSave={callbackAutoSave}
            autoSaveInProgress={autoSaveInProgress}
            setIsSplashOnly={setIsSplashOnly}
          />
        )}
        {selected_tab === 3 && (
          <ReviewForm
            loading={loading}
            setLoading={setLoading}
            submitcallback={(data) => callbackReviewsubmit(data)}
            backBtncallback={(e) => callback_handleBackBtn(e)}
            autoSaveInProgress={autoSaveInProgress}
            setIsSplashOnly={setIsSplashOnly}
          />
        )}
      </div>
      {timeChange && (
        <Dialog
          className="alert-form"
          title={"Alert"}
          width={400}
          // height={280}
          onClose={() => setTimeChange(false)}
        >
          <p>
            The selected publishing time has already passed. Could we proceed by
            submitting the new publish time for {getTime()}?
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "150px",
                height: "30px",
                borderRadius: "10px",
                background: "white",
                color: "#eb6e1f",
                cursor: "pointer",
                border: "1px solid #eb6e1f",
              }}
              onClick={() => backtoInformation()}
            >
              Go back
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "150px",
                height: "30px",
                borderRadius: "10px",
                background: "#eb6e1f",
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => SubmitToAPI()}
            >
              Submit
            </div>
          </div>
        </Dialog>
      )}
      {dateChange && (
        <Dialog
          className="alert-form"
          title={"Alert"}
          width={400}
          // height={280}
          onClose={() => setDateChange(false)}
        >
          <p>{getDate()}</p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "150px",
                height: "30px",
                borderRadius: "10px",
                background: "white",
                color: "#eb6e1f",
                cursor: "pointer",
                border: "1px solid #eb6e1f",
              }}
              onClick={() => backtoInformation()}
            >
              Go back
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "150px",
                height: "30px",
                borderRadius: "10px",
                background: "#eb6e1f",
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => SubmitToAPIDateChange()}
            >
              Submit
            </div>
          </div>
        </Dialog>
      )}
      {showError && (
        <Dialog
          className="alert-form"
          title={"Alert"}
          width={400}
          // height={280}
          onClose={() => setShowError(false)}
        >
          <p>End Date cannot be earlier than Start Date!</p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "150px",
                height: "30px",
                borderRadius: "10px",
                background: "#eb6e1f",
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => backtoInformation()}
            >
              Change
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};
