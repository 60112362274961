import { useMemo } from "react";

import "./style.css";

export const IconSize = {
  small: "fa-sm",
  large: "fa-lg",
};

const Icons = (props) => {
  const iconSize = IconSize[props?.size ?? "small"];

  // console.log('iconSize: ', iconSize, props);
  const classIcon = useMemo(() => {
    const cls = `${props?.className} ${iconSize}`;
    if (props?.hidden) {
      return `${cls} icon-disable`;
    }

    return cls;
  }, [props?.className, props?.hidden, iconSize]);

  return <i {...props} className={classIcon} onClick={props?.onClick} />;
};

export default Icons;
