import { useState, useEffect } from "react";
import CognitoContext from "./cognitoContext";
import { Loader } from "@progress/kendo-react-indicators";

const {
  getCurrentLoggedInSessionTokenWithPromise,
  getCurrentLoggedInUserWithPromise,
  requrieRefresh,
  signout,
} = require("./config/userPool");

const CognitoProvider = ({ children }) => {
  const [cognitoToken, setCognitoToken] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUserLoading, setIsUserLoading] = useState(true);

  useEffect(() => {
    // Code to get and refresh the Cognito token goes here
    console.log("CognitoProvider UseEffect");

    const getIdToken = () => {
      const prom = getCurrentLoggedInSessionTokenWithPromise();
      prom.then(
        (result) => {
          console.log(
            "getIdToken - getCurrentLoggedInSessionTokenWithPromise Success:",
            result
          );
          setCognitoToken(result);
          setIsLoading(false);
          localStorage.setItem("login", "true");
        },
        (error) => {
          console.log(
            "getIdToken - getCurrentLoggedInSessionTokenWithPromise Error:",
            error
          );
          setCognitoToken(null);
          setIsLoading(false);

          if (window.location.pathname !== "/") {
            // alert("Your login session has expired. Please login again.");
            localStorage.removeItem("login");
            localStorage.removeItem("username");
            localStorage.removeItem("user_permissions");
            window.location.href = "/";
            localStorage.setItem("redirect_to", window.location.href);
          }
        }
      );
    };

    const getUserPayload = () => {
      const prom = getCurrentLoggedInUserWithPromise();
      prom.then(
        (result) => {
          console.log(
            "getUserPayload - getCurrentLoggedInUserWithPromise Success:",
            result
          );
          setCognitoUser(result);
          setIsUserLoading(false);
        },
        (error) => {
          console.log(
            "getUserPayload - getCurrentLoggedInUserWithPromise Error:",
            error
          );
          setCognitoUser(null);
          setIsUserLoading(false);
        }
      );
    };

    getIdToken();
    getUserPayload();

    const intervalId = setInterval(() => {
      console.log("CONTEXT INTERVAL IS RUNNING.");
      const doRequire = requrieRefresh();
      console.log("Required to refresh token: ", doRequire);
      if (doRequire) {
        getIdToken();
        getUserPayload();
      }
    }, 1000 * 10); // Check every 10 seconds

    return () => clearInterval(intervalId);
  }, []);

  const contextValue = { cognitoToken, cognitoUser };

  return (
    <CognitoContext.Provider value={contextValue}>
      {isLoading || isUserLoading ? (
        <div style={{ width: "100vw", height: "100vh" }}>
          <Loader
            type="infinite-spinner"
            style={{
              // height: "89vh",
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              minHeight: "100%",
            }}
          />
        </div>
      ) : (
        children
      )}
    </CognitoContext.Provider>
  );
};

export default CognitoProvider;
