import { useEffect, useState, useRef } from "react";
// kendo
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
// lib
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
// css
import "./dialog.css";

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

export const ImageChecker = ({
  img: image_,
  width,
  height,
  required_ratio,
  current_ratio,
  onCloseClicked,
  file_type,
  onAddCroppedImage,
}) => {
  const [upImage, setUpImage] = useState(null);
  const [crop, setCrop] = useState(
    // {
    // unit: "px",
    // width: 480,
    // height: 338.02,
    // aspect: required_ratio,
    // locked: true,
    // keepSelection: true,
    // }
    centerAspectCrop(width, height, required_ratio)
  );
  const previewCanvasRef = useRef(null);
  const previewDivRef = useRef(null);
  const imgRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [croppedRatio, setCroppedRatio] = useState(null);

  const setCanvasImage = (image, canvas, crop) => {
    console.log("setCanvasImage");
    console.log(crop, canvas, image);

    if (!canvas || !image) {
      return;
    }
    if (crop === null) {
      crop = {
        height: 225,
        unit: "px",
        width: 270,
        x: 15,
        y: 37.5,
      };
    }
    try {
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      console.log(
        "Natural width & height:",
        image.naturalWidth,
        image.naturalHeight
      );
      console.log("Width & height:", image.width, image.height);
      const ctx = canvas.getContext("2d");

      const pixelRatio = window.devicePixelRatio;
      canvas.width = crop.width * pixelRatio * scaleX;
      canvas.height = crop.height * pixelRatio * scaleY;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "medium";
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
      let ratio = canvas.width / canvas.height;
      ratio = ratio.toFixed(2);
      setCroppedRatio(ratio);
      console.log("setCanvasImage");
    } catch (error) {
      console.log("setCanvasImage error:", error);
    }
  };

  const readableSize = (size) => {
    return size / 1024 > 1024
      ? ~~((10 * size) / 1024 / 1024) / 10 + "MB"
      : ~~(size / 1024) + "KB";
  };

  const handleProceedClicked = async () => {
    previewCanvasRef.current.toBlob(
      function (blob) {
        console.log(blob);
        if (blob === null) {
          alert("Please Crop the Image first!");
          return;
        }
        console.log("Canvas size:", readableSize(blob.size));
        let ratio =
          previewCanvasRef.current.width / previewCanvasRef.current.height;
        console.log("Canvas width:", previewCanvasRef.current.width);
        console.log("Canvas height:", previewCanvasRef.current.height);
        console.log("Canvas ratio:", ratio.toFixed(2));
        console.log("Required ratio:", required_ratio.toFixed(2));
        console.log(image_);
        let file = new File([blob], image_.name, { type: image_.type });
        console.log("Canvas file:", file);
        console.log("Canvas name:", image_.name);
        console.log("Canvas file type:", file_type);
        onAddCroppedImage(file, image_.name, file_type, image_.type);
        console.log("Sent to parent.");
        // if (ratio.toFixed(2) !== required_ratio.toFixed(2)) {
        //   alert("Image ratio is not cropped as required.");
        // } else {
        //   // let blobBin = atob(url.split(",")[1]);
        //   // let array = [];
        //   // for (let i = 0; i < blobBin.length; i++) {
        //   //   array.push(blobBin.charCodeAt(i));
        //   // }
        //   // let file = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
        //   let file = new File([blob], image_.name);
        //   console.log("Canvas file:", file);
        //   console.log("Canvas name:", image_.name);
        //   console.log("Canvas file type:", file_type);
        //   onAddCroppedImage(file, image_.name, file_type);
        //   console.log("Sent to parent.");
        // }
      },
      "image/jpeg",
      0.5
    );

    // let url = previewCanvasRef.current.toDataURL();
    // let ratio =
    //   previewCanvasRef.current.width / previewCanvasRef.current.height;
    // console.log("Canvas width:", previewCanvasRef.current.width);
    // console.log("Canvas height:", previewCanvasRef.current.height);
    // console.log("Canvas ratio:", ratio.toFixed(2));
    // console.log("Required ratio:", required_ratio.toFixed(2));
    // console.log("Canvas data:", url);
    // if (ratio.toFixed(2) !== required_ratio.toFixed(2)) {
    //   alert("Image ratio is not cropped as required.");
    // } else {
    //   let blobBin = atob(url.split(",")[1]);
    //   let array = [];
    //   for (let i = 0; i < blobBin.length; i++) {
    //     array.push(blobBin.charCodeAt(i));
    //   }
    //   let file = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
    //   console.log("Canvas file:", file);
    //   console.log("Canvas name:", image_.name);
    //   console.log("Canvas file type:", file_type);
    //   onAddCroppedImage(file, image_.name, file_type);
    //   console.log("Sent to parent.");
    // }
  };

  console.log(crop);
  useEffect(() => {
    setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop);
  }, [completedCrop]);

  useEffect(() => {
    if (image_) {
      console.log("img:", image_);
      setUpImage(URL.createObjectURL(image_));
      console.log("done setting image to React Crop.");
    }
  }, []);

  return (
    <Dialog
      width={"1600px"}
      height={"90vh"}
      title={"Resize image"}
      onClose={() => onCloseClicked(file_type)}
    >
      <div className="image-checker-titles">
        <div className="image-checker-titles-item">Original Image</div>
        <div className="image-checker-titles-item">Cropped Image</div>
      </div>
      <div className="image-checker-image-holders">
        <div className="image-checker-image-holders-item">
          <ReactCrop
            crop={crop}
            aspect={required_ratio}
            // minWidth={400}
            minHeight={200}
            // onChange={setCrop}
            // onComplete={(c) => setCompletedCrop(c)}
            onChange={setCrop}
            onComplete={(c) => setCompletedCrop(c)}
            keepSelection={true}
          >
            <img ref={imgRef} src={upImage} />
          </ReactCrop>
        </div>
        <div className="image-checker-image-holders-item" ref={previewDivRef}>
          <canvas
            ref={previewCanvasRef}
            style={{
              width: Math.round(completedCrop?.width ?? 0),
              height: Math.round(completedCrop?.height ?? 0),
            }}
          />
        </div>
      </div>
      <br></br>
      {/* <div>
        <table className="image-checker-table">
          <tr>
            <td width={300}>Required Image Ratio:</td>
            <td>{required_ratio ? required_ratio.toFixed(2) : "NA"}</td>
          </tr>
          <tr>
            <td width={300}>Cropped Image Ratio:</td>
            <td>{croppedRatio ? croppedRatio : "NA"}</td>
          </tr>
        </table>
      </div> */}
      <DialogActionsBar>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={handleProceedClicked}
          // disabled={required_ratio.toFixed(2) !== croppedRatio}
        >
          Proceed
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};
