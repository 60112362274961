import React, { useState } from "react";

import { useNavigate, useLocation } from "react-router";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";

import "./table.css";
import {
  NOTIFICATION_VIEW_ROUTE,
  ADD_NOTIFICATION_INFORMATION,
  INBOX_ROUTE,
} from "../../routes";

import api from "../../config/api";
import config from "../../config/config";
import { getCurrentLoggedInSessionToken } from "../../config/userPool";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Confirmation } from "../../components/dialog/confirmation";
import moment from "moment";

const { NOTIFICATION_ACTION_ENDPOINT, NOTIFICATION_ENDPOINT } =
  config.api_endpoint;

export const AssignmentTable = ({
  data,
  actionDropdownClicked,
  onSortBy,
  showUpdateStatusOption,
}) => {
  const initialDataState = {
    skip: 0,
    take: 10,
  };
  const initialSort = [
    {
      field: "created_on",
      dir: "asc",
    },
  ];
  const navigate = useNavigate();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [page, setPage] = useState(initialDataState);
  const [sort, setSort] = useState(initialSort);
  const [orderBy, setOrderBy] = useState(true);
  const [sortBy, setSortBy] = useState("created_on");
  const [pageSizeValue, setPageSizeValue] = React.useState();
  // const userPermissions = getUserPermission();
  // const check = checkMultiPermission(userPermissions, [
  //   "dr_infra.delete_incident",
  // ]);
  // const options = check ? ["View Details", "Delete"] : ["View Details"];
  const options = ["Delete"];
  // if (showUpdateStatusOption) {
  //   options.push("Update Status");
  // }
  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === "All" ? 77 : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };
  const sortChange = (event) => {
    console.log("sort change- ", event.sort);
    setSort(event.sort);
    console.log("sort- ", sort);
    for (let i in event.sort) {
      if (event.sort[i].dir == "desc") {
        setOrderBy(false);
      } else if (event.sort[i].dir == "asc") {
        setOrderBy(true);
      }
      if (event.sort[i].field == "created_on") {
        setSortBy("created_on");
      } else if (event.sort[i].field == "updated_on") {
        setSortBy("updated_on");
      }
      console.log("sortby-", sortBy, "orderby-", orderBy);
      onSortBy(sortBy, orderBy);
    }
  };
  const customDateCell = (props) => {
    if (props.dataItem[props.field] !== "") {
      return (
        <td>
          {moment(props.dataItem[props.field]).format("DD MMM YYYY, hh:mm A")}
        </td>
      );
    }
    return <td>{props.dataItem[props.field]}</td>;
  };

  return (
    <div className="tbody-content">
      <Grid
        data={data.slice(page.skip, page.take + page.skip)}
        skip={page.skip}
        take={page.take}
        total={data.length}
        pageable={{
          buttonCount: 4,
          pageSizes: [10, 20, 30, "All"],
          pageSizeValue: pageSizeValue,
        }}
        onPageChange={pageChange}
        sortable={{ allowUnsort: false }}
        sort={sort}
        onSortChange={sortChange}
        className="drTable"
      >
        <GridColumn
          field="service_no"
          title="S.No"
          width={85}
          cell={(props) => (
            <td className="k-table-td">
              {props.dataIndex < 10
                ? `000${props.dataIndex + 1}`
                : `00${props.dataIndex + 1}`}
            </td>
          )}
        />
        <GridColumn
          field={"assigned_to"}
          title="Assigned"
          // width={120}
          cell={(props) => (
            <td className="k-table-td">
              {`${props.dataItem.assigned_to.name}, ${props.dataItem.assigned_to.id}`}
            </td>
          )}
        />
        <GridColumn
          field="scheme_name"
          title="Feedback Scheme"
          // width={160}
          sortable={false}
        />
        <GridColumn
          field="assigned_bc"
          title="Assigned BC"
          sortable={false}
          width={120}
        />
        <GridColumn
          className="td-align-center"
          field="service_no"
          title="Service No."
          width={100}
          sortable={false}
        />
        <GridColumn
          className="td-align-center"
          title="Direction"
          width={105}
          field="direction"
          sortable={false}
        />
        <GridColumn
          width={90}
          title="Borading Bus Stop"
          field="boarding_bus_stop"
          sortable={false}
        />
        <GridColumn field="type" title="Type" sortable={false} width={80} />
        <GridColumn
          width={120}
          field="checker_to_note"
          title="Check To Note"
          sortable={false}
        />
        <GridColumn
          width={120}
          field="commuter_feedback"
          title="Commuters Feedback"
          sortable={false}
        />
        <GridColumn
          width={130}
          sortable={false}
          field="Action"
          cell={(props) => (
            <td>
              <DropDownList
                data={options}
                defaultValue={"Action"}
                onChange={(e) => actionDropdownClicked(e, props)}
                style={{
                  fontSize: "15px",
                  width: "100px",
                  height: "48px",
                  backgroundColor: "#EB6E1F",
                  color: "white",
                  textAlign: "center",
                  borderRadius: "8px",
                }}
              />
            </td>
          )}
        />
      </Grid>
    </div>
  );
};
