import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import "../../../../components/dialog/notification.css";
// import { fetchAllCategories } from "../../store/category";
import { Loader } from "@progress/kendo-react-indicators";
import { RadioButton } from "@progress/kendo-react-inputs";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
// import { DatePicker } from "@progress/kendo-react-dateinputs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CustomDatePicker } from "../../../../components/custom/datepicker";
import { fetchMatterList } from "../../../../store/matter";

const all_or_date_range = [
  { value: "all", label: "All dates" },
  { value: "date", label: "Date range" },
];
const filter_status = [
  { value: "published", label: "Published" },
  { value: "scheduled", label: "Scheduled" },
  { value: "draft", label: "Draft" },
  { value: "pendingapproval", label: "Pending Approval" },
];
const acknowledge_status = [
  { value: "required", label: "Yes" },
  { value: "not_required", label: "No" },
];

export const IFeedbackListFilterDialog = (props) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [selectedUpdatedOnDateOption, setSelectedUpdatedOnDateOption] =
    useState("all");
  const [selectedCreatedDateOption, setSelectedCreatedDateOption] =
    useState("all");

  const [CreatedDateFrom, setCreatedDateFrom] = useState("");
  const [CreatedDateTo, setCreatedDateTo] = useState("");
  const [UpdatedDateFrom, setUpdatedDateFrom] = useState("");
  const [UpdatedDateTo, setUpdatedDateTo] = useState("");
  const [selectedMatter, setSelectedMatter] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const [allMatterList, setAllMatterList] = useState([]);
  const [loading, setLoading] = useState(true);
  const statuses = ["All", "Submitted", "Investigating", "Closed"];

  const [createdDateError, setCreatedDateError] = useState(false);
  const [updatedDateError, setUpdatedDateError] = useState(false);

  // redux
  const { matter_list_data, matter_list_loading, matter_list_error } =
    useSelector((state) => state.matter);

  const CreatedDateErrorMsg =
    "Created on end date should be later than start date.";
  const UpdatedDateErrorMsg =
    "Updated on end date should be later than start date.";

  function removeDuplicates(arr) {
    return [...new Set(arr)];
  }

  useEffect(() => {
    if (matter_list_data) {
      let all_matters = [{ no: "All", description: "All" }];
      matter_list_data.filter((x) => all_matters.push(x));
      setAllMatterList(all_matters);

      if (query.get("matter")) {
        let selected_matters = [];
        let matter_no = query.get("matter").split(",");
        matter_no.forEach((x) => {
          selected_matters.push(all_matters.filter((y) => y.no === x)[0]);
        });

        setSelectedMatter(selected_matters);
      }

      setLoading(false);
    }
  }, [matter_list_data]);

  useEffect(() => {
    if (CreatedDateFrom && CreatedDateTo) {
      if (CreatedDateTo < CreatedDateFrom) {
        setCreatedDateError(true);
      } else {
        setCreatedDateError(false);
      }
    }
  }, [CreatedDateFrom, CreatedDateTo]);

  useEffect(() => {
    if (UpdatedDateFrom && UpdatedDateTo) {
      if (UpdatedDateTo < UpdatedDateFrom) {
        setUpdatedDateError(true);
      } else {
        setUpdatedDateError(false);
      }
    }
  }, [UpdatedDateFrom, UpdatedDateTo]);

  useEffect(() => {
    if (query.toString()) {
      if (query.get("created_on_start")) {
        setCreatedDateFrom(new Date(query.get("created_on_start")));
      }
      if (query.get("created_on_end")) {
        setCreatedDateTo(new Date(query.get("created_on_end")));
      }
      if (query.get("created_on_start") || query.get("created_on_end")) {
        setSelectedCreatedDateOption("date");
      }
      if (query.get("updated_on_start")) {
        setUpdatedDateFrom(new Date(query.get("updated_on_start")));
      }
      if (query.get("updated_on_end")) {
        setUpdatedDateTo(new Date(query.get("updated_on_end")));
      }
      if (query.get("updated_on_start") || query.get("updated_on_end")) {
        setSelectedUpdatedOnDateOption("date");
      }
      if (query.get("status")) {
        setSelectedStatus(query.get("status").split(","));
      }
    }
    dispatch(fetchMatterList());
  }, []);
  const handleUpdatedDateOptionChange = (e) => {
    setSelectedUpdatedOnDateOption(e.value);
  };
  const handleCreatedDateOptionChange = (e) => {
    console.log("handleCreatedDateOptionChange:", e.value);
    setSelectedCreatedDateOption(e.value);
  };
  const handleclearfilter = () => {
    setUpdatedDateFrom("");
    setUpdatedDateTo("");
    setCreatedDateFrom("");
    setCreatedDateTo("");
    setCreatedDateError(false);
    setUpdatedDateError(false);
  };
  const onSubmitFilter = () => {
    let data = {
      created_on_start:
        selectedCreatedDateOption === "all" ? "" : CreatedDateFrom,
      created_on_end: selectedCreatedDateOption === "all" ? "" : CreatedDateTo,
      updated_on_start:
        selectedUpdatedOnDateOption === "all" ? "" : UpdatedDateFrom,
      updated_on_end:
        selectedUpdatedOnDateOption === "all" ? "" : UpdatedDateTo,
      matter:
        selectedMatter.filter((x) => x.no === "All").length > 0
          ? ""
          : selectedMatter.map((x) => x.no).join(","),
      status: selectedStatus.includes("All") ? "" : selectedStatus.join(","),
    };
    console.log("onSubmitFilter data:", data);
    props.onFiltercallback(data);
  };

  const handleStatusDropdownChanged = (e) => {
    setSelectedStatus(e.target.value.slice(0, 3));
  };

  const handleMatterListDropdownChanged = (e) => {
    setSelectedMatter(e.target.value.slice(0, 3));
  };

  return (
    <>
      <Dialog
        className="search-notification"
        title={" "}
        width={395}
        height={"100%"}
        onClose={() => props.setShowFilterDialog(false)}
      >
        {matter_list_loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <div className="search-body">
            <div className="filter-title">
              <div className="title">Filter</div>
              <div className="clear-filter" onClick={handleclearfilter}>
                <u>Clear Filter</u>
              </div>
            </div>
            <div className="filter-body">
              <p className="filter-sub-title">Created On</p>
              {createdDateError && (
                <p className="filter-error">{CreatedDateErrorMsg}</p>
              )}
              <div className="category-flex-row">
                {all_or_date_range.map((v) => (
                  <div className="date-option-div">
                    <RadioButton
                      className="filter-option-input"
                      value={v.value}
                      key={v.value}
                      label={v.label}
                      onChange={(e) => handleCreatedDateOptionChange(e)}
                      checked={selectedCreatedDateOption === v.value}
                    />
                  </div>
                ))}
              </div>
              {selectedCreatedDateOption !== "all" && (
                <div className="category-flex-row">
                  <div className="category-date-div">
                    <div>Date From</div>
                    <DatePicker
                      className="filter-date-range"
                      format={"dd MMM yyyy"}
                      selected={CreatedDateFrom}
                      onChange={(date) => setCreatedDateFrom(date)}
                      customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                    />
                  </div>
                  <div className="category-date-div">
                    <div>Date To</div>
                    <DatePicker
                      selected={CreatedDateTo}
                      onChange={(date) => setCreatedDateTo(date)}
                      format={"dd MMM yyyy"}
                      customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                    />
                  </div>
                </div>
              )}
              <p className="filter-sub-title">Updated On</p>
              {updatedDateError && (
                <p className="filter-error">{UpdatedDateErrorMsg}</p>
              )}
              <div className="category-flex-row">
                {all_or_date_range.map((v) => (
                  <div className="date-option-div">
                    <RadioButton
                      className="filter-option-input"
                      value={v.value}
                      key={v.value}
                      label={v.label}
                      onChange={(e) => handleUpdatedDateOptionChange(e)}
                      checked={selectedUpdatedOnDateOption === v.value}
                    />
                  </div>
                ))}
              </div>
              {selectedUpdatedOnDateOption !== "all" && (
                <div className="category-flex-row">
                  <div className="category-date-div">
                    <div>Date From</div>
                    <DatePicker
                      className="filter-date-range"
                      format={"dd MMM yyyy"}
                      selected={UpdatedDateFrom}
                      onChange={(date) => setUpdatedDateFrom(date)}
                      customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                    />
                  </div>
                  <div className="category-date-div">
                    <div>Date To</div>
                    <DatePicker
                      className="filter-date-range"
                      format={"dd MMM yyyy"}
                      selected={UpdatedDateTo}
                      onChange={(date) => setUpdatedDateTo(date)}
                      customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                    />
                  </div>
                </div>
              )}
              <p className="filter-sub-title">Matter</p>
              <div className="category-flex-row">
                <MultiSelect
                  className=""
                  style={{ width: "100%" }}
                  data={allMatterList}
                  onChange={handleMatterListDropdownChanged}
                  value={selectedMatter}
                  placeholder="Select (max 3 items)"
                  textField="description"
                  dataItemKey="no"
                />
              </div>
              <p className="filter-sub-title">Status</p>
              <div className="category-flex-row">
                <MultiSelect
                  className=""
                  style={{ width: "100%" }}
                  data={statuses}
                  onChange={handleStatusDropdownChanged}
                  value={selectedStatus}
                  placeholder="Select (max 3 items)"
                />
              </div>
            </div>
          </div>
        )}

        <DialogActionsBar>
          <Button
            className="search-button cancel-btn"
            onClick={() => props.setShowFilterDialog(false)}
          >
            Cancel
          </Button>
          <Button
            className="search-button search-btn"
            onClick={() => onSubmitFilter()}
            disabled={createdDateError || updatedDateError}
          >
            Submit
          </Button>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};
