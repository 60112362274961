import { CaretDown } from "@phosphor-icons/react";
import { useClickAway } from "ahooks";
import { useMemo, useCallback, useRef, useState } from "react";

const Component = props => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [select, setSelect] = useState({});
  const ref = useRef();

  useClickAway(() => {
    if (openDropdown) setOpenDropdown(false);
  }, ref);

  const onToggleSelectOption = () => !props?.disabled && setOpenDropdown(prev => !prev);

  const onSelectOption = useCallback(
    data => {
      setSelect(data);
      if (typeof props?.onSelect === 'function') {
        props?.onSelect(data)
      }

      onToggleSelectOption();
    },
    [props]
  );

  const wrapperClass = useMemo(
    () => {
      if (typeof props?.onAction === 'function' && props?.actionName) return 'select-input-wrapper'

      return 'select-input-single-wrapper'
    },
    [props?.onAction, props?.actionName]
  )

  const styleInput = useMemo(
    () => ({
      position: 'relative',
      padding: '8px 16px',
      cursor: 'pointer',
      color: 'black',
      ...(props?.inputStyle ?? {})
    }),
    [props?.inputStyle]
  )
  return (
    <div ref={ref} style={{ position: 'relative' }} className={props?.className}>
      <div className={wrapperClass}>
        <div
          onClick={onToggleSelectOption}
          className='card-item'
          style={styleInput}>
          <span>{props?.title ?? "-"}</span>
          <div className='button-action-dropdown'>
            <CaretDown size={20} />
          </div>
        </div>
        {typeof props?.onAction === 'function' && props?.actionName && (
          <div className='wrapper-button'>
            <button onClick={props?.onAction} className='button-add cursor-pointer'>{props?.actionName ?? ""}</button>
          </div>
        )}
      </div>
      {openDropdown && (
        <div className='dropdown-select' style={{ ...(props?.dropdownStyle ?? {}) }}>
          {props?.useSearch && (
            <input className='search-options' type='text' placeholder='Please input' />
          )}
          <div className='list-opt'>
            {!!props?.options?.length && props?.options?.map(
              ({ label, isSelected, data }) => (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => isSelected && onSelectOption(data)}
                  className={`select-label ${select?.id === data?.id && 'selected-option'}`}
                >
                  {label}
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Component;
