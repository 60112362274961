import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { TableHeader } from "../../../components/table/Tableheader";
import { TableBody } from "../../../components/table/TableBody";
import { NotificationTable } from "../../../components/table/notificationTable";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";

import "../communication.css";
import { ADD_NOTIFICATION_INFORMATION, INBOX_ROUTE } from "../../../routes";
import {
  exportToCSVNotifications,
  fetchAllNotifications,
} from "../../../store/notification";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";
import { SearchNotification } from "../../../components/dialog/notification_search";
import { FilterNotification } from "../../../components/dialog/notification_filter";

import {
  shouldShowAddNew,
  shouldShowExport,
  filterDataByPermissions,
} from "../inbox/permission_checker";

export const Inbox = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [pagenumber, setPageNumber] = useState(0);
  const [notification_data, setNotificationData] = useState([]);
  const [notification_next, setNotificationNext] = useState({});
  const [show_records, setShowRecords] = useState([]);
  const [TableLoading, setTableLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showSearchDialog, setShowSearchDialog] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [is_previous, setIsPrevious] = useState(false);
  const [sortby, setSortBy] = useState(query.get("sort_by", "publish_on"));
  const [sort_with, setSortWith] = useState(query.get("order", false));
  const { loading, notification_ids, error, notification_next_key } =
    useSelector((state) => state.notification);

  useEffect(() => {
    console.log("Dispatching....");
    query.delete("next_key");
    query.delete("pagination");
    query.delete("sorting");
    navigate(INBOX_ROUTE + "?" + query.toString());
    dispatch(fetchAllNotifications(query));
  }, []);
  useEffect(() => {
    // if (localStorage.getItem("needReload")) {
    //   localStorage.removeItem("needReload");
    //   dispatch(fetchAllNotifications(query));
    // }
    // dispatch(fetchAllNotifications(query));
    // reloading_form();
    let totalrecords = [...show_records, ...notification_ids];
    console.log(totalrecords.length / (pagenumber + 1));
    if (totalrecords.length <= 10 && pagenumber === 0) {
      console.log("1");
      setNotificationData([
        { notification_ids: totalrecords, notification_next_key },
      ]);
      setShowRecords(filterDataByPermissions(totalrecords));
      setNotificationNext(notification_next_key);
      if (totalrecords.length < 10 && notification_next_key.publish_on) {
        console.log("2");

        query.set("next_key", JSON.stringify(notification_next_key));
        dispatch(fetchAllNotifications(query));
      }
    } else if (totalrecords.length / (pagenumber + 1) <= 10) {
      console.log("3");

      console.log(totalrecords);
      if (notification_data.length > pagenumber) {
        let notification_data_list = notification_data;
        let result = notification_data[pagenumber];
        totalrecords = [...result.notification_ids, ...notification_ids];
        notification_data_list[pagenumber] = {
          notification_ids: totalrecords,
          notification_next_key,
        };
        console.log("4");

        setNotificationData(notification_data_list);
      } else if (notification_ids.length == 0) {
        setNotificationNext(notification_next_key);
        console.log("5");
      } else {
        console.log("6");

        let result = notification_data[pagenumber - 1];
        if (result.notification_ids.length >= 10) {
          console.log("7");

          totalrecords = notification_ids;
        }
        setNotificationData([
          ...notification_data,
          { notification_ids: totalrecords, notification_next_key },
        ]);
      }
      setShowRecords(filterDataByPermissions(totalrecords));
      setNotificationNext(notification_next_key);
      if (
        totalrecords.length > 0 &&
        totalrecords.length < 10 &&
        notification_next_key.publish_on &&
        totalrecords.length / (pagenumber + 1) < 5
      ) {
        console.log("8");

        query.set("next_key", JSON.stringify(notification_next_key));
        dispatch(fetchAllNotifications(query));
      }
    } else if (pagenumber == 0 && totalrecords.length > 10) {
      console.log("9");

      setNotificationData([
        { notification_ids: totalrecords, notification_next_key },
      ]);
      setNotificationNext(notification_next_key);
      setShowRecords(totalrecords);
    } else if (pagenumber == 0 && notification_ids.length > 0) {
      console.log("10");

      setNotificationData([{ notification_ids, notification_next_key }]);
      setNotificationNext(notification_next_key);
      setShowRecords(filterDataByPermissions(notification_ids));
      if (notification_ids.length < 10 && notification_next_key.publish_on) {
        query.set("next_key", JSON.stringify(notification_next_key));
        dispatch(fetchAllNotifications(query));
        console.log("11");
      }
    } else if (totalrecords.length > 10) {
      console.log("12");

      console.log(totalrecords);
      setNotificationData([
        ...notification_data,
        { notification_ids: totalrecords, notification_next_key },
      ]);
      setShowRecords(filterDataByPermissions(totalrecords));
      setNotificationNext(notification_next_key);
    } else if (notification_ids.length > 0) {
      console.log("13");

      setNotificationData([
        ...notification_data,
        { notification_ids, notification_next_key },
      ]);
      setNotificationNext(notification_next_key);
      setShowRecords(filterDataByPermissions(notification_ids));
    }

    query.set("next_key", JSON.stringify(notification_next_key));
    console.log("Notification After Effect", notification_ids);
    console.log("Notification Next Key", notification_next_key);
    if (notification_ids.length === 0) {
      console.log("14");

      if (pagenumber === 0) {
        console.log("15");

        setIsPrevious(false);
      } else {
        console.log("16");

        setIsPrevious(notification_data.length === 1);
        setPageNumber(notification_data.length - 1);
      }
      query.set("next_key", JSON.stringify({}));
      setNotificationNext({});
    }
    // dispatch(fetchAllNotifications(query));
    navigate(INBOX_ROUTE + "?" + query.toString());
    // reloading_form();
  }, [notification_ids, notification_next_key]);

  const onFiltercallback = (data) => {
    const {
      category_id,
      status,
      start_date,
      end_date,
      created_on_start,
      created_on_end,
      ack_required,
      is_all_category,
      is_all_ack,
      is_all_status,
    } = data;
    query.delete("next_key");
    query.delete("pagination");
    query.delete("search");
    query.delete("sorting");
    query.delete("filter");
    query.set("category_id", category_id);
    query.set("status", status);
    query.set("ack_required", ack_required);
    query.set("start_date", start_date);
    query.set("end_date", end_date);
    query.set("created_on_start", created_on_start);
    query.set("created_on_end", created_on_end);
    query.set("is_all_category", is_all_category);
    query.set("is_all_ack", is_all_ack);
    query.set("is_all_status", is_all_status);
    if (
      category_id ||
      status ||
      ack_required ||
      start_date ||
      end_date ||
      created_on_start ||
      created_on_end
    ) {
      console.log("Filtering...");
      query.set("filter", true);
    } else if (is_all_ack || is_all_category || is_all_status) {
      query.set("filter", true);
    }
    setPageNumber(0);
    setNotificationData([]);
    setShowRecords([]);
    dispatch(fetchAllNotifications(query));
    setShowFilterDialog(false);
    navigate(INBOX_ROUTE + "?" + query.toString());
  };
  const onSearchcallback = (data) => {
    const { created_by_name, title } = data;
    query.delete("next_key");
    query.delete("pagination");
    query.delete("search");
    query.delete("sorting");
    query.set("title", title);
    query.set("created_by_name", created_by_name);
    if (title || created_by_name) {
      query.set("search", true);
    }
    let notidata = [];
    setPageNumber(0);
    setShowSearchDialog(false);
    setNotificationData([]);
    setShowRecords([]);
    dispatch(fetchAllNotifications(query));
    navigate(INBOX_ROUTE + "?" + query.toString());
  };
  const onSortChangeCallback = (data) => {
    const { field, dir } = data;
    console.log(sort_with);
    console.log(sortby);
    query.delete("next_key");
    query.delete("pagination");
    query.set("sort_by", sortby);
    query.set("order", sort_with);
    query.set("sorting", true);
    console.log(query.toString());
    setPageNumber(0);
    setNotificationData([]);
    setShowRecords([]);
    dispatch(fetchAllNotifications(query));
    navigate(INBOX_ROUTE + "?" + query.toString());
  };
  const handlenextpagecallback = () => {
    query.delete("pagination");
    console.log(pagenumber + 1);
    console.log(notification_data.length);
    if (pagenumber + 1 < notification_data.length) {
      let result = notification_data[pagenumber + 1];
      setShowRecords(result.notification_ids);
      setNotificationNext(result.notification_next_key);
      query.set("next_key", JSON.stringify(result.notification_next_key));
      setPageNumber(pagenumber + 1);
    } else {
      query.set("next_key", JSON.stringify(notification_next));
      dispatch(fetchAllNotifications(query));
    }
    setIsPrevious(true);
    setPageNumber(pagenumber + 1);
    query.set("pagination", true);
    navigate(INBOX_ROUTE + "?" + query.toString());
  };
  const handleClearFilterOptions = () => {
    query.delete("category_id");
    query.delete("status");
    query.delete("ack_required");
    query.delete("start_date");
    query.delete("end_date");
    query.delete("created_on_start");
    query.delete("created_on_end");
    query.delete("next_key");
    query.delete("is_all_category");
    query.delete("is_all_ack");
    query.delete("is_all_status");
    query.delete("filter");
    setIsPrevious(false);
    setPageNumber(0);
    setNotificationData([]);
    setShowRecords([]);
    dispatch(fetchAllNotifications(query));
    navigate(INBOX_ROUTE + "?" + query.toString());
  };
  const handleClearSearchOptions = () => {
    query.delete("title");
    query.delete("created_by_name");
    query.delete("next_key");
    query.delete("search");
    setIsPrevious(false);
    setPageNumber(0);
    setNotificationData([]);
    setShowRecords([]);
    dispatch(fetchAllNotifications(query));
    navigate(INBOX_ROUTE + "?" + query.toString());
  };
  const handlePreviouscallback = () => {
    if (pagenumber === 1) {
      setIsPrevious(false);
    }
    let result = notification_data[pagenumber - 1];
    console.log(result);
    setShowRecords(result.notification_ids);
    setNotificationNext(result.notification_next_key);
    setPageNumber(pagenumber - 1);
    query.set("next_key", JSON.stringify(result.notification_next_key));
    navigate(INBOX_ROUTE + "?" + query.toString());
  };
  const handlePageChange = (number) => {
    console.log(number);
    if (number === 0) {
      setIsPrevious(false);
    } else {
      setIsPrevious(true);
    }
    let result = notification_data[number];
    setShowRecords(result.notification_ids);
    setNotificationNext(result.notification_next_key);
    setPageNumber(number);
    query.set("next_key", JSON.stringify(result.notification_next_key));
    navigate(INBOX_ROUTE + "?" + query.toString());
  };
  const reloading_form = () => {
    setPageNumber(0);
    query.delete("next_key");
    query.delete("pagination");
    query.delete("search");
    query.delete("sorting");
    setNotificationData([]);
    setShowRecords([]);
    dispatch(fetchAllNotifications(query));
    navigate(INBOX_ROUTE + "?" + query.toString());
  };

  console.log(notification_next_key);

  return (
    <div className="body">
      {showSearchDialog && (
        <SearchNotification
          setShowSearchDialog={setShowSearchDialog}
          onSearchcallback={onSearchcallback}
        />
      )}
      {showFilterDialog && (
        <FilterNotification
          setShowFilterDialog={setShowFilterDialog}
          onFiltercallback={onFiltercallback}
        />
      )}
      {showDialog && (
        <Dialog
          title={"Info"}
          width={300}
          height={150}
          onClose={() => setShowDialog(false)}
          className="notfication-info-dialog"
        >
          <p>{showDialog}</p>
        </Dialog>
      )}
      <TableHeader
        title="Inbox"
        addurl={ADD_NOTIFICATION_INFORMATION + "?state=0"}
        onSearch={() => setShowSearchDialog(true)}
        onFilter={() => setShowFilterDialog(true)}
        onRefresh={() => reloading_form()}
        hideaddbtn={!shouldShowAddNew()}
        showNotificationExport={shouldShowExport()}
      />
      {(query.get("filter") || query.get("search")) && (
        <div className="table-filter-div">
          {query.get("filter") && (
            <div className="table-filter-item">
              <div className="table-filter-title">Filter applied</div>
              <span
                className="table-filter-clear"
                onClick={handleClearFilterOptions}
              >
                Clear filter
              </span>
            </div>
          )}
          {query.get("search") && (
            <div className="table-filter-item">
              <div className="table-filter-title">Keyword search applied</div>
              <span
                className="table-filter-clear"
                onClick={handleClearSearchOptions}
              >
                Clear search
              </span>
            </div>
          )}
        </div>
      )}
      {loading || TableLoading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <NotificationTable
          data={show_records}
          alldata={notification_data}
          loadData={() => reloading_form()}
          setTableLoading={setTableLoading}
          setShowDialog={setShowDialog}
          onSortChangeCallback={onSortChangeCallback}
          next_key={notification_next}
          handlenextpagecallback={handlenextpagecallback}
          handlePreviouscallback={handlePreviouscallback}
          is_previous={is_previous}
          pagenumber={pagenumber}
          handlePageChange={handlePageChange}
          setSortBy={setSortBy}
          setSortWith={setSortWith}
          sortby={sortby}
          sort_with={sort_with}
        />
      )}
    </div>
  );
};
