// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const { DR_INFRA_LOCATION_ENDPOINT, DR_INFRA_SYNC_LOCATION_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: false,
  location_data: [],
  error: null,
};

export const fetchAllDrInfraLocations = createAsyncThunk(
  "dr_infra/fetchAllDrInfraLocations",
  async (query, { rejectWithValue }) => {
    try {
      let q_params = {}

      if (query["locationCategory"]) {
        q_params["location_category"] = query["locationCategory"]
      }

      if (query["locationName"]) {
        q_params["name"] = query["locationName"]
      }

      if (query["locationRailLineId"]) {
        q_params["rail_line_id"] = query["locationRailLineId"]
      }
      
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(DR_INFRA_LOCATION_ENDPOINT, q_params, token);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const syncDrInfraLocations = createAsyncThunk(
  "dr_infra/syncDrInfraLocations",
  async (query, { rejectWithValue }) => {
    try {
      let q_params = {}
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(DR_INFRA_SYNC_LOCATION_ENDPOINT, q_params, token);
      return {
        "status": results.status,
        "message": results.message,
        "data": results.data,
      }
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const DrInfraLocation = createSlice({
  name: "location",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDrInfraLocations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllDrInfraLocations.fulfilled, (state, action) => {
        state.loading = false;
        state.location_data = action.payload;
      })
      .addCase(fetchAllDrInfraLocations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const DrInfraLocationReducer = DrInfraLocation.reducer;
export default DrInfraLocationReducer;
