import React, { useState,useEffect } from "react";
import { Navigate, useNavigate } from "react-router";
import { useDispatch,useSelector } from "react-redux";

// import DrTableHeader from "../../../components/drTable/dr_table_header";
import DrInfraSystemTable from "../../../components/drTable/dr_infra_system_table";
import DrInfraSystemListHeader from "./list/dr_infra_system_list_header";
import "../dr_infra.css";
import { fetchAllDrInfraSystem } from "../../../store/drInfraSystem";
import { Loader } from "@progress/kendo-react-indicators";

export const DrInfraSystemList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDrSearchDialog,setShowDrSearchDialog] = useState(false);
  const [searchResult,setSearchResult] = useState([]);
  const [isSearch,setIsSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { system_data ,loading, error } = useSelector((state) => state.system);

  useEffect(() => {
    dispatch(fetchAllDrInfraSystem({}));
  }, []);

  const handleSearchAction= async (systemName, subSystemName) => {
    const res= await dispatch(fetchAllDrInfraSystem({ systemName, subSystemName }));
    if (res.payload.length > 0){
      setSearchResult(res.payload);
      setIsSearch("search");
      setErrorMessage("");
    }
    else{
      setSearchResult([]);
      setErrorMessage("");
    }   
    setShowDrSearchDialog(false);
  };

  console.log(system_data);
  return (
    <div className="dr-infra-list-wrapper">
      <DrInfraSystemListHeader 
        title={"System ID"} 
        showDrSearchDialog={showDrSearchDialog}
        onSearchBtnClicked={() => setShowDrSearchDialog(true)}
        onSearchDialogClose={() => setShowDrSearchDialog(false)}
        onSearchAction = {(systemName, subSystemName)=> handleSearchAction(systemName, subSystemName)}
      />
      {loading ? (
        <Loader
            type="infinite-spinner"
            style={{
                display: "flex",
                alignItems: "center",
                width: "40px",
                margin: "auto",
                justifyContent: "center",
                height: "100vh",
            }}
            />
      ):(
        <DrInfraSystemTable
        data={isSearch=="search" ? searchResult:system_data}
        loading={loading}
        />
      )}
    </div>
  );
};