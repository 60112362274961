import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// kendo
import { Loader } from "@progress/kendo-react-indicators";
import { TextBox, TextArea } from "@progress/kendo-react-inputs";
// image
import LeftArrowIcon from "../../../../assets/dr_safe/left-arrow.png";
// routes
import { DR_SECURE_CONTACT_LIST } from "../../../../routes";
// css
import "./contact_details.css";
import {
  fetchAllDRSecureContactDetails,
  saveDRSecureNewContactDetails,
  updateDRSecureContactDetails,
} from "../../../../store/drSecureContact";

const DrSecureContactDetails = () => {
  let { search } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBackBtnClicked = () => {
    navigate(DR_SECURE_CONTACT_LIST);
  };

  const {
    drSecureContactDetails,
    details_loading: loading,
    details_error: error,
    drSecureContactNewDetails,
    drSecureContactUpdateDetails,
  } = useSelector((state) => state.drSecureContact);

  const [contact, setContact] = useState({});
  const [contactName, setContactName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);

  const handleContactName = (e) => {
    setContactName(e.target.value);
  };

  const handleContactNo = (e) => {
    setContactNo(e.target.value);
  };

  const handleSaveBtnClicked = () => {
    const query = new URLSearchParams(search);
    if (query.get("id")) {
      dispatch(
        updateDRSecureContactDetails({
          id: query.get("id"),
          contact_name: contactName,
          contact_number: contactNo,
          occ: contact["occ"],
        })
      );
    } else {
      dispatch(
        saveDRSecureNewContactDetails({
          contact_name: contactName,
          contact_number: contactNo,
          occ: true,
        })
      );
    }
    setSaveBtnClicked(true);
  };

  useEffect(() => {
    console.log("drSecureContactNewDetails:", drSecureContactNewDetails);
    if (drSecureContactNewDetails.id && saveBtnClicked) {
      navigate(DR_SECURE_CONTACT_LIST);
    }
  }, [drSecureContactNewDetails]);

  useEffect(() => {
    console.log("drSecureContactUpdateDetails:", drSecureContactUpdateDetails);
    if (drSecureContactUpdateDetails.id && saveBtnClicked) {
      navigate(DR_SECURE_CONTACT_LIST);
    }
  }, [drSecureContactUpdateDetails]);

  useEffect(() => {
    console.log("drSecureContactDetails:", drSecureContactDetails);
    if (drSecureContactDetails.length > 0) {
      setContact(drSecureContactDetails[0]);
      setContactName(drSecureContactDetails[0]["contact_name"]);
      setContactNo(drSecureContactDetails[0]["contact_number"]);
    }
  }, [drSecureContactDetails]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    console.log("Contact Details query:", query.get("id"));

    if (query.get("id")) {
      dispatch(fetchAllDRSecureContactDetails(query.get("id")));
    } else {
      setContactName("");
      setContactNo("");
    }
  }, []);

  return (
    <div className="dr-secure-content-details-wrapper">
      <div className="dr-secure-contact-content-header">
        <span
          className="dr-secure-contact-content-header-text"
          onClick={handleBackBtnClicked}
        >
          <img src={LeftArrowIcon} /> Back
        </span>
      </div>

      {loading ? (
        <Loader
          className="dr-secure-contact-details-loader"
          type="infinite-spinner"
        />
      ) : (
        <React.Fragment>
          <div className="dr-secure-contact-content-details">
            <div className="dr-secure-contact-content-details-left">
              <div className="dr-secure-contact-content-details-left-label normal">
                Contact Name
              </div>
              <div className="dr-secure-contact-content-details-left-label normal">
                Contact No.
              </div>
            </div>
            <div className="dr-secure-contact-content-details-right">
              <div className="dr-secure-contact-content-details-right-label normal">
                <TextBox
                  value={contactName}
                  onChange={handleContactName}
                  height={"48px"}
                />
              </div>
              <div className="dr-secure-contact-content-details-right-label normal">
                <TextBox
                  value={contactNo}
                  onChange={handleContactNo}
                  height={"48px"}
                />
              </div>
            </div>
          </div>
          <div
            className="dr-secure-contact-content-header-btn-save"
            onClick={handleSaveBtnClicked}
          >
            Save
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default DrSecureContactDetails;
