// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { EMAIL_GROUP_LIST_ENDPOINT, EMAIL_GROUP_DETAILS_ENDPOINT } =
  config.api_endpoint;

const initialState = {
  email_groups_loading: false,
  email_groups: [],
  email_groups_error: null,
  email_group_details_loading: false,
  email_group_details: [],
  email_group_details_error: null,
  create_email_group_loading: false,
  create_email_group: {},
  create_email_group_error: null,
  update_email_group_loading: false,
  update_email_group: {},
  update_email_group_error: null,
  delete_email_group_loading: false,
  delete_email_group: {},
  delete_email_group_error: null,
};

export const fetchAllEmailGroupList = createAsyncThunk(
  "emailGroup/fetchAllEmailGroupList",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(EMAIL_GROUP_LIST_ENDPOINT, {}, token);
      console.log("fetchAllEmailGroupList Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchEmailGroupById = createAsyncThunk(
  "emailGroup/fetchEmailGroupById",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        EMAIL_GROUP_DETAILS_ENDPOINT + "/" + id,
        {},
        token
      );
      console.log("fetchEmailGroupById Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const createEmailGroup = createAsyncThunk(
  "emailGroup/createEmailGroup",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        EMAIL_GROUP_LIST_ENDPOINT,
        JSON.stringify(data),
        token
      );
      console.log("createEmailGroup Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const updateEmailGroup = createAsyncThunk(
  "emailGroup/updateEmailGroup",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        EMAIL_GROUP_DETAILS_ENDPOINT + "/" + data.id,
        JSON.stringify(data.data),
        token
      );
      console.log("updateEmailGroup Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const deleteEmailGroup = createAsyncThunk(
  "emailGroup/deleteEmailGroup",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.remove(
        EMAIL_GROUP_DETAILS_ENDPOINT + "/" + id,
        JSON.stringify({ id: id }),
        token
      );
      console.log("deleteEmailGroup Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const EmailGroupSlice = createSlice({
  name: "emailGroup",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEmailGroupList.pending, (state) => {
        state.email_groups_loading = true;
      })
      .addCase(fetchAllEmailGroupList.fulfilled, (state, action) => {
        state.email_groups_loading = false;
        state.email_groups = action.payload;
      })
      .addCase(fetchAllEmailGroupList.rejected, (state, action) => {
        state.email_groups_loading = false;
        state.email_groups_error = action.error.message;
      })
      .addCase(fetchEmailGroupById.pending, (state) => {
        state.email_group_details_loading = true;
      })
      .addCase(fetchEmailGroupById.fulfilled, (state, action) => {
        state.email_group_details_loading = false;
        state.email_group_details = action.payload;
      })
      .addCase(fetchEmailGroupById.rejected, (state, action) => {
        state.email_group_details_loading = false;
        state.email_group_details_error = action.error.message;
      })
      .addCase(createEmailGroup.pending, (state) => {
        state.create_email_group_loading = true;
      })
      .addCase(createEmailGroup.fulfilled, (state, action) => {
        state.create_email_group_loading = false;
        state.create_email_group = action.payload;
      })
      .addCase(createEmailGroup.rejected, (state, action) => {
        state.create_email_group_loading = false;
        state.create_email_group_error = action.error.message;
      })
      .addCase(updateEmailGroup.pending, (state) => {
        state.update_email_group_loading = true;
      })
      .addCase(updateEmailGroup.fulfilled, (state, action) => {
        state.update_email_group_loading = false;
        state.update_email_group = action.payload;
      })
      .addCase(updateEmailGroup.rejected, (state, action) => {
        state.update_email_group_loading = false;
        state.update_email_group_error = action.error.message;
      })
      .addCase(deleteEmailGroup.pending, (state) => {
        state.delete_email_group_loading = true;
      })
      .addCase(deleteEmailGroup.fulfilled, (state, action) => {
        state.delete_email_group_loading = false;
        state.delete_email_group = action.payload;
      })
      .addCase(deleteEmailGroup.rejected, (state, action) => {
        state.delete_email_group_loading = false;
        state.delete_email_group_error = action.error.message;
      });
  },
});

const EmailGroupReducer = EmailGroupSlice.reducer;
export default EmailGroupReducer;
