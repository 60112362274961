import { useEffect, useMemo, useState, useCallback, useRef } from "react";
import qs from 'qs';
import { GetBSQCPeriod, GetBusServiceList } from "../../../services/feedbackOnBus";
import moment from "moment";
import { useLocation, useNavigate } from "react-router";

export const TABS = {
  selected: 'Selected Service',
  unselected: 'Unselected Service'
}

export const useSelectService = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const [isFetching, setIsFetching] = useState(true);
  const [bsqcPeriodPublish, setBsqcPeriodPublish] = useState([]);
  const [selectPeriodPublish, setSelectPeriodPublish] = useState({});
  const [tab, setTab] = useState(TABS.selected);
  const [list, setList] = useState([]);

  const { id: idBSQC, tab: tabActive } = qs.parse(params?.search?.replace('?', ''))

  useEffect(() => {
    const getListBSQCPeriod = async () => {
      const [err, response] = await GetBSQCPeriod({ is_published: true });

      if (!err && response) {
        const period = response?.data ?? [];

        if (period?.length) {
          const newList = period?.map(
            ({ name, pk, updated_on, end_date, start_date }) => ({
              label: `${name} - ${moment(updated_on).format('MMM DD YYYY hh:mm A')}`,
              id: pk,
              isSelected: true,
              data: { name, pk, end_date, start_date }
            })
          );
  
          setBsqcPeriodPublish(newList);
        }
      }

      setIsFetching(false);
    };

    getListBSQCPeriod();
  }, []);

  useEffect(
    () => {
      if (tabActive) setTab(tabActive)
    },
    [params?.search, tabActive]
  )

  const getSelectService = async () => {
    setIsFetching(true);

    const isSelected = tabActive === TABS.selected || tabActive === ""
    const { id } = qs.parse(params?.search?.replace('?', ''))

    if (id) {
      const [err, response] = await GetBusServiceList(id, isSelected)

      if (!err && response) {
        const newList = response?.data?.map(
          ({ is_am, ...rest }) => ({ ...rest, AmPm: is_am ? "AM" : "PM" })
        )
        setList(newList ?? []);
      }
    }
    
    setIsFetching(false);
  }

  const refetch = () => getSelectService();
  
  useEffect(() => {
    if (tab && params?.search) {
      getSelectService();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, params?.search])

  const onSelectPeriod = useCallback(
    ({ pk }) => {
      const findPeriod = bsqcPeriodPublish?.filter(
        ({ id }) => id === pk
      )?.[0];
  
      setSelectPeriodPublish(findPeriod);
      navigate(`/feedback-on-bus/selected-services?id=${findPeriod?.id}&tab=${tab}`)
    },
    [bsqcPeriodPublish, tab]
  )

  const id = useMemo(
    () => {
      const { id } = qs.parse(params?.search?.replace('?', ''))

      return id
    },
    [params?.search]
  )
  
  const onChangeTab = (tab) => {
    const findPeriod = bsqcPeriodPublish?.filter(
      ({ id }) => id === selectPeriodPublish?.id
    )[0];

    setTab(tab);
    navigate(`/feedback-on-bus/selected-services?id=${findPeriod?.id}&tab=${tab}`)
  }

  return {
    id,
    tab,
    list,
    bsqcPeriodPublish,
    selectPeriodPublish,
    isFetching,
    isAbleShowList: !!idBSQC,
    onSelectPeriod,
    setTab: onChangeTab,
    refetch
  }
}

export const useExport = (header = []) => {
  const ref = useRef();
  const _mounted = useRef(false);
  const params = useLocation();
  
  // Set Data Export
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();


  const getSelectService = async () => {
    // Query Search
    const { id, tab: tabActive } = qs.parse(params?.search?.replace('?', ''))
    const isSelected = tabActive === TABS.selected || tabActive === "";

    if (id) {
      const [err, response] = await GetBusServiceList(id, isSelected)

      if (!err && response) {
        const newList = response?.data?.map(
          ({ is_am, ...rest }) => ({ ...rest, AmPm: is_am ? "AM" : "PM" })
        )

        return newList
      }
    }

    return null
  }

  useEffect(() => {
    if (ref.current && _mounted.current) {
      ref.current.link.click();
    }

    if (!_mounted.current) _mounted.current = true
  }, [ref, data])

  const onExport = useCallback(
    async () => {
      setLoading(true);
      const data = await getSelectService();
      const { headerKey, headerCsv } = header.reduce(
        ({ headerKey, headerCsv } ,{ title, field }) => {
          return {
            headerKey: [...headerKey, field],
            headerCsv: [...headerCsv, title]
          }
        },
        {
          headerKey: [],
          headerCsv: []
        }
      );

      const rows = data?.map(
        (itm) => {
          return headerKey.reduce(
            (state, key, idx) => {
              state[idx] = itm[key];

              return state;
            },
            []
          )
        }
      )

      setData([headerCsv, ...rows])
      setLoading(false);
    },
    [header]
  );

  return {
    ref,
    data,
    loading,
    onExport
  }
}