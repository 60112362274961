import { useEffect, useMemo, useState } from "react";
import Modal from "../../../bus-checker-periods/partials/list-of-check-period/components/modal";
import {
  AssignChecker,
  GetListChecker,
} from "../../../../../services/feedbackOnBus";

import "./assigned-people.css";
import {
  checkPermission,
  getUserPermission,
} from "../../../../../config/permission_helper";

const TAB = {
  ASSIGNED: "assigned",
  UNASSIGNED: "unassigned",
};

const Component = (props) => {
  const [tab, setTab] = useState(TAB.UNASSIGNED);
  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingSubmit, setIsFetchingSubmit] = useState(false);
  const [availableChecker, setAvailableChecker] = useState([]);
  const [notAvailableChecker, setNotAvailableChecker] = useState([]);
  const [selected, setSelected] = useState([]);
  const [errorsFetch, setErrorsFetch] = useState({});

  const onChangeTab = (select) => setTab(select);

  useEffect(() => {
    if (props?.id && props?.isOpen) {
      const GetList = async (id) => {
        const [err, response] = await GetListChecker(id);

        if (!err && response) {
          const { available, notavailable } = response?.data;

          setAvailableChecker(available);
          setNotAvailableChecker(notavailable);

          setIsFetching(false);
        }
      };

      GetList(props?.id);
    }
  }, [props?.id, props?.isOpen]);

  const onSubmit = async () => {
    setIsFetchingSubmit(true);
    /**
     *     "id": "c2b90b43-a645-45ac-980e-a634113d7354",
            "sk": "busstop#c2b90b43-a645-45ac-980e-a634113d7354#26#80101#1#False",
            "user_id": "131066", // cognito username
            "name": "testing user"
     */
    const payloads = selected?.map((keyString) => {
      const data = keyString?.split(":");
      const user_name = data[0];
      const user_id = data[1];
      const sk = props?.sk;

      return { id: props?.id, user_name, user_id, sk };
    });

    const requests = payloads.map((payload) => AssignChecker(payload));

    const response = await Promise.all(requests);

    if (response) {
      let errors = {};

      response?.forEach(([err, _], id) => {
        const findId = selected?.filter((itm) => {
          const data = itm?.split(":");
          const idx = data[3];
          console.log("IDX: ", idx, id);

          return idx == id;
        })?.[0];

        const idSelect = findId?.split(":")?.[3];
        if (err) errors = { ...errors, [idSelect]: err };
      });

      if (Object.keys(errors)?.length) {
        setErrorsFetch(errors);

        setIsFetchingSubmit(false);

        return;
      }

      setIsFetchingSubmit(false);
      props?.onToggle();
    }
  };

  const list = useMemo(() => {
    if (tab === TAB.ASSIGNED) return notAvailableChecker;

    return availableChecker;
  }, [availableChecker, notAvailableChecker, tab]);

  return (
    <Modal
      width={450}
      height={250}
      isOpen={props?.isOpen}
      onClose={props?.onToggle}
      actionComponent={
        <>
          {checkPermission(
            getUserPermission(),
            "fob.bsqc_select_services_assign_people"
          ) && (
            <div
              style={{
                display: "flex",
                columnGap: 10,
                position: "absolute",
                right: 0,
              }}
            >
              <button onClick={onSubmit} className="button-submit">
                {isFetchingSubmit ? "Loading ..." : "SUBMIT"}
              </button>

              <button onClick={props?.onToggle} className="button-cancel">
                CANCEL
              </button>
            </div>
          )}
        </>
      }
    >
      <div className="assigned-people">
        <div className="tabs">
          <div
            onClick={() => onChangeTab(TAB.ASSIGNED)}
            className={`tab ${tab === TAB.ASSIGNED ? "active" : ""}`}
          >
            Assigned Checkers
          </div>
          <div className="divider"></div>
          <div
            onClick={() => onChangeTab(TAB.UNASSIGNED)}
            className={`tab ${tab === TAB.UNASSIGNED ? "active" : ""}`}
          >
            Unassigned Checkers
          </div>
        </div>

        {isFetching ? (
          <div>Loading ...</div>
        ) : (
          <div className="list-assigned">
            {!!list?.length &&
              list?.map(({ name, username, group_id }, idx) => (
                <div key={idx} className="form-select-group">
                  <input
                    value={`${name}:${username}:${group_id}:${idx}`}
                    type="checkbox"
                    checked={
                      selected[idx] === `${name}:${username}:${group_id}:${idx}`
                    }
                    onChange={(e) => {
                      setSelected((prev) => {
                        const findIdx = prev?.findIndex(
                          (itm) => itm === e.target.value
                        );

                        if (!e.target.checked && findIdx >= 0) {
                          return prev.filter((_, idx) => idx !== findIdx);
                        }

                        return [...prev, e.target.value];
                      });
                    }}
                  />
                  <span>{name}</span>
                  {errorsFetch?.[idx] && (
                    <span style={{ fontSize: 12, color: "red" }}>Failed</span>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Component;
