import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { TextBox } from "@progress/kendo-react-inputs";

import "./notification.css";
export const SearchMicImprovTypeSearch = (props) => {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [searchName, setSearchName] = useState("");
  useEffect(() => {
    if (query.get("name")) {
      setSearchName(query.get("name"));
    }
  }, []);
  const onhandleSearch = () => {
    const data = {
      name: searchName ? searchName : "",
    };
    console.log(data);
    props.onSearchcallback(data);
  };
  const handleSearchName = (e) => {
    setSearchName(e.target.value);
  };
  return (
    <>
      <Dialog
        className="search-notification"
        title={" "}
        // width={500}
        height={"100%"}
        onClose={() => props.setShowSearchDialog(false)}
      >
        <div className="search-body">
          <div className="noti-dialog-title dialog-row">Search</div>
          <div className="dialog-row">
            <span className="search-row-title">By Name</span>
            <TextBox
              className="search-input"
              value={searchName}
              onChange={handleSearchName}
              suffix={() => (
                <Button
                  className="clear-button-dialog"
                  onClick={() => setSearchName("")}
                >
                  X
                </Button>
              )}
            />
          </div>
        </div>
        <DialogActionsBar>
          <Button
            className="search-button cancel-btn"
            onClick={() => props.setShowSearchDialog(false)}
          >
            Cancel
          </Button>
          <Button className="search-button search-btn" onClick={onhandleSearch}>
            Submit
          </Button>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};
