import { Loader } from "@progress/kendo-react-indicators";
import { useCallback, useState, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";

import BackIcon from "../../../../../assets/communication/Back.svg";
import { useNavigate, useParams } from "react-router";
import { ADMIN_TOOLS_MIC_TYPE_OF_IMPROVEMENT } from "../../../../../routes";
import { TextBox } from "@progress/kendo-react-inputs";
import { CreateTypeOfImprovement, GetTypeImprovementByID, UpdateTypeOfImprovement } from "../../../../../services/ideas";

const Page = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.id;

  console.log('params: ', params);

  const [isFetching, setIsFetching] = useState(false);
  const [isErrorField, setIsErrorField] = useState(false);
  const [isFetchingUpdate, setIsFetchingUpdate] = useState(false);
  const [desc, setDesc] = useState('');

  useEffect(() => {
    const GetTypeOfImprovementDetail = async () => {
      setIsFetching(true);
      const [err, response] = await GetTypeImprovementByID(id);

      if (!err && response) {
        setDesc(response?.data?.desc ?? "")
      }

      setIsFetching(false);
    }

    if (id) GetTypeOfImprovementDetail();
  }, [id])

  const onSubmit = useCallback(
    async () => {
      setIsFetchingUpdate(true);

      const handler = id ? UpdateTypeOfImprovement(id, desc) : CreateTypeOfImprovement(desc)
      if (!desc) {
        setIsErrorField(true)

        return;
      }

      const [err, response] = await handler;

      if (!err && response) {
        navigate(ADMIN_TOOLS_MIC_TYPE_OF_IMPROVEMENT);
      }

      setIsFetchingUpdate(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [desc, id]
  )

  return (
    <>
      <div className="detail-body">
        {isFetching ? (
          <Loader
              type="infinite-spinner"
              style={{
                  display: "flex",
                  alignItems: "center",
                  width: "40px",
                  margin: "auto",
                  justifyContent: "center",
                  height: "100vh",
              }}
          />
        ) : (
          <>
            <div className="detail-header">
              <div className="categoryback-group-div" onClick={() => navigate(ADMIN_TOOLS_MIC_TYPE_OF_IMPROVEMENT)}>
                <img src={BackIcon} className="categoryback-icon" />
                <span className="categoryback-label">Back</span>
              </div>
            </div>
            <div className="detail-content">
              <table>
                <tr>
                  <td>
                    <div>Type Of Improvement</div>
                    {isErrorField && <span style={{ color: 'red', fontSize: 12 }}>this field is required</span>}
                  </td>
                  <td>
                    <TextBox defaultValue={desc} value={desc} onChange={(e) => setDesc(e?.target?.value)} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="detail-button-div">
                        <Button className="detail-button detail-cancel" onClick={() => navigate(ADMIN_TOOLS_MIC_TYPE_OF_IMPROVEMENT)}>Cancel</Button>
                        <Button disabled={isFetchingUpdate} className="detail-button detail-submit" onClick={onSubmit}>{isFetchingUpdate ? 'Loading ...' : 'Submit'}</Button>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Page;
  