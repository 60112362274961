import { ArrowRight, CaretLeft, X } from "@phosphor-icons/react";
import { Loader } from "@progress/kendo-react-indicators";
import { Switch } from "@progress/kendo-react-inputs";
import "./mic-details.css";
import { useNavigate } from "react-router-dom";
import { useComment, useMICDetails, useModal } from "./actions";
import { STATUS, VISIBILITY } from "../constant";

import PopupStatus from "../components/modal-status";
import PopupVisibility from "../components/modal-visibility";
import PopupDepartment from "../components/modal-department";
import { ToggleComment } from "../../../services/ideas";
import moment from "moment";
import { MICProvider, useMICContext } from "../mic/action";

import { MicImageDialog } from "../../../components/dialog/mic_image_dialog";
import { useEffect, useState } from "react";
import { getCurrentLoggedInUserWithPromise } from "../../../config/userPool";

const Component = () => {
  const { visible, onToggle } = useModal();
  const { visible: isOpenVisibility, onToggle: onToggleVisibility } =
    useModal();
  const { visible: isOpenDepartment, onToggle: onToggleDepartment } =
    useModal();
  const { masterData, isLoading } = useMICContext();
  const [clickedImage, setClickedImage] = useState({});
  const [showImageDialog, setShowImageDialog] = useState(false);
  const handleImageDialogClose = () => setShowImageDialog(false);

  const {
    data,
    activityLog,
    comments,
    isFetching,
    onUpdateData,
    onAppendNewComment,
    refreshComments,
    isCommentLoading,
  } = useMICDetails();
  console.log("MIC DETAILS Data:", data);
  const { comment, onChange, onAdd } = useComment({
    id: data?.pk,
    onSuccess: async ({ data: newCommentData, comment }) => {
      const currentUser = await getCurrentLoggedInUserWithPromise();
      const newData = {
        is_show: false,
        pk: newCommentData?.id,
        desc: comment,
        updated_on: moment().format("DD MMM YYYY, hh:mm A"),
        created_by: currentUser,
      };

      console.log("new: ", newData);

      onAppendNewComment(newData);
    },
  });
  const navigate = useNavigate();

  const onHideShowComment = async (payload = {}) => {
    await ToggleComment(payload);
    await refreshComments();
  };

  console.log("masterData: ", masterData);
  return (
    <>
      {showImageDialog && (
        <MicImageDialog
          image={clickedImage}
          onImageDialogClose={handleImageDialogClose}
        />
      )}
      <div className="wrapper_MIC">
        <div className="mic-page-content">
          <div className="mic-details__headers">
            <div
              className="router-back"
              onClick={() => {
                navigate("/mic");
              }}
            >
              <CaretLeft weight="bold" size={24} color="#333333" />
              <div className="router-back__label">Back</div>
            </div>
          </div>
          <div className="mic-details__body">
            {isFetching ? (
              <div className="loader-wrapper">
                <Loader size="large" type={"pulsing"} />
              </div>
            ) : (
              <>
                <div className="mic-details-info">
                  <div className="mic-details-info__item">
                    <div className="mic-details-info__desc">MIC ID</div>
                    <div className="mic-details-info__value">{data?.pk}</div>
                  </div>
                  <div className="mic-details-info__item">
                    <div className="mic-details-info__desc">Status</div>
                    <div className="mic-details-info__value">
                      {data?.status_str}
                    </div>
                  </div>
                  <div className="mic-details-info__item">
                    <div className="mic-details-info__desc">Date Submitted</div>
                    <div className="mic-details-info__value">
                      {data?.created_on}
                    </div>
                  </div>

                  <div className="mic-details-info__item">
                    <div className="mic-details-info__desc">Department</div>
                    <div className="mic-details-info__value">
                      {data?.group_str}
                    </div>
                  </div>

                  <div className="mic-details-info__item">
                    <div className="mic-details-info__desc">Name</div>
                    <div className="mic-details-info__value">
                      {data?.updated_by?.name}
                    </div>
                  </div>

                  <div className="mic-details-info__item">
                    <div className="mic-details-info__desc">SAP</div>
                    <div className="mic-details-info__value">
                      {data?.created_by_sap}
                    </div>
                  </div>

                  <div className="mic-details-info__item">
                    <div className="mic-details-info__desc">
                      Type of Improvement
                    </div>
                    <div className="mic-details-info__value">
                      {data?.type_of_improvement_str?.join(", ")}
                    </div>
                  </div>

                  <div className="mic-details-info__item">
                    <div className="mic-details-info__desc">Suggestion</div>
                    <div className="mic-details-info__value">{data?.desc}</div>
                  </div>

                  <div className="mic-details-info__item">
                    <div className="mic-details-info__desc">Attachment</div>
                    <div className="mic-details-info__value">
                      {data?.images?.map((x) => (
                        <img
                          src={x.s3_url ?? ""}
                          alt="attachment"
                          height={100}
                          style={{ paddingRight: "10px" }}
                          onClick={() => {
                            setClickedImage(x);
                            setShowImageDialog(true);
                          }}
                        />
                      ))}
                    </div>
                  </div>

                  <div className="mic-details-info__item">
                    <div className="mic-details-info__desc">No. of Likes</div>
                    <div className="mic-details-info__value">
                      {data?.total_liked}
                    </div>
                  </div>

                  <div className="mic-details-info__item">
                    <div className="mic-details-info__desc">Visibility</div>
                    <div className="mic-details-info__value">
                      {data?.is_visible ? "Published" : "Unpublished"}
                    </div>
                  </div>
                </div>
                <div className="mic-details-comments">
                  <div className="mic-details-comments__title">Comments</div>
                  <div className="mic-details-comments__comments">
                    {isCommentLoading ? (
                      <div className="loader-wrapper">
                        <Loader size="large" type={"pulsing"} />
                      </div>
                    ) : (
                      <div>
                        {!!(comments ?? []).length &&
                          (comments ?? [])?.map(
                            ({
                              desc,
                              updated_on,
                              created_by,
                              pk,
                              sk,
                              is_show,
                            }) => (
                              <div
                                className="comment-item"
                                key={Math.floor(Math.random() * 10000) + 1}
                              >
                                <div className="owner-name">
                                  {created_by?.name ?? ""}
                                </div>
                                <div className="date-time-comment">
                                  {updated_on}
                                </div>
                                <div className="date-time-content">{desc}</div>
                                <div className="toggle-comment">
                                  <Switch
                                    defaultChecked={is_show}
                                    onChange={async (selected) => {
                                      await onHideShowComment({
                                        id: pk,
                                        sk,
                                        is_show: selected?.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    )}

                    <div className="comment-add">
                      <div className="comment-add__label">Add Comment</div>
                      <input
                        value={comment}
                        onChange={onChange}
                        type="textbox"
                        className="comment-add__textbox"
                      />
                      <div className="comment-add__footer">
                        <button onClick={onAdd} className="button-add">
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mic-page-sidebar">
          <div className="card">
            <div className="content-label">Status</div>
            <div className="label-status">{data?.status_str}</div>
          </div>

          <div className="card">
            <div className="content-label">Actions</div>
            <div onClick={onToggle} className="label-action">
              Update Status{" "}
              <ArrowRight
                style={{ float: "right" }}
                color="#333333"
                size={18}
              />
            </div>
            <div onClick={onToggleDepartment} className="label-action">
              Route to Department{" "}
              <ArrowRight
                style={{ float: "right" }}
                color="#333333"
                size={18}
              />
            </div>
            <div onClick={onToggleVisibility} className="label-action">
              Update visibility{" "}
              <ArrowRight
                style={{ float: "right" }}
                color="#333333"
                size={18}
              />
            </div>
          </div>

          <div className="card">
            <div className="content-label">Activity</div>
            <ul class="StepProgress">
              {(activityLog ?? []).map(
                ({ created_by: { name }, pk, desc, changed_at }, idx) => (
                  <div
                    class={`StepProgress-item ${
                      activityLog?.length - 1 === idx ? "current" : "done"
                    } is-done`}
                  >
                    <strong>{desc}</strong>
                    <div className="username-action">by {name}</div>
                    <div>{changed_at}</div>
                  </div>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
      {visible && (
        <PopupStatus
          micId={data?.pk}
          sk={data?.sk}
          onToggle={onToggle}
          title={"Status"}
          list={STATUS}
          onSuccess={(newData) => {
            let newStatus = newData?.newStatus;
            let findStatus = STATUS.filter((x) => x.id === newStatus);
            if (findStatus.length > 0) {
              onUpdateData("status", { newStatus: findStatus[0].label });
              onUpdateData("status_str", findStatus[0].label);
            }
            onToggle();
          }}
          onFailed={() => {
            //
            onToggle();
          }}
        />
      )}
      {isOpenVisibility && (
        <PopupVisibility
          micId={data?.pk}
          sk={data?.sk}
          onToggle={onToggleVisibility}
          title={"Visibility"}
          list={VISIBILITY}
          onSuccess={(newData) => {
            let newVisibility = newData?.visibility;
            onUpdateData("is_visible", newVisibility);
            onToggleVisibility();
          }}
          onFailed={() => {
            // TODO: Snackbar Error
            onToggleVisibility();
          }}
        />
      )}
      {isOpenDepartment && (
        <PopupDepartment
          micId={data?.pk}
          sk={data?.sk}
          onToggle={onToggleDepartment}
          title={"Department"}
          list={(masterData?.category ?? []).map((item) => ({
            id: item?.pk,
            label: item?.desc,
          }))}
          onSuccess={(newData) => {
            onUpdateData("group_str", newData?.groupName);
            onToggleDepartment();
          }}
          onFailed={() => {
            // TODO: Snackbar Error
            onToggleDepartment();
          }}
        />
      )}
    </>
  );
};

const WrapperComponent = () => (
  <MICProvider>
    <Component />
  </MICProvider>
);

export default WrapperComponent;
