import { DialogActionsBar } from "@progress/kendo-react-dialogs";

import Drawer from "../../../../../components/drawer";
import Button from "../../../../../components/button";

import './filter.css';
import { CREATED_ON, STATUS } from "../../../constant";
import useAction from './action';

const Component = props => {
  const {
    status,
    date,
    createdOn,
    categories,
    onSubmit,
    onSelectCategory,
    onChangeCreatedOn,
    onChangeDate,
    onSelectStatus
  } = useAction({ onClose: props?.onClose });

  return (
    <Drawer
      width={350}
      onClose={props?.onClose}
      dialogComponents={() => (
        <DialogActionsBar>
          <Button onClick={props?.onClose}>Cancel</Button>
          <Button onClick={onSubmit} secondary>Submit</Button>
        </DialogActionsBar>
      )}
    >
      <div style={{ margin: 2 }}>
        <h2>Filters</h2>
        <div>
          <h3>Department</h3>
          <div>
            {props?.filter?.map(
              ({ pk, desc }) => (
                <div key={pk} className="row flex-left-center flex-column-gap mt-4">
                  <input
                    checked={categories.includes(pk)}
                    name={pk}
                    onChange={onSelectCategory}
                    type="checkbox"
                  />
                  <label>{desc ?? ""}</label>
                </div>
              )
            )}
          </div>
        </div>

        <div>
          <h3>Created On</h3>
          <div className="filter-date">
            <div className="filter-date__item">
              <input checked={createdOn === 'all'} type="radio" name={CREATED_ON?.ALL} onChange={onChangeCreatedOn} />
              <label>All dates</label>
            </div>
            <div className="filter-date__item">
              <input checked={createdOn === 'range'} type="radio" name={CREATED_ON?.RANGE} onChange={onChangeCreatedOn} />
              <label>Date range</label>
            </div>
          </div>
          {createdOn === CREATED_ON?.RANGE && (
            <div className="filter-date-byrange">
              <div className="filter-date-byrange__item">
                <div className="filter-date-byrange__label">Date From</div>
                <input
                  value={date?.startFrom}
                  type="date"
                  name="startFrom"
                  onChange={onChangeDate}
                  className="filter-date-byrange__input"
                />
              </div>
              <div className="filter-date-byrange__item">
                <div className="filter-date-byrange__label">Date From</div>
                <input
                  value={date?.startTo}
                  type="date"
                  name="startTo"
                  onChange={onChangeDate}
                  className="filter-date-byrange__input"
                />
              </div>
            </div>
          )}
        </div>

        <div>
          <h3>Status</h3>
          <div className="status-content">
            {STATUS.map(
              ({ id, label }) => (
                <div key={id} className="status-item">
                  <input
                    checked={status.includes(id)}
                    name={id}
                    value={id}
                    onChange={onSelectStatus}
                    type="checkbox"
                  />
                  <label className="label-group">{label}</label>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default Component;
