const config = {
  url:
    process.env.REACT_APP_URL ??
    "https://sbstilink-local-api.int.weeswares.com",
  api_endpoint: {
    CATEGORY_ENDPOINT: "/api-comm/category",
    LINK_TO_TYPE_ENDPOINT: "/api-comm/link-to",
    CHANNELS_ENDPOINT: "/api-comm/channel",
    DIVISION_ENDPOINT: "/api-comm/division",
    NOTIFICATION_ENDPOINT: "/api-comm/notification",
    UPLOAD_ENDPOINT: "/api-comm/upload",
    DEPOT_ENDPOINT: "/api-comm/depot",
    DEPOT_BY_DIVISION: "/api-comm/division-depot",
    INTERCHANGE_DEPOT_ENDPOINT: "/api-comm/depot-interchange",
    NOTIFICATION_RECIPIENT_ENDPOINT: "/api-comm/notification-recipient",
    GET_EMPLOYEE_INTERCHANGE_ENDPOINT: "/api-comm/employee",
    DESIGNATION_ENDPOINT: "/api-comm/designation",
    NOTIFICATION_ACTION_ENDPOINT: "/api-comm/actions",
    UPLOAD_RECIPIENTS_ENDPOINT: "/api-comm/upload-recipient",
    DR_SAFE_CONTACT_ENDPOINT: "/api-dr/dr-safe/contacts",
    DR_SAFE_INCIDENT_ENDPOINT: "/api-dr/dr-safe/incidents",
    DR_SAFE_GET_GROUPS: "/api-dr/dr-safe/incidents/route-groups",
    DR_SECURE_CONTACT_ENDPOINT: "/api-dr/dr-secure/contacts",
    DR_SECURE_INCIDENT_ENDPOINT: "/api-dr/dr-secure/incidents",
    DR_SECURE_GET_GROUPS: "/api-dr/dr-secure/incidents/route-groups",
    DR_INFRA_INCIDENT_ENDPOINT: "/api-dr/dr-infra/incidents",
    NOTIFICATION_OVERVIEW_ENDPOINT: "/api-comm/notification-recipient-summary",
    DR_INFRA_LOCATION_ENDPOINT: "/api-dr/dr-infra/web-locations",
    DR_INFRA_SYNC_LOCATION_ENDPOINT: "/api-dr/dr-infra/sync-locations",
    DR_INFRA_SUBSYSTEM_ENDPOINT: "/api-dr/dr-infra/web-sub-systems",
    DR_INFRA_SYNC_SUBSYSTEM_ENDPOINT: "/api-dr/dr-infra/sync-subsystems",
    DR_INFRA_SYSTEM_ENDPOINT: "/api-dr/dr-infra/web-systems",
    DR_INFRA_GET_GROUPS: "/api-dr/dr-infra/incidents/route-groups",
    EXPORT_NOTIFICATION_ENDPOINT: "/api-comm/notification-csv",
    AUDIT_NOTIFICATION_ENDPOINT: "/api-audit/audit",
    IFEEDBACK_ENDPOINT: "/api-ifeedback/ifeedback",
    EXPORT_IFEEDBACK_ENDPOINT: "/api-ifeedback/ifeedback-csv",
    IFEEDBACK_MATTER_ENDPOINT: "/api-ifeedback/matter",
    IFEEDBACK_MATTER_LIST_ENDPOINT: "/api-ifeedback/matter-list/",
    AUDIT_ENDPOINT: "/api-audit/audit",
    EXPORT_RECIPIENT_ENDPOINT: "/api-comm/recipient-csv",
    USER_PERMISSION_ENDPOINT: "/api-user/user-permissions",
    USER_BY_PERMISSION_ENDPOINT: "/api-user/user-by-permissions",
    USER_DEACTIVE_ACTIVATE_ENDPOINT: "/api-user/update_account_status",
    USER_GROUP_LIST_ENDPOINT: "/api-user/groups",
    ALL_PERMISSIONS_USER_MGMT_ENDPOINT: "/api-user/permissions",
    ALL_PERMISSIONS_USER_MGMT_BY_MODULES_ENDPOINT:
      "/api-user/permissions-by-modules",
    ALL_USERS_USER_MGMT_ENDPOINT: "/api-user/cog-users",
    SYNC_USERS_USER_MGMT_ENDPOINT: "/api-user/sync-users",
    EXPORT_USER_ENDPOINT: "/api-user/export-cog-users",
    TELEPONE_DIRECTORY_DEPARTMENT_ENDPOINT:
      "/api-telephone-directory/departments",
    TELEPONE_DIRECTORY_DEPOT_ENDPOINT: "/api-telephone-directory/depots",
    TELEPONE_DIRECTORY_SUB_DEPOT_ENDPOINT:
      "/api-telephone-directory/child-depots",
    TELEPONE_DIRECTORY_CONTACT_ENDPOINT: "/api-telephone-directory/contacts",
    ALL_COMM_ROLES_ENDPOINT: "/api-comm/role",
    ISAFE_ENDPOINT: "/api-isafe/safety-message",
    EMAIL_GROUP_LIST_ENDPOINT: "/api-fob/fob-email-groups",
    EMAIL_GROUP_DETAILS_ENDPOINT: "/api-fob/fob-email-group",
    EMAIL_TEMPLATE_LIST_ENDPOINT: "/api-fob/fob-email-templates",
    EMAIL_TEMPLATE_DETAILS_ENDPOINT: "/api-fob/fob-email-template",
    VERIFY_USER_ENDPOINT: "/api-user/auth-forgot-password",
    CHANGE_FORGOTPASSWORD_ENDPOINT: "/api-user/change-forgot-password",
    FEEDBACK_ON_BUS_SUMMARY_CHECKS_DETAIL_ENDPOINT:
      "/api-fob/fob-feedback-detail",
    FEEDBACK_ON_BUS_SUMMARY_CHECKS_DETAIL_ROUTEHISTORY_ENDPOINT:
      "/api-fob/fob-route-history",
    FEEDBACK_ON_BUS_EMAIL_ADDRESS_SEARCH_ENDPOINT: "/api-fob/fob-email-address",
    FEEDBACK_ON_BUS_SUMMARY_CHECK_ROUTE_EMAIL: "/api-fob/fob-route",
    FEEDBACK_ON_BUS_SUMMARY_CHECKS_ACKNOWLEDGE_ENDPOINT:
      "/api-fob/fob-feedback-acknowledge",
    FEEDBACK_ON_BUS_SUMMARY_CHECKS_LATEST_ACKNOWLEDGE_ENDPOINT:
      "/api-fob/fob-feedback-latest-acknowledge",
    FEEDBACK_ON_BUS_SUMMARY_CHECK_LIST: "/api-fob/fob-feedback-list",
    FEEDBACK_ON_BUS_SUMMARY_CHECKS_EXPORT: "/api-fob/fob-feedback-list-export",
    FEEDBACK_ON_BUS_UPDATE_STATUS: "/api-fob/fob-feedback-update-status",
    FOB_ASSIGNMENT_GET_ENDPOINT: "/api-fob/fob-assignment-list",
    FOB_ASSIGNMENT_PERIOD_ENDPOINT: "/api-fob/fob-period",
    FOB_ASSIGNMENT_ROADNAME_ENDPOINT:
      "/api-fob/fob-particular-assignment-busstop",
    FOB_ASSIGNMENT_CHECKER_ENDPOINT: "/api-fob/fob-all-checker",
    FOB_ASSIGNMENT_DELETE_ENDPOINT: "/api-fob/fob-assignment-list",
    FOB_ASSIGNMENT_CREATE_ENDPOINT: "/api-fob/fob-particular-assignment",
    FEEDBACK_ON_BUS_SUMMARY_CHECKS_ADD_UPDATE:"/api-fob/fob-feedback-addupdate",
    FEEDBACK_ON_BUS_SUMMARY_CHECKS_UPDATES_LIST:"/api-fob/fob-feedback-updates",
    FEEDBACK_ON_BUS_REPORT_LIST: "/api-fob/fob-report-list",
    FEEDBACK_ON_BUS_SUMMARY_CHECKS_ACKNOWLEDGE_LIST_ENDPOINT: "/api-fob/fob-feedback-acknowledge-list",
    FEEDBACK_ON_BUS_EXPORT_REPORT : "/api-fob/fob-export-report",
    COMM_RECIPIENT_GET_FILE_ENDPOINT: "/api-comm/get-comm-recipients-presigned-url"
  },
};

export default config;
