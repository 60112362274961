import React, { useEffect, useState } from "react";
import { TableHeader } from "../../components/table/Tableheader";
import {
  ADD_FEEDBACK_ON_BUS_ASSIGNMENT_LISTING,
  FEEDBACK_ON_BUS_ASSIGNMENT_LISTING,
} from "../../routes";
import { AssignmentTable } from "../../components/table/assignmentTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssignment } from "../../store/assignment";
import { Loader } from "@progress/kendo-react-indicators";
import { useLocation, useNavigate } from "react-router";
import { AssignmentTableFilter } from "../../components/dialog/assignmentTableFilter";
import { getCurrentLoggedInSessionToken } from "../../config/userPool";
import api from "../../config/api";
import config from "../../config/config";
import { Dialog } from "@progress/kendo-react-dialogs";

export const FOBAssignmentListing = () => {
  const dispatch = useDispatch();
  const [editFilter, setEditFilter] = useState({
    feedbackScheme: "",
    assigned_to: "",
    serviceNo: "",
    direction: "",
    busStopCode: "",
    assignedBC: "",
  });
  const [checker, setChecker] = useState([]);
  const [Mainloading, setMainLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [assignmentDelete, setAssignmentDelete] = useState({
    isDelete: false,
    id: "",
    sk: "",
  });

  const {
    FOB_ASSIGNMENT_CHECKER_ENDPOINT,
    FOB_ASSIGNMENT_GET_ENDPOINT,
    FOB_ASSIGNMENT_DELETE_ENDPOINT,
  } = config.api_endpoint;

  const { assignmentList, loading, next_key } = useSelector(
    (state) => state.assignmentList
  );

  const reloading_form = () => {
    console.log("reloading");
    dispatch(fetchAssignment());
    CallChecker();
  };

  const exportData = () => {
    console.log("exporting");
  };

  const CallChecker = async () => {
    setMainLoading(true);
    const token = getCurrentLoggedInSessionToken();
    const checkers = await api.get(FOB_ASSIGNMENT_CHECKER_ENDPOINT, {}, token);
    setChecker(checkers.data);
    setMainLoading(false);
  };

  useEffect(() => {
    dispatch(fetchAssignment());
    CallChecker();
  }, []);

  const DeleteCall = (val) => {
    setDeleteDialog(true);
    console.log(val);
    console.log(val.dataItem);
    setAssignmentDelete({
      ...assignmentDelete,
      isDelete: true,
      id: val.dataItem.pk,
      sk: val.dataItem.sk,
    });
  };

  const handleActionDropdownClicked = (event, val) => {
    console.log("handleActionDropdownClicked event:", event);
    console.log("handleActionDropdownClicked val:", val);
    if (event.value === "Delete") {
      DeleteCall(val);
    }
  };

  const onEditFilterChange = (type, value) => {
    setEditFilter({
      ...editFilter,
      [type]: value,
    });
  };

  const SubmitFilter = async () => {
    setMainLoading(true);
    setShowFilter(false);
    setFiltered(true);
    const token = getCurrentLoggedInSessionToken();
    let DataToFilter = {};
    if (editFilter.feedbackScheme !== "") {
      DataToFilter = {
        ...DataToFilter,
        scheme: editFilter.feedbackScheme,
      };
    }
    if (editFilter.assigned_to !== "") {
      DataToFilter = {
        ...DataToFilter,
        checker_name: editFilter.assigned_to,
      };
    }
    if (editFilter.serviceNo !== "") {
      DataToFilter = {
        ...DataToFilter,
        service_no: editFilter.serviceNo,
      };
    }
    if (editFilter.direction !== "") {
      DataToFilter = {
        ...DataToFilter,
        direction: editFilter.direction,
      };
    }
    if (editFilter.busStopCode !== "") {
      DataToFilter = {
        ...DataToFilter,
        bus_stop_code: editFilter.busStopCode,
      };
    }
    if (editFilter.assignedBC !== "") {
      DataToFilter = {
        ...DataToFilter,
        assigned_bc: editFilter.assignedBC,
      };
    }
    const result = await api.get(
      FOB_ASSIGNMENT_GET_ENDPOINT,
      DataToFilter,
      token
    );
    setFilteredData(result.data);
    setMainLoading(false);
    console.log(result);
  };

  const ClearFilter = () => {
    setEditFilter({
      ...editFilter,
      feedbackScheme: "",
      assigned_to: "",
      serviceNo: "",
      direction: "",
      busStopCode: "",
      assignedBC: "",
    });
    setFilteredData([]);
    setFiltered(false);
    setShowFilter(false);
    dispatch(fetchAssignment());
    CallChecker();
  };

  const submitDelete = async () => {
    setDeleteDialog(false);
    setMainLoading(true);
    const token = getCurrentLoggedInSessionToken();
    const result = await api.remove(
      FOB_ASSIGNMENT_DELETE_ENDPOINT,
      JSON.stringify({
        id: assignmentDelete.id,
        sk: assignmentDelete.sk,
      }),
      token
    );
    if (result.status === "success") {
      setAssignmentDelete({
        ...assignmentDelete,
        id: "",
        sk: "",
        isDelete: false,
      });
      dispatch(fetchAssignment());
      CallChecker();
    } else {
      alert("Error");
    }

    console.log(result);
  };

  return (
    <div className="body">
      {deleteDialog && (
        <Dialog>
          <div className="Assignment_delete_dialog_title">Delete!</div>
          <div className="Assignment_delete_dialog_text">
            Are you sure? Do you want to delete this assignment!
          </div>
          <div className="Assignment_delete_dialog_button_box">
            <div
              className="Assignment_delete_dialog_button_cancel"
              onClick={() => setDeleteDialog(false)}
            >
              Cancel
            </div>
            <div
              className="Assignment_delete_dialog_button_confirm"
              onClick={() => submitDelete()}
            >
              Confirm
            </div>
          </div>
        </Dialog>
      )}
      <TableHeader
        title="Assignment Listing"
        addurl={ADD_FEEDBACK_ON_BUS_ASSIGNMENT_LISTING}
        onFilter={() => setShowFilter(true)}
        onRefresh={() => reloading_form()}
        hideSearchBtn={true}
        // hideaddbtn
        showNotificationExport={false}
      />
      {showFilter && (
        <AssignmentTableFilter
          checker={checker}
          setShowFilter={setShowFilter}
          onEditFilterChange={onEditFilterChange}
          editFilter={editFilter}
          onSubmitFilter={SubmitFilter}
          ClearFilter={ClearFilter}
        />
      )}
      {loading || Mainloading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <AssignmentTable
          data={filtered ? filteredData : assignmentList}
          actionDropdownClicked={handleActionDropdownClicked}
          // onSortBy={(sortBy, orderBy) => handleSortAction(sortBy, orderBy)}
          // showUpdateStatusOption={showUpdateStatusButton}
        />
      )}
    </div>
  );
};
