import { useTable } from "react-table";
import { Popup } from "@progress/kendo-react-popup";
import { useRef, useState } from "react";

import "./custom-table.css";
import {
  checkPermission,
  getUserPermission,
} from "../../../../../config/permission_helper";

const EmptyState = () => (
  <tr>
    <td colSpan={16} style={{ textAlign: "center" }}>
      Empty data
    </td>
  </tr>
);

const Actions = (props) => {
  const anchor = useRef(null);
  const [show, setShow] = useState(false);

  const onClick = () => setShow((prev) => !prev);
  console.log(props);
  return (
    <>
      <td {...props?.cell.getCellProps()}>
        {!props.approve && (
          <button
            ref={anchor}
            onClick={onClick}
            className="button-add cursor-pointer"
          >
            Action
          </button>
        )}
      </td>

      <Popup
        style={{ width: 65 }}
        anchor={anchor.current}
        show={show}
        popupClass={"popup-content"}
      >
        {props?.actions ? (
          props?.actions?.map(({ permission, label, id }) => (
            <>
              {checkPermission(getUserPermission(), permission) && (
                <div
                  className="actions-table"
                  onClick={() => {
                    onClick();
                    props?.onAction(props?.data, id);
                  }}
                >
                  <div>{label}</div>
                </div>
              )}
            </>
          ))
        ) : (
          <>
            {checkPermission(getUserPermission(), props.permission) && (
              <div
                className="actions-table"
                onClick={() => {
                  onClick();
                  props?.onAction(props?.data, "edit");
                }}
              >
                <div>Edit</div>
              </div>
            )}
          </>
        )}
      </Popup>
    </>
  );
};

const Component = ({
  columns,
  data,
  onAction,
  actions,
  approve,
  permission,
}) => {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>
                {approve && column.Header === "Action"
                  ? null
                  : column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {!rows?.length && <EmptyState />}
        {!!rows.length &&
          rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const isActionButton = cell
                    .getCellProps()
                    ?.key?.includes("action");
                  if (isActionButton) {
                    return (
                      <Actions
                        permission={permission}
                        data={data[i]}
                        cell={cell}
                        approve={approve}
                        actions={actions}
                        onAction={onAction}
                      />
                    );
                  }
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default Component;
