import React from "react";
import { Dialog,DialogActionsBar } from "@progress/kendo-react-dialogs";

export const Confirmation = (props) => {
    return (
        <Dialog 
            title={props?.title ?? "Please confirm"} 
            onClose={props.onClickNo}
            >
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            {props?.message ?? 'Are you sure you want to continue?'}
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={props.onClickNo}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={props.onClickYes}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
    );
  };