import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { TableHeader } from "./Tableheader";
import { MatterTableBody } from "../../../../components/table/MatterTable";

import { Loader } from "@progress/kendo-react-indicators";
import { fetchAllMatters, deleteMatter } from "../../../../store/matter";
// import { fetchAllMatters,deleteMatter } from "../../../../store/matter";
import {
  ADMIN_TOOLS_MATTER_LIST,
  ADMIN_TOOLS_MATTER_ADD,
} from "../../../../routes";
// import { FilterMatter } from "../../../../components/dialog/matter_filter";
import { SearchMatter } from "../../../../components/dialog/matter_search";

import moment from "moment";
// permission
import {
  getUserPermission,
  checkMultiPermission,
  ADMIN_TOOLS_ADD_EDIT_MATTER,
} from "../../../../config/permission_helper";

export const MatterList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [matter_ids, setMatter_ids] = useState([]);
  const [all_matters, setAllMatter] = useState([]);
  const { loading, error } = useSelector((state) => state.matter);
  const [showSearchDialog, setShowSearchDialog] = useState(false);

  useEffect(() => {
    let results = all_matters;
    console.log(results);
    if (query.toString()) {
      if (query.get("description")) {
        results = results.filter(
          (v) =>
            v.description
              .toLowerCase()
              .indexOf(query.get("description").toLowerCase()) > -1
        );
      }
      if (query.get("created_by_name")) {
        results = results.filter(
          (v) =>
            v.created_by.name
              .toLowerCase()
              .indexOf(query.get("created_by_name").toLowerCase()) > -1
        );
      }
    }
    setMatter_ids(results);
  }, [all_matters]);

  useEffect(() => {
    dispatch(fetchAllMatters()).then((res) => {
      setAllMatter(res.payload);
    });
  }, []);
  const handleDeleteAction = (id) => {
    dispatch(deleteMatter(id)).then(() => {
      dispatch(fetchAllMatters()).then((res) => {
        setAllMatter(res.payload);
      });
    });
  };
  //   const handleFilterCallback = (data)=>{
  //     const {
  //       created_on_start,
  //       created_on_end,
  //       updated_on_start,
  //       updated_on_end,
  //     } = data;
  //     query.delete("filter");
  //     query.set("created_on_start",new Date(created_on_start)? created_on_start: "");
  //     query.set("created_on_end",new Date(created_on_end)? created_on_end: "");
  //     query.set("updated_on_start",new Date(updated_on_start)? updated_on_start: "");
  //     query.set("updated_on_end",new Date(updated_on_end)? updated_on_end: "");
  //     if(created_on_start || created_on_end || updated_on_start || updated_on_end){
  //       query.set("filter",true);
  //     }
  //     navigate(ADMIN_TOOLS_DIVISION_LIST+"?"+ query.toString());
  //     setShowFilterDialog(false);
  //     handleFilterAction();
  //   }
  const handleFilterAction = () => {
    let results = all_matters;

    if (query.toString()) {
      if (query.get("description")) {
        results = results.filter(
          (v) =>
            v.description
              .toLowerCase()
              .indexOf(query.get("description").toLowerCase()) > -1
        );
      }
      if (query.get("created_by_name")) {
        results = results.filter(
          (v) =>
            v.created_by.name
              .toLowerCase()
              .indexOf(query.get("created_by_name").toLowerCase()) > -1
        );
      }
    }
    setMatter_ids(results);
  };
  const handleSearchCallback = (data) => {
    const { name, created_by_name } = data;

    query.delete("search");
    query.set("description", name);
    query.set("created_by_name", created_by_name);
    if (name || created_by_name) {
      query.set("search", true);
    }
    navigate(ADMIN_TOOLS_MATTER_LIST + "?" + query.toString());
    setShowSearchDialog(false);
    handleFilterAction();
  };

  const handleClearSearchOptions = () => {
    query.delete("search");
    query.delete("description");
    query.delete("created_by_name");
    navigate(ADMIN_TOOLS_MATTER_LIST + "?" + query.toString());
    handleFilterAction();
  };
  // permission
  const shouldShowCreateNew = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, ADMIN_TOOLS_ADD_EDIT_MATTER)) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className="body">
      {/* {showFilterDialog && (
        <FilterDivision
          setShowFilterDialog={setShowFilterDialog}
          onFiltercallback={handleFilterCallback}          
        />
      )} */}
      {showSearchDialog && (
        <SearchMatter
          setShowSearchDialog={setShowSearchDialog}
          onSearchcallback={handleSearchCallback}
        />
      )}
      <TableHeader
        title="Manage Matter"
        loading={loading}
        onRefresh={() => {
          dispatch(fetchAllMatters()).then((res) => {
            setAllMatter(res.payload);
          });
        }}
        onSearch={() => setShowSearchDialog(true)}
        addurl={ADMIN_TOOLS_MATTER_ADD}
        showCreateNew={shouldShowCreateNew()}
      />
      {query.get("search") && (
        <div className="table-filter-div">
          {query.get("search") && (
            <div className="table-filter-item">
              <div className="table-filter-title">Keyword search applied</div>
              <span
                className="table-filter-clear"
                onClick={handleClearSearchOptions}
              >
                Clear search
              </span>
            </div>
          )}
        </div>
      )}
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <MatterTableBody
          data={matter_ids}
          handleDeleteAction={handleDeleteAction}
        />
      )}
    </div>
  );
};
