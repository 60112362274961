import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { CreateComment, GetIdeaByID } from "../../../services/ideas";
import { GetCommentsByIdeaID } from "../../../services/comments";
import { GetActivityLog } from "../../../services/audits";

export const useModal = () => {
  const [visible, setVisible] = useState(false);
  const toggleDialog = () => {
    setVisible(!visible);
  };

  return { visible, onToggle: toggleDialog };
};
export const useUpdateStatus = () => {
  const [selected, setSelected] = useState("");
  const [notes, setNotes] = useState("");

  const onSelect = (e) => setSelected(e?.target?.value);

  return { selected, notes, onSelect };
};

export const useComment = (props) => {
  const [comment, setComment] = useState("");

  const onChange = (e) => setComment(e?.target?.value);

  const onAdd = async () => {
    const [err, response] = await CreateComment(props?.id, comment);
    if (!err) {
      console.log("a: ", { ...response, comment });
      props?.onSuccess({ ...response, comment });
      setComment("");
    }
  };

  return { onChange, onAdd, comment };
};

export const useMICDetails = () => {
  const [data, setData] = useState({});
  const [activityLog, setActivityLog] = useState([]);
  const [comments, setComments] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isCommentLoading, setCommentLoading] = useState(false);
  const params = useParams();

  useEffect(() => {
    const getDetails = async () => {
      const [err, response] = await GetIdeaByID(params?.id);
      const [errComment, responseComment] = await GetCommentsByIdeaID(
        params?.id
      );
      const [errActivity, responseActivity] = await GetActivityLog(params?.id);

      console.log("responseComment: ", responseComment);
      if (!err) setData(response?.data);
      if (!errComment) setComments(responseComment?.data);
      if (!errActivity) setActivityLog(responseActivity?.data);

      setIsFetching(false);
    };

    getDetails();
  }, [params?.id]);

  const refreshComments = async () => {
    setCommentLoading(true);
    const [errComment, responseComment] = await GetCommentsByIdeaID(params?.id);
    if (!errComment) setComments(responseComment?.data);
    setCommentLoading(false);
  };

  const onUpdateData = async (targetName, value) => {
    setData((prev) => ({
      ...data,
      [targetName]: value,
    }));

    const [errActivity, responseActivity] = await GetActivityLog(params?.id);
    if (!errActivity) setActivityLog(responseActivity?.data);
  };

  const onAppendNewComment = async (newData) => {
    setComments((prev) => [newData, ...prev]);

    const [errActivity, responseActivity] = await GetActivityLog(params?.id);
    if (!errActivity) setActivityLog(responseActivity?.data);
  };

  return {
    data,
    activityLog,
    comments,
    isFetching,
    onUpdateData,
    onAppendNewComment,
    refreshComments,
    isCommentLoading,
  };
};
