import NavigateIcon from "../../../../assets/dr_safe/navigate.png";
// components
import IFeedbackDetailsStatusDialog from "./ifeedback_details_status_dialog";
// import DrSafeDetailsMarkAsDuplicateDialog from "./dr_safe_details_mark_as_duplicate_dialog";
import IFeedbackDetailsShareDialog from "./ifeedback_details_share_dialog";
import IFeedbackDetailsRouteToDepDialog from "./ifeedback_details_route_dialog";
import IFeedbackDetailsMatterDialog from "./ifeedback_details_matter_dialog";
import {
  getUserPermission,
  checkMultiPermission,
  IFEEDBACK_PERMISSIONS_FOR_UPDATE_STATUS,
  IFEEDBACK_PERMISSIONS_FOR_ROUTE_DEPARTMENT,
  IFEEDBACK_PERMISSIONS_FOR_SHARE,
  IFEEDBACK_PERMISSIONS_FOR_CHANGE_MATTER_TYPE
} from "../../../../config/permission_helper";

const shouldShowUpdateStatus = () => {
  const userPermissions = getUserPermission();
  if (checkMultiPermission(userPermissions, IFEEDBACK_PERMISSIONS_FOR_UPDATE_STATUS)) {
    return true;
  } else {
    return false;
  }
};

const shouldShowRouteDepartment = () => {
  const userPermissions = getUserPermission();
  if (checkMultiPermission(userPermissions, IFEEDBACK_PERMISSIONS_FOR_ROUTE_DEPARTMENT)) {
    return true;
  } else {
    return false;
  }
};

const shouldShowShare = () => {
  const userPermissions = getUserPermission();
  if (checkMultiPermission(userPermissions, IFEEDBACK_PERMISSIONS_FOR_SHARE)) {
    return true;
  } else {
    return false;
  }
};

const shouldShowChangeMatter = () => {
  const userPermissions = getUserPermission();
  if (checkMultiPermission(userPermissions, IFEEDBACK_PERMISSIONS_FOR_CHANGE_MATTER_TYPE)) {
    return true;
  } else {
    return false;
  }
};

export const IFeedbackDetailsActions = ({
  onToggleDialog,
  ifeedbackStatuses,
  currentiFeedbackStatus,
  onChangeiFeedbackStatus,
  onSaveiFeedbackStatus,
  showChangeStatusDialog,
  onUpdateStatusBtnClicked,
//   showChangeMarkAsDuplicateDialog,
//   onMarkAsDuplicateBtnClicked,
//   onSubmitMarkAsDuplicate,
//   parentCasesList,
//   currentParentCase,
//   onChangeParentCaseList,
  showChangeShareDialog,
  onShareBtnClicked,
  onSubmitShare,
  onRouteToDepClose,
  onRouteToDepOpen,
  onRouteToDepAction,
  showRouteToDepeDialog,
  showMatterDialog,
  currentiFeedbackMatter,
  onMatterDialogClose,
  onMatterDialogOpen,
  onMatterAction,
}) => {
  return (
    <div className="ifeedback-details-actions-wrapper">
      <div className="ifeedback-details-actions-title">Actions</div>

      <div className="ifeedback-details-actions-button-list">
        { shouldShowUpdateStatus() && (
        <div
          className="ifeedback-details-actions-button" onClick={onUpdateStatusBtnClicked} >
          <span>Update status</span> <img src={NavigateIcon} />
        </div>
        )}
        { shouldShowRouteDepartment() && (
        <div className="ifeedback-details-actions-button" onClick={onRouteToDepOpen} >
          <span>Route to other department</span> <img src={NavigateIcon} />
        </div>        
        )}
        { shouldShowChangeMatter() && (
        <div className="ifeedback-details-actions-button" onClick={onMatterDialogOpen} >
          <span>Change matter type</span> <img src={NavigateIcon} />
        </div>
        )}
        { shouldShowShare() && (
        <div className="ifeedback-details-actions-button" onClick={onShareBtnClicked} >
          <span>Share</span> <img src={NavigateIcon} />
        </div>
        )}
      </div>
      {showChangeStatusDialog && (
        <IFeedbackDetailsStatusDialog
          onToggleDialog={onToggleDialog}
          ifeedbackStatuses={ifeedbackStatuses}
          currentiFeedbackStatus={currentiFeedbackStatus}
          onChangeiFeedbackStatus={onChangeiFeedbackStatus}
          onSaveiFeedbackStatus={onSaveiFeedbackStatus}
        />
      )}

      {showMatterDialog && (
        <IFeedbackDetailsMatterDialog
          currentiFeedbackMatter={currentiFeedbackMatter}
          onMatterDialogClose={onMatterDialogClose}
          onMatterAction={onMatterAction}
        />
      )}

      {showChangeShareDialog && (
        <IFeedbackDetailsShareDialog
          onToggleDialog={onToggleDialog}
          onSubmitShare={onSubmitShare}
        />
      )}
      {showRouteToDepeDialog && (
        <IFeedbackDetailsRouteToDepDialog
        onRouteToDepClose={onRouteToDepClose}
        onRouteToDepAction={onRouteToDepAction}
        />
      )}
    </div>
  );
};
