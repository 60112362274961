import React, { useState, useEffect, useRef } from "react";
import {useDispatch} from "react-redux";
import { TextBox, RadioButton, InputPrefix } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import api from "../../../../config/api";
import config from "../../../../config/config";
import { UserListDialog } from "../../../../components/dialog/userListDialog";
import { fetchAllDepartments, fetchAllDepots, fetchAllSubDepots } from '../../../../store/telephoneDirectory';
import { TELEPHONE_DIRECTORY_CONTACT_LIST } from "../../../../routes";
import { useNavigate } from "react-router";
import "../../telephone_directory.css";

export const ContactForm = ({
    data,
    isEdit,
    isCreate,
    setIsEdit,
    submitForm
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [parentDepot, setParentDepot] = useState(null);
    const [childDepot, setChildDepot] = useState(null);
    const [department, setDepartment] = useState(null);
    const [contactName, setContactName] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [departmentsList, setDepartmentsList] = useState([]);
    const [parentDepotsList, setparentDepotsList] = useState([]);
    const [childDepotsList, setchildDepotsList] = useState([]);
    const [is_general, setIsGeneral] = useState(false);
    const [is_emergency, setIsEmergency] = useState(false);
    const [occ, setOCC] = useState(false);
    console.log('Contact Data--',data);
    useEffect(() => {
        if (data) {
            setParentDepot(data.depot);
            setChildDepot(data.child_depot);
            setDepartment(data.department);
            setPhoneNumber(data.contact_number);
            setContactName(data.contact_name);
            setIsEmergency(data.is_emergency);
            setIsGeneral(data.is_general);
            setOCC(data.occ);
        }
        dispatch(fetchAllDepartments()).then ((res) => {setDepartmentsList(res.payload)}).then
        (dispatch(fetchAllDepots()).then((res) =>{setparentDepotsList(res.payload)})).then
        (dispatch(fetchAllSubDepots()).then((res) => {setchildDepotsList(res.payload)}));
    }, [])

    const submitContact = () => {
        let data = {
            depot:parentDepot,
            child_depot:childDepot,
            department,
            contact_number:phoneNumber,
            contact_name:contactName,
            is_general,
            is_emergency,
            occ
            // user_list:users
        }
        submitForm(data)
    }
    const cancelForm = ()=>{
        if(data && isCreate){
            setIsEdit(false);
        }else{
            navigate(TELEPHONE_DIRECTORY_CONTACT_LIST);
        }
    }
    const handleEmergencySelected = (e) => {
        console.log('E-',e.value);
        setIsEmergency(e.value);
    }
    const handleGeneralSelected = (e) => {
        console.log('E-',e.value);
        setIsGeneral(e.value);
    }
    const handleOCCSelected = (e) => {
        console.log('E-',e.value);
        setOCC(e.value);
    }
    const usersSelectedCallback = (data) => {
        console.log("Call back---",data);
        // setUsers(data);
        // setShowUserList(false);
    }
    const updateParentDepot = (parentDepot) => {
        console.log("updateParentDepot", parentDepot);
        setParentDepot(parentDepot);
        (dispatch(fetchAllSubDepots({parentDepot})).then((res) => {setchildDepotsList(res.payload)}));
    }

    console.log('Selected User--',department)
    return (
        <div>
        <div className="details-info">
        {/* {showUserList && (
                <UserListDialog
                    callback={usersSelectedCallback}
                    defaultSelected={users}
                    onClickNo={() => setShowUserList(false)}
                />
            )} */}
            <div className="details-info-left">
                <div className="details-info__grp">
                    <div>Parent Depot<span style={{color:"red"}}>*</span></div>
                    <select  className='details-info--dropdown-value select-case-dropdown'
                    // onChange={(e)=> setParentDepot(e.target.value)}
                    onChange={(e)=> updateParentDepot(e.target.value)}
                        style={{
                            width: "100%",
                          }}>
                            {isCreate ? (<option key={'default'} value={''}>Select</option>):(<option key={'default'} value={parentDepot}>{parentDepot}</option>)}
                            {parentDepotsList ? parentDepotsList.map(
                                ({ id, depot }) => (
                                    <option key={id} value={depot}>{depot}</option>
                                )
                            ):null}
                        </select>
                </div>
                <div className="details-info__grp">
                    <div>Department <span style={{color:"red"}}>*</span></div>
                    <div>
                    <select  className='details-info--dropdown-value select-case-dropdown'
                    onChange={(e)=> setDepartment(e.target.value)}
                        style={{
                            width: "100%",
                          }}>
                            {isCreate ? (<option key={'default'} value={''}>Select</option>):(<option key={'default'} value={department}>{department}</option>)}
                            
                            {departmentsList ? departmentsList.map(
                                ({ id, department }) => (
                                    <option key={id} value={department}>{department}</option>
                                )
                            ): null}
                        </select>
                    
                    </div>
                </div>
                <div>
                <Checkbox value={is_emergency} label='Emergency' onChange={(e)=>handleEmergencySelected(e)} />
                </div>
                <div>
                <Checkbox value={is_general} label='General' onChange={(e)=>handleGeneralSelected(e)}  />
                </div>
                <div>
                <Checkbox value={occ} label='OCC' onChange={(e)=>handleOCCSelected(e)}  />
                </div>
            </div>
            <div className="details-info-right">
                <div className="details-info__grp">
                    <div>Sub Depot</div>
                    <select  className='details-info--dropdown-value select-case-dropdown'
                    onChange={(e)=> setChildDepot(e.target.value)}
                        style={{
                            width: "100%",
                          }}>
                            {isCreate ? (<option key={'default'} value={''}>Select</option>):(<option key={'default'} value={childDepot}>{childDepot}</option>)}
                            {childDepotsList ? childDepotsList.map(
                                ({ id, depot }) => (
                                    <option key={id} value={depot}>{depot}</option>
                                )
                            ):null}
                        </select>
                </div>
                
                <div className="details-info__grp">
                    <div>Contact Name <span style={{color:"red"}}>*</span></div>
                    <div>
                    <div><TextBox value={contactName} onChange={(e)=> setContactName(e.target.value)}/></div>
                    </div>
                </div>
                <div className="details-info__grp">
                    <div>Telephone Number <span style={{color:"red"}}>*</span></div>
                    <div>
                    <div><TextBox value={phoneNumber} onChange={(e)=> setPhoneNumber(e.target.value)}/></div>
                    </div>
                </div>
                
            </div>
            
        </div>
        <div>
                {isEdit && (
                    <div>
                        <div colSpan={2}>
                            <div className="detail-button-div">
                                <Button className="detail-button detail-cancel" onClick={cancelForm}>Cancel</Button>
                                <Button className="detail-button detail-submit" onClick={submitContact}>Submit</Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};