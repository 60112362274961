import { X } from "@phosphor-icons/react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const Component = props => {
  if (!props?.isOpen) return null;

  return (
    <Dialog
      width={props?.width ?? 1000}
      height={props?.height ?? 500}
    >
      <div style={{ float: 'right', cursor: 'pointer' }}>
        <X size={20} onClick={props?.onClose} />
      </div>
      {props?.children}
      <DialogActionsBar>
        <div style={{ position: 'relative', height: 40 }}>
          {props?.actionComponent}
        </div>
      </DialogActionsBar>
    </Dialog>
  )
}

export default Component;
