// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const { LINK_TO_TYPE_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: false,
  link_to_types: [],
  error: null,
};

export const fetchAllLinkToTypes = createAsyncThunk(
  "linkToType/fetchAllLinkToType",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(LINK_TO_TYPE_ENDPOINT, {}, token);
      // console.log(results);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const LinkToTypeSlice = createSlice({
  name: "link_to_type",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLinkToTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllLinkToTypes.fulfilled, (state, action) => {
        // console.log(action);
        state.loading = false;
        state.link_to_types = action.payload;
      })
      .addCase(fetchAllLinkToTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const LinkToTypeReducer = LinkToTypeSlice.reducer;
export default LinkToTypeReducer;
