import { Dialog } from "@progress/kendo-react-dialogs";
import Button from "../../../components/button";
import { X } from "@phosphor-icons/react";

import "../mic/mic.css";
import { useState } from "react";
import { UpdateStatus } from "../../../services/ideas";

const Component = (props) => {
  const [status, setStatus] = useState("");
  const [isFetch, setIsFetch] = useState(false);

  const onChangeStatus = (e) => {
    const value = e?.target?.value;

    setStatus(value);
  };

  const onSubmit = async () => {
    if (!status) return;

    try {
      setIsFetch(true);
      const payload = {
        id: props?.micId,
        sk: props?.sk,
        status,
      };

      const [err, response] = await UpdateStatus(payload);
      setIsFetch(false);

      if (err) props?.onFailed(err);
      else props?.onSuccess({ ...response, newStatus: status });
    } catch (err) {
      setIsFetch(true);

      props?.onFailed(err);
    }
  };

  const listOfDropdown = props?.list ?? [];

  return (
    <Dialog onClose={props?.onToggle}>
      <div className="mic-popup__wrapper">
        <div className="button-close-popup" onClick={props?.onToggle}>
          <X size={16} />
        </div>
        <div>
          <h3>{props?.title}</h3>

          <div className="dropdown">
            <div className="dropdown-label">
              <strong>Set Status</strong>
            </div>
            <select
              style={{ position: "relative", top: "unset", left: "unset" }}
              onChange={onChangeStatus}
              className="dropdown-select"
              name={props?.section}
              id="lang"
            >
              <option key={"default"} value={""}>
                Select
              </option>
              {listOfDropdown.map(({ id, label }) => (
                <option key={id} value={id}>
                  {label}
                </option>
              ))}
            </select>

            {/* <div className='dropdown-label'>
              <strong>Comment</strong>
            </div>
            <textarea className="input-area" placeholder={"Add comment"} /> */}

            <div className="footer-section">
              <Button disabled={isFetch} onClick={onSubmit}>
                {isFetch ? "Loading ..." : "Submit"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default Component;
