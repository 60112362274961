import { useNavigate, useLocation } from "react-router-dom";
const { client_id, pool_id, identityPoolId, region } = require("../service");
const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

export const poolData = {
  UserPoolId: pool_id, // Your user pool id here
  ClientId: client_id, // Your client id here
};

export const getPool = () => {
  return new AmazonCognitoIdentity.CognitoUserPool(poolData);
};

export const getAuthDetails = (username, password) => {
  return new AmazonCognitoIdentity.AuthenticationDetails({
    Username: username,
    Password: password,
  });
};
export const getCognitoUser = (username) => {
  var username = localStorage.getItem("username");
  var userData = {
    Username: username,
    Pool: getPool(),
  };
  return new AmazonCognitoIdentity.CognitoUser(userData);
};

export const signout = () => {
  const username = localStorage.getItem("username");
  var userData = {
    Username: username,
    Pool: getPool(),
  };
  var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  cognitoUser.signOut();
};
const userPool = getPool();

export const cognitoUser = userPool.getCurrentUser();

export const getCurrentLoggedInSessionToken = () => {
  const userPool = getPool();

  const cognitoUser = userPool.getCurrentUser();
  let CurrentidToken = null;
  // console.log(cognitoUser)
  if (cognitoUser != null) {
    CurrentidToken = cognitoUser.getSession(function (err, session) {
      if (err) {
        console.log(err.message);
      } else {
        var idToken = session.getIdToken().getJwtToken();
        // console.log(idToken);
        return idToken;
      }
    });
  } else {
    console.log("Session expired. Please log in again.");
  }
  return CurrentidToken;
};

export const checkTokenExpiry = () => {
  const userPool = getPool();
  const cognitoUser = userPool.getCurrentUser();
  let expiresIn = null;
  if (cognitoUser) {
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
        return null;
      }
      const currentTime = Math.floor(Date.now() / 1000);
      expiresIn = session.getAccessToken().getExpiration() - currentTime;
      console.log(`Access token expires in ${expiresIn} seconds`);
    });
  } else {
    console.log("No user found");
  }
  return expiresIn;
};

export const shouldRefresh = (expiry) => {
  // refresh if it's within 5 mins
  if (expiry < 300) {
    return true;
  }
  return false;
};

export const requrieRefresh = () => {
  const expiresIn = checkTokenExpiry();
  console.log("Access token expires in: ", expiresIn);
  if (expiresIn !== null) {
    const should_refresh = shouldRefresh(expiresIn);
    console.log("Should refresh access token: ", should_refresh);
    if (should_refresh) {
      return true;
    }
  }
  return false;
};

const doGetJWTIdToken = (callback) => {
  const userPool = getPool();
  const cognitoUser = userPool.getCurrentUser();

  cognitoUser.getSession((error, session) => {
    if (error) {
      callback(error, null);
    } else {
      if (requrieRefresh()) {
        cognitoUser.refreshSession(session.getRefreshToken(), (error) => {
          if (error) {
            callback(error, null);
          } else {
            const idToken = session.getIdToken().getJwtToken();
            callback(null, idToken);
          }
        });
      } else {
        const idToken = session.getIdToken().getJwtToken();
        callback(null, idToken);
      }
    }
  });
};

export const getCurrentLoggedInSessionTokenWithPromise = () => {
  return new Promise((resolve, reject) => {
    doGetJWTIdToken((error, token) => {
      if (error) {
        reject(error);
      } else {
        resolve(token);
      }
    });
  });
};

const doGetIdTokenPayload = (callback) => {
  const userPool = getPool();
  const cognitoUser = userPool.getCurrentUser();

  cognitoUser.getSession((error, session) => {
    if (error) {
      callback(error, null);
    } else {
      if (requrieRefresh()) {
        cognitoUser.refreshSession(session.getRefreshToken(), (error) => {
          if (error) {
            callback(error, null);
          } else {
            const idToken = session.getIdToken().payload;
            callback(null, idToken);
          }
        });
      } else {
        const idToken = session.getIdToken().payload;
        callback(null, idToken);
      }
    }
  });
};

export const getCurrentLoggedInUserWithPromise = () => {
  return new Promise((resolve, reject) => {
    doGetIdTokenPayload((error, token) => {
      if (error) {
        reject(error);
      } else {
        resolve(token);
      }
    });
  });
};

export const getCurrentUserDetails = () => {
  const userPool = getPool();
  const cognitoUser = userPool.getCurrentUser();
  let CurrentidToken = null;
  if (cognitoUser != null) {
    CurrentidToken = cognitoUser.getSession(function (err, session) {
      if (err) {
        console.log(err.message);
      } else {
        var idToken = session.getIdToken().payload;
        return idToken;
      }
    });
  } else {
    console.log("Session expired. Please log in again.");
  }
  return CurrentidToken;
};
