import { Loader } from "@progress/kendo-react-indicators";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";

import BackIcon from "../../../../../assets/communication/Back.svg";
import ArrorRightIcon from "../../../../../assets/common/arrao-right.svg";
import EditIcon from "../../../../../assets/common/edit_icon.svg";
import { useNavigate, useParams } from "react-router";
import { ADMIN_TOOLS_MIC_DEPARTMENT } from "../../../../../routes";
import { TextBox } from "@progress/kendo-react-inputs";
import {
  CreateDepartment,
  GetCategoryByID,
  UpdateDepartment,
  UpdateGroup,
} from "../../../../../services/ideas";
import "../../../admin_tools.css";
import { UserListDialog } from "../userListDialog";

const UserList = [
  { username: "131066", name: "iLink Admin" },
  { username: "81956", name: "Test" },
]; // mock data

const Page = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.id || "";

  const [isFetching, setIsFetching] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isErrorField, setIsErrorField] = useState(false);
  const [isFetchingUpdate, setIsFetchingUpdate] = useState(false);
  const [desc, setDesc] = useState("");
  const [selected, setSelected] = useState([]);
  const [showUserListDialog, setShowUserListDialog] = useState(false);

  const userSelectedCallback = (data) => {
    setSelected(data);
    setShowUserListDialog(false);
  };

  useEffect(() => {
    const GetDepartmentDetails = async (id) => {
      setIsFetching(true);
      const [err, response] = await GetCategoryByID(id);
      if (!err && response) {
        try {
          const usrs = JSON.parse(response?.data?.user_list) ?? [];
          setSelected(usrs);
          setDesc(response?.data?.desc ?? "");
          setIsFetching(false);
        } catch (err) {
          console.log("err:", err);
          setIsFetching(false);
        }
      }
    };

    if (id) GetDepartmentDetails(id);
  }, [id]);

  const onSubmit = useCallback(
    async () => {
      setIsFetchingUpdate(true);
      if (!desc) {
        setIsErrorField(true);

        setIsFetchingUpdate(false);
        return;
      }

      const handler = id
        ? UpdateDepartment(id, { desc, user_list: selected })
        : CreateDepartment(desc, selected);
      const [err, response] = await handler;

      if (!err && response) {
        navigate(ADMIN_TOOLS_MIC_DEPARTMENT);
      }

      setIsFetchingUpdate(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [desc, selected]
  );

  const isAbleToEdit = useMemo(() => {
    if (!id) return true; // Add mode

    return !!(id && isEdit);
  }, [id, isEdit]);

  return (
    <>
      {showUserListDialog && (
        <UserListDialog
          callback={userSelectedCallback}
          defaultSelected={selected}
          onClickNo={() => setShowUserListDialog(false)}
        />
      )}
      <div className="detail-body">
        {isFetching ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <>
            <div className="detail-header">
              <div
                className="categoryback-group-div"
                onClick={() => navigate(ADMIN_TOOLS_MIC_DEPARTMENT)}
              >
                <img src={BackIcon} className="categoryback-icon" />
                <span className="categoryback-label">Back</span>
              </div>
              {id && !isEdit && (
                <div>
                  <Button className="editBtn" onClick={() => setIsEdit(true)}>
                    <img src={EditIcon} style={{ verticalAlign: "middle" }} />
                    <span className="editBtn-label">Edit</span>
                    <img
                      src={ArrorRightIcon}
                      style={{ verticalAlign: "middle" }}
                    />
                  </Button>
                </div>
              )}
            </div>
            <div className="detail-content">
              <table>
                <thead></thead>
                <tbody>
                  <tr>
                    <td>
                      <div>Department Name</div>
                      {isErrorField && (
                        <span style={{ color: "red", fontSize: 12 }}>
                          this field is required
                        </span>
                      )}
                    </td>
                    <td>
                      <TextBox
                        disabled={!isAbleToEdit}
                        value={desc}
                        onChange={(e) => setDesc(e?.target?.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>User List</td>
                    <td>
                      <div className="feedback-email-group-users-edit-permission-layout">
                        {(!id || isEdit) && (
                          <Button onClick={() => setShowUserListDialog(true)}>
                            Click here to update the user(s)
                          </Button>
                        )}
                        {selected
                          ? selected.map((v) => (
                              <div className="feedback-email-group-users-permission-item">
                                {v.name}
                              </div>
                            ))
                          : null}
                      </div>
                    </td>
                  </tr>

                  {(!id || isEdit) && (
                    <tr>
                      <td colSpan={2}>
                        <div className="detail-button-div">
                          <Button
                            className="detail-button detail-cancel"
                            onClick={() => navigate(ADMIN_TOOLS_MIC_DEPARTMENT)}
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={isFetchingUpdate}
                            className="detail-button detail-submit"
                            onClick={onSubmit}
                          >
                            {isFetchingUpdate ? "Loading ..." : "Submit"}
                          </Button>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Page;
