import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import BusRouteIcon from "../../assets/communication/bus_route.svg";
import RosterIcon from "../../assets/communication/roster.svg";
import OperationIcon from "../../assets/communication/operation.svg";
import "./notification.css";
import { fetchAllCategories } from "../../store/category";
import { Loader } from "@progress/kendo-react-indicators";
import { RadioButton } from "@progress/kendo-react-inputs";
// import { DatePicker } from "@progress/kendo-react-dateinputs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CustomDatePicker } from "../custom/datepicker";

import moment from "moment/moment";

const all_or_date_range = [
  { value: "all", label: "All dates" },
  { value: "date", label: "Date range" },
];
const filter_status = [
  { value: "published", label: "Published" },
  { value: "scheduled", label: "Scheduled" },
  { value: "draft", label: "Draft" },
  { value: "pendingapproval", label: "Pending Approval" },
];
const acknowledge_status = [
  { value: "required", label: "Yes" },
  { value: "not_required", label: "No" },
];

export const FilterNotification = (props) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const [selected_category_ids, setSelectedCategoryIds] = useState([]);
  const [selectedScheduleDateOption, setSelectedScheduleDateOption] =
    useState("all");
  const [selectedCreatedDateOption, setSelectedCreatedDateOption] =
    useState("all");

  const [CreatedDateFrom, setCreatedDateFrom] = useState(null);
  const [CreatedDateTo, setCreatedDateTo] = useState(null);
  const [ScheduledDateFrom, setScheduledDateFrom] = useState(null);
  const [ScheduledDateTo, setScheduledDateTo] = useState(null);
  const [selectedack, setSelectedAck] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const { category_ids, loading: category_loading } = useSelector(
    (state) => state.category
  );

  useEffect(() => {
    dispatch(fetchAllCategories(query));
    if (query.toString()) {
      if (query.get("category_id")) {
        setSelectedCategoryIds(JSON.parse(query.get("category_id")));
      } else if (query.get("is_all_category")) {
        setSelectedCategoryIds(category_ids.map((v) => v.id));
      }
      if (query.get("ack_required")) {
        const ack_required = query.get("ack_required");
        console.log(ack_required);
        setSelectedAck(
          ack_required === "True" ? ["required"] : ["not_required"]
        );
      } else if (query.get("is_all_ack")) {
        console.log(query.get("is_all_ack"));
      }
      if (query.get("start_date")) {
        setScheduledDateFrom(new Date(query.get("start_date")));
      }
      if (query.get("end_date")) {
        setScheduledDateTo(new Date(query.get("end_date")));
      }
      if (query.get("start_date") || query.get("end_date")) {
        setSelectedScheduleDateOption("date");
      }
      if (query.get("created_on_start")) {
        setCreatedDateFrom(new Date(query.get("created_on_start")));
      }
      if (query.get("created_on_end")) {
        setCreatedDateTo(new Date(query.get("created_on_end")));
      }
      if (query.get("created_on_start") || query.get("created_on_end")) {
        setSelectedCreatedDateOption("date");
      }
      if (query.get("status")) {
        setSelectedStatus(JSON.parse(query.get("status")));
      } else if (query.get("is_all_status")) {
        setSelectedStatus(filter_status.map((v) => v.value));
      }
    }
  }, []);
  const handleCategorySelected = (id) => {
    var updatedList = [...selected_category_ids];
    console.log(updatedList);
    if (!selected_category_ids.includes(id)) {
      updatedList = [...selected_category_ids, id];
    } else {
      var selected_index = selected_category_ids.indexOf(id);
      updatedList.splice(selected_index, 1);
    }
    console.log(updatedList);
    setSelectedCategoryIds(updatedList);
  };
  const handleScheduleDateOptionChange = (e) => {
    setSelectedScheduleDateOption(e.value);
  };
  const handleCreatedDateOptionChange = (e) => {
    setSelectedCreatedDateOption(e.value);
  };
  const isIdSelected = (item) =>
    selected_category_ids.includes(item) ? true : false;

  const handleclearfilter = () => {
    setSelectedCategoryIds([]);
    setSelectedStatus([]);
    setSelectedAck([]);
    setSelectedCreatedDateOption("all");
    setSelectedScheduleDateOption("all");
    setScheduledDateFrom(null);
    setScheduledDateTo(null);
    setCreatedDateFrom(null);
    setCreatedDateTo(null);
  };
  const onSubmitFilter = () => {
    console.log(selectedStatus);
    let data = {
      category_id:
        selected_category_ids.length === category_ids.length ||
        selected_category_ids.length === 0
          ? ""
          : JSON.stringify(selected_category_ids),
      is_all_category:
        selected_category_ids.length === category_ids.length ? true : "",
      start_date:
        selectedScheduleDateOption === "all"
          ? ""
          : moment(ScheduledDateFrom).format("YYYY-MM-DD"),
      end_date:
        selectedScheduleDateOption === "all"
          ? ""
          : moment(ScheduledDateTo).format("YYYY-MM-DD"),
      created_on_start:
        selectedCreatedDateOption === "all"
          ? ""
          : moment(CreatedDateFrom).format("YYYY-MM-DD"),
      created_on_end:
        selectedCreatedDateOption === "all"
          ? ""
          : moment(CreatedDateTo).format("YYYY-MM-DD"),
      ack_required:
        selectedack.length === acknowledge_status.length ||
        selectedack.length === 0
          ? ""
          : selectedack.includes("required")
          ? "True"
          : "False",
      is_all_ack: selectedack.length === acknowledge_status.length ? true : "",
      status:
        selectedStatus.length === 0 ||
        filter_status.length === selectedStatus.length
          ? ""
          : JSON.stringify(selectedStatus),
      is_all_status: selectedStatus.length === filter_status.length ? true : "",
    };
    console.log(data);
    props.onFiltercallback(data);
  };
  const handleStatusCheck = (e, value) => {
    var updatedList = [...selectedStatus];
    if (e.target.value) {
      updatedList = [...selectedStatus, value];
    } else {
      var selected_index = selectedStatus.indexOf(value);
      updatedList.splice(selected_index, 1);
    }
    setSelectedStatus(updatedList);
  };
  const isStatusChecked = (item) =>
    selectedStatus.includes(item) ? true : false;

  const handleAckCheck = (e, value) => {
    var updatedList = [...selectedack];
    if (e.target.value) {
      updatedList = [...selectedack, value];
    } else {
      var selected_index = selectedack.indexOf(value);
      updatedList.splice(selected_index, 1);
    }
    setSelectedAck(updatedList);
  };
  const isAckChecked = (item) => (selectedack.includes(item) ? true : false);
  return (
    <>
      <Dialog
        className="search-notification"
        title={" "}
        width={395}
        height={"100%"}
        onClose={() => props.setShowFilterDialog(false)}
      >
        <div className="search-body">
          <div className="filter-title">
            <div className="title">Filter</div>
            <div className="clear-filter" onClick={handleclearfilter}>
              <u>Clear Filter</u>
            </div>
          </div>
          {category_loading ? (
            <Loader
              type="infinite-spinner"
              style={{
                display: "flex",
                alignItems: "center",
                width: "40px",
                margin: "auto",
                justifyContent: "center",
                height: "100vh",
              }}
            />
          ) : (
            <div className="filter-body">
              <p className="filter-sub-title">Category</p>
              <ul className="filter-category-group">
                {category_ids.map((v) => (
                  <li
                    style={
                      isIdSelected(v.id)
                        ? { border: "2px solid #781D7E" }
                        : null
                    }
                    className="filter-category-item"
                    onClick={() => handleCategorySelected(v.id)}
                  >
                    <div>
                      <img src={v.icon_s3_link} width={36} height={36} />
                    </div>
                    <div className="category-name">{v.name}</div>
                  </li>
                ))}
              </ul>
              <p className="filter-sub-title">Scheduled / Published On</p>
              <div className="category-flex-row">
                {all_or_date_range.map((v) => (
                  <div className="date-option-div">
                    <RadioButton
                      className="filter-option-input"
                      value={v.value}
                      key={v.value}
                      label={v.label}
                      onChange={(e) => handleScheduleDateOptionChange(e)}
                      checked={selectedScheduleDateOption === v.value}
                    />
                  </div>
                ))}
              </div>
              {selectedScheduleDateOption !== "all" && (
                <div className="category-flex-row">
                  <div className="category-date-div">
                    <div>Date From</div>
                    <DatePicker
                      className="filter-date-range"
                      format={"dd MMM yyyy"}
                      selected={ScheduledDateFrom}
                      onChange={(date) => setScheduledDateFrom(date)}
                      customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                    />
                  </div>
                  <div className="category-date-div">
                    <div>Date To</div>
                    <DatePicker
                      className="filter-date-range"
                      format={"dd MMM yyyy"}
                      selected={ScheduledDateTo}
                      onChange={(date) => setScheduledDateTo(date)}
                      customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                    />
                  </div>
                </div>
              )}
              <p className="filter-sub-title">Acknowledgement Required</p>
              <div className="category-flex-row">
                {acknowledge_status.map((v) => (
                  <div className="date-option-div">
                    <Checkbox
                      style={{ border: "1px solid #CCC" }}
                      key={v.value}
                      value={v.value}
                      onChange={(e) => handleAckCheck(e, v.value)}
                      checked={isAckChecked(v.value)}
                    />
                    <label>{v.label}</label>
                  </div>
                ))}
              </div>
              <p className="filter-sub-title">Created On</p>
              <div className="category-flex-row">
                {all_or_date_range.map((v) => (
                  <div className="date-option-div">
                    <RadioButton
                      className="filter-option-input"
                      value={v.value}
                      key={v.value}
                      label={v.label}
                      onChange={(e) => handleCreatedDateOptionChange(e)}
                      checked={selectedCreatedDateOption === v.value}
                    />
                  </div>
                ))}
              </div>
              {selectedCreatedDateOption !== "all" && (
                <div className="category-flex-row">
                  <div className="category-date-div">
                    <div>Date From</div>
                    <DatePicker
                      className="filter-date-range"
                      format={"dd MMM yyyy"}
                      selected={CreatedDateFrom}
                      onChange={(date) => setCreatedDateFrom(date)}
                      customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                    />
                  </div>
                  <div className="category-date-div">
                    <div>Date To</div>
                    <DatePicker
                      selected={CreatedDateTo}
                      onChange={(date) => setCreatedDateTo(date)}
                      format={"dd MMM yyyy"}
                      customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                    />
                  </div>
                </div>
              )}
              <p className="filter-sub-title">Status</p>
              <ul className="filter-status-group">
                {filter_status.map((v) => (
                  <li className="filter-status-item">
                    <Checkbox
                      style={{ border: "1px solid #CCC" }}
                      key={v.value}
                      value={v.value}
                      onChange={(e) => handleStatusCheck(e, v.value)}
                      checked={isStatusChecked(v.value)}
                    />
                    <label>{v.label}</label>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <DialogActionsBar>
          <Button
            className="search-button cancel-btn"
            onClick={() => props.setShowFilterDialog(false)}
          >
            Cancel
          </Button>
          <Button
            className="search-button search-btn"
            onClick={() => onSubmitFilter()}
          >
            Submit
          </Button>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};
